import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import React from 'react';

import {
  CalendarStarIconSvg,
  FileDocumentIconSvg,
  ThumbUpIconSvg
} from '../../utils/MaterialIconSvg';
import Button from '../Button';
import Color from '../../constants/Color';
import Column from '../Column';
import Container from '../Container';
import Headline from '../Headline';
import Image from '../Image';
import Paragraph from '../Paragraph';
import SeparatedCard from '../Card/SeparatedCard';
import Text from '../Text';
import View from '../View';

export default class FeatureContent extends React.Component {
  render() {
    const { style = {} } = this.props;

    return (
      <View style={{ position: 'relative', ...style }}>
        <View
          style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <Image
            source={require('../../assets/img/mountain-large.jpg')}
            style={{ width: '100%' }}
          />
          <View
            style={{
              backgroundColor: Color.navyBlack,
              height: '100%'
            }}
          />
        </View>
        <View style={{ paddingBottom: 96 }}>
          <Container>
            <View style={{ padding: '96px 16px 0 16px' }}>
              <Headline
                size={2}
                style={{
                  fontSize: 24,
                  color: Color.white,
                  textShadow: `${Color.black} 0 2px 4px`,
                  textAlign: 'center',
                  marginBottom: 48
                }}
              >
                VISIONSの特徴
              </Headline>
            </View>
            <Row>
              <Column
                xs={4}
                containerStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1
                }}
                style={{ flex: 1 }}
              >
                <SeparatedCard
                  head={
                    <View
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '24px 0'
                      }}
                    >
                      <ThumbUpIconSvg
                        fill={Color.lightSlate}
                        width={96}
                        height={96}
                        viewBox="0 0 24 24"
                        style={{ marginBottom: 16 }}
                      />
                      <Text
                        style={{
                          color: Color.lightSlate,
                          fontSize: 17,
                          fontWeight: 'bold'
                        }}
                      >
                        手数料が少ない
                      </Text>
                    </View>
                  }
                  body={
                    <Paragraph
                      style={{ fontSize: 13 }}
                      containerStyle={{ padding: 24 }}
                    >
                      決済手数料3.6%と振込手数料以外は一切かかりません。10〜20%を手数料とする他のプラットフォームに比べて、とても快適にご利用いただけます。
                    </Paragraph>
                  }
                  bodyStyle={{ flex: 1 }}
                  style={{ flex: 1 }}
                />
              </Column>
              <Column
                xs={4}
                containerStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1
                }}
                style={{ flex: 1 }}
              >
                <SeparatedCard
                  head={
                    <View
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '24px 0'
                      }}
                    >
                      <FileDocumentIconSvg
                        fill={Color.lightSlate}
                        width={96}
                        height={96}
                        viewBox="0 0 24 24"
                        style={{ marginBottom: 16 }}
                      />
                      <Text
                        style={{
                          color: Color.lightSlate,
                          fontSize: 17,
                          fontWeight: 'bold'
                        }}
                      >
                        かんたん事業計画機能
                      </Text>
                    </View>
                  }
                  body={
                    <View style={{ padding: 24 }}>
                      <Paragraph
                        style={{ fontSize: 13 }}
                        containerStyle={{ marginBottom: 16 }}
                      >
                        フォームに入力するだけで、あなたのプロジェクトの事業計画書をA3サイズのPDFで生成します。プロジェクトの内容を明瞭にしましょう。
                      </Paragraph>
                      <Link to="/proposal">
                        <Button mini>事業計画書を作る</Button>
                      </Link>
                    </View>
                  }
                  bodyStyle={{ flex: 1 }}
                  style={{ flex: 1 }}
                />
              </Column>
              <Column
                xs={4}
                containerStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1
                }}
                style={{ flex: 1 }}
              >
                <SeparatedCard
                  head={
                    <View
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '24px 0'
                      }}
                    >
                      <CalendarStarIconSvg
                        fill={Color.lightSlate}
                        width={96}
                        height={96}
                        viewBox="0 0 24 24"
                        style={{ marginBottom: 16 }}
                      />
                      <Text
                        style={{
                          color: Color.lightSlate,
                          fontSize: 17,
                          fontWeight: 'bold'
                        }}
                      >
                        月額課金機能
                      </Text>
                    </View>
                  }
                  body={
                    <Paragraph
                      style={{ fontSize: 13 }}
                      containerStyle={{ padding: 24 }}
                    >
                      プロジェクトの事業主（ファウンダー）は、月額のサブスクリプション機能を利用することができます。サロン運営などのに活用できます。
                    </Paragraph>
                  }
                  bodyStyle={{ flex: 1 }}
                  style={{ flex: 1 }}
                />
              </Column>
            </Row>
          </Container>
        </View>
      </View>
    );
  }
}
