export default class FundingResult {
  _status = 'funding'; // success || funding || failed

  constructor(currentFundingPrice, fundingGoalPrice, deadline) {
    if (currentFundingPrice > fundingGoalPrice) {
      this._status = 'success';
    } else if (!deadline.isFinished) {
      this._status = 'funding';
    } else {
      this._status = 'failed';
    }
  }

  get status() {
    return this._status;
  }
}
