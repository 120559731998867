import * as types from '../constants/ActionTypes';

export function updateProposalFormParams({
  proposal,
  viewPassword,
  editPassword
}) {
  return {
    type: types.UPDATE_PROPOSAL_FORM_PARAMS,
    state: {
      params: {
        proposal,
        viewPassword,
        editPassword
      }
    }
  };
}
