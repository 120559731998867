import React from 'react';

import { validate } from '../../utils/Validation';
import Color from '../../constants/Color';
import Hr from '../Hr';
import MaterialIcon from '../../utils/MaterialIcon';
import Panel from '../Panel';
import Paragraph from '../Paragraph';
import SectionCard from '../Card/SectionCard';
import Text from '../Text';
import View from '../View';

export default class ProposalInputSectionCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.defaultOpen || false
    };
  }

  render() {
    const {
      title,
      number,
      description,
      labels,
      rule,
      properties,
      children,
      style = {}
    } = this.props;

    const correctCount = Object.keys(properties).reduce((acc, key) => {
      if (
        validate({
          label: labels[key],
          value: properties[key],
          rule: rule[key]
        })
      ) {
        return acc;
      }

      return acc + 1;
    }, 0);

    const propertyNum = Object.keys(rule).length;

    let leastDescription;

    if (correctCount === 0) {
      leastDescription = '未入力';
    } else if (correctCount >= propertyNum) {
      leastDescription = '完了';
    } else {
      leastDescription = `${correctCount}/${propertyNum} 入力完了`;
    }

    return (
      <SectionCard
        title={title}
        panel={
          <Panel size={40} style={{ backgroundColor: Color.pink }}>
            <Text
              style={{
                color: Color.white,
                fontWeight: 'bold',
                fontSize: 24,
                lineHeight: 1
              }}
            >
              {number}
            </Text>
          </Panel>
        }
        after={
          <View
            onClick={() => {
              this.setState({ isOpen: !this.state.isOpen });
            }}
          >
            <Hr style={{ margin: '0 8px' }} />
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: 16
              }}
            >
              <View />
              <View>
                <View style={{ flexDirection: 'row' }}>
                  <MaterialIcon
                    icon="check_circle"
                    color={
                      correctCount >= propertyNum ? Color.green : Color.warmGrey
                    }
                    size={20}
                  />
                  <Text
                    style={{
                      color: Color.slate,
                      fontSize: 13,
                      fontWeight: 'bold',
                      marginLeft: 8
                    }}
                  >
                    {!this.state.isOpen ? '入力する' : '閉じる'} (
                    {leastDescription})
                  </Text>
                </View>
              </View>
              <View>
                <MaterialIcon
                  icon={
                    !this.state.isOpen
                      ? 'keyboard_arrow_down'
                      : 'keyboard_arrow_up'
                  }
                  color={Color.slate}
                  size={20}
                />
              </View>
            </View>
          </View>
        }
        style={style}
      >
        <Paragraph>{description}</Paragraph>
        {this.state.isOpen && <View style={{ marginTop: 16 }}>{children}</View>}
      </SectionCard>
    );
  }
}
