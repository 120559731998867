import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../components/Button';
import Headline from '../../components/Headline';
import Paragraph from '../../components/Paragraph';
import RegisterModal from '../RegisterModal';
import SupportModal from '../SupportModal';
import View from '../../components/View';
import actions from '../../actions';

class DonateButton extends React.Component {
  state = {
    isOpenSupportModal: false,
    isOpenRegisterModal: false
  };

  render() {
    const { data, style = {} } = this.props;

    return (
      <View style={style}>
        <Button
          onClick={() => {
            if (this.props.auth.uid) {
              this.setState({ isOpenSupportModal: true });
            } else {
              this.setState({ isOpenRegisterModal: true });
            }
          }}
        >
          支援する
        </Button>
        <SupportModal
          dreamId={data.id}
          visible={this.state.isOpenSupportModal}
          onClose={() => this.setState({ isOpenSupportModal: false })}
        />
        <RegisterModal
          visible={this.state.isOpenRegisterModal}
          onClose={() => this.setState({ isOpenRegisterModal: false })}
          content={
            <View style={{ marginBottom: 8 }}>
              <Headline size={3} style={{ marginBottom: 16 }}>
                VISIONSをご利用になるために
              </Headline>
              <Paragraph>
                VISIONSの各機能をご利用になるためには、新規登録/ログインをする必要があります。
              </Paragraph>
            </View>
          }
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonateButton);
