import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class SchemeCanvasFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.problem}
          name="problem"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={problem => {
            onChangeParams({
              problem
            });
          }}
          label="(1) 従来モデルの課題点"
          errorMessage={
            rules &&
            validate({
              label: '従来モデルの課題点',
              value: params.problem,
              rule: rules.problem
            })
          }
          limitLength={rules.problem.maxLength}
          helpText="今までのビジネスモデルでの問題点や課題点を上げ、なぜ駄目なのかを簡潔に記載しましょう・"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.solution}
          name="solution"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={solution => {
            onChangeParams({
              solution
            });
          }}
          label="(2) このモデルによる解決法"
          errorMessage={
            rules &&
            validate({
              label: 'このモデルによる解決法',
              value: params.solution,
              rule: rules.solution
            })
          }
          limitLength={rules.solution.maxLength}
          helpText="このビジネスモデルによって、前述の課題がどのように解決されているのかを記述しましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.uniqueValueProposition}
          name="uniqueValueProposition"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={uniqueValueProposition => {
            onChangeParams({
              uniqueValueProposition
            });
          }}
          label="(3) 独自の価値提案"
          errorMessage={
            rules &&
            validate({
              label: '独自の価値提案',
              value: params.uniqueValueProposition,
              rule: rules.uniqueValueProposition
            })
          }
          limitLength={rules.uniqueValueProposition.maxLength}
          helpText="このビジネスモデルが成り立つ理由を示しましょう。顧客がなぜこのビジネスを利用するのか、このビジネスが提供する独自の価値という観点から記述しましょう。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchemeCanvasFormField);
