export default {
  shadow: {
    large: {
      boxShadow: '0px 2px 16px rgba(0,0,0,0.3)'
    },
    medium: {
      boxShadow: '0px 1px 8px rgba(0,0,0,0.3)'
    },
    xLarge: {
      boxShadow: '0px 4px 32px 8px rgba(0,0,0,0.3)'
    }
  }
};
