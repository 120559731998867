import React from 'react';
import numeral from 'numeral';

import Button from '../Button';
import Color from '../../constants/Color';
import Image from '../Image';
import Markdown from '../Markdown';
import MaterialIcon from '../../utils/MaterialIcon';
import PanelCard from '../Card/PanelCard';
import Paragraph from '../Paragraph';
import Style from '../../constants/Style';
import Text from '../Text';
import View from '../../components/View';

export default class ProductItem extends React.Component {
  state = {
    isCollapse: false
  };

  render() {
    const {
      data,
      submit = false,
      renderSubmit = () => null,
      style = {}
    } = this.props;

    return (
      <View style={style}>
        <PanelCard
          panel={
            <Image
              source={data.images[0]}
              style={{
                width: 320,
                height: 180,
                borderRadius: 8,
                ...Style.shadow.medium
              }}
            />
          }
          panelLeft={16}
          panelWidth={320}
          panelHeight={180}
          style={{ flex: 1, marginBottom: 16 }}
          after={
            <View style={{ padding: 8 }}>
              <View
                style={{
                  borderTop: `1px solid ${Color.lightGrey}`,
                  borderBottom: submit ? `1px solid ${Color.lightGrey}` : '',
                  marginBottom: submit ? 8 : 0
                }}
              >
                {this.state.isCollapsed ? (
                  <Button
                    variant="text"
                    onClick={event => {
                      this.setState({
                        isCollapsed: !this.state.isCollapsed
                      });

                      event.stopPropagation();
                    }}
                  >
                    <View>
                      <Text style={{ color: Color.slate, fontWeight: 'bold' }}>
                        詳細を見る
                      </Text>
                      <View style={{ position: 'absolute', right: 8 }}>
                        <MaterialIcon
                          icon={
                            this.state.isCollapsed
                              ? 'keyboard_arrow_up'
                              : 'keyboard_arrow_down'
                          }
                          color={Color.slate}
                          size={24}
                        />
                      </View>
                    </View>
                  </Button>
                ) : (
                  <View style={{ padding: '16px 0' }}>
                    <Markdown large>{data.detailContent}</Markdown>
                  </View>
                )}
              </View>
              {submit && (
                <View style={{ padding: 8 }}>
                  {renderSubmit(
                    <Button mini disable={data.stockCount < 1}>
                      このコースを申し込む
                    </Button>
                  )}
                </View>
              )}
            </View>
          }
        >
          <View style={{ padding: 8 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Text
                style={{
                  color: Color.slate,
                  fontSize: 17,
                  fontWeight: 'bold'
                }}
              >
                {data.title}
              </Text>
            </View>
            <Paragraph style={{ fontSize: 13 }}>{data.subhead}</Paragraph>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <View
                style={{
                  backgroundColor: Color.pink,
                  borderRadius: 16,
                  padding: '2px 8px'
                }}
              >
                <Text
                  style={{
                    color: Color.white,
                    fontSize: 13,
                    fontWeight: 'bold'
                  }}
                >
                  残り
                  {Number(data.stockCount)}点
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'baseline',
                justifyContent: 'flex-end'
              }}
            >
              <Text
                style={{
                  color: Color.slate,
                  fontWeight: 'bold',
                  fontSize: 24,
                  marginRight: 4
                }}
              >
                {numeral(data.price).format('0,0')}
              </Text>
              <Text
                style={{ color: Color.slate, fontWeight: 'bold', fontSize: 11 }}
              >
                円（税込）
              </Text>
            </View>
          </View>
        </PanelCard>
      </View>
    );
  }
}
