import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { svgAsPngUri } from '../../utils/saveSvgAsPng';
import ArrowDownSvg from '../../components/Pdf/ArrowDownSvg';
import BusinessCategory from '../../models/BusinessCategory';
import Color from '../../constants/Color';
import HeadlineDescSvg from '../../components/Proposal/HeadlineDescSvg';
import Marketing from '../../components/Pdf/Marketing';
import View from '../../components/View';
import actions from '../../actions';

const image2base64 = require('image-to-base64');

const SIZE_TIMES = 2;

class MarketingDownloadLink extends React.Component {
  state = {
    assets: null,
    icons: null
  };

  async componentDidMount() {
    const {
      data: {
        basicInfo: { primaryColor, businessCategoryId }
      }
    } = this.props;

    const category = BusinessCategory.getItems().find(
      item => item.value === businessCategoryId
    );

    const mainColor = `#${primaryColor}`;

    const ICON = [
      {
        id: 'category',
        componentName: `${category.iconName}Svg`,
        color: Color.white,
        size: 64
      },
      {
        id: 'domain',
        componentName: 'DomainIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'account',
        componentName: 'AccountIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'accountOff',
        componentName: 'AccountOffIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'gift',
        componentName: 'GiftIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'bookmarkPlus',
        componentName: 'BookmarkPlusIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'heartMultiple',
        componentName: 'HeartMultipleIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'creation',
        componentName: 'CreationIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'bell',
        componentName: 'BellIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'brain',
        componentName: 'BrainIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'bookOpenVariant',
        componentName: 'BookOpenVariantIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'checkDecagram',
        componentName: 'CheckDecagramIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'thumbUpActive',
        componentName: 'ThumbUpIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'thumbUpInactive',
        componentName: 'ThumbUpIconSvg',
        color: Color.warmGrey,
        size: 64
      }
    ];

    const icons = await Promise.all(
      ICON.map(async icon => {
        const module = await import('../../utils/MaterialIconSvg');

        const component = module[icon.componentName];

        return { ...icon, component };
      })
    );

    this.setState({ icons });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.icons && !prevState.icons) {
      const assetData = await Promise.all([
        ...this.state.icons.map(async icon => {
          const data = await svgAsPngUri(
            document.getElementById(`${icon.id}Icon`),
            {
              scale: SIZE_TIMES
            }
          );

          return {
            id: icon.id,
            data
          };
        })
      ]);

      const headlineDescData = await svgAsPngUri(
        document.getElementById(`headlineDesc`),
        {
          scale: SIZE_TIMES
        }
      );

      const arrowDownData = await svgAsPngUri(
        document.getElementById(`arrowDown`),
        {
          scale: SIZE_TIMES
        }
      );

      const profileImageMimeType = await this.getMimeType(
        this.props.data.basicInfo.profileImage.uri
      );

      const profileImageData = await image2base64(
        this.props.data.basicInfo.profileImage.uri
      );

      const assets = assetData.reduce(
        (acc, asset) => {
          return {
            ...acc,
            [asset.id]: asset.data
          };
        },
        {
          profileImage: `data:${profileImageMimeType};base64,${profileImageData}`,
          headlineDesc: headlineDescData,
          arrowDown: arrowDownData
        }
      );

      this.setState({ assets });

      return true;
    }
  }

  getMimeType(url) {
    return new Promise(resolve => {
      const request = new XMLHttpRequest();

      request.open('GET', url);

      request.addEventListener('load', function() {
        const type = this.getResponseHeader('Content-Type');

        resolve(type);
      });

      request.send();
    });
  }

  render() {
    const { data, preview = false, style = {}, previewStyle = {} } = this.props;

    const {
      data: {
        basicInfo: { primaryColor, businessCategoryId }
      }
    } = this.props;

    const mainColor = `#${primaryColor}`;

    if (!this.state.icons) {
      return null;
    }

    if (this.state.icons && !this.state.assets) {
      return (
        <View style={{ display: 'none' }}>
          <HeadlineDescSvg id="headlineDesc" fill={mainColor} />
          <ArrowDownSvg id="arrowDown" fill={mainColor} />
          {this.state.icons.map(icon => {
            const Icon = icon.component;

            return (
              <Icon
                id={`${icon.id}Icon`}
                key={icon.id}
                width={icon.size * SIZE_TIMES}
                height={icon.size * SIZE_TIMES}
                viewBox="0 0 24 24"
                style={{ fill: icon.color }}
              />
            );
          })}
        </View>
      );
    }

    const category = BusinessCategory.getItems().find(
      item => item.value === businessCategoryId
    );

    return (
      <View style={style}>
        <Marketing
          preview={preview}
          data={{
            ...data,
            categoryName: category.label,
            assets: this.state.assets
          }}
          previewStyle={previewStyle}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingDownloadLink);
