import React from 'react';
import moment from 'moment';

import Button from '../Button';
import Color from '../../constants/Color';
import ReplyPlate from './ReplyPlate';
import Style from '../../constants/Style';
import Text from '../Text';
import UserCard from '../Card/UserCard';
import UserIcon from '../Icons/UserIcon';
import View from '../../components/View';

export default class CommentItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: props.data.replies.length > 2
    };
  }

  render() {
    const {
      data,
      founderId,
      onClick,
      onlyLast = false,
      users = [
      ],
      style = {}
    } = this.props;

    let replies =
      onlyLast && data.replies.length > 0
        ? data.replies.slice(-2)
        : data.replies;

    const commentUser = users.find(user => user.id === data.userId);

    if (this.state.isCollapsed && replies.length > 2) {
      replies = replies.slice(-2);
    }

    return (
      <View>
        <UserCard
          source={commentUser && commentUser.iconImage}
          onClick={onClick}
          style={style}
          after={
            data.replies.length > 0 && (
              <View
                style={{
                  margin: '8px 0 0 0',
                  borderTop: `1px solid ${Color.lightGrey}`
                }}
              >
                {this.state.isCollapsed &&
                  !onlyLast && (
                    <View
                      style={{ borderBottom: `1px solid ${Color.lightGrey}` }}
                    >
                      <Button
                        variant="text"
                        onClick={event => {
                          this.setState({
                            isCollapsed: !this.state.isCollapsed
                          });

                          event.stopPropagation();
                        }}
                      >
                        <Text
                          style={{ color: Color.slate, fontWeight: 'bold' }}
                        >
                          もっと見る ({data.replies.length - 2})
                        </Text>
                      </Button>
                    </View>
                  )}
                {replies.map(reply => {
                  const replyUser = users.find(
                    user => user.id === reply.userId
                  );

                  return (
                    <View
                      key={`reply-${reply.id}`}
                      style={{
                        flexDirection: 'row',
                        padding: '16px 0 16px 0'
                      }}
                    >
                      <UserIcon
                        source={replyUser && replyUser.iconImage}
                        size={52}
                        style={{
                          margin: '0 16px 0 16px',
                          ...Style.shadow.medium
                        }}
                      />
                      <ReplyPlate
                        text={reply.text}
                        invert={reply.userId === founderId}
                        createdAt={reply.createdAt}
                      />
                    </View>
                  );
                })}
              </View>
            )
          }
        >
          <View
            style={{
              flex: 1,
              justifyContent: 'space-between'
            }}
          >
            <Text
              style={{
                color: Color.slate,
                fontSize: 11
              }}
            >
              {data.text}
            </Text>
            <Text
              style={{
                color: Color.lightSlate,
                fontSize: 10,
                alignSelf: 'flex-end'
              }}
            >
              {moment(data.createdAt).format('YYYY/MM/DD HH:mm:ss')}
            </Text>
          </View>
        </UserCard>
      </View>
    );
  }
}
