import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import FeaturePriority from '../../../models/FeaturePriority';
import SelectFormField from '../../../components/Form/SelectFormField';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class MarketingIdealValuesFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.utilitarianValue}
          name="utilitarianValue"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={utilitarianValue => {
            onChangeParams({
              utilitarianValue
            });
          }}
          label="(1) 実利価値"
          errorMessage={
            rules &&
            validate({
              label: '実利価値',
              value: params.utilitarianValue,
              rule: rules.utilitarianValue
            })
          }
          limitLength={rules.utilitarianValue.maxLength}
          helpText="機能的で実態がある価値について記述しましょう。重いものを積んでも高速道路でスピードが落ちない。安定して加速することができて高速道路でも安心できる。など、実感できて実態のあるものなど。"
          style={{ marginBottom: 16 }}
        />
        <SelectFormField
          staticControl={!Boolean(rules)}
          label="(2) 実利価値の重要度"
          direction="column"
          verification
          name="utilitarianLevel"
          items={FeaturePriority.getItems()}
          value={params.utilitarianLevel}
          errorMessage={
            rules &&
            validate({
              label: '実利価値の重要度',
              value: params.utilitarianLevel,
              rule: rules.utilitarianLevel
            })
          }
          onChangeValue={utilitarianLevel =>
            onChangeParams({ utilitarianLevel })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.guaranteedValue}
          name="guaranteedValue"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={guaranteedValue => {
            onChangeParams({
              guaranteedValue
            });
          }}
          label="(3) 保証価値"
          errorMessage={
            rules &&
            validate({
              label: '保証価値',
              value: params.guaranteedValue,
              rule: rules.guaranteedValue
            })
          }
          limitLength={rules.guaranteedValue.maxLength}
          helpText="機能的で実態がない価値について記述しましょう。例えば、壊れにくい商品など、今現時点では実感がわかないが、実態のあるものなど。"
          style={{ marginBottom: 16 }}
        />
        <SelectFormField
          staticControl={!Boolean(rules)}
          label="(4) 保証価値の重要度"
          direction="column"
          verification
          name="guaranteedLevel"
          items={FeaturePriority.getItems()}
          value={params.guaranteedLevel}
          errorMessage={
            rules &&
            validate({
              label: '保証価値の重要度',
              value: params.guaranteedLevel,
              rule: rules.guaranteedLevel
            })
          }
          onChangeValue={guaranteedLevel => onChangeParams({ guaranteedLevel })}
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.reputationValue}
          name="reputationValue"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={reputationValue => {
            onChangeParams({
              reputationValue
            });
          }}
          label="(5) 評判価値"
          errorMessage={
            rules &&
            validate({
              label: '評判価値',
              value: params.reputationValue,
              rule: rules.reputationValue
            })
          }
          limitLength={rules.reputationValue.maxLength}
          helpText="情緒的で実態がある価値について記述しましょう。ベンツに乗っているとパッシングされない、お金持ちに見られるなど。"
          style={{ marginBottom: 16 }}
        />
        <SelectFormField
          staticControl={!Boolean(rules)}
          label="(6) 評判価値の重要度"
          direction="column"
          verification
          name="reputationLevel"
          items={FeaturePriority.getItems()}
          value={params.reputationLevel}
          errorMessage={
            rules &&
            validate({
              label: '評判価値の重要度',
              value: params.reputationLevel,
              rule: rules.reputationLevel
            })
          }
          onChangeValue={reputationLevel => onChangeParams({ reputationLevel })}
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.sympathyValue}
          name="sympathyValue"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={sympathyValue => {
            onChangeParams({
              sympathyValue
            });
          }}
          label="(7) 共感価値"
          errorMessage={
            rules &&
            validate({
              label: '共感価値',
              value: params.sympathyValue,
              rule: rules.sympathyValue
            })
          }
          limitLength={rules.sympathyValue.maxLength}
          helpText="情緒的で実態がない価値について記述しましょう。例えば、商品コンセプトに対して強く共感できる点があるなど。"
          style={{ marginBottom: 16 }}
        />
        <SelectFormField
          staticControl={!Boolean(rules)}
          label="(8) 共感価値の重要度"
          direction="column"
          verification
          name="sympathyLevel"
          items={FeaturePriority.getItems()}
          value={params.sympathyLevel}
          errorMessage={
            rules &&
            validate({
              label: '共感価値の重要度',
              value: params.sympathyLevel,
              rule: rules.sympathyLevel
            })
          }
          onChangeValue={sympathyLevel => onChangeParams({ sympathyLevel })}
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingIdealValuesFormField);
