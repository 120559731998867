import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../utils/Validation';
import TextFormField from '../Form/TextFormField';
import View from '../View';
import actions from '../../actions';

class DreamApplicationFormField extends React.Component {
  render() {
    const {
      params,
      onChangeParams,
      rules,
      direction = 'column',
      style = {}
    } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.name}
          direction={direction}
          onChangeText={name => {
            onChangeParams({ name });
          }}
          label="お名前"
          labelWidth={30}
          errorMessage={
            rules &&
            validate({
              label: 'お名前',
              value: params.name,
              rule: rules.name
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.email}
          direction={direction}
          onChangeText={email => {
            onChangeParams({ email });
          }}
          label="メールアドレス"
          labelWidth={140}
          errorMessage={
            rules &&
            validate({
              label: 'メールアドレス',
              value: params.email,
              rule: rules.email
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.phoneNumber}
          direction={direction}
          onChangeText={phoneNumber => {
            onChangeParams({ phoneNumber });
          }}
          label="電話番号"
          labelWidth={140}
          errorMessage={
            rules &&
            validate({
              label: '電話番号',
              value: params.phoneNumber,
              rule: rules.phoneNumber
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.title}
          direction={direction}
          onChangeText={title => {
            onChangeParams({ title });
          }}
          label="事業のタイトル"
          labelWidth={140}
          errorMessage={
            rules &&
            validate({
              label: '事業のタイトル',
              value: params.title,
              rule: rules.title
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.detail}
          rows={3}
          multiline
          direction={direction}
          onChangeText={detail => {
            onChangeParams({ detail });
          }}
          label="事業の内容"
          labelWidth={140}
          errorMessage={
            rules &&
            validate({
              label: '事業の内容',
              value: params.detail,
              rule: rules.detail
            })
          }
          style={{ marginBottom: 16 }}
          helpText="事業の内容を簡潔に入力してください。"
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.reason}
          rows={3}
          multiline
          direction={direction}
          onChangeText={reason => {
            onChangeParams({ reason });
          }}
          label="事業の背景"
          labelWidth={140}
          errorMessage={
            rules &&
            validate({
              label: '事業の背景',
              value: params.reason,
              rule: rules.reason
            })
          }
          style={{ marginBottom: 16 }}
          helpText="なぜこの事業を始めようと思ったのかの理由と、その動機について記述してください。"
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamApplicationFormField);
