import BusinessCategory from '../models/BusinessCategory';
import Gender from '../models/Gender';
import Prefecture from '../models/Prefecture';
import PricingClass from '../models/PricingClass';
import SupplyClass from '../models/SupplyClass';
import TimeOfDeliverClass from '../models/TimeOfDeliverClass';

export const SHIPPING_INFO_LABELS = {
  kanjiSei: '姓（全角）',
  kanjiMei: '名（全角）',
  furiganaSei: 'セイ（全角）',
  furiganaMei: 'メイ（全角）',
  birthday: '生年月日',
  phoneNumber: '電話番号',
  postalCode: '郵便番号',
  preficture: '都道府県',
  city: '市区町村',
  addressLine1: '番地',
  addressLine2: '建物名'
};

export const SHIPPING_INFO_VALIDATION_RULE = {
  kanjiSei: {
    minLength: 1,
    maxLength: 10
  },
  kanjiMei: {
    minLength: 1,
    maxLength: 10
  },
  furiganaSei: {
    minLength: 1,
    maxLength: 10
  },
  furiganaMei: {
    minLength: 1,
    maxLength: 10
  },
  gender: {
    validators: [
      gender => {
        if (Gender.getItems(false).find(item => item.value === gender)) {
          return '';
        }

        return '性別を入力してください。';
      }
    ]
  },
  birthday: {
    minLength: 1,
    maxLength: 30
  },
  phoneNumber: {
    minLength: 1,
    maxLength: 15
  },
  postalCode: {
    minLength: 7,
    maxLength: 7
  },
  prefecture: {
    validators: [
      prefecture => {
        if (
          Prefecture.getItems(false).find(item => item.value === prefecture)
        ) {
          return '';
        }

        return '都道府県を入力してください。';
      }
    ]
  },
  city: {
    minLength: 1,
    maxLength: 30
  },
  addressLine1: {
    minLength: 1,
    maxLength: 40
  },
  addressLine2: {
    minLength: 0,
    maxLength: 40
  }
};

export const PROPOSAL_BASIC_INFO_LABELS = {
  title: '事業タイトル',
  name: '名前',
  profileImage: 'プロフィール画像',
  birthday: '生年月日',
  job: '職業',
  biography: '略歴',
  businessCategoryId: '事業カテゴリーID',
  primaryColor: 'メインカラー',
  secondaryColor: 'サブカラー'
};

export const PROPOSAL_BASIC_INFO_VALIDATION_RULE = {
  title: {
    minLength: 1,
    maxLength: 16
  },
  name: {
    minLength: 1,
    maxLength: 10
  },
  profileImage: {
    validators: [
      value => {
        if (JSON.stringify(value) !== '{}') {
          return '';
        }

        return 'プロフィール画像を入力してください。';
      }
    ]
  },
  birthday: {
    minLength: 1,
    maxLength: 12
  },
  job: {
    minLength: 1,
    maxLength: 10
  },
  biography: {
    minLength: 1,
    maxLength: 50
  },
  businessCategoryId: {
    validators: [
      category => {
        if (
          BusinessCategory.getItems(false).find(item => item.value === category)
        ) {
          return '';
        }

        return '事業カテゴリを入力してください。';
      }
    ]
  },
  primaryColor: {
    minLength: 3,
    maxLength: 6
  },
  secondaryColor: {
    minLength: 3,
    maxLength: 6
  }
};

export const PROPOSAL_INTRODUCTION_LABELS = {
  story: '創業までのストーリー',
  ux: 'ターゲットと提供する体験',
  factor: '懸念点と実現のポイント'
};

export const PROPOSAL_INTRODUCTION_VALIDATION_RULE = {
  story: {
    minLength: 1,
    maxLength: 50
  },
  ux: {
    minLength: 1,
    maxLength: 50
  },
  factor: {
    minLength: 1,
    maxLength: 50
  }
};

export const PROPOSAL_SPECIFICATION_LABELS = {
  specification: '仕様・特徴',
  plan: '研究内容・新企画状況',
  supply: '提供',
  pricing: '価格帯',
  timeOfDeliver: '納品時期',
  supplyClassId: '提供種別',
  pricingClassId: '価格帯種別',
  timeOfDeliverClassId: '納品時期種別'
};

export const PROPOSAL_SPECIFICATION_VALIDATION_RULE = {
  specification: {
    minLength: 1,
    maxLength: 40
  },
  plan: {
    minLength: 1,
    maxLength: 40
  },
  supply: {
    minLength: 1,
    maxLength: 40
  },
  pricing: {
    minLength: 1,
    maxLength: 40
  },
  timeOfDeliver: {
    minLength: 1,
    maxLength: 40
  },
  supplyClassId: {
    validators: [
      value => {
        if (SupplyClass.getItems(false).find(item => item.value === value)) {
          return '';
        }

        return '提供種別を入力してください。';
      }
    ]
  },
  pricingClassId: {
    validators: [
      value => {
        if (PricingClass.getItems(false).find(item => item.value === value)) {
          return '';
        }

        return '価格帯種別を入力してください。';
      }
    ]
  },
  timeOfDeliverClassId: {
    validators: [
      value => {
        if (
          TimeOfDeliverClass.getItems(false).find(item => item.value === value)
        ) {
          return '';
        }

        return '納期種別を入力してください。';
      }
    ]
  }
};

export const PROPOSAL_BUSINESS_PROCESS_LABELS = {
  buying: '仕入れ・供給ルート',
  production: '製造・外注ルート',
  distribution: '販売・流通ルート',
  marketing: '販促・PRルート'
};

export const PROPOSAL_BUSINESS_PROCESS_VALIDATION_RULE = {
  buying: {
    minLength: 1,
    maxLength: 40
  },
  production: {
    minLength: 1,
    maxLength: 40
  },
  distribution: {
    minLength: 1,
    maxLength: 40
  },
  marketing: {
    minLength: 1,
    maxLength: 40
  }
};

export const PROPOSAL_ENVIRONMENT_LABELS = {
  market: 'マーケット動向',
  competitor: '競合状況',
  substitute: '代替状況',
  risk: '事業リスク'
};

export const PROPOSAL_ENVIRONMENT_VALIDATION_RULE = {
  market: {
    minLength: 1,
    maxLength: 60
  },
  competitor: {
    minLength: 1,
    maxLength: 60
  },
  substitute: {
    minLength: 1,
    maxLength: 60
  },
  risk: {
    minLength: 1,
    maxLength: 60
  }
};

export const PROPOSAL_FEATURE_LABELS = {
  strengths: '強み',
  weaknesses: '弱み',
  opportunities: '機会',
  threats: '脅威',
  identity: '独自性',
  potential: '成長性',
  keyFactor: '成功ポイント',
  issue: '優先課題'
};

export const PROPOSAL_FEATURE_VALIDATION_RULE = {
  strengths: {
    minLength: 1,
    maxLength: 60
  },
  weaknesses: {
    minLength: 1,
    maxLength: 60
  },
  opportunities: {
    minLength: 1,
    maxLength: 60
  },
  threats: {
    minLength: 1,
    maxLength: 60
  },
  identity: {
    minLength: 1,
    maxLength: 50
  },
  potential: {
    minLength: 1,
    maxLength: 50
  },
  keyFactor: {
    minLength: 1,
    maxLength: 50
  },
  issue: {
    minLength: 1,
    maxLength: 50
  }
};

const salesAndCostTitleLabels = new Array(3).fill(true).reduce(
  (acc, _, index) => ({
    ...acc,
    [`salesTitle${index + 1}`]: `売上タイトル${index + 1}`,
    [`salesAmount${index + 1}`]: `売上数値${index + 1}`,
    [`salesUnit${index + 1}`]: `売上単位${index + 1}`,
    [`costTitle${index + 1}`]: `コストタイトル${index + 1}`,
    [`costAmount${index + 1}`]: `コスト数値${index + 1}`,
    [`costUnit${index + 1}`]: `コスト単位${index + 1}`
  }),
  {}
);

const salesAndCostTitleValdiations = new Array(3).fill(true).reduce(
  (acc, _, index) => ({
    ...acc,
    [`salesTitle${index + 1}`]: { minLength: 1, maxLength: 8 },
    [`salesAmount${index + 1}`]: { type: 'number' },
    [`salesUnit${index + 1}`]: { minLength: 1, maxLength: 4 },
    [`costTitle${index + 1}`]: { minLength: 1, maxLength: 8 },
    [`costAmount${index + 1}`]: { type: 'number' },
    [`costUnit${index + 1}`]: { minLength: 1, maxLength: 4 }
  }),
  {}
);

export const PROPOSAL_PROFIT_LABELS = {
  ...salesAndCostTitleLabels,
  salesTotalTitle: '売上合計タイトル',
  salesTotalAmount: '売上合計数値',
  salesTotalUnit: '売上合計単位',
  costTotalTitle: 'コスト合計タイトル',
  costTotalAmount: 'コスト合計数値',
  costTotalUnit: 'コスト合計単位',
  profitTitle: '利益タイトル',
  profitAmount: '利益数値',
  profitUnit: '利益単位'
};

export const PROPOSAL_PROFIT_VALIDATION_RULE = {
  ...salesAndCostTitleValdiations,
  salesTotalTitle: { minLength: 1, maxLength: 8 },
  salesTotalAmount: { type: 'number' },
  salesTotalUnit: { minLength: 1, maxLength: 4 },
  costTotalTitle: { minLength: 1, maxLength: 8 },
  costTotalAmount: { type: 'number' },
  costTotalUnit: { minLength: 1, maxLength: 4 },
  profitTitle: { minLength: 1, maxLength: 8 },
  profitAmount: { type: 'number' },
  profitUnit: { minLength: 1, maxLength: 4 }
};

export const PROPOSAL_METADATA_LABELS = {
  editPassword: '編集パスワード',
  viewPassword: '閲覧パスワード'
};

export const PROPOSAL_METADATA_VALIDATION_RULE = {
  editPassword: { minLength: 4, maxLength: 32 },
  viewPassword: { minLength: 4, maxLength: 32 }
};

export const PROPOSAL_LABELS = {
  basicInfo: '基本情報',
  introduction: '導入',
  specification: '事業内容',
  businessProcess: '業務プロセス',
  environment: '経営環境',
  feature: '特徴',
  profit: '営利'
};

export const PROPOSAL_VALIDATION_RULE = {
  basicInfo: {
    children: {
      rule: PROPOSAL_BASIC_INFO_VALIDATION_RULE,
      label: PROPOSAL_BASIC_INFO_LABELS
    }
  },
  introduction: {
    children: {
      rule: PROPOSAL_INTRODUCTION_VALIDATION_RULE,
      label: PROPOSAL_INTRODUCTION_LABELS
    }
  },
  specification: {
    children: {
      rule: PROPOSAL_SPECIFICATION_VALIDATION_RULE,
      label: PROPOSAL_SPECIFICATION_LABELS
    }
  },
  businessProcess: {
    children: {
      rule: PROPOSAL_BUSINESS_PROCESS_VALIDATION_RULE,
      label: PROPOSAL_BUSINESS_PROCESS_LABELS
    }
  },
  environment: {
    children: {
      rule: PROPOSAL_ENVIRONMENT_VALIDATION_RULE,
      label: PROPOSAL_ENVIRONMENT_LABELS
    }
  },
  feature: {
    children: {
      rule: PROPOSAL_FEATURE_VALIDATION_RULE,
      label: PROPOSAL_FEATURE_LABELS
    }
  },
  profit: {
    children: {
      rule: PROPOSAL_PROFIT_VALIDATION_RULE,
      label: PROPOSAL_PROFIT_LABELS
    }
  }
};

export const PROPOSAL_CREATE_FORM_LABELS = {
  proposal: '事業計画',
  ...PROPOSAL_METADATA_LABELS
};

export const PROPOSAL_CREATE_FORM_VALIDATION_RULE = {
  proposal: {
    children: {
      rule: PROPOSAL_VALIDATION_RULE,
      label: PROPOSAL_LABELS
    }
  },
  ...PROPOSAL_METADATA_VALIDATION_RULE
};

export const PROPOSAL_UPDATE_FORM_LABELS = {
  proposal: '事業計画',
  editPassword: PROPOSAL_METADATA_LABELS.editPassword
};

export const PROPOSAL_UPDATE_FORM_VALIDATION_RULE = {
  proposal: {
    children: {
      rule: PROPOSAL_VALIDATION_RULE,
      label: PROPOSAL_LABELS
    }
  },
  editPassword: PROPOSAL_METADATA_VALIDATION_RULE.editPassword
};

export const MYPAGE_ACCOUNT_LABELS = {
  name: 'ユーザー名',
  profile: 'プロフィール',
  twitterId: 'プロフィール',
  email: 'メールアドレス'
};

export const MYPAGE_ACCOUNT_VALIDATION_RULE = {
  name: {
    minLength: 1,
    maxLength: 30
  },
  profile: {
    minLength: 1,
    maxLength: 140
  },
  twitterId: {
    minLength: 1,
    maxLength: 60
  },
  email: {
    matches: [
      {
        reg:
          "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
        message: 'メールアドレスが正しくありません。'
      }
    ]
  }
};

export const PROPOSAL_APPLICATION_LABELS = {
  email: 'メールアドレス',
  phoneNumber: '電話番号',
  editPassword: '編集パスワード'
};

export const PROPOSAL_APPLICATION_VALIDATION_RULE = {
  phoneNumber: {
    minLength: 1,
    maxLength: 30
  },
  email: {
    matches: [
      {
        reg:
          "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
        message: 'メールアドレスが正しくありません。'
      }
    ]
  },
  editPassword: { minLength: 4, maxLength: 32 }
};

export const SCHEME_APPLICATION_LABELS = PROPOSAL_APPLICATION_LABELS;

export const SCHEME_APPLICATION_VALIDATION_RULE = PROPOSAL_APPLICATION_VALIDATION_RULE;

export const MARKETING_APPLICATION_LABELS = PROPOSAL_APPLICATION_LABELS;

export const MARKETING_APPLICATION_VALIDATION_RULE = PROPOSAL_APPLICATION_VALIDATION_RULE;

export const REVIEW_LABELS = {
  editPassword: '編集パスワード',
  phoneNumber: '電話番号',
  email: 'メールアドレス'
};

export const REVIEW_VALIDATION_RULE = {
  editPassword: { minLength: 4, maxLength: 32 },
  phoneNumber: {
    minLength: 1,
    maxLength: 15
  },
  email: {
    matches: [
      {
        reg:
          "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
        message: 'メールアドレスが正しくありません。'
      }
    ]
  }
};

export const SCHEME_BASIC_INFO_LABELS = PROPOSAL_BASIC_INFO_LABELS;

export const SCHEME_BASIC_INFO_VALIDATION_RULE = PROPOSAL_BASIC_INFO_VALIDATION_RULE;

export const SCHEME_BUSINESS_MODEL_LABELS = {
  models: 'モデル',
  relations: '関係'
};

export const SCHEME_BUSINESS_MODEL_VALIDATION_RULE = {
  models: {},
  relations: {},
  levelOptions: {}
};

export const SCHEME_CANVAS_LABELS = {
  problem: '従来モデルの課題点',
  solution: 'このモデルによる解決法',
  uniqueValueProposition: '独自の価値提案'
};

export const SCHEME_CANVAS_VALIDATION_RULE = {
  problem: {
    minLength: 1,
    maxLength: 50
  },
  solution: {
    minLength: 1,
    maxLength: 50
  },
  uniqueValueProposition: {
    minLength: 1,
    maxLength: 50
  }
};

export const SCHEME_PROCESS_STREAM_LABELS = {
  detail: '詳細文'
};

export const SCHEME_PROCESS_STREAM_VALIDATION_RULE = {
  detail: {
    minLength: 1,
    maxLength: 250
  }
};

export const SCHEME_ASSET_LABELS = {
  revenue: '収入',
  cost: '費用',
  blandishment: '評判',
  criticism: '批判'
};

export const SCHEME_ASSET_VALIDATION_RULE = {
  revenue: {
    minLength: 1,
    maxLength: 60
  },
  cost: {
    minLength: 1,
    maxLength: 60
  },
  blandishment: {
    minLength: 1,
    maxLength: 60
  },
  criticism: {
    minLength: 1,
    maxLength: 60
  }
};

export const SCHEME_INDICATOR_LABELS = {
  kpi1: 'KPI1',
  kpi2: 'KPI2',
  kpi3: 'KPI3',
  kgi: 'KGI'
};

export const SCHEME_INDICATOR_VALIDATION_RULE = {
  kpi1: {
    minLength: 1,
    maxLength: 50
  },
  kpi2: {
    minLength: 1,
    maxLength: 50
  },
  kpi3: {
    minLength: 1,
    maxLength: 50
  },
  kgi: {
    minLength: 1,
    maxLength: 50
  }
};

export const SCHEME_METADATA_LABELS = {
  editPassword: '編集パスワード',
  viewPassword: '閲覧パスワード'
};

export const SCHEME_METADATA_VALIDATION_RULE = {
  editPassword: { minLength: 4, maxLength: 32 },
  viewPassword: { minLength: 4, maxLength: 32 }
};

export const SCHEME_LABELS = {
  basicInfo: '基本情報',
  businessModel: 'ビジネスモデル',
  canvas: 'モデルの背景',
  processStream: '一連の流れ',
  asset: '資産',
  indicator: '経営環境'
};

export const SCHEME_VALIDATION_RULE = {
  basicInfo: {
    children: {
      rule: SCHEME_BASIC_INFO_VALIDATION_RULE,
      label: SCHEME_BASIC_INFO_LABELS
    }
  },
  businessModel: {
    children: {
      rule: SCHEME_BUSINESS_MODEL_LABELS,
      label: SCHEME_BUSINESS_MODEL_VALIDATION_RULE
    }
  },
  canvas: {
    children: {
      rule: SCHEME_CANVAS_LABELS,
      label: SCHEME_CANVAS_VALIDATION_RULE
    }
  },
  processStream: {
    children: {
      rule: SCHEME_PROCESS_STREAM_LABELS,
      label: SCHEME_PROCESS_STREAM_VALIDATION_RULE
    }
  },
  asset: {
    children: {
      rule: SCHEME_ASSET_VALIDATION_RULE,
      label: SCHEME_ASSET_LABELS
    }
  },
  indicator: {
    children: {
      rule: SCHEME_INDICATOR_VALIDATION_RULE,
      label: SCHEME_INDICATOR_LABELS
    }
  }
};

export const SCHEME_CREATE_FORM_LABELS = {
  scheme: 'ビジネスモデル',
  ...SCHEME_METADATA_LABELS
};

export const SCHEME_CREATE_FORM_VALIDATION_RULE = {
  scheme: {
    children: {
      rule: SCHEME_VALIDATION_RULE,
      label: SCHEME_LABELS
    }
  },
  ...SCHEME_METADATA_VALIDATION_RULE
};

export const SCHEME_UPDATE_FORM_LABELS = {
  scheme: 'ビジネスモデル',
  editPassword: SCHEME_METADATA_LABELS.editPassword
};

export const SCHEME_UPDATE_FORM_VALIDATION_RULE = {
  scheme: {
    children: {
      rule: SCHEME_VALIDATION_RULE,
      label: SCHEME_LABELS
    }
  },
  editPassword: SCHEME_METADATA_VALIDATION_RULE.editPassword
};

export const MARKETING_BASIC_INFO_LABELS = PROPOSAL_BASIC_INFO_LABELS;

export const MARKETING_BASIC_INFO_VALIDATION_RULE = PROPOSAL_BASIC_INFO_VALIDATION_RULE;

export const MARKETING_MARKET_LABELS = {
  competitorDefinition: '競合の定義',
  customerDefinition: '顧客の定義',
  nonCustomerDefinition: '非顧客の定義',
  scale: '市場の規模',
  potential: '成長性',
  competitorEnvironment: '競合環境',
  ability: '能力と親和性',
  synergy: '既存事業とのシナジー'
};

export const MARKETING_MARKET_VALIDATION_RULE = {
  competitorDefinition: {
    minLength: 1,
    maxLength: 50
  },
  customerDefinition: {
    minLength: 1,
    maxLength: 50
  },
  nonCustomerDefinition: {
    minLength: 1,
    maxLength: 50
  },
  scale: {
    minLength: 1,
    maxLength: 40
  },
  potential: {
    minLength: 1,
    maxLength: 40
  },
  competitorEnvironment: {
    minLength: 1,
    maxLength: 40
  },
  ability: {
    minLength: 1,
    maxLength: 40
  },
  synergy: {
    minLength: 1,
    maxLength: 40
  }
};

export const MARKETING_IDEAL_VALUES_LABELS = {
  utilitarianValue: '実利価値',
  utilitarianLevel: '実利価値の重要度',
  guaranteedValue: '保証価値',
  guaranteedLevel: '保証価値の重要度',
  reputationValue: '評判価値',
  reputationLevel: '評判価値の重要度',
  sympathyValue: '共感価値',
  sympathyLevel: '共感価値の重要度'
};

export const MARKETING_IDEAL_VALUES_VALIDATION_RULE = {
  utilitarianValue: {
    minLength: 1,
    maxLength: 60
  },
  guaranteedValue: {
    minLength: 1,
    maxLength: 60
  },
  reputationValue: {
    minLength: 1,
    maxLength: 60
  },
  sympathyValue: {
    minLength: 1,
    maxLength: 60
  },
  utilitarianLevel: {
    terms: [
      {
        operator: '<=',
        value: 4,
        message: 'レベルは4以下を指定してください。'
      },
      { operator: '>=', value: 1, message: 'レベルは1以上を指定してください。' }
    ]
  },
  guaranteedLevel: {
    terms: [
      {
        operator: '<=',
        value: 4,
        message: 'レベルは4以下を指定してください。'
      },
      { operator: '>=', value: 1, message: 'レベルは1以上を指定してください。' }
    ]
  },
  reputationLevel: {
    terms: [
      {
        operator: '<=',
        value: 4,
        message: 'レベルは4以下を指定してください。'
      },
      { operator: '>=', value: 1, message: 'レベルは1以上を指定してください。' }
    ]
  },
  sympathyLevel: {
    terms: [
      {
        operator: '<=',
        value: 4,
        message: 'レベルは4以下を指定してください。'
      },
      { operator: '>=', value: 1, message: 'レベルは1以上を指定してください。' }
    ]
  }
};

export const MARKETING_REALITY_VALUES_LABELS = {
  product: '商品・サービス',
  support: '付加価値・サポート',
  communication: 'コミュニケーション',
  concept: '広告コンセプト・チームの文化'
};

export const MARKETING_REALITY_VALUES_VALIDATION_RULE = {
  product: {
    minLength: 1,
    maxLength: 60
  },
  support: {
    minLength: 1,
    maxLength: 60
  },
  communication: {
    minLength: 1,
    maxLength: 60
  },
  concept: {
    minLength: 1,
    maxLength: 60
  }
};

export const MARKETING_COMMUNICATION_LABELS = {
  toNotice: '気づいてもらうための仕組み',
  toRemember: '覚えてもらうための仕組み',
  toLike: '好きになってもらうための仕組み',
  toUnderstand: '深く知ってもらうための仕組み',
  toSelect: '選んでもらうための仕組み'
};

export const MARKETING_COMMUNICATION_VALIDATION_RULE = {
  toNotice: {
    minLength: 1,
    maxLength: 90
  },
  toRemember: {
    minLength: 1,
    maxLength: 90
  },
  toLike: {
    minLength: 1,
    maxLength: 90
  },
  toUnderstand: {
    minLength: 1,
    maxLength: 90
  },
  toSelect: {
    minLength: 1,
    maxLength: 90
  }
};

export const MARKETING_METADATA_LABELS = {
  editPassword: '編集パスワード',
  viewPassword: '閲覧パスワード'
};

export const MARKETING_METADATA_VALIDATION_RULE = {
  editPassword: { minLength: 4, maxLength: 32 },
  viewPassword: { minLength: 4, maxLength: 32 }
};

export const MARKETING_LABELS = {
  basicInfo: '基本情報',
  businessModel: 'ビジネスモデル',
  canvas: 'モデルの背景',
  processStream: '一連の流れ',
  asset: '資産',
  indicator: '経営環境'
};

export const MARKETING_VALIDATION_RULE = {
  basicInfo: {
    children: {
      rule: MARKETING_BASIC_INFO_VALIDATION_RULE,
      label: MARKETING_BASIC_INFO_LABELS
    }
  },
  market: {
    children: {
      rule: MARKETING_MARKET_LABELS,
      label: MARKETING_MARKET_VALIDATION_RULE
    }
  },
  idealValues: {
    children: {
      rule: MARKETING_IDEAL_VALUES_LABELS,
      label: MARKETING_IDEAL_VALUES_VALIDATION_RULE
    }
  },
  realityValues: {
    children: {
      rule: MARKETING_REALITY_VALUES_LABELS,
      label: MARKETING_REALITY_VALUES_VALIDATION_RULE
    }
  },
  communication: {
    children: {
      rule: MARKETING_COMMUNICATION_VALIDATION_RULE,
      label: MARKETING_COMMUNICATION_LABELS
    }
  }
};

export const MARKETING_CREATE_FORM_LABELS = {
  scheme: 'ビジネスモデル',
  ...MARKETING_METADATA_LABELS
};

export const MARKETING_CREATE_FORM_VALIDATION_RULE = {
  scheme: {
    children: {
      rule: MARKETING_VALIDATION_RULE,
      label: MARKETING_LABELS
    }
  },
  ...MARKETING_METADATA_VALIDATION_RULE
};

export const MARKETING_UPDATE_FORM_LABELS = {
  scheme: 'ビジネスモデル',
  editPassword: MARKETING_METADATA_LABELS.editPassword
};

export const MARKETING_UPDATE_FORM_VALIDATION_RULE = {
  scheme: {
    children: {
      rule: MARKETING_VALIDATION_RULE,
      label: MARKETING_LABELS
    }
  },
  editPassword: MARKETING_METADATA_VALIDATION_RULE.editPassword
};

export const DREAM_APPLICATION_LABELS = {
  name: '名前',
  email: 'メールアドレス',
  phoneNumber: '電話番号',
  title: '事業タイトル',
  detail: '事業の内容',
  reason: '事業の背景'
};

export const DREAM_APPLICATION_VALIDATION_RULE = {
  name: {
    minLength: 1,
    maxLength: 30
  },
  phoneNumber: {
    minLength: 1,
    maxLength: 30
  },
  email: {
    matches: [
      {
        reg:
          "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$",
        message: 'メールアドレスが正しくありません。'
      }
    ]
  },
  title: {
    minLength: 1,
    maxLength: 16
  },
  detail: {
    minLength: 1,
    maxLength: 500
  },
  reason: {
    minLength: 1,
    maxLength: 200
  }
};
