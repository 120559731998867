import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  CalendarStarIconSvg,
  FileDocumentIconSvg,
  ThumbUpIconSvg
} from '../../utils/MaterialIconSvg';
import Button from '../Button';
import Color from '../../constants/Color';
import MessagePlate from '../../components/MessagePlate';
import Panel from '../Panel';
import Paragraph from '../../components/Paragraph';
import SectionCard from '../Card/SectionCard';
import Style from '../../constants/Style';
import View from '../../components/View';
import actions from '../../actions';

class Wish extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getJobs();
  }

  render() {
    const { style = {} } = this.props;

    return (
      <View style={style}>
        <View
          style={{
            borderRadius: 8,
            backgroundColor: Color.white,
            margin: '-8px 0 0 0',
            ...Style.shadow.large,
            zIndex: 1
          }}
        >
          <View
            style={{
              backgroundImage: `url(${require('../../assets/img/founder-recruit-background.jpg')})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              alignItems: 'center',
              borderRadius: 8,
              padding: '24px 0',
              margin: '-16px 8px 16px 8px'
            }}
          >
            <MessagePlate title="FEATURE" message="VISIONSの特徴" />
          </View>
          <View style={{ padding: '0 8px 24px 8px' }}>
            <SectionCard
              title="手数料が少ない"
              panel={
                <Panel gradient={Color.pinkGradient} size={40}>
                  <ThumbUpIconSvg
                    fill={Color.white}
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  />
                </Panel>
              }
              style={{ marginBottom: 24 }}
            >
              <Paragraph>
                決済手数料3.6%と振込手数料以外は一切かかりません。10〜20%を手数料とする他のプラットフォームに比べて、とても快適にご利用いただけます。
              </Paragraph>
            </SectionCard>
            <SectionCard
              title="かんたん事業計画機能"
              panel={
                <Panel gradient={Color.pinkGradient} size={40}>
                  <FileDocumentIconSvg
                    fill={Color.white}
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  />
                </Panel>
              }
              style={{ marginBottom: 24 }}
            >
              <Paragraph containerStyle={{ marginBottom: 16 }}>
                フォームに入力するだけで、あなたのプロジェクトの事業計画書をA3サイズのPDFで生成します。プロジェクトの内容を明瞭にしましょう。
              </Paragraph>
              <Link to="/proposal">
                <Button mini>事業計画書を作る</Button>
              </Link>
            </SectionCard>
            <SectionCard
              title="月額課金機能"
              panel={
                <Panel gradient={Color.pinkGradient} size={40}>
                  <CalendarStarIconSvg
                    fill={Color.white}
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                  />
                </Panel>
              }
              style={{ marginBottom: 24 }}
            >
              <Paragraph>
                プロジェクトの事業主（ファウンダー）は、月額のサブスクリプション機能を利用することができます。サロン運営などのに活用できます。
              </Paragraph>
            </SectionCard>
          </View>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wish);
