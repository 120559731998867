export default class FeaturePriority {
  static getItems(withDefault = true) {
    const items = [
      {
        label: '1',
        value: 1
      },
      {
        label: '2',
        value: 2
      },
      {
        label: '3',
        value: 3
      },
      {
        label: '4',
        value: 4
      }
    ];

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getItem(value) {
    return FeaturePriority.getItems().find(item => item.value === value);
  }

  static getLabel(value) {
    return FeaturePriority.getItems().find(item => item.value === value).label;
  }
}
