import { getCollection } from '../utils/Firebase';
import User from './User';

export default class Founder {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      name: state.name,
      iconImage: state.iconImage,
      images: state.images || [],
      desktopHeaderImage: state.desktopHeaderImage,
      screenName: state.screenName || '',
      birthplace: state.birthplace || '',
      bloodType: state.bloodType || '',
      birthday: state.birthday || '',
      profile: state.profile || '',
      patronGiftText: state.patronGiftText || ''
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }

  static async get(id) {
    const userDoc = await getCollection('users')
      .doc(id)
      .get();

    const founderDoc = await getCollection('founders')
      .doc(id)
      .get();

    const user = new User({
      id: userDoc.id,
      ...userDoc.data()
    }).fromFirestore();

    const founder = new Founder({
      id: founderDoc.id,
      ...user,
      ...founderDoc.data()
    }).fromFirestore();

    return founder;
  }
}
