import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import Headline from '../../../../components/Headline';
import PageHeader from '../../../../containers/page/PageHeader';
import Paragraph from '../../../../components/Paragraph';
import ProductItem from '../../../../components/Dream/ProductItem';
import ProductPurchaseModal from '../../../../containers/ProductPurchaseModal';
import ResponseDispatcher from '../../../../components/ResponseDispatcher';
import ShippingInfoFormField from '../../../../containers/form/ShippingInfoFormField';
import Stepper from '../../../../components/Stepper';
import Style from '../../../../constants/Style';
import View from '../../../../components/View';
import actions from '../../../../actions';

class DreamProductConfirm extends React.Component {
  state = {
    isOpenPurchaseModal: false
  };

  componentDidMount() {
    this.props.getDream(this.props.match.params.dreamId);

    this.props.getShippingInfo(this.props.match.params.shippingInfoId);
  }

  render() {
    const shippingInfo = this.props.shippingInfo.hash[
      this.props.match.params.shippingInfoId
    ];

    if (!shippingInfo) {
      return null;
    }

    const dream = this.props.dream.hash[this.props.match.params.dreamId];

    if (!dream) {
      return null;
    }

    const product = dream.products.find(
      product => product.id === this.props.match.params.productId
    );

    return (
      <View>
        <PageHeader />
        <Stepper
          steps={['コースの選択', '必要情報の入力', '購入', '完了']}
          activeIndex={2}
          style={{ padding: '24px 8px 16px 8px' }}
        />
        <View style={{ padding: '0 24px' }}>
          <Headline style={{ marginBottom: 16 }}>3. 購入</Headline>
          <Paragraph style={{ marginBottom: 24 }}>
            申込み内容をご確認の上、ご購入してください。
          </Paragraph>
          <Headline
            size={2}
            style={{ color: Color.lightSlate, marginBottom: 16 }}
          >
            申込み内容のご確認
          </Headline>
        </View>
        <View
          style={{
            borderRadius: 8,
            padding: 16,
            ...Style.shadow.large,
            margin: '0 16px 32px 16px'
          }}
        >
          <Headline size={3} style={{ color: Color.slate, marginBottom: 16 }}>
            お届先情報
          </Headline>
          <ShippingInfoFormField params={shippingInfo} />
        </View>
        <View style={{ padding: '0 24px' }}>
          <Headline
            size={2}
            style={{ color: Color.lightSlate, marginBottom: 16 }}
          >
            購入する商品
          </Headline>
        </View>
        <View
          style={{
            margin: '0 16px 16px 16px'
          }}
        >
          <ProductItem data={product} />
        </View>
        <View style={{ padding: '0 16px', marginBottom: 32 }}>
          <Button onClick={() => this.setState({ isOpenPurchaseModal: true })}>
            購入する
          </Button>
        </View>
        <ProductPurchaseModal
          visible={this.state.isOpenPurchaseModal}
          product={product}
          dreamId={this.props.match.params.dreamId}
          shippingInfoId={this.props.match.params.shippingInfoId}
          onClose={() => this.setState({ isOpenPurchaseModal: false })}
        />
        <ResponseDispatcher
          isLoading={this.props.dream.isPurchaseProductLoading}
          isFailed={this.props.dream.isPurchaseProductFailed}
          onComplete={() => {
            this.props.getMyselfUser(this.props.auth.uid);

            this.props.history.push(
              `/dream/${this.props.match.params.dreamId}/product/${
                this.props.match.params.productId
              }/complete`
            );
          }}
          onError={() => {
            alert(this.props.dream.purchaseProductError.message);

            this.props.recoverPurchaseDreamProduct();
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamProductConfirm);
