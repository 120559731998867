import React from 'react';

import Color from '../../constants/Color';
import Container from '../Container';
import FundingStatusLabel from './FundingStatusLabel';
import Headline from '../Headline';
import Style from '../../constants/Style';
import Text from '../Text';
import View from '../View';

export default function DreamDesktopHeader({ data, businessCategory }) {
  return (
    <View
      style={{
        height: 320,
        backgroundImage: `url(${data.desktopHeaderImage.uri})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative'
      }}
    >
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <Container>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View
              style={{
                width: 120,
                height: 120,
                borderRadius: 60,
                backgroundColor: Color.white,
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 32
              }}
            >
              <businessCategory.icon
                width={64}
                height={64}
                viewBox="0 0 24 24"
                style={{ fill: Color.slate }}
              />
              <Text
                style={{
                  color: Color.slate,
                  fontSize: 15,
                  fontWeight: 'bold'
                }}
              >
                {businessCategory.label}
              </Text>
            </View>
            <View style={{ justifyContent: 'center' }}>
              <Headline
                size={1}
                style={{
                  color: Color.white,
                  fontFamily: 'YuMincho',
                  fontSize: 32,
                  textShadow: `${Color.black} 0 2px 4px`
                }}
              >
                {data.fundingTitle}
              </Headline>
            </View>
          </View>
        </Container>
      </View>
      <View
        style={{
          position: 'absolute',
          justifyContent: 'center',
          bottom: 0,
          right: 0,
          left: 0
        }}
      >
        <Container>
          <View style={{ flexDirection: 'row', marginBottom: -16, zIndex: 2 }}>
            <FundingStatusLabel
              data={data}
              style={{ padding: '8px 20px', ...Style.shadow.medium }}
              textStyle={{ fontSize: 24 }}
            />
          </View>
        </Container>
      </View>
    </View>
  );
}
