import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';

import activityHistory from './activityHistory';
import auth from './auth';
import config from './config';
import documentHistory from './documentHistory';
import dream from './dream';
import dreamApplication from './dreamApplication';
import dreamProduct from './dreamProduct';
import dreamReport from './dreamReport';
import dreamThank from './dreamThank';
import founder from './founder';
import job from './job';
import layout from './layout';
import marketing from './marketing';
import marketingForm from './marketingForm';
import patronContract from './patronContract';
import paymentInfo from './paymentInfo';
import proposal from './proposal';
import proposalForm from './proposalForm';
import review from './review';
import scheme from './scheme';
import schemeForm from './schemeForm';
import shippingInfo from './shippingInfo';
import sponsor from './sponsor';
import user from './user';

export default persistCombineReducers(
  {
    key: 'root',
    storage,
    whitelist: ['auth', 'proposalForm', 'schemeForm', 'marketingForm']
  },
  {
    activityHistory,

    auth,
    config,
    documentHistory,
    dream,
    dreamApplication,
    dreamProduct,
    dreamReport,
    dreamThank,
    founder,
    job,
    layout,
    marketing,
    marketingForm,
    patronContract,
    paymentInfo,
    proposal,
    proposalForm,
    review,
    scheme,
    schemeForm,
    shippingInfo,
    sponsor,
    user
  }
);
