import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import AmountCardBody from '../../../components/Card/AmountCardBody';
import BusinessCategory from '../../../models/BusinessCategory';
import CardPrimitive from '../../../components/Card/CardPrimitive';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import ContentContainer from '../../../components/Page/ContentContainer';
import Deadline from '../../../utils/Deadline';
import DreamCommentContent from '../../../containers/dream/DreamCommentContent';
import DreamDesktopHeader from '../../../components/Dream/DreamDesktopHeader';
import DreamDetailLargeContent from '../../../components/Dream/DreamDetailLargeContent';
import DreamProgressCard from '../../../components/Dream/DreamProgressCard';
import DreamPurchaseContent from '../../../containers/dream/DreamPurchaseContent';
import DreamReportTimeline from '../../../components/Dream/DreamReportTimeline';
import FooterContainer from '../../../components/FooterContainer';
import FounderCard from '../../../components/Founder/FounderCard';
import Headline from '../../../components/Headline';
import PageHeader from '../../../containers/page/PageContaineredHeader';
import Paragraph from '../../../components/Paragraph';
import RegisterModal from '../../../containers/RegisterModal';
import SupportModal from '../../../containers/SupportModal';
import View from '../../../components/View';
import actions from '../../../actions';

class DreamDetail extends React.Component {
  state = {
    isOpenRegisterModal: false,
    isOpenSupportModal: false,
    isOpenActionModal: false
  };

  componentDidMount() {
    this.props.getDream(this.props.match.params.id);
  }

  render() {
    const dream = this.props.dream.hash[this.props.match.params.id];

    if (!dream) {
      return null;
    }

    const businessCategory = BusinessCategory.getItem(dream.businessCategoryId);

    const deadline = new Deadline(dream.deadlineAt);

    return (
      <View>
        <PageHeader />
        <DreamDesktopHeader data={dream} businessCategory={businessCategory} />
        <ContentContainer>
          <Row>
            <Column xs={8}>
              <Headline
                size={1}
                style={{
                  color: Color.slate,
                  fontSize: 32,
                  marginTop: 40,
                  marginBottom: 24
                }}
              >
                {dream.title}
              </Headline>
              <DreamDetailLargeContent
                data={dream}
                style={{ marginBottom: 40 }}
              />
              <DreamCommentContent data={dream} />
            </Column>
            <Column xs={4}>
              <Link to={`/founder/${dream.founder.id}`}>
                <FounderCard
                  data={dream.founder}
                  style={{ marginTop: -120, marginBottom: 24 }}
                />
              </Link>
              <DreamProgressCard data={dream} style={{ marginBottom: 24 }} />
              <View style={{ flexDirection: 'row', marginBottom: 40 }}>
                <CardPrimitive style={{ flex: 1, marginRight: 16 }}>
                  <AmountCardBody
                    style={{ padding: '16px 0 16px 0' }}
                    title="支援者"
                    amount={dream.supporterCount}
                    unit="人"
                  />
                </CardPrimitive>
                <CardPrimitive style={{ flex: 1 }}>
                  <AmountCardBody
                    style={{ padding: '16px 0 16px 0' }}
                    title="募集締切まで"
                    amount={deadline.leastAmount}
                    unit={deadline.leastUnit}
                  />
                </CardPrimitive>
              </View>
              <View style={{ marginBottom: 16 }}>
                <Headline size={2} style={{ fontSize: 24 }}>
                  活動レポート
                </Headline>
              </View>
              <DreamReportTimeline
                dreamId={dream.id}
                style={{ marginBottom: 24 }}
              />
              <DreamPurchaseContent data={dream} style={{ marginBottom: 24 }} />
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
        <RegisterModal
          visible={this.state.isOpenRegisterModal}
          onClose={() => this.setState({ isOpenRegisterModal: false })}
          content={
            <View style={{ marginBottom: 8 }}>
              <Headline size={3} style={{ marginBottom: 16 }}>
                VISIONSをご利用になるために
              </Headline>
              <Paragraph>
                VISIONSの各機能をご利用になるためには、新規登録/ログインをする必要があります。
              </Paragraph>
            </View>
          }
        />
        <SupportModal
          dreamId={dream.id}
          visible={this.state.isOpenSupportModal}
          onClose={() => this.setState({ isOpenSupportModal: false })}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamDetail);
