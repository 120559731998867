import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../components/Button';
import Color from '../../constants/Color';
import RegisterButton from '../auth/RegisterButton';
import Style from '../../constants/Style';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class PageHeader extends React.Component {
  render() {
    const { style = {} } = this.props;

    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px ',
          zIndex: 10,
          ...Style.shadow.medium,
          ...style
        }}
      >
        <Link to="/">
          <Text
            style={{
              fontSize: 17,
              fontWeight: 'bold',
              color: Color.slate,
              letterSpacing: 4
            }}
          >
            VISIONS
          </Text>
        </Link>
        {this.props.auth.uid ? (
          <Link to="/mypage">
            <Button
              variant="outlined"
              color={Color.pink}
              tiny
              shadow={false}
              onClick={() => this.setState({ isOpenRegisterModal: true })}
            >
              <Text
                style={{ fontSize: 13, fontWeight: 'bold', color: Color.pink }}
              >
                マイページ
              </Text>
            </Button>
          </Link>
        ) : (
          <RegisterButton />
        )}
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageHeader);
