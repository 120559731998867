export default class Job {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      iconImage: state.iconImage,
      businessCategoryId: state.businessCategoryId,
      bannerImage: state.bannerImage,
      desktopHeaderImage: state.desktopHeaderImage,
      name: state.name,
      iconName: state.iconName,
      alphabet: state.alphabet,
      kana: state.kana,
      detail: state.detail,
      yearlyProfitAmount: state.yearlyProfitAmount,
      experiencedYears: state.experiencedYears,
      fundAmount: state.fundAmount,
      planContent: state.planContent,
      skillContent: state.skillContent,
      fundContent: state.fundContent,
      successProjects: state.successProjects
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
