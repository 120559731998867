import React from 'react';

import { PdfImage, PdfText, PdfView } from '../../utils/Pdf';

export default function PdfHeadline({
  title,
  subTitle,
  descImage,
  style = {}
}) {
  return (
    <PdfView style={style}>
      <PdfView
        style={{
          flexDirection: 'row'
        }}
      >
        <PdfImage
          src={descImage}
          style={{
            width: 7,
            height: 14,
            marginTop: 3,
            marginRight: 4
          }}
        />
        <PdfView
          style={{
            flexDirection: 'row',
            alignItems: 'baseline'
          }}
        >
          <PdfText
            style={{
              fontSize: 20,
              fontWeight: 'bold'
            }}
          >
            {title}
          </PdfText>
          <PdfText style={{ fontSize: 13, marginBottom: 1 }}>
            {subTitle}
          </PdfText>
        </PdfView>
      </PdfView>
    </PdfView>
  );
}
