import { Link } from 'react-router-dom';
import React from 'react';

import Color from '../../constants/Color';
import FundingStatusLabel from './FundingStatusLabel';
import Hr from '../Hr';
import MaterialIcon from '../../utils/MaterialIcon';
import Style from '../../constants/Style';
import Text from '../Text';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

export default function DreamDetailHeader({
  data: {
    deadlineAt,
    title,
    subTitle,
    founder,
    fundingTitle,
    currentFundingPrice,
    fundingGoalPrice,
    cardBackgroundImage,
    backgroundGradient
  },
  style = {}
}) {
  return (
    <View style={style}>
      <View
        style={{
          backgroundImage: `url(${cardBackgroundImage.uri})`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
          justifyContent: 'flex-end',
          width: 320,
          height: 440,
          padding: 24
        }}
      >
        <View style={{ flexDirection: 'row', marginBottom: 8 }}>
          <FundingStatusLabel
            data={{
              currentFundingPrice,
              fundingGoalPrice,
              deadlineAt
            }}
            style={Style.shadow.medium}
          />
        </View>
        <Text
          style={{
            color: Color.white,
            fontSize: 17,
            fontFamily: 'YuMincho',
            marginBottom: 16,
            textShadow: `${Color.black} 0 2px 4px`
          }}
        >
          {fundingTitle}
        </Text>
      </View>
      <View style={{ marginTop: -24, padding: '0 24px' }}>
        <View
          style={{
            borderRadius: 8,
            padding: 16,
            backgroundColor: Color.white,
            ...Style.shadow.large
          }}
        >
          <View style={{ marginBottom: 8 }}>
            <Link to={`/founder/${founder.id}`}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <UserIcon
                    source={founder.iconImage}
                    size={32}
                    style={{ marginRight: 8, ...Style.shadow.medium }}
                  />
                  <Text
                    style={{
                      color: Color.slate,
                      fontSize: 15,
                      alignSelf: 'center'
                    }}
                  >
                    {founder.screenName}
                  </Text>
                </View>
                <MaterialIcon
                  icon="arrow_forward_ios"
                  color={Color.slate}
                  size={15}
                />
              </View>
            </Link>
          </View>
          <Hr style={{ marginBottom: 16 }} />
          <Text
            style={{
              color: Color.lightSlate,
              fontSize: 15,
              fontWeight: 'bold',
              marginBottom: 8
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              color: Color.slate,
              fontSize: 13
            }}
          >
            {subTitle}
          </Text>
        </View>
      </View>
    </View>
  );
}
