import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { DREAM_APPLICATION_ARTICLES } from '../../../constants/Articles/DreamApplication';
import {
  DREAM_APPLICATION_LABELS,
  DREAM_APPLICATION_VALIDATION_RULE
} from '../../../constants/Validations';
import { validateAll } from '../../../utils/Validation';
import ArrowStream from '../../../components/Icons/ArrowStream';
import ArticleTemplate from '../../../components/Article/ArticleTemplate';
import Button from '../../../components/Button';
import CardPrimitive from '../../../components/Card/CardPrimitive';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import Container from '../../../components/Container';
import ContentContainer from '../../../components/Page/ContentContainer';
import DreamApplication from '../../../models/DreamApplication';
import DreamApplicationFormField from '../../../components/Dream/DreamApplicationFormField';
import FooterContainer from '../../../components/FooterContainer';
import Headline from '../../../components/Headline';
import PageContaineredHeader from '../../../containers/page/PageContaineredHeader';
import Paragraph from '../../../components/Paragraph';
import ResponseDialog from '../../../components/ResponseDialog';
import View from '../../../components/View';
import actions from '../../../actions';

class DreamApplicationIndex extends React.Component {
  state = {
    ...new DreamApplication().object
  };

  render() {
    return (
      <View>
        <PageContaineredHeader />
        <ContentContainer>
          <Container>
            <Row style={{ marginTop: 64 }}>
              <Column>
                <View style={{ marginBottom: 24 }}>
                  <Headline style={{ fontSize: 32, marginBottom: 16 }}>
                    応募の流れ
                  </Headline>
                  <Paragraph
                    style={{ fontSize: 17 }}
                    containerStyle={{ marginBottom: 40 }}
                  >
                    下記の4
                    STEPで、プロジェクト事業者（ファウンダー）へ応募することができます。
                  </Paragraph>
                </View>
              </Column>
            </Row>
            <Row>
              <Column>
                <View>
                  {DREAM_APPLICATION_ARTICLES.map((article, index) => {
                    const key = `article-${index}`;

                    return (
                      <ArticleTemplate
                        key={key}
                        title={article.title}
                        number={article.number}
                        description={article.description}
                        sections={article.sections}
                        style={{ marginBottom: 32 }}
                      />
                    );
                  })}
                </View>
              </Column>
            </Row>
            <Row>
              <Column>
                <View>
                  <Paragraph
                    style={{ fontSize: 17 }}
                    containerStyle={{ marginBottom: 24 }}
                  >
                    審査に合格後、数回のミーティングでプロジェクトの開始時期、内容について精査していき、プロジェクトを公開します。
                  </Paragraph>
                </View>
                <View style={{ alignItems: 'center' }}>
                  <ArrowStream
                    color={Color.slate}
                    style={{ marginBottom: 40 }}
                  />
                  <Paragraph
                    style={{ fontSize: 24, fontWeight: 'bold' }}
                    containerStyle={{ marginBottom: 24 }}
                  >
                    フォームに入力して、プロジェクトへ応募してください。
                  </Paragraph>
                </View>
              </Column>
            </Row>
            <Row>
              <Column offset={{ xs: 1 }} xs={10}>
                <CardPrimitive style={{ padding: 16, marginBottom: 64 }}>
                  <Headline
                    style={{ color: Color.lightSlate, marginBottom: 16 }}
                  >
                    応募フォーム
                  </Headline>
                  <DreamApplicationFormField
                    params={this.state}
                    onChangeParams={state => this.setState(state)}
                    rules={DREAM_APPLICATION_VALIDATION_RULE}
                  />
                  <Button
                    disable={this.props.dreamApplication.isCreateLoading}
                    onClick={() => {
                      const errorMessage = validateAll({
                        labels: DREAM_APPLICATION_LABELS,
                        params: this.state,
                        rules: DREAM_APPLICATION_VALIDATION_RULE
                      });

                      if (errorMessage) {
                        alert(errorMessage);

                        return;
                      }

                      this.props.createDreamApplication(this.state);
                    }}
                    style={{ marginBottom: 24 }}
                  >
                    プロジェクトを応募する
                  </Button>
                  <ResponseDialog
                    isLoading={this.props.dreamApplication.isCreateLoading}
                    isFailed={this.props.dreamApplication.isCreateFailed}
                    error={this.props.dreamApplication.createError}
                    completeMessage="応募が完了しました。"
                    onComplete={() =>
                      this.setState({
                        ...new DreamApplication().object
                      })
                    }
                    onError={() => {
                      this.props.recoverCreateDreamApplication();
                    }}
                  />
                </CardPrimitive>
              </Column>
            </Row>
          </Container>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamApplicationIndex);
