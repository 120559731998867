import moment from 'moment';

export default class Deadline {
  constructor(deadlineAt) {
    this._deadlineAt = moment(deadlineAt);

    const result = this._deadlineAt.fromNow().match(/(\d+)(.+)/);

    this._leastAmount = result[1];

    this._leastUnit = result[2];
  }

  get isFinished() {
    return moment().diff(this._deadlineAt) > 0;
  }

  get leastAmount() {
    if (this.isFinished) {
      return '終了';
    }

    return this._leastAmount;
  }

  get leastUnit() {
    if (this.isFinished) {
      return '';
    }

    return this._leastUnit;
  }
}
