import { getCollection } from '../utils/Firebase';
import ErrorMessages from '../constants/ErrorMessages';
import Job from '../models/Job';
import JobSuccessProject from '../models/JobSuccessProject';
import * as types from '../constants/ActionTypes';

// get jobs
function fetchGetJobs() {
  return {
    type: types.FETCH_GET_JOBS
  };
}

function fetchGetJobsSuccess(list) {
  return {
    type: types.FETCH_GET_JOBS_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetJobsFailed(error) {
  return {
    type: types.FETCH_GET_JOBS_FAILED,
    error
  };
}

export function recoverGetJobs() {
  return {
    type: types.FETCH_GET_JOBS_RECOVER
  };
}

export function getJobs(jobId) {
  return dispatch => {
    dispatch(fetchGetJobs());

    let query = getCollection('jobs').orderBy('createdAt', 'desc');

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const jobId = doc.id;

            const { docs: projectDocs } = await getCollection('jobs')
              .doc(jobId)
              .collection('successProjects')
              .get();

            const successProjects = await Promise.all(
              projectDocs.map(async doc => {
                return new JobSuccessProject({
                  id: doc.id,
                  ...doc.data()
                }).fromFirestore();
              })
            );

            return new Job({
              id: doc.id,
              successProjects,
              ...doc.data()
            }).fromFirestore();
          })
        );

        return dispatch(fetchGetJobsSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetJobsFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}

// get job successProject
function fetchGetJob() {
  return {
    type: types.FETCH_GET_JOB
  };
}

function fetchGetJobSuccess(id, params) {
  return {
    type: types.FETCH_GET_JOB_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetJobFailed(error) {
  return {
    type: types.FETCH_GET_JOB_FAILED,
    error
  };
}

export function recoverGetJob() {
  return {
    type: types.FETCH_GET_JOB_RECOVER
  };
}

export function getJob(jobId) {
  return dispatch => {
    dispatch(fetchGetJob());

    const jobs = getCollection('jobs').doc(jobId);

    return jobs
      .get()
      .then(async doc => {
        const { docs: successProjectDocs } = await getCollection('jobs')
          .doc(jobId)
          .collection('successProjects')
          .get();

        const successProjects = successProjectDocs.map(doc => {
          return new JobSuccessProject({
            id: doc.id,
            ...doc.data()
          }).fromFirestore();
        });

        const job = new Job({
          id: doc.id,
          successProjects,
          ...doc.data()
        }).fromFirestore();

        return dispatch(fetchGetJobSuccess(jobId, job));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetJobFailed(error));
        }
      });
  };
}
