import { Link } from 'react-router-dom';
import React from 'react';

import { getFundingTypeExplain } from '../../models/DreamFundingType';
import Deadline from '../../utils/Deadline';
import DonateButton from './DonateButton';
import GiftList from './GiftList';
import Headline from '../../components/Headline';
import Paragraph from '../../components/Paragraph';
import ProductList from './ProductList';
import ProductPurchaseLink from './ProductPurchaseLink';
import SupporterProgress from '../../components/Dream/SupporterProgress';
import View from '../../components/View';

function renderDonationTitle({ dream }) {
  return (
    <View>
      <Headline size={2} style={{ fontSize: 24, marginBottom: 16 }}>
        支援のお礼
      </Headline>
      <Paragraph containerStyle={{ marginBottom: 24 }}>
        {getFundingTypeExplain(dream.fundingType)}
      </Paragraph>
    </View>
  );
}

function renderProductTitle({ dream }) {
  return (
    <View>
      <Headline size={2} style={{ fontSize: 24, marginBottom: 16 }}>
        支援するコースを選択する
      </Headline>
      <Paragraph containerStyle={{ marginBottom: 24 }}>
        {getFundingTypeExplain(dream.fundingType)}
      </Paragraph>
    </View>
  );
}

function renderProductList({ dream }) {
  return (
    <View>
      <ProductList
        dreamId={dream.id}
        renderSubmit={({ element, item }) => (
          <ProductPurchaseLink
            to={`/dream/${dream.id}/product/${item.id}/input`}
            stockCount={item.stockCount}
          >
            {element}
          </ProductPurchaseLink>
        )}
      />
    </View>
  );
}

function renderGiftList({ dream }) {
  const deadline = new Deadline(dream.deadlineAt);

  return (
    <View>
      <GiftList data={dream} />
      <SupporterProgress
        supporter={dream.supporter}
        style={{ marginBottom: 16 }}
      />
      <Paragraph onerow containerStyle={{ marginBottom: 16 }}>
        支援のお礼は、支援の合計金額を超えると受け取ることができます。また、寄付をされた方の名前は、
        <Link
          to={`/dream/${dream.id}/thank`}
          style={{
            display: 'inline-flex',
            fontWeight: 'bold',
            textDecoration: 'underline'
          }}
        >
          支援者一覧
        </Link>
        に掲載されます。
      </Paragraph>
      {!deadline.isFinished && <DonateButton data={dream} />}
    </View>
  );
}

function renderTitle({ dream }) {
  switch (dream.fundingType) {
    case 'donation':
      return renderDonationTitle({ dream });

    case 'productAllOrNothing':
    case 'productAllIn':
      return renderProductTitle({ dream });
    default:
      return null;
  }
}

function renderContent({ dream }) {
  switch (dream.fundingType) {
    case 'donation':
      return renderGiftList({ dream });

    case 'productAllOrNothing':
    case 'productAllIn':
      return renderProductList({ dream });
    default:
      return null;
  }
}

export default function DreamPurchaseContent({ data: dream, style = {} }) {
  return (
    <View style={style}>
      {renderTitle({ dream })}
      {renderContent({ dream })}
    </View>
  );
}
