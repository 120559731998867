import { getCollection } from '../utils/Firebase';
import Founder from '../models/Founder';
import * as types from '../constants/ActionTypes';

// get current founders
function fetchGetFounders({ reset = false }) {
  return {
    type: types.FETCH_GET_FOUNDERS,
    state: { reset }
  };
}

function fetchGetFoundersSuccess(list) {
  return {
    type: types.FETCH_GET_FOUNDERS_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetFoundersFailed(error) {
  return {
    type: types.FETCH_GET_FOUNDERS_FAILED,
    error
  };
}

export function recoverGetFounders() {
  return {
    type: types.FETCH_GET_FOUNDERS_RECOVER
  };
}

export function getFounders(options = { reset: false, lastItem: null }) {
  return dispatch => {
    dispatch(fetchGetFounders({ reset: options.reset }));

    let query = getCollection('founders')
      .where('isReplyed', '==', true)
      .orderBy('replyedAt', 'desc');

    if (options.lastItem) {
      query = query.startAfter(options.lastItem.replyedAt);
    }

    return query
      .get()
      .then(({ docs }) => {
        const list = docs.map(doc => {
          const data = doc.data();

          return new Founder({
            id: doc.id,
            ...data
          }).fromFirestore();
        });

        return dispatch(fetchGetFoundersSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetFoundersFailed(error));
        }
      });
  };
}

// get founder
function fetchGetFounder() {
  return {
    type: types.FETCH_GET_FOUNDER
  };
}

function fetchGetFounderSuccess(id, item) {
  return {
    type: types.FETCH_GET_FOUNDER_SUCCESS,
    state: {
      id,
      item
    }
  };
}

function fetchGetFounderFailed(error) {
  return {
    type: types.FETCH_GET_FOUNDER_FAILED,
    error
  };
}

export function recoverGetFounder() {
  return {
    type: types.FETCH_GET_FOUNDER_RECOVER
  };
}

export function getFounder(id) {
  return dispatch => {
    dispatch(fetchGetFounder());

    return Founder.get(id)
      .then(founder => {
        return dispatch(fetchGetFounderSuccess(id, founder));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetFounderFailed(error));
        }
      });
  };
}
