import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  SCHEME_CREATE_FORM_LABELS,
  SCHEME_CREATE_FORM_VALIDATION_RULE,
  SCHEME_METADATA_LABELS,
  SCHEME_METADATA_VALIDATION_RULE
} from '../../../constants/Validations';
import { validate, validateAll } from '../../../utils/Validation';
import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import ContentContainer from '../../../components/Page/ContentContainer';
import FooterContainer from '../../../components/FooterContainer';
import Headline from '../../../components/Headline';
import PageHeader from '../../../containers/page/PageContaineredHeader';
import Panel from '../../../components/Panel';
import Paragraph from '../../../components/Paragraph';
import Scheme from '../../../models/Scheme';
import SchemeFormField from '../../../components/Scheme/SchemeFormField';
import ResponseDispatcher from '../../../components/ResponseDispatcher';
import SectionCard from '../../../components/Card/SectionCard';
import Text from '../../../components/Text';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class SchemeNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scheme: new Scheme(props.schemeForm.formParams.scheme).object,
      editPassword: '',
      viewPassword: ''
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      this.props.updateSchemeFormParams(nextState);

      return true;
    }

    if (nextProps.scheme !== this.props.scheme) {
      return true;
    }

    if (nextProps) return false;
  }

  render() {
    // if (this.props.scheme.isGetLoading) {
    //   return null;
    // }

    return (
      <View>
        <PageHeader />
        <ContentContainer>
          <Row>
            <Column offset={{ xs: 1 }} xs={10}>
              <View style={{ padding: '16px 16px 0px 16px', marginBottom: 24 }}>
                <Headline style={{ marginBottom: 16 }}>ビジネス設計書</Headline>
                <Paragraph>
                  入力フォームに入力して、あなたのビジネスの構造と、その作用について明らかにしましょう。
                </Paragraph>
              </View>
              <SchemeFormField
                params={this.state}
                onChangeParams={state => this.setState(state)}
              />
              <SectionCard
                title="パスワード"
                panel={
                  <Panel size={40} style={{ backgroundColor: Color.pink }}>
                    <Text
                      style={{
                        color: Color.white,
                        fontWeight: 'bold',
                        fontSize: 24
                      }}
                    >
                      07
                    </Text>
                  </Panel>
                }
                style={{ marginBottom: 24 }}
              >
                <TextFormField
                  value={this.state.viewPassword}
                  name="viewPassword"
                  direction="column"
                  type="password"
                  verification
                  onChangeText={viewPassword => {
                    this.setState({
                      viewPassword
                    });
                  }}
                  label="閲覧パスワード"
                  errorMessage={validate({
                    label: SCHEME_METADATA_LABELS.viewPassword,
                    value: this.state.viewPassword,
                    rule: SCHEME_METADATA_VALIDATION_RULE.viewPassword
                  })}
                  limitLength={
                    SCHEME_METADATA_VALIDATION_RULE.viewPassword.maxLength
                  }
                  helpText="閲覧のためのパスワードを設定する場合は、パスワードを設定してください。"
                  style={{ marginBottom: 16 }}
                />
                <TextFormField
                  value={this.state.editPassword}
                  name="editPassword"
                  direction="column"
                  type="password"
                  verification
                  onChangeText={editPassword => {
                    this.setState({
                      editPassword
                    });
                  }}
                  label="編集パスワード"
                  errorMessage={validate({
                    label: SCHEME_METADATA_LABELS.editPassword,
                    value: this.state.editPassword,
                    rule: SCHEME_METADATA_VALIDATION_RULE.editPassword
                  })}
                  limitLength={
                    SCHEME_METADATA_VALIDATION_RULE.editPassword.maxLength
                  }
                  helpText="編集のためのパスワードを設定する場合は、パスワードを設定してください。"
                  style={{ marginBottom: 16 }}
                />
              </SectionCard>
              <Button
                disable={this.props.scheme.isCreateLoading}
                onClick={() => {
                  const errorMessage = validateAll({
                    labels: SCHEME_CREATE_FORM_LABELS,
                    params: this.state,
                    rules: SCHEME_CREATE_FORM_VALIDATION_RULE
                  });

                  if (errorMessage) {
                    alert(errorMessage);

                    return;
                  }

                  this.props.createScheme(
                    this.state.scheme,
                    this.state.viewPassword,
                    this.state.editPassword
                  );
                }}
                style={{ marginBottom: 24 }}
              >
                ビジネス設計書を生成する
              </Button>
              <ResponseDispatcher
                isLoading={this.props.scheme.isCreateLoading}
                isFailed={this.props.scheme.isCreateFailed}
                error={this.props.scheme.createError}
                onComplete={() =>
                  this.props.history.push(
                    `/scheme/${this.props.scheme.list[0]}/result`
                  )
                }
                onError={() => {
                  alert(this.props.scheme.createError.message);

                  this.props.recoverCreateScheme();
                }}
              />
              <Paragraph
                containerStyle={{ marginBottom: 24 }}
                style={{ textAlign: 'center' }}
              >
                ボタンを押すことでプライバシーポリシーと
                {'\n'}
                利用規約に同意したものとみなします。
              </Paragraph>
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchemeNew);
