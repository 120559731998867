import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Headline from '../../../components/Headline';
import PageHeader from '../../../containers/page/PageHeader';
import ThankTimelineList from '../../../components/Dream/ThankTimelineList';
import View from '../../../components/View';
import actions from '../../../actions';

class DreamThankIndex extends React.Component {
  render() {
    return (
      <View>
        <PageHeader />
        <View style={{ marginTop: 24, marginLeft: 24, marginBottom: 16 }}>
          <Headline>THANK YOU!!</Headline>
        </View>
        <ThankTimelineList
          dreamId={this.props.match.params.dreamId}
          style={{ padding: '0 24px' }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamThankIndex);
