import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class MarketingMarketFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.competitorDefinition}
          name="competitorDefinition"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={competitorDefinition => {
            onChangeParams({
              competitorDefinition
            });
          }}
          label="(1) だれが競合なのか"
          errorMessage={
            rules &&
            validate({
              label: 'だれが競合なのか',
              value: params.competitorDefinition,
              rule: rules.competitorDefinition
            })
          }
          limitLength={rules.competitorDefinition.maxLength}
          helpText="誰が競合なのか、誰が競争相手なのかを定義しましょう。そして、誰が競争相手でないかについても触れておきましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.customerDefinition}
          name="customerDefinition"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={customerDefinition => {
            onChangeParams({
              customerDefinition
            });
          }}
          label="(2) 誰が客なのか"
          errorMessage={
            rules &&
            validate({
              label: '誰が客なのか',
              value: params.customerDefinition,
              rule: rules.customerDefinition
            })
          }
          limitLength={rules.customerDefinition.maxLength}
          helpText="だれが競合なのかの定義を踏まえ、誰がお客さんなのかを定義しましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.nonCustomerDefinition}
          name="nonCustomerDefinition"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={nonCustomerDefinition => {
            onChangeParams({
              nonCustomerDefinition
            });
          }}
          label="(3) 客でないのは誰か"
          errorMessage={
            rules &&
            validate({
              label: '客でないのは誰か',
              value: params.nonCustomerDefinition,
              rule: rules.nonCustomerDefinition
            })
          }
          limitLength={rules.nonCustomerDefinition.maxLength}
          helpText="定義したお客さんを踏まえ、誰がお客さんでないのかも定義し、市場の境界線を明確にしましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.scale}
          name="scale"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={scale => {
            onChangeParams({
              scale
            });
          }}
          label="(4) 市場の規模"
          errorMessage={
            rules &&
            validate({
              label: '市場の規模',
              value: params.scale,
              rule: rules.scale
            })
          }
          limitLength={rules.scale.maxLength}
          helpText="どのくらい大きな市場なのかについて記述しましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.potential}
          name="potential"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={potential => {
            onChangeParams({
              potential
            });
          }}
          label="(5) 成長性"
          errorMessage={
            rules &&
            validate({
              label: '成長性',
              value: params.potential,
              rule: rules.potential
            })
          }
          limitLength={rules.potential.maxLength}
          helpText="その市場がどれだけ成長しているのかについて記述しましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.competitorEnvironment}
          name="competitorEnvironment"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={competitorEnvironment => {
            onChangeParams({
              competitorEnvironment
            });
          }}
          label="(6) 競合環境"
          errorMessage={
            rules &&
            validate({
              label: '競合環境',
              value: params.competitorEnvironment,
              rule: rules.competitorEnvironment
            })
          }
          limitLength={rules.competitorEnvironment.maxLength}
          helpText="どのくらいの数の競合が、どういう状況でひしめきあっているのかを分析しましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.ability}
          name="ability"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={ability => {
            onChangeParams({
              ability
            });
          }}
          label="(7) 能力と親和性"
          errorMessage={
            rules &&
            validate({
              label: '能力と親和性',
              value: params.ability,
              rule: rules.ability
            })
          }
          limitLength={rules.ability.maxLength}
          helpText="自分たちが持っている能力との親和性について記述しましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.synergy}
          name="synergy"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={synergy => {
            onChangeParams({
              synergy
            });
          }}
          label="(8) 既存事業とのシナジー"
          errorMessage={
            rules &&
            validate({
              label: '既存事業とのシナジー',
              value: params.synergy,
              rule: rules.synergy
            })
          }
          limitLength={rules.synergy.maxLength}
          helpText="既存の事業とのシナジーがうまく効くかについて記述しましょう。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingMarketFormField);
