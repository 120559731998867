import React from 'react';

import Image from '../Image';
import View from '../View';

export default class ReportSecretSliderItem extends React.Component {
  render() {
    const { source, style = {} } = this.props;

    return (
      <View style={style}>
        <Image source={source} style={{ width: '100%', borderRadius: 16 }} />
      </View>
    );
  }
}

