import { getCollection } from '../utils/Firebase';
import DocumentHistory from '../models/DocumentHistory';
import ErrorMessages from '../constants/ErrorMessages';
import * as types from '../constants/ActionTypes';

// get documentHistories
function fetchGetDocumentHistories() {
  return {
    type: types.FETCH_GET_DOCUMENT_HISTORIES
  };
}

function fetchGetDocumentHistoriesSuccess(list) {
  return {
    type: types.FETCH_GET_DOCUMENT_HISTORIES_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetDocumentHistoriesFailed(error) {
  return {
    type: types.FETCH_GET_DOCUMENT_HISTORIES_FAILED,
    error
  };
}

export function recoverGetDocumentHistories() {
  return {
    type: types.FETCH_GET_DOCUMENT_HISTORIES_RECOVER
  };
}

export function getDocumentHistories({ type = null }) {
  return dispatch => {
    dispatch(fetchGetDocumentHistories());

    let query = getCollection('documentHistories')
      .where('type', '==', type)
      .orderBy('createdAt', 'desc')
      .limit(1);

    return query
      .get()
      .then(({ docs }) => {
        const list = docs.map(doc =>
          new DocumentHistory({
            id: doc.id,
            ...doc.data()
          }).fromFirestore()
        );

        return dispatch(fetchGetDocumentHistoriesSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetDocumentHistoriesFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}
