import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../utils/Validation';
import TextFormField from './Form/TextFormField';
import View from './View';
import actions from '../actions';

class ApplicationFormField extends React.Component {
  render() {
    const {
      params,
      onChangeParams,
      rules,
      direction = 'row',
      style = {}
    } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.email}
          direction={direction}
          onChangeText={email => {
            onChangeParams({ email });
          }}
          label="メールアドレス"
          labelWidth={140}
          errorMessage={
            rules &&
            validate({
              label: 'メールアドレス',
              value: params.email,
              rule: rules.email
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.phoneNumber}
          direction={direction}
          onChangeText={phoneNumber => {
            onChangeParams({ phoneNumber });
          }}
          label="電話番号"
          labelWidth={140}
          errorMessage={
            rules &&
            validate({
              label: '電話番号',
              value: params.phoneNumber,
              rule: rules.phoneNumber
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          value={params.editPassword}
          direction={direction}
          name="editPassword"
          type="password"
          onChangeText={editPassword => {
            onChangeParams({ editPassword });
          }}
          label="編集パスワード"
          labelWidth={140}
          errorMessage={
            rules &&
            validate({
              label: '編集パスワード',
              value: params.editPassword,
              rule: rules.editPassword
            })
          }
          helpText="応募のためには、編集パスワードの入力が必要です。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationFormField);
