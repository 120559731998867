export function getFundingTypeExplain(type) {
  switch (type) {
    case 'donation':
      return 'このプロジェクトは寄附型です。';
    case 'productAllIn':
      return 'このプロジェクトはAll-in型です。達成の成否にかかわらず購入したコースが実施されます。';
    case 'productAllOrNothing':
      return 'このプロジェクトはAll-or-Nothing型です。達成された場合のみ決済が行われ、購入したコースが実施されます。';
    default:
      return '';
  }
}
