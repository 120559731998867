import { getHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isCreateLoading: false,
  isCreateFailed: false,
  createError: null,
  isUpdateLoading: false,
  isUpdateFailed: false,
  updateError: null
};

export default (state = initialState, action) => {
  let newHash;

  switch (action.type) {
    // get jobs
    case types.FETCH_GET_JOBS:
      return {
        ...state,
        isGetLoading: true
      };

    case types.FETCH_GET_JOBS_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      return {
        ...state,
        list: action.state.list.map(item => item.id),
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_JOBS_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_JOBS_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // get job
    case types.FETCH_GET_JOB:
      return {
        ...state,
        isGetLoading: true
      };

    case types.FETCH_GET_JOB_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        list: [action.state.id],
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_JOB_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_JOB_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    default:
      return state;
  }
};
