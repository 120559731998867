import Model from './Model';

export default class Proposal extends Model {
  constructor(state = {}) {
    super();

    this.params = {
      ...state,
      basicInfo: {
        title: '',
        name: '',
        profileImage: {},
        birthday: '',
        job: '',
        biography: '',
        businessCategoryId: 'unselected',
        primaryColor: 'EE5465',
        secondaryColor: '6DA5BF',
        ...state.basicInfo
      },
      introduction: {
        story: '',
        ux: '',
        factor: '',
        ...state.introduction
      },
      specification: {
        specification: '',
        plan: '',
        supply: '',
        pricing: '',
        timeOfDeliver: '',
        supplyClassId: 'unselected',
        pricingClassId: 'unselected',
        timeOfDeliverClassId: 'unselected',
        ...state.specification
      },
      businessProcess: state.businessProcess || {
        buying: '',
        production: '',
        distribution: '',
        marketing: '',
        ...state.businessProcess
      },
      environment: state.environment || {
        market: '',
        competitor: '',
        substitute: '',
        risk: '',
        ...state.environment
      },
      feature: state.feature || {
        strengths: '',
        weaknesses: '',
        opportunities: '',
        threats: '',
        identity: '',
        potential: '',
        keyFactor: '',
        issue: '',
        ...state.feature
      },
      profit: state.profit || {
        salesTitle1: '',
        salesAmount1: '',
        salesUnit1: '',
        salesTitle2: '',
        salesAmount2: '',
        salesUnit2: '',
        salesTitle3: '',
        salesAmount3: '',
        salesUnit3: '',
        costTitle1: '',
        costAmount1: '',
        costUnit1: '',
        costTitle2: '',
        costAmount2: '',
        costUnit2: '',
        costTitle3: '',
        costAmount3: '',
        costUnit3: '',
        salesTotalTitle: '',
        salesTotalAmount: '',
        salesTotalUnit: '',
        costTotalTitle: '',
        costTotalAmount: '',
        costTotalUnit: '',
        profitTitle: '',
        profitAmount: '',
        profitUnit: '',
        ...state.profit
      }
    };
  }

  get object() {
    return this.params;
  }

  get fields() {
    return {
      basicInfo: this.params.basicInfo,
      introduction: this.params.introduction,
      specification: this.params.specification,
      businessProcess: this.params.businessProcess,
      environment: this.params.environment,
      feature: this.params.feature
    };
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
