import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import ActivityHistoryItem from '../../components/Activity/ActivityHistoryItem';
import LoadMoreList from '../../components/List/LoadMoreList';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class ActivityHistoryList extends React.Component {
  componentDidMount() {
    this.props.getActivityHistories({ reset: true });
  }

  render() {
    const { style = {} } = this.props;

    if (
      this.props.activityHistory.list.length < 1 &&
      !this.props.activityHistory.isGetLoading
    ) {
      return (
        <View style={{ padding: 16 }}>
          <Text>履歴がありません。</Text>
        </View>
      );
    }

    return (
      <View
        style={{
          ...style
        }}
      >
        <LoadMoreList
          data={this.props.activityHistory.list.map(
            id => this.props.activityHistory.hash[id]
          )}
          renderItem={({ item, index }) => {
            return <ActivityHistoryItem key={item.id} {...item} />;
          }}
          onFetchMore={lastItem => {
            this.props.getActivityHistories({ lastItem });
          }}
          isLoading={this.props.activityHistory.isGetLoading}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityHistoryList);
