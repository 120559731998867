import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Footer from '../../../../components/Footer';
import PageHeader from '../../../../containers/page/PageHeader';
import ReportItem from '../../../../components/Dream/ReportItem';
import View from '../../../../components/View';
import actions from '../../../../actions';

class DreamReportDetail extends React.Component {
  componentDidMount() {
    this.props.getDreamReport(
      this.props.match.params.dreamId,
      this.props.match.params.reportId
    );
  }

  render() {
    const report = this.props.dreamReport.hash[
      this.props.match.params.reportId
    ];

    if (!report) {
      return null;
    }

    return (
      <View>
        <PageHeader />
        <ReportItem data={report} style={{ marginBottom: 16 }} />
        <Footer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamReportDetail);
