import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { SHIPPING_INFO_VALIDATION_RULE } from '../../../../constants/Validations';
import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import Column from '../../../../components/Column';
import ContentContainer from '../../../../components/Page/ContentContainer';
import FooterContainer from '../../../../components/FooterContainer';
import Headline from '../../../../components/Headline';
import MypageMenu from '../../../../containers/mypage/MypageMenu';
import PageHeader from '../../../../containers/page/PageContaineredHeader';
import ResponseDispatcher from '../../../../components/ResponseDispatcher';
import Section from '../../../../components/Section/Section';
import ShippingInfo from '../../../../models/ShippingInfo';
import ShippingInfoFormField from '../../../../containers/form/ShippingInfoFormField';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypageShippingInfoDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: new ShippingInfo().object
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.shippingInfo.isGetLoading &&
      !nextProps.shippingInfo.isGetLoading
    ) {
      const shippingInfo =
        nextProps.shippingInfo.hash[this.props.match.params.id];

      this.setState({
        params: shippingInfo
      });
    }

    return true;
  }

  componentDidMount() {
    this.props.getShippingInfo(this.props.match.params.id);
  }

  render() {
    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <ContentContainer>
          <Row style={{ margin: '40px 0' }}>
            <Column xs={4}>
              <MypageMenu />
            </Column>
            <Column xs={8}>
              <Headline style={{ fontSize: 32, marginBottom: 40 }}>
                パトロン
              </Headline>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 16
                }}
              >
                <Headline size={3}>カード情報</Headline>
                <Button
                  tiny
                  color={Color.red}
                  onClick={() => {
                    const result = window.confirm(
                      'お届先情報を削除します。よろしいですか？'
                    );

                    if (result) {
                      this.props.deleteShippingInfo(this.props.match.params.id);
                    }
                  }}
                >
                  削除する
                </Button>
              </View>
              <Section
                sideline
                style={{
                  padding: 16,
                  marginBottom: 40,
                  borderBottom: `1px solid ${Color.lightGrey}`
                }}
              >
                <ShippingInfoFormField
                  params={this.state.params}
                  onChangeParams={state =>
                    this.setState({
                      params: { ...this.state.params, ...state }
                    })
                  }
                  rules={SHIPPING_INFO_VALIDATION_RULE}
                />
              </Section>
              <Button
                disable={this.props.shippingInfo.isUpdateLoading}
                onClick={() =>
                  this.props.updateShippingInfo(
                    this.props.match.params.id,
                    new ShippingInfo(this.state.params).fields
                  )
                }
                style={{ marginBottom: 24 }}
              >
                更新する
              </Button>
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
        <ResponseDispatcher
          isLoading={this.props.shippingInfo.isUpdateLoading}
          isFailed={this.props.shippingInfo.isUpdateFailed}
          onComplete={() => {
            this.props.getShippingInfos();

            this.props.history.push('/mypage/shippingInfo');
          }}
        />
        <ResponseDispatcher
          isLoading={this.props.shippingInfo.isDeleteLoading}
          isFailed={this.props.shippingInfo.isDeleteFailed}
          onComplete={() => {
            this.props.getShippingInfos();

            this.props.history.push('/mypage/shippingInfo');
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypageShippingInfoDetail);
