import React from 'react';

import Color from '../../constants/Color';
import Style from '../../constants/Style';
import View from '../View';

export default function SeparatedCard({
  head,
  body,
  headStyle = {},
  bodyStyle = {},
  style = {}
}) {
  return (
    <View style={style}>
      <View
        style={{
          backgroundColor: Color.white,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          zIndex: 1,
          ...Style.shadow.medium,
          ...headStyle
        }}
      >
        {head}
      </View>
      <View
        style={{
          backgroundColor: Color.smokeWhite,
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          ...Style.shadow.medium,
          ...bodyStyle
        }}
      >
        {body}
      </View>
    </View>
  );
}
