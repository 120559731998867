import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import View from '../View';

export default function ArticleHeadline({
  number,
  children,
  after,
  style = {}
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'baseline',
        ...style
      }}
    >
      <Text
        style={{
          color: Color.pink,
          fontWeight: 'bold',
          fontSize: 40,
          marginRight: 8,
          lineHeight: 1
        }}
      >
        {number}
      </Text>
      <Text
        style={{
          color: Color.slate,
          fontWeight: 'bold',
          fontSize: 24,
          marginRight: 8
        }}
      >
        {children}
      </Text>
      {after}
    </View>
  );
}
