import React from 'react';

import Color from '../constants/Color';
import Text from './Text';
import View from './View';

export default function MessagePlate({ title, message, large = false }) {
  return (
    <View
      style={{
        backgroundColor: Color.printWhite,
        alignItems: 'center',
        justifyContent: 'center',
        padding: large ? '64px 80px' : '24px 40px'
      }}
    >
      <Text
        style={{
          color: Color.navyBlack,
          fontWeight: 'bold',
          fontSize: large ? 32 : 24
        }}
      >
        {title}
      </Text>
      <View
        style={{
          width: '100%',
          height: large ? 5 : 3,
          margin: '12px 0 16px 0',
          backgroundColor: Color.navyBlack
        }}
      />
      <Text
        style={{
          color: Color.navyBlack,
          fontWeight: 'bold',
          fontSize: large ? 17 : 11
        }}
      >
        {message}
      </Text>
    </View>
  );
}
