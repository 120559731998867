import React from 'react';

import { PdfText, PdfView } from '../../utils/Pdf';
import Color from '../../constants/Color';

export default function PdfCard({
  title,
  content,
  label,
  color,
  subColor,
  style = {}
}) {
  return (
    <PdfView
      style={{
        backgroundColor: Color.white,
        border: `1pt solid ${Color.warmGrey}`,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 16,
        paddingRight: 16,
        ...style
      }}
    >
      <PdfView
        style={{
          marginBottom: 8
        }}
      >
        <PdfView
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <PdfText
            style={{
              fontSize: 12,
              fontWeight: 'bold',
              color
            }}
          >
            {title}
          </PdfText>
          {label && (
            <PdfView
              style={{
                borderRadius: 16,
                backgroundColor: subColor,
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 8,
                paddingRight: 8
              }}
            >
              <PdfText
                style={{
                  fontSize: 10,
                  fontWeight: 'bold',
                  color: Color.white
                }}
              >
                {label}
              </PdfText>
            </PdfView>
          )}
        </PdfView>
      </PdfView>
      <PdfText style={{ fontSize: 10, lineHeight: 1.3 }}>{content}</PdfText>
    </PdfView>
  );
}
