import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import Headline from '../../../../components/Headline';
import Label from '../../../../components/Form/Label';
import Markdown from '../../../../components/Markdown';
import PageHeader from '../../../../containers/page/PageHeader';
import ResponseDispatcher from '../../../../components/ResponseDispatcher';
import Section from '../../../../components/Section/Section';
import TextFormField from '../../../../components/Form/TextFormField';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypagePaymentInfoIndex extends React.Component {
  componentDidMount() {
    this.props.getPatronContract(this.props.match.params.id);
  }

  render() {
    const patronContract = this.props.patronContract.hash[
      this.props.match.params.id
    ];

    if (!patronContract) {
      return null;
    }

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <View style={{ paddingTop: 32, marginBottom: 32 }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 16px 0 16px',
              marginBottom: 16
            }}
          >
            <Headline size={3} style={{ fontSize: 15 }}>
              パトロン支援
            </Headline>
            <Button
              tiny
              color={Color.red}
              onClick={() => {
                const result = window.confirm(
                  'パトロン支援を解除します。よろしいですか？'
                );

                if (result) {
                  this.props.deletePatronContract(this.props.match.params.id);
                }
              }}
            >
              解除する
            </Button>
          </View>
          <Section
            style={{
              padding: 16,
              marginBottom: 16,
              borderBottom: `1px solid ${Color.lightGrey}`
            }}
          >
            <TextFormField
              staticControl
              labelWidth={120}
              label="ファウンダー"
              value={patronContract.founder.screenName}
              style={{ marginBottom: 24 }}
            />
            <TextFormField
              staticControl
              labelWidth={120}
              label="月額"
              value={patronContract.amount}
            />
          </Section>
          <Section
            style={{
              padding: 16,
              marginBottom: 16,
              borderBottom: `1px solid ${Color.lightGrey}`
            }}
          >
            <Label style={{ marginBottom: 16 }}>パトロン特典</Label>
            <Markdown>{patronContract.founder.patronGiftText}</Markdown>
          </Section>
          <View style={{ padding: '0 16px', marginBottom: 16 }}>
            <Link to={`/founder/${patronContract.founder.id}`}>
              <Button>ファウンダーのページへ</Button>
            </Link>
          </View>
          <View style={{ padding: '0 16px' }}>
            <Link to="/mypage/patronContract">
              <Button variant="flat">戻る</Button>
            </Link>
          </View>
        </View>
        <ResponseDispatcher
          isLoading={this.props.patronContract.isDeleteLoading}
          isFailed={this.props.patronContract.isDeleteFailed}
          onComplete={() => {
            this.props.getMyselfUser(this.props.auth.uid);

            this.props.history.push('/mypage/patronContract');
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypagePaymentInfoIndex);
