import React from 'react';

import Color from '../../constants/Color';
import Style from '../../constants/Style';
import Text from '../Text';
import View from '../View';

export default function SectionPointPanel({
  number,
  title,
  labelComponent,
  style = {}
}) {
  return (
    <View
      style={{
        justifyContent: 'space-between',
        backgroundColor: Color.white,
        borderRadius: 16,
        alignItems: 'center',
        padding: '8px 4px',
        width: 88,
        height: 88,
        ...Style.shadow.medium,
        ...style
      }}
    >
      <Text
        style={{
          color: Color.pink,
          fontWeight: 'bold',
          fontSize: 32,
          lineHeight: 1
        }}
      >
        {number}
      </Text>
      <Text
        style={{
          color: Color.lightSlate,
          fontWeight: 'bold',
          fontSize: 13,
          marginBottom: 4,
          lineHeight: 1
        }}
      >
        {title}
      </Text>
      <View
        style={{
          backgroundColor: Color.lightSlate,
          borderRadius: 8,
          padding: '2px 4px'
        }}
      >
        {labelComponent}
      </View>
    </View>
  );
}
