import React from 'react';

import {
  PROPOSAL_BASIC_INFO_LABELS,
  PROPOSAL_BASIC_INFO_VALIDATION_RULE,
  PROPOSAL_BUSINESS_PROCESS_LABELS,
  PROPOSAL_BUSINESS_PROCESS_VALIDATION_RULE,
  PROPOSAL_ENVIRONMENT_LABELS,
  PROPOSAL_ENVIRONMENT_VALIDATION_RULE,
  PROPOSAL_FEATURE_LABELS,
  PROPOSAL_FEATURE_VALIDATION_RULE,
  PROPOSAL_INTRODUCTION_LABELS,
  PROPOSAL_INTRODUCTION_VALIDATION_RULE,
  PROPOSAL_PROFIT_LABELS,
  PROPOSAL_PROFIT_VALIDATION_RULE,
  PROPOSAL_SPECIFICATION_LABELS,
  PROPOSAL_SPECIFICATION_VALIDATION_RULE
} from '../../constants/Validations';
import ProposalBasicInfoFormField from '../../containers/form/proposal/ProposalBasicInfoFormField';
import ProposalBusinessProcessFormField from '../../containers/form/proposal/ProposalBusinessProcessFormField';
import ProposalEnvironmentFormField from '../../containers/form/proposal/ProposalEnvironmentFormField';
import ProposalFeatureFormField from '../../containers/form/proposal/ProposalFeatureFormField';
import ProposalInputSectionCard from './ProposalInputSectionCard';
import ProposalIntroductionFormField from '../../containers/form/proposal/ProposalIntroductionFormField';
import ProposalProfitFormField from '../../containers/form/proposal/ProposalProfitFormField';
import ProposalSpecificationFormField from '../../containers/form/proposal/ProposalSpecificationFormField';
import View from '../View';

export default class ProposalFormField extends React.Component {
  render() {
    const { params, onChangeParams, style = {} } = this.props;

    return (
      <View style={style}>
        <ProposalInputSectionCard
          title="基本情報"
          number="01"
          description="あなたの事業の基盤となる情報をまとめましょう。"
          labels={PROPOSAL_BASIC_INFO_LABELS}
          rule={PROPOSAL_BASIC_INFO_VALIDATION_RULE}
          properties={params.proposal.basicInfo}
          style={{ marginBottom: 24 }}
        >
          <ProposalBasicInfoFormField
            params={params.proposal.basicInfo}
            onChangeParams={state => {
              onChangeParams({
                proposal: {
                  ...params.proposal,
                  basicInfo: { ...params.proposal.basicInfo, ...state }
                }
              });
            }}
            rules={PROPOSAL_BASIC_INFO_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="導入"
          number="02"
          description="創業理由やターゲット、ユーザー体験などを簡潔にまとめましょう。"
          labels={PROPOSAL_INTRODUCTION_LABELS}
          rule={PROPOSAL_INTRODUCTION_VALIDATION_RULE}
          properties={params.proposal.introduction}
          style={{ marginBottom: 24 }}
        >
          <ProposalIntroductionFormField
            params={params.proposal.introduction}
            onChangeParams={state =>
              onChangeParams({
                proposal: {
                  ...params.proposal,
                  introduction: {
                    ...params.proposal.introduction,
                    ...state
                  }
                }
              })
            }
            rules={PROPOSAL_INTRODUCTION_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="事業内容"
          number="03"
          description="事業についての基本的な仕様を記述しましょう。"
          labels={PROPOSAL_SPECIFICATION_LABELS}
          rule={PROPOSAL_SPECIFICATION_VALIDATION_RULE}
          properties={params.proposal.specification}
          style={{ marginBottom: 24 }}
        >
          <ProposalSpecificationFormField
            params={params.proposal.specification}
            onChangeParams={state =>
              onChangeParams({
                proposal: {
                  ...params.proposal,
                  specification: {
                    ...params.proposal.specification,
                    ...state
                  }
                }
              })
            }
            rules={PROPOSAL_SPECIFICATION_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="業務プロセス"
          number="04"
          description="ユーザーに提供するまでの業務プロセスについてまとめましょう。"
          labels={PROPOSAL_BUSINESS_PROCESS_LABELS}
          rule={PROPOSAL_BUSINESS_PROCESS_VALIDATION_RULE}
          properties={params.proposal.businessProcess}
          style={{ marginBottom: 24 }}
        >
          <ProposalBusinessProcessFormField
            params={params.proposal.businessProcess}
            onChangeParams={state =>
              onChangeParams({
                proposal: {
                  ...params.proposal,
                  businessProcess: {
                    ...params.proposal.businessProcess,
                    ...state
                  }
                }
              })
            }
            rules={PROPOSAL_BUSINESS_PROCESS_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="経営環境"
          number="05"
          description="この事業を取り巻く外部の経営環境について記述しましょう。"
          labels={PROPOSAL_ENVIRONMENT_LABELS}
          rule={PROPOSAL_ENVIRONMENT_VALIDATION_RULE}
          properties={params.proposal.environment}
          style={{ marginBottom: 24 }}
        >
          <ProposalEnvironmentFormField
            params={params.proposal.environment}
            onChangeParams={state =>
              onChangeParams({
                proposal: {
                  ...params.proposal,
                  environment: { ...params.proposal.environment, ...state }
                }
              })
            }
            rules={PROPOSAL_ENVIRONMENT_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="特徴"
          number="06"
          description="事業の特徴を多角的にまとめ、事業に対するイメージを固めましょう。"
          labels={PROPOSAL_FEATURE_LABELS}
          rule={PROPOSAL_FEATURE_VALIDATION_RULE}
          properties={params.proposal.feature}
          style={{ marginBottom: 24 }}
        >
          <ProposalFeatureFormField
            params={params.proposal.feature}
            onChangeParams={state =>
              onChangeParams({
                proposal: {
                  ...params.proposal,
                  feature: { ...params.proposal.feature, ...state }
                }
              })
            }
            rules={PROPOSAL_FEATURE_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="営業年利"
          number="07"
          description="最小限の営業利益を算出し、ビジネスとして成り立つことを確認しましょう。"
          labels={PROPOSAL_PROFIT_LABELS}
          rule={PROPOSAL_PROFIT_VALIDATION_RULE}
          properties={params.proposal.profit}
          style={{ marginBottom: 24 }}
        >
          <ProposalProfitFormField
            params={params.proposal.profit}
            onChangeParams={state =>
              onChangeParams({
                proposal: {
                  ...params.proposal,
                  profit: { ...params.proposal.profit, ...state }
                }
              })
            }
            rules={PROPOSAL_PROFIT_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
      </View>
    );
  }
}
