import React from 'react';

import { PdfText, PdfView } from '../../utils/Pdf';
import Color from '../../constants/Color';

export default function PdfCategoryCard({ title, color, style = {} }) {
  return (
    <PdfView
      style={{
        backgroundColor: Color.white,
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        border: `1pt solid ${Color.warmGrey}`,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 16,
        paddingBottom: 8,
        ...style
      }}
    >
      <PdfText
        style={{
          fontSize: 10,
          color,
          fontWeight: 'bold',
          lineHeight: 2
        }}
      >
        {title.split('').join('\n')}
      </PdfText>
    </PdfView>
  );
}
