import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import PageHeader from '../../../../containers/page/PageHeader';
import ReportTimelineList from '../../../../components/Dream/ReportTimelineList';
import View from '../../../../components/View';
import actions from '../../../../actions';

class DreamReportIndex extends React.Component {
  render() {
    return (
      <View>
        <PageHeader />
        <ReportTimelineList
          dreamId={this.props.match.params.dreamId}
          style={{ padding: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamReportIndex);
