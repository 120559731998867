import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalEnvironmentFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.market}
          name="market"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={market => {
            onChangeParams({
              market
            });
          }}
          label="(1) マーケット動向"
          errorMessage={
            rules &&
            validate({
              label: 'マーケット動向',
              value: params.market,
              rule: rules.market
            })
          }
          limitLength={rules.market.maxLength}
          helpText="市場規模や、最近のマーケット動向と予測を記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.competitor}
          name="competitor"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={competitor => {
            onChangeParams({
              competitor
            });
          }}
          label="(2) 競合状況"
          errorMessage={
            rules &&
            validate({
              label: '競合状況',
              value: params.competitor,
              rule: rules.competitor
            })
          }
          limitLength={rules.competitor.maxLength}
          helpText="競合他社がどのような施策を進めているか記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.substitute}
          name="substitute"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={substitute => {
            onChangeParams({
              substitute
            });
          }}
          label="(3) 代替状況"
          errorMessage={
            rules &&
            validate({
              label: '代替状況',
              value: params.substitute,
              rule: rules.substitute
            })
          }
          limitLength={rules.substitute.maxLength}
          helpText="あなたの事業が存在しない現在、どのような代替品が存在するか記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.risk}
          name="risk"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={risk => {
            onChangeParams({
              risk
            });
          }}
          label="(4) 事業リスク"
          errorMessage={
            rules &&
            validate({
              label: '事業リスク',
              value: params.risk,
              rule: rules.risk
            })
          }
          limitLength={rules.risk.maxLength}
          helpText="この事業をやる上での明確なリスクについて記述してください。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalEnvironmentFormField);
