import * as types from '../constants/ActionTypes';

const initialState = {
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    // get ad banner
    case types.FETCH_GET_CONFIGS:
      return {
        ...state,
        isGetLoading: true
      };

    case types.FETCH_GET_CONFIGS_SUCCESS:
      return {
        ...state,
        hash: action.state.hash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_CONFIGS_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_CONFIGS_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    default:
      return state;
  }
};
