import { getCollection, getTimestamp } from '../utils/Firebase';
import { request } from '../utils/Network';
import Dream from '../models/Dream';
import DreamComment from '../models/DreamComment';
import DreamCommentReply from '../models/DreamCommentReply';
import DreamGift from '../models/DreamGift';
import DreamProduct from '../models/DreamProduct';
import DreamReport from '../models/DreamReport';
import ErrorMessages from '../constants/ErrorMessages';
import Founder from '../models/Founder';
import User from '../models/User';
import * as types from '../constants/ActionTypes';

// get current dreams
function fetchGetDreams({ reset = false }) {
  return {
    type: types.FETCH_GET_DREAMS,
    state: { reset }
  };
}

function fetchGetDreamsSuccess(list) {
  return {
    type: types.FETCH_GET_DREAMS_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetDreamsFailed(error) {
  return {
    type: types.FETCH_GET_DREAMS_FAILED,
    error
  };
}

export function recoverGetDreams() {
  return {
    type: types.FETCH_GET_DREAMS_RECOVER
  };
}

export function getDreams(options = { reset: false, lastItem: null }) {
  return dispatch => {
    dispatch(fetchGetDreams({ reset: options.reset }));

    let query = getCollection('dreams').where('status', '==', 'public');

    if (options.lastItem) {
      query = query.startAfter(options.lastItem.startAt);
    }

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const data = doc.data();

            const founder = await Founder.get(data.founderId);

            return new Dream({
              id: doc.id,
              founder,
              ...data
            }).fromFirestore();
          })
        );

        return dispatch(fetchGetDreamsSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetDreamsFailed(error));
        }
      });
  };
}

// get dream
function fetchGetDream() {
  return {
    type: types.FETCH_GET_DREAM
  };
}

function fetchGetDreamSuccess(id, item) {
  return {
    type: types.FETCH_GET_DREAM_SUCCESS,
    state: {
      id,
      item
    }
  };
}

function fetchGetDreamFailed(error) {
  return {
    type: types.FETCH_GET_DREAM_FAILED,
    error
  };
}

export function recoverGetDream() {
  return {
    type: types.FETCH_GET_DREAM_RECOVER
  };
}

function fetchGetUsersSuccess(list) {
  return {
    type: types.FETCH_GET_USERS_SUCCESS,
    state: {
      list
    }
  };
}

export function getDream(id) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchGetDream());

    const dream = getCollection('dreams').doc(id);

    return dream
      .get()
      .then(async doc => {
        const data = doc.data();

        const founder = await Founder.get(data.founderId);

        let supporter = {};

        if (auth.uid) {
          const supporterDoc = await dream
            .collection('supporters')
            .doc(auth.uid)
            .get();

          supporter = supporterDoc.data();
        }

        const { docs: giftDocs } = await getCollection('dreams')
          .doc(id)
          .collection('gifts')
          .orderBy('price', 'asc')
          .get();

        const gifts = await Promise.all(
          giftDocs.map(doc => {
            const giftData = doc.data();

            return new DreamGift({
              id: doc.id,
              ...giftData
            }).fromFirestore();
          })
        );

        const { docs: commentDocs } = await dream
          .collection('comments')
          .orderBy('createdAt', 'desc')
          .get();

        const userIdSet = new Set();

        let products = [];

        if (
          data.fundingType === 'productAllIn' ||
          data.fundingType === 'productAllOrNothing'
        ) {
          const { docs: productDocs } = await getCollection('dreams')
            .doc(id)
            .collection('products')
            .orderBy('price', 'asc')
            .get();

          products = productDocs.map(doc => {
            return new DreamProduct({
              id: doc.id,
              ...doc.data()
            }).fromFirestore();
          });
        }

        const comments = await Promise.all(
          commentDocs.map(async doc => {
            const { docs: replyDocs } = await dream
              .collection('comments')
              .doc(doc.id)
              .collection('replies')
              .orderBy('createdAt', 'asc')
              .get();

            const replies = replyDocs.map(doc => {
              const replyData = doc.data();

              userIdSet.add(replyData.userId);

              return new DreamCommentReply({
                id: doc.id,
                ...replyData
              }).fromFirestore();
            });

            const commentData = doc.data();

            userIdSet.add(commentData.userId);

            return new DreamComment({
              id: doc.id,
              replies,
              ...commentData
            }).fromFirestore();
          })
        );

        const { docs: reportDocs } = await dream
          .collection('reports')
          .where('status', '==', 'public')
          .orderBy('startAt', 'desc')
          .get();

        const reports = reportDocs.map(doc => {
          const id = doc.id;

          const data = doc.data();

          return new DreamReport({ id, ...data }).fromFirestore();
        });

        const users = await Promise.all(
          Array.from(userIdSet).map(async userId => {
            const user = await User.get(userId);

            return user;
          })
        );

        dispatch(fetchGetUsersSuccess(users));

        const result = new Dream({
          id: doc.id,
          founder,
          supporter,
          comments,
          reports,
          gifts,
          products,
          ...data
        }).fromFirestore();

        return dispatch(fetchGetDreamSuccess(id, result));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetDreamFailed(error));
        }
      });
  };
}

// support dream
function fetchSupportDream() {
  return {
    type: types.FETCH_SUPPORT_DREAM
  };
}

function fetchSupportDreamSuccess(dreamId, amount, { activityHistories }) {
  return {
    type: types.FETCH_SUPPORT_DREAM_SUCCESS,
    state: {
      dreamId,
      amount,
      reset: true,
      list: activityHistories
    }
  };
}

function fetchSupportDreamFailed(error) {
  return {
    type: types.FETCH_SUPPORT_DREAM_FAILED,
    error
  };
}

export function recoverSupportDream() {
  return {
    type: types.FETCH_SUPPORT_DREAM_RECOVER
  };
}

export function supportDream({ dreamId, amount, token, paymentInfoId }) {
  return dispatch => {
    dispatch(fetchSupportDream());

    return request(dispatch, 'POST', '/supportDream', {
      params: {
        dreamId,
        amount,
        token,
        paymentInfoId
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchSupportDreamFailed(error));
      }

      return dispatch(fetchSupportDreamSuccess(dreamId, amount, response));
    });
  };
}

// purchase dream product
function fetchPurchaseDreamProduct() {
  return {
    type: types.FETCH_PURCHASE_DREAM_PRODUCT
  };
}

function fetchPurchaseDreamProductSuccess(dreamId, { activityHistories }) {
  return {
    type: types.FETCH_PURCHASE_DREAM_PRODUCT_SUCCESS,
    state: {
      dreamId,
      reset: true,
      list: activityHistories
    }
  };
}

function fetchPurchaseDreamProductFailed(error) {
  return {
    type: types.FETCH_PURCHASE_DREAM_PRODUCT_FAILED,
    error
  };
}

export function recoverPurchaseDreamProduct() {
  return {
    type: types.FETCH_PURCHASE_DREAM_PRODUCT_RECOVER
  };
}

export function purchaseDreamProduct({
  dreamId,
  productId,
  shippingInfoId,
  token,
  paymentInfoId
}) {
  return dispatch => {
    dispatch(fetchPurchaseDreamProduct());

    return request(dispatch, 'POST', '/purchaseDreamProduct', {
      params: {
        dreamId,
        productId,
        shippingInfoId,
        token,
        paymentInfoId
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchPurchaseDreamProductFailed(error));
      }

      return dispatch(fetchPurchaseDreamProductSuccess(dreamId, response));
    });
  };
}

// create comment
function fetchCreateComment() {
  return {
    type: types.FETCH_CREATE_DREAM_COMMENT
  };
}

function fetchCreateCommentSuccess(id, params) {
  return {
    type: types.FETCH_CREATE_DREAM_COMMENT_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateCommentFailed(error) {
  return {
    type: types.FETCH_CREATE_DREAM_COMMENT_FAILED,
    error
  };
}

export function recoverCreateDreamComment() {
  return {
    type: types.FETCH_CREATE_DREAM_COMMENT_RECOVER
  };
}

export function createDreamComment({ dreamId, text }) {
  return (dispatch, getState) => {
    const { auth, user } = getState();

    dispatch(fetchCreateComment());

    const comments = getCollection('dreams')
      .doc(dreamId)
      .collection('comments');

    const params = {
      text,
      userId: auth.uid,
      createdAt: getTimestamp()
    };

    comments
      .add(params)
      .then(result => {
        return dispatch(
          fetchCreateCommentSuccess(dreamId, {
            ...params,
            id: result.id,
            replies: [],
            user: user.myself
          })
        );
      })
      .catch(error => {
        return dispatch(
          fetchCreateCommentFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}

// create comment reply
function fetchCreateCommentReply() {
  return {
    type: types.FETCH_CREATE_DREAM_COMMENT_REPLY
  };
}

function fetchCreateCommentReplySuccess(dreamId, commentId, params) {
  return {
    type: types.FETCH_CREATE_DREAM_COMMENT_REPLY_SUCCESS,
    state: {
      dreamId,
      commentId,
      params
    }
  };
}

function fetchCreateCommentReplyFailed(error) {
  return {
    type: types.FETCH_CREATE_DREAM_COMMENT_REPLY_FAILED,
    error
  };
}

export function recoverCreateDreamCommentReply() {
  return {
    type: types.FETCH_CREATE_DREAM_COMMENT_REPLY_RECOVER
  };
}

export function createDreamCommentReply({ dreamId, commentId, text }) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchCreateCommentReply());

    const replies = getCollection('dreams')
      .doc(dreamId)
      .collection('comments')
      .doc(commentId)
      .collection('replies');

    const params = {
      text,
      userId: auth.uid,
      createdAt: getTimestamp()
    };

    replies
      .add(params)
      .then(() => {
        return dispatch(
          fetchCreateCommentReplySuccess(dreamId, commentId, params)
        );
      })
      .catch(error => {
        return dispatch(
          fetchCreateCommentReplyFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}
