import React from 'react';
import numeral from 'numeral';

import { getFundingTypeExplain } from '../../models/DreamFundingType';
import { getGradient, getGradientCss } from '../../utils/ColorJs';
import Color from '../../constants/Color';
import Deadline from '../../utils/Deadline';
import Headline from '../Headline';
import Paragraph from '../Paragraph';
import Text from '../Text';
import View from '../View';

export default function DreamProgress({
  data: {
    fundingType,
    currentFundingPrice,
    fundingGoalPrice,
    supporterCount,
    deadlineAt
  },
  style = {}
}) {
  const rate = Math.floor((currentFundingPrice / fundingGoalPrice) * 100);

  const colors = getGradient(Color.pinkGradient, rate);

  const deadline = new Deadline(deadlineAt);

  return (
    <View style={style}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 8
        }}
      >
        <Headline>集まったお金</Headline>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'baseline',
          justifyContent: 'space-between'
        }}
      >
        <Text
          style={{
            color: Color.slate,
            fontSize: 13
          }}
        >
          <Text
            style={{
              color: Color.pink,
              fontWeight: 'bold',
              fontSize: 24
            }}
          >
            {numeral(currentFundingPrice).format('0,0')}
          </Text>{' '}
          /{numeral(fundingGoalPrice).format('0,0')}円
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'baseline'
          }}
        >
          <Text
            style={{
              color: Color.pink,
              fontSize: 20
            }}
          >
            {rate}
          </Text>
          <Text
            style={{
              color: Color.slate,
              fontSize: 15
            }}
          >
            %
          </Text>
        </View>
      </View>
      <View
        style={{
          borderRadius: 8,
          backgroundColor: Color.slate,
          padding: 2,
          marginBottom: 8
        }}
      >
        <View
          style={{
            backgroundImage: getGradientCss(colors, 135),
            width: `${Math.min(rate, 100)}%`,
            height: 7,
            border: `1px solid ${Color.white}`,
            borderRadius: 4
          }}
        />
      </View>
      <Paragraph
        style={{ color: Color.grey, fontSize: 10 }}
        containerStyle={{ marginBottom: 16 }}
      >
        {getFundingTypeExplain(fundingType)}
      </Paragraph>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 8
        }}
      >
        <Headline size={3}>支援者数</Headline>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'baseline'
          }}
        >
          <Text
            style={{
              color: Color.pink,
              fontWeight: 'bold',
              fontSize: 20
            }}
          >
            {supporterCount}
          </Text>
          <Text
            style={{
              color: Color.slate,
              fontSize: 15
            }}
          >
            人
          </Text>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 8
        }}
      >
        <Headline size={3}>募集締切まで</Headline>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'baseline'
          }}
        >
          <Text
            style={{
              color: Color.pink,
              fontWeight: 'bold',
              fontSize: 20
            }}
          >
            {deadline.leastAmount}
          </Text>
          <Text
            style={{
              color: Color.slate,
              fontSize: 15
            }}
          >
            {deadline.leastUnit}
          </Text>
        </View>
      </View>
    </View>
  );
}
