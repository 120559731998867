import { Col } from 'react-grid-system';
import React from 'react';

import View from './View';

export default function Column(props) {
  const {
    children,
    style = {},
    containerStyle = {},
    verticalAlign = null,
    ...rest
  } = props;

  let verticalAlignStyle = {};

  if (verticalAlign) {
    verticalAlignStyle = {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      justifyContent: verticalAlign
    };
  }

  return (
    <Col {...rest} style={{ ...verticalAlignStyle, ...containerStyle }}>
      <View style={style}>{children}</View>
    </Col>
  );
}
