import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalSpecificationFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.strengths}
          name="strengths"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={strengths => {
            onChangeParams({
              strengths
            });
          }}
          label="(1) 強み"
          errorMessage={
            rules &&
            validate({
              label: '強み',
              value: params.strengths,
              rule: rules.strengths
            })
          }
          limitLength={rules.strengths.maxLength}
          helpText="内部環境によって支えられる、活かすべき強みについて記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.weaknesses}
          name="weaknesses"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={weaknesses => {
            onChangeParams({
              weaknesses
            });
          }}
          label="(2) 弱み"
          errorMessage={
            rules &&
            validate({
              label: '弱み',
              value: params.weaknesses,
              rule: rules.weaknesses
            })
          }
          limitLength={rules.weaknesses.maxLength}
          helpText="内部環境によって現れてしまう、克服すべき弱みについて記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.opportunities}
          name="opportunities"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={opportunities => {
            onChangeParams({
              opportunities
            });
          }}
          label="(3) 機会"
          errorMessage={
            rules &&
            validate({
              label: '機会',
              value: params.opportunities,
              rule: rules.opportunities
            })
          }
          limitLength={rules.opportunities.maxLength}
          helpText="外部環境によってもたらされる市場機会について記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.threats}
          name="threats"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={threats => {
            onChangeParams({
              threats
            });
          }}
          label="(4) 脅威"
          errorMessage={
            rules &&
            validate({
              label: '脅威',
              value: params.threats,
              rule: rules.threats
            })
          }
          limitLength={rules.threats.maxLength}
          helpText="外部環境からもたらされる脅威と、その対策について記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.identity}
          name="identity"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={identity => {
            onChangeParams({
              identity
            });
          }}
          label="(5) 独自性"
          errorMessage={
            rules &&
            validate({
              label: '独自性',
              value: params.identity,
              rule: rules.identity
            })
          }
          limitLength={rules.identity.maxLength}
          helpText="あなたの事業の独自のビジネス構造や、ユニークな点を記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.potential}
          name="potential"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={potential => {
            onChangeParams({
              potential
            });
          }}
          label="(6) 成長性"
          errorMessage={
            rules &&
            validate({
              label: '成長性',
              value: params.potential,
              rule: rules.potential
            })
          }
          limitLength={rules.potential.maxLength}
          helpText="この事業が成長する具体的な理由、今後の計画を記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.keyFactor}
          name="keyFactor"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={keyFactor => {
            onChangeParams({
              keyFactor
            });
          }}
          label="(7) 成功ポイント"
          errorMessage={
            rules &&
            validate({
              label: '成功ポイント',
              value: params.keyFactor,
              rule: rules.keyFactor
            })
          }
          limitLength={rules.keyFactor.maxLength}
          helpText="この事業が成功するためのキーファクターと、理想の状態を記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.issue}
          name="issue"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={issue => {
            onChangeParams({
              issue
            });
          }}
          label="(8) 優先課題"
          errorMessage={
            rules &&
            validate({
              label: '優先課題',
              value: params.issue,
              rule: rules.issue
            })
          }
          limitLength={rules.issue.maxLength}
          helpText="この事業にとって障害となる、優先度の高い課題と対策を記述してください。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalSpecificationFormField);
