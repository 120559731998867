import { getCollection } from '../utils/Firebase';

export default class User {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      name: state.name,
      iconImage: state.iconImage || null,
      profile: state.profile,
      twitterId: state.twitterId,
      email: state.email,
      paymentInfos: state.paymentInfos,
      defaultPaymentInfoId: state.defaultPaymentInfoId
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }

  static async get(id) {
    const userDoc = await getCollection('users')
      .doc(id)
      .get();

    const user = new User({
      id: userDoc.id,
      ...userDoc.data()
    }).fromFirestore();

    return user;
  }
}
