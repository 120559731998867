import { Container as GridContainer } from 'react-grid-system';
import React from 'react';

import View from './View';

export default function Container(props) {
  const { children, style = {}, containerStyle = {} } = props;

  return (
    <div style={{ ...containerStyle }}>
      <GridContainer>
        <View style={{ ...style }}>{children}</View>
      </GridContainer>
    </div>
  );
}
