import React from 'react';

import Color from '../constants/Color';
import MaterialIcon from '../utils/MaterialIcon';
import View from './View';

export default function Image({
  id = 'image',
  source,
  alt = '',
  style = {},
  ...otherProps
}) {
  const src = (source && (source.uri || source.data)) || source;

  if (!src || JSON.stringify(src) === '{}') {
    return (
      <View
        style={{
          backgroundColor: Color.lightGrey,
          alignItems: 'center',
          justifyContent: 'center',
          ...style
        }}
        {...otherProps}
      >
        <MaterialIcon icon="image" color={Color.warmGrey} size={64} />
      </View>
    );
  }

  return <img id={id} alt={alt} src={src} style={style} {...otherProps} />;
}
