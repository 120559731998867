import {
  Document,
  PDFDownloadLink,
  PDFViewer,
  Page
} from '@react-pdf/renderer';
import React from 'react';
import moment from 'moment';

import { PdfImage, PdfText, PdfView } from '../../utils/Pdf';
import Button from '../Button';
import Color from '../../constants/Color';
import PdfCard from './PdfCard';
import PdfCategoryCard from './PdfCategoryCard';
import PdfFeatureCard from './PdfFeatureCard';
import PdfFeatureColumnCard from './PdfFeatureColumnCard';
import PdfFeaturePanel from './PdfFeaturePanel';
import PdfHeadline from './PdfHeadline';
import PdfIntroduction from './PdfIntroduction';
import PdfProcess from './PdfProcess';
import View from '../View';

const HEADER_IMAGE = require('../../assets/img/pdf/proposal/header.png');

export default class Marketing extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { data, preview = false, style = {}, previewStyle = {} } = this.props;

    const primaryColor = `#${data.basicInfo.primaryColor}`;

    const secondaryColor = `#${data.basicInfo.secondaryColor}`;

    const MarketingComponent = (
      <Document>
        <Page
          size="A3"
          orientation="landscape"
          style={{
            flexDirection: 'row'
          }}
          wrap={false}
        >
          <PdfView
            style={{
              width: 595,
              height: 842,
              position: 'relative'
            }}
          >
            <PdfView
              style={{
                flex: 1,
                justifyContent: 'space-between',
                position: 'relative'
              }}
            >
              <PdfImage src={HEADER_IMAGE} />
            </PdfView>
            <PdfView
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
              }}
            >
              <PdfView
                style={{
                  flexDirection: 'row',
                  width: 595,
                  height: 145,
                  marginBottom: 40
                }}
              >
                <PdfView style={{ flex: 1 }}>
                  <PdfText
                    style={{
                      fontSize: 24,
                      fontWeight: 'bold',
                      color: Color.white,
                      textDecoration: 'underline',
                      paddingTop: 40,
                      paddingLeft: 40,
                      marginBottom: 16
                    }}
                  >
                    {data.basicInfo.title}
                  </PdfText>
                  <PdfView style={{ flexDirection: 'row' }}>
                    <PdfView
                      style={{
                        width: 80,
                        height: 80,
                        padding: 3,
                        backgroundColor: Color.white,
                        border: `1pt solid ${Color.lightGrey}`,
                        marginLeft: 40
                      }}
                    >
                      <PdfImage
                        src={data.assets.profileImage}
                        style={{ width: 72, height: 72 }}
                      />
                    </PdfView>
                    <PdfView
                      style={{
                        paddingTop: 8,
                        paddingLeft: 16
                      }}
                    >
                      <PdfText
                        style={{
                          fontSize: 10,
                          fontWeight: 'bold',
                          color: Color.white,
                          marginBottom: 8
                        }}
                      >
                        {data.basicInfo.name} （{data.basicInfo.birthday}
                        生）
                        {data.basicInfo.job}
                      </PdfText>
                      <PdfText
                        style={{
                          fontSize: 10,
                          fontWeight: 'bold',
                          color: Color.white,
                          width: 280,
                          lineHeight: 1.3
                        }}
                      >
                        {data.basicInfo.biography}
                      </PdfText>
                    </PdfView>
                  </PdfView>
                </PdfView>
                <PdfView
                  style={{
                    flex: 1,
                    alignItems: 'flex-end'
                  }}
                >
                  <PdfView
                    style={{
                      width: 145,
                      height: 145,
                      backgroundColor: primaryColor,
                      marginRight: 16,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <PdfImage
                      src={data.assets.category}
                      style={{ width: 64, height: 64, marginBottom: 8 }}
                    />
                    <PdfText
                      style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: Color.white
                      }}
                    >
                      {data.categoryName}
                    </PdfText>
                  </PdfView>
                </PdfView>
              </PdfView>
              <PdfView style={{ paddingLeft: 40 }}>
                {/* MARKET */}
                <PdfView style={{ height: 327, marginBottom: 16 }}>
                  <PdfHeadline
                    title="MARKET"
                    descImage={data.assets.headlineDesc}
                    subTitle="（市場の定義）"
                    style={{ marginBottom: 16 }}
                  />
                  <PdfView
                    style={{
                      flexDirection: 'row',
                      paddingRight: 20,
                      height: 86
                    }}
                  >
                    <PdfIntroduction
                      title={`だれが\n競合なのか`}
                      content={data.market.competitorDefinition}
                      icon={data.assets.domain}
                      color={primaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                    <PdfIntroduction
                      title={`誰が\n客なのか`}
                      content={data.market.customerDefinition}
                      icon={data.assets.account}
                      color={primaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                    <PdfIntroduction
                      title={`客ではないのは\n誰か`}
                      content={data.market.nonCustomerDefinition}
                      icon={data.assets.gift}
                      color={primaryColor}
                      style={{ flex: 1 }}
                    />
                  </PdfView>
                  <PdfView
                    style={{
                      flexDirection: 'row',
                      paddingRight: 20,
                      marginBottom: 8
                    }}
                  >
                    <PdfCard
                      title="市場の規模"
                      content={data.market.scale}
                      color={primaryColor}
                      subColor={secondaryColor}
                      style={{ flex: 1, height: 80, marginRight: 16 }}
                    />
                    <PdfCard
                      title="成長性"
                      content={data.market.potential}
                      color={primaryColor}
                      subColor={secondaryColor}
                      style={{ flex: 1, height: 80 }}
                    />
                  </PdfView>
                  <PdfView
                    style={{
                      flexDirection: 'row',
                      paddingRight: 20
                    }}
                  >
                    <PdfCard
                      title="競合環境"
                      content={data.market.competitorEnvironment}
                      color={primaryColor}
                      subColor={secondaryColor}
                      style={{ flex: 1, height: 101, marginRight: 16 }}
                    />
                    <PdfCard
                      title="能力と親和性"
                      content={data.market.ability}
                      color={primaryColor}
                      subColor={secondaryColor}
                      style={{ flex: 1, height: 101, marginRight: 16 }}
                    />
                    <PdfCard
                      title="既存事業とのシナジー"
                      content={data.market.synergy}
                      color={primaryColor}
                      subColor={secondaryColor}
                      style={{ flex: 1, height: 101 }}
                    />
                  </PdfView>
                </PdfView>
                {/* IDEAL VALUES */}
                <PdfView style={{ height: 274 }}>
                  <PdfHeadline
                    title="IDEAL VALUES"
                    descImage={data.assets.headlineDesc}
                    subTitle="（価値の定義 - あるべき姿）"
                    style={{ marginBottom: 16 }}
                  />
                  <PdfView
                    style={{
                      flexDirection: 'row',
                      paddingLeft: 36,
                      marginBottom: 4
                    }}
                  >
                    <PdfFeatureColumnCard
                      title="機能的"
                      color={secondaryColor}
                      style={{ flex: 1, marginRight: 8 }}
                    />
                    <PdfFeatureColumnCard
                      title="情緒的"
                      color={secondaryColor}
                      style={{ flex: 1 }}
                    />
                  </PdfView>
                  <PdfView style={{ flexDirection: 'row', marginBottom: 4 }}>
                    <PdfCategoryCard
                      title="実態あり"
                      color={secondaryColor}
                      style={{ marginRight: 8 }}
                    />
                    <PdfFeatureCard
                      title="UTILITARIAN"
                      ruby="実利価値"
                      importanceLevel={data.idealValues.utilitarianLevel}
                      importanceActiveIcon={data.assets.thumbUpActive}
                      importanceInactiveIcon={data.assets.thumbUpInactive}
                      color={primaryColor}
                      subColor={secondaryColor}
                      content={data.idealValues.utilitarianValue}
                      style={{ flex: 1, marginRight: 8 }}
                    />
                    <PdfFeatureCard
                      title="GUARANTEED"
                      ruby="保証価値"
                      importanceLevel={data.idealValues.guaranteedLevel}
                      importanceActiveIcon={data.assets.thumbUpActive}
                      importanceInactiveIcon={data.assets.thumbUpInactive}
                      color={primaryColor}
                      subColor={secondaryColor}
                      content={data.idealValues.guaranteedValue}
                      style={{ flex: 1 }}
                    />
                  </PdfView>
                  <PdfView style={{ flexDirection: 'row' }}>
                    <PdfCategoryCard
                      title="実態なし"
                      color={secondaryColor}
                      style={{ marginRight: 8 }}
                    />
                    <PdfFeatureCard
                      title="REPUTATION"
                      ruby="評判価値"
                      importanceLevel={data.idealValues.reputationLevel}
                      importanceActiveIcon={data.assets.thumbUpActive}
                      importanceInactiveIcon={data.assets.thumbUpInactive}
                      color={primaryColor}
                      subColor={secondaryColor}
                      content={data.idealValues.reputationValue}
                      style={{ flex: 1, marginRight: 8 }}
                    />
                    <PdfFeatureCard
                      title="SYMPATHY"
                      ruby="共感価値"
                      importanceLevel={data.idealValues.sympathyLevel}
                      importanceActiveIcon={data.assets.thumbUpActive}
                      importanceInactiveIcon={data.assets.thumbUpInactive}
                      color={primaryColor}
                      subColor={secondaryColor}
                      content={data.idealValues.sympathyValue}
                      style={{ flex: 1 }}
                    />
                  </PdfView>
                </PdfView>
              </PdfView>
            </PdfView>
          </PdfView>
          <PdfView
            style={{
              width: 595,
              height: 842,
              position: 'relative'
            }}
          >
            <PdfView
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                paddingTop: 40,
                paddingLeft: 20,
                paddingBottom: 40,
                paddingRight: 40
              }}
            >
              {/* REALITY VALUES */}
              <PdfView style={{ height: 239, marginBottom: 24 }}>
                <PdfHeadline
                  title="REALITY VALUES"
                  descImage={data.assets.headlineDesc}
                  subTitle="（価値の創造 - 語るべき今）"
                  style={{ marginBottom: 16 }}
                />
                <PdfView
                  style={{
                    flexDirection: 'row',
                    marginBottom: 16
                  }}
                >
                  <PdfFeaturePanel
                    title="商品・サービス"
                    icon={data.assets.gift}
                    content={data.realityValues.product}
                    color={secondaryColor}
                    style={{ flex: 1, height: 115, marginRight: 8 }}
                  />
                  <PdfFeaturePanel
                    title="付加価値・サポート"
                    icon={data.assets.bookmarkPlus}
                    content={data.realityValues.support}
                    color={secondaryColor}
                    style={{ flex: 1, height: 115, marginRight: 8 }}
                  />
                  <PdfFeaturePanel
                    title="コミュニケーション"
                    icon={data.assets.heartMultiple}
                    content={data.realityValues.communication}
                    color={secondaryColor}
                    style={{ flex: 1, height: 115 }}
                  />
                </PdfView>
                <PdfFeaturePanel
                  title="広告コンセプト・チームの文化"
                  icon={data.assets.creation}
                  content={data.realityValues.concept}
                  color={primaryColor}
                  style={{ flex: 1, height: 72 }}
                />
              </PdfView>
              {/* COMMUNICATION */}
              <PdfView style={{ height: 121, marginBottom: 24 }}>
                <PdfHeadline
                  title="COMMUNICATION"
                  descImage={data.assets.headlineDesc}
                  subTitle="（価値の伝達）"
                  style={{ marginBottom: 16 }}
                />
                <PdfProcess
                  title={`気づいてもらう`}
                  content={data.communication.toNotice}
                  icon={data.assets.bell}
                  color={primaryColor}
                  style={{ flex: 1, minHeight: 80 }}
                />
                <PdfView style={{ alignItems: 'center', padding: '4px 0' }}>
                  <PdfImage
                    src={data.assets.arrowDown}
                    style={{ widht: 14, height: 7 }}
                  />
                </PdfView>
                <PdfProcess
                  title={`覚えてもらう`}
                  content={data.communication.toRemember}
                  icon={data.assets.brain}
                  color={primaryColor}
                  style={{ flex: 1, minHeight: 80 }}
                />
                <PdfView style={{ alignItems: 'center', padding: '4px 0' }}>
                  <PdfImage
                    src={data.assets.arrowDown}
                    style={{ widht: 14, height: 7 }}
                  />
                </PdfView>
                <PdfProcess
                  title={`好きになってもらう`}
                  content={data.communication.toLike}
                  icon={data.assets.thumbUpActive}
                  color={primaryColor}
                  style={{ flex: 1, minHeight: 80 }}
                />
                <PdfView style={{ alignItems: 'center', padding: '4px 0' }}>
                  <PdfImage
                    src={data.assets.arrowDown}
                    style={{ widht: 14, height: 7 }}
                  />
                </PdfView>
                <PdfProcess
                  title={`深く知ってもらう`}
                  content={data.communication.toUnderstand}
                  icon={data.assets.bookOpenVariant}
                  color={primaryColor}
                  style={{ flex: 1, minHeight: 80 }}
                />
                <PdfView style={{ alignItems: 'center', padding: '4px 0' }}>
                  <PdfImage
                    src={data.assets.arrowDown}
                    style={{ widht: 14, height: 7 }}
                  />
                </PdfView>
                <PdfProcess
                  title={`選んでもらう`}
                  content={data.communication.toSelect}
                  icon={data.assets.checkDecagram}
                  color={primaryColor}
                  style={{ flex: 1, minHeight: 80 }}
                />
              </PdfView>
            </PdfView>
          </PdfView>
        </Page>
      </Document>
    );

    const timestamp = moment().format('YYYYMMDD_HHmm');

    return (
      <div style={style}>
        {preview && (
          <View style={{ marginBottom: 16 }}>
            <PDFViewer width={previewStyle.width} height={previewStyle.height}>
              {MarketingComponent}
            </PDFViewer>
          </View>
        )}
        {!preview && (
          <PDFDownloadLink
            document={MarketingComponent}
            fileName={`${timestamp}_${data.basicInfo.title}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <Button disable>生成中</Button>
              ) : (
                <Button>ダウンロード</Button>
              )
            }
          </PDFDownloadLink>
        )}
      </div>
    );
  }
}
