import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import View from '../View';

export default function TextField({
  value,
  onChange,
  style = {},
  inputStyle,
  errorMessage = '',
  ...rest
}) {
  return (
    <View
      style={{
        backgroundColor: Color.white,
        borderRadius: 8,
        border: `solid 1px ${Color.lightGrey}`,
        padding: 4,
        ...style
      }}
    >
      <input
        type="text"
        value={value}
        onChange={event => onChange(event.target.value)}
        style={{ fontSize: 17, padding: 4, width: '100%' }}
        {...rest}
        {...inputStyle}
      />
      {errorMessage && (
        <Text style={{ color: Color.red, fontSize: 11, padding: 4 }}>
          {errorMessage}
        </Text>
      )}
    </View>
  );
}
