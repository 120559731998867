import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../components/Button';
import Color from '../../constants/Color';
import Headline from '../../components/Headline';
import Paragraph from '../../components/Paragraph';
import RegisterModal from '../RegisterModal';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class RegisterButton extends React.Component {
  state = {
    isOpenRegisterModal: false
  };

  render() {
    return (
      <View>
        <Button
          tiny
          shadow={false}
          onClick={() => this.setState({ isOpenRegisterModal: true })}
        >
          <Text
            style={{ fontSize: 13, fontWeight: 'bold', color: Color.white }}
          >
            ログイン
          </Text>
        </Button>
        <RegisterModal
          visible={this.state.isOpenRegisterModal}
          onClose={() => this.setState({ isOpenRegisterModal: false })}
          content={
            <View style={{ marginBottom: 8 }}>
              <Headline size={3} style={{ marginBottom: 16 }}>
                VISIONSをご利用になるために
              </Headline>
              <Paragraph>
                VISIONSの各機能をご利用になるためには、新規登録/ログインをする必要があります。
              </Paragraph>
            </View>
          }
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterButton);
