import React from 'react';

export default function ProposalBackgroundSvg({ id, fill }) {
  return (
    <svg
      id={id}
      width="1190px"
      height="317px"
      viewBox="0 0 1190 317"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Plans"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="A4"
          transform="translate(0.000000, -244.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-17" transform="translate(0.000000, 244.000000)">
            <rect id="Rectangle" x="0" y="160" width="595" height="157" />
            <polygon
              id="Rectangle"
              points="595 160 1191 -2.19962937e-15 1191 157 595 317"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
