import { getCollection } from '../utils/Firebase';
import ErrorMessages from '../constants/ErrorMessages';
import Sponsor from '../models/Sponsor';
import User from '../models/User';
import * as types from '../constants/ActionTypes';

// get jobs
function fetchGetSponsors() {
  return {
    type: types.FETCH_GET_SPONSORS
  };
}

function fetchGetSponsorsSuccess(list) {
  return {
    type: types.FETCH_GET_SPONSORS_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetSponsorsFailed(error) {
  return {
    type: types.FETCH_GET_SPONSORS_FAILED,
    error
  };
}

export function recoverGetSponsors() {
  return {
    type: types.FETCH_GET_SPONSORS_RECOVER
  };
}

export function getSponsors() {
  return dispatch => {
    dispatch(fetchGetSponsors());

    let query = getCollection('sponsors').orderBy('createdAt', 'desc');

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const data = doc.data();

            const user = await User.get(data.userId);

            return new Sponsor({
              id: doc.id,
              ...user,
              ...data
            }).fromFirestore();
          })
        );

        return dispatch(fetchGetSponsorsSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(
            fetchGetSponsorsFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        }
      });
  };
}
