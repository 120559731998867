import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import ContentContainer from '../../../components/Page/ContentContainer';
import DreamDesktopItem from '../../../components/Dream/DreamDesktopItem';
import FeatureContent from '../../../components/Feature/FeatureContent';
import FooterContainer from '../../../components/FooterContainer';
import Headline from '../../../components/Headline';
import Logo from '../../../components/Icons/Logo';
import MaterialIcon from '../../../utils/MaterialIcon';
import PageHeader from '../../../containers/page/PageContaineredHeader';
import Text from '../../../components/Text';
import TopLogo from '../../../components/Top/TopLogo';
import View from '../../../components/View';
import WishContainer from '../../../containers/wish/WishContainer';
import actions from '../../../actions';

class Top extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getDreams({ reset: true });
  }

  render() {
    const dreams = this.props.dream.list.map(id => this.props.dream.hash[id]);

    const exemplaries = dreams.filter(dream => dream.level === 'exemplary');

    return (
      <View>
        <PageHeader />
        <View
          style={{
            backgroundImage: `url(${require('../../../assets/img/header.jpg')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: '98px 0',
            marginBottom: -1,
            alignItems: 'center'
          }}
        >
          <View style={{ marginBottom: 36 }}>
            <Logo fill={Color.white} size={120} />
          </View>
          <TopLogo style={{ marginBottom: 32 }} />
          <Link to={`/document/beta`}>
            <Button tiny variant="flat">
              <Text style={{ color: Color.slate, marginRight: 8 }}>
                ベータ版
              </Text>
              <MaterialIcon
                icon="arrow_forward_ios"
                color={Color.slate}
                size={17}
              />
            </Button>
          </Link>
        </View>
        <ContentContainer style={{ paddingBottom: 88 }}>
          <Headline
            style={{
              fontSize: 32,
              textAlign: 'center',
              marginTop: 88,
              marginBottom: 56
            }}
          >
            PROJECTS
          </Headline>
          <Row>
            {exemplaries.map(exemplary => {
              return (
                <Column
                  key={`exemplary-${exemplary.id}`}
                  style={{ alignItems: 'center' }}
                >
                  <Link to={`/dream/${exemplary.id}`}>
                    <DreamDesktopItem data={exemplary} />
                  </Link>
                </Column>
              );
            })}
          </Row>
        </ContentContainer>
        <FeatureContent />
        <WishContainer />
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Top);
