import { ScaleLoader } from 'react-spinners';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import Color from '../constants/Color';
import Firebase from '../utils/Firebase';
import ResponseDialog from '../components/ResponseDialog';
import View from '../components/View';
import actions from '../actions';

class FoundationContainer extends Component {
  updateWindowDimensions() {
    this.props.updateWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  componentDidMount() {
    // this.props.getConfigs();

    this.updateWindowDimensions();

    window.addEventListener('resize', this.updateWindowDimensions.bind(this));

    Firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // User is signed in.
        this.props.getMyselfUser(user.uid);
        // this.props.login();
      } else {
        // No user is signed in.
        this.props.signOut();
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.updateWindowDimensions.bind(this)
    );
  }

  getNotification(condition, index) {
    const key = index;

    return <ResponseDialog key={key} {...condition} style={{ zIndex: 200 }} />;
  }

  renderNotifications() {
    const conditions = [
      {
        isLoading: this.props.dream.isSupportLoading,
        isFailed: this.props.dream.isSupportFailed,
        error: this.props.dream.supportError,
        completeMessage: 'サポートが完了しました。',
        onComplete: () => {
          this.props.getMyselfUser(this.props.auth.uid);
        },
        onError: () => {
          this.props.recoverSupportDream();
        }
      },
      {
        isLoading: this.props.dream.isCreateCommentLoading,
        isFailed: this.props.dream.isCreateCommentFailed,
        error: this.props.dream.createCommentError,
        completeMessage: 'コメントを投稿しました。',
        onError: () => {
          this.props.recoverCreateDreamComment();
        }
      },
      {
        isLoading: this.props.dream.isCreateCommentReplyLoading,
        isFailed: this.props.dream.isCreateCommentReplyFailed,
        error: this.props.dream.createCommentReplyError,
        completeMessage: 'リプライを投稿しました。',
        onError: () => {
          this.props.recoverCreateDreamCommentReply();
        }
      },
      {
        isLoading: this.props.patronContract.isCreateLoading,
        isFailed: this.props.patronContract.isCreateFailed,
        error: this.props.patronContract.createError,
        completeMessage: 'パトロンを支援しました。',
        onComplete: () => {
          this.props.getMyselfUser(this.props.auth.uid);
        },
        onError: () => {
          this.props.recoverCreatePatronContract();
        }
      },
      {
        isLoading: this.props.paymentInfo.isDeleteLoading,
        isFailed: this.props.paymentInfo.isDeleteFailed,
        error: this.props.paymentInfo.deleteError,
        completeMessage: 'カード情報を削除しました。',
        onError: () => {
          this.props.recoverDeletePaymentInfo();
        }
      },
      {
        isLoading: this.props.patronContract.isDeleteLoading,
        isFailed: this.props.patronContract.isDeleteFailed,
        error: this.props.patronContract.deleteError,
        completeMessage: 'パトロンの支援をキャンセルしました。',
        onError: () => {
          this.props.recoverDeletePatronContract();
        }
      }
    ];

    return conditions.map((condition, index) =>
      this.getNotification(condition, index)
    );
  }

  renderLoadingOverlay() {
    const { dream, patronContract, paymentInfo, proposal } = this.props;

    if (
      !dream.isSupportLoading &&
      !dream.isCreateCommentLoading &&
      !dream.isCreateCommentReplyLoading &&
      !dream.isPurchaseProductLoading &&
      !patronContract.isCreateLoading &&
      !paymentInfo.isDeleteLoading &&
      !patronContract.isDeleteLoading &&
      !proposal.isCreateLoading
    ) {
      return null;
    }

    return (
      <View
        style={{
          display: 'flex',
          top: 0,
          left: 0,
          position: 'fixed',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: Color.printBlack,
          zIndex: 100
        }}
      >
        <ScaleLoader color={Color.peach} />
      </View>
    );
  }

  render() {
    return (
      <View style={{ backgroundColor: Color.red }}>
        <View
          style={{
            pointerEvents: 'none',
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: 200
          }}
        >
          {this.renderNotifications()}
        </View>
        {this.renderLoadingOverlay()}
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FoundationContainer);
