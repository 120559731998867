import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class MarketingCommunicationFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.toNotice}
          name="toNotice"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={toNotice => {
            onChangeParams({
              toNotice
            });
          }}
          label="(1) 気づいてもらうための仕組み"
          errorMessage={
            rules &&
            validate({
              label: '気づいてもらうための仕組み',
              value: params.toNotice,
              rule: rules.toNotice
            })
          }
          limitLength={rules.toNotice.maxLength}
          helpText="市場の定義で定めた顧客に対し、気づいてもらうための仕組み記述してください。夾雑物の中でいかにして目立っていくか。どのようにして気をてらってもらうかを記述しましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.toRemember}
          name="toRemember"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={toRemember => {
            onChangeParams({
              toRemember
            });
          }}
          label="(2) 覚えてもらうための仕組み"
          errorMessage={
            rules &&
            validate({
              label: '覚えてもらうための仕組み',
              value: params.toRemember,
              rule: rules.toRemember
            })
          }
          limitLength={rules.toRemember.maxLength}
          helpText="市場の定義で定めた顧客に対し、覚えてもらうための仕組みを記述してください。強い感情の動きを作り、覚えてもらうことがポイントです。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.toLike}
          name="toLike"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={toLike => {
            onChangeParams({
              toLike
            });
          }}
          label="(3) 好きになってもらうための仕組み"
          errorMessage={
            rules &&
            validate({
              label: '好きになってもらうための仕組み',
              value: params.toLike,
              rule: rules.toLike
            })
          }
          limitLength={rules.toLike.maxLength}
          helpText="市場の定義で定めた顧客に対し、好きになってもらうための仕組みを記述してください。強い感情の動きにより、好きになってもらいましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.toUnderstand}
          name="toUnderstand"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={toUnderstand => {
            onChangeParams({
              toUnderstand
            });
          }}
          label="(4) 深く知ってもらうための仕組み"
          errorMessage={
            rules &&
            validate({
              label: '深く知ってもらうための仕組み',
              value: params.toUnderstand,
              rule: rules.toUnderstand
            })
          }
          limitLength={rules.toUnderstand.maxLength}
          helpText="価値の定義で定めた価値を、どのようにして深く知ってもらうかの仕組みを記述してください。様々な方法が考えられます。あらゆる方法を列挙しましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.toSelect}
          name="toSelect"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={toSelect => {
            onChangeParams({
              toSelect
            });
          }}
          label="(5) 選んでもらうための仕組み"
          errorMessage={
            rules &&
            validate({
              label: '選んでもらうための仕組み',
              value: params.toSelect,
              rule: rules.toSelect
            })
          }
          limitLength={rules.toSelect.maxLength}
          helpText="価値の創造で定めた商品やサービスを、どのようにして選んでもらうかの仕組みを記述してください。最後のひと押しとして、割引をするなどが有効です。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingCommunicationFormField);
