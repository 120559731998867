import { Link } from 'react-router-dom';
import React from 'react';

import DreamItem from '../Dream/DreamItem';
import View from '../View';

export default function FounderDreamList({ data, style = {} }) {
  return (
    <View
      style={{
        ...style
      }}
    >
      {data.map((datum, index) => {
        return (
          <View
            key={`founder-dream-${datum.id}-${index}`}
            style={{ marginBottom: 16 }}
          >
            <Link to={`/dream/${datum.id}`}>
              <DreamItem data={datum} />
            </Link>
          </View>
        );
      })}
    </View>
  );
}
