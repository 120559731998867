import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class SchemeAssetFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.revenue}
          name="revenue"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={revenue => {
            onChangeParams({
              revenue
            });
          }}
          label="(1) 収入"
          errorMessage={
            rules &&
            validate({
              label: '収入',
              value: params.revenue,
              rule: rules.revenue
            })
          }
          limitLength={rules.revenue.maxLength}
          helpText="この事業を行う際、どのような収入があるのかを明記してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.cost}
          name="cost"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={cost => {
            onChangeParams({
              cost
            });
          }}
          label="(2) コスト"
          errorMessage={
            rules &&
            validate({
              label: 'コスト',
              value: params.cost,
              rule: rules.cost
            })
          }
          limitLength={rules.cost.maxLength}
          helpText="この事業を行う際、何にどれくらいのコストがかかるかを明記してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.blandishment}
          name="blandishment"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={blandishment => {
            onChangeParams({
              blandishment
            });
          }}
          label="(3) 評判"
          errorMessage={
            rules &&
            validate({
              label: '評判',
              value: params.blandishment,
              rule: rules.blandishment
            })
          }
          limitLength={rules.blandishment.maxLength}
          helpText="この事業を行った後得られる評判と、得られる評判を最大化するための方法について記載してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.criticism}
          name="criticism"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={criticism => {
            onChangeParams({
              criticism
            });
          }}
          label="(4) 批判"
          errorMessage={
            rules &&
            validate({
              label: '批判',
              value: params.criticism,
              rule: rules.criticism
            })
          }
          limitLength={rules.criticism.maxLength}
          helpText="この事業を行った後発生しうる批判、あるいは人が無意識に思ってしまうことについて記載し、それを最小化する方法を記載して下さい。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchemeAssetFormField);
