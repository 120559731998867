import { getCollection } from '../utils/Firebase';
import * as types from '../constants/ActionTypes';

// get header images
function fetchGetConfigs() {
  return {
    type: types.FETCH_GET_CONFIGS
  };
}

function fetchGetConfigsSuccess(list) {
  return {
    type: types.FETCH_GET_CONFIGS_SUCCESS,
    state: {
      hash: list.reduce(
        (acc, item) => ({
          ...acc,
          [item.key]: item.value
        }),
        {}
      )
    }
  };
}

function fetchGetConfigsFailed(error) {
  return {
    type: types.FETCH_GET_CONFIGS_FAILED,
    error
  };
}

export function recoverGetConfigs() {
  return {
    type: types.FETCH_GET_CONFIGS_RECOVER
  };
}

export function getConfigs() {
  return dispatch => {
    dispatch(fetchGetConfigs());

    const configs = getCollection('configs');

    return configs
      .get()
      .then(({ docs }) => {
        const list = docs.map(doc => ({ id: doc.id, ...doc.data() }));

        return dispatch(fetchGetConfigsSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetConfigsFailed(error));
        }
      });
  };
}
