import React from 'react';

import { PdfImage, PdfText, PdfView } from '../../utils/Pdf';
import Color from '../../constants/Color';

export default function PdfFeatureCard({
  title,
  ruby,
  color,
  subColor,
  importanceActiveIcon,
  importanceInactiveIcon,
  importanceLevel,
  content,
  style = {}
}) {
  const [firstWord, ...restWords] = title;

  return (
    <PdfView
      style={{
        border: `1pt solid ${Color.warmGrey}`,
        padding: 8,
        backgroundColor: Color.white,
        ...style
      }}
    >
      <PdfView
        style={{
          flexDirection: 'row',
          borderBottom: `1pt solid ${Color.warmGrey}`,
          paddingBottom: 4,
          marginBottom: 8
        }}
      >
        <PdfView
          style={{
            flexDirection: 'row',
            flex: 1
          }}
        >
          <PdfText
            style={{
              fontSize: 32,
              color,
              fontWeight: 'bold'
            }}
          >
            {firstWord}
          </PdfText>
          <PdfView
            style={{
              justifyContent: 'space-between',
              paddingTop: 4,
              paddingBottom: 4,
              alignItems: 'center'
            }}
          >
            <PdfText
              style={{
                fontSize: 10,
                color,
                fontWeight: 'bold'
              }}
            >
              {ruby.split('').join('  ')}
            </PdfText>
            <PdfText
              style={{
                fontSize: 10,
                color,
                fontWeight: 'bold'
              }}
            >
              {restWords}
            </PdfText>
          </PdfView>
        </PdfView>
        <PdfView
          style={{
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            paddingBottom: 4
          }}
        >
          <PdfView>
            <PdfText style={{ color: subColor, fontSize: 10 }}>重要度</PdfText>
          </PdfView>
          <PdfView style={{ flexDirection: 'row' }}>
            {new Array(4).fill(true).map((_, index) => (
              <PdfImage
                key={`importance-${index}`}
                src={
                  index < importanceLevel
                    ? importanceActiveIcon
                    : importanceInactiveIcon
                }
                style={{ width: 12, height: 12, marginLeft: 2 }}
              />
            ))}
          </PdfView>
        </PdfView>
      </PdfView>
      <PdfView>
        <PdfText style={{ fontSize: 10, lineHeight: 1.3 }}>{content}</PdfText>
      </PdfView>
    </PdfView>
  );
}
