import React from 'react';

import Color from '../../constants/Color';
import Headline from '../Headline';

export default function ArticleHeadline({ children, style = {} }) {
  return (
    <Headline
      size={2}
      style={{
        fontSize: 20,
        color: Color.lightSlate,
        ...style
      }}
    >
      {children}
    </Headline>
  );
}
