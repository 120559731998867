import React from 'react';
import numeral from 'numeral';

import Color from '../../constants/Color';
import MaterialIcon from '../../utils/MaterialIcon';
import PanelCard from '../Card/PanelCard';
import Paragraph from '../Paragraph';
import Text from '../Text';
import View from '../View';

const gradients = [
  Color.orangeGradient,
  Color.peachGradient,
  Color.roseGradient,
  Color.redGradient
];

export default function DreamGifts({ data: { gifts, supporter }, style = {} }) {
  return (
    <View style={style}>
      {gifts.map((gift, index) => {
        return (
          <PanelCard
            key={`gift-${index}`}
            gradient={gradients[index]}
            panel={
              <View style={{ alignItems: 'center' }}>
                <Text
                  style={{
                    color: Color.white,
                    fontWeight: 'bold',
                    fontSize: 13
                  }}
                >
                  THANK
                </Text>
                <Text
                  style={{
                    color: Color.white,
                    fontWeight: 'bold',
                    fontSize: 24
                  }}
                >
                  {index + 1}
                </Text>
              </View>
            }
            style={{ marginBottom: 16 }}
          >
            <View style={{ flexDirection: 'row' }}>
              <View style={{ flex: 1, marginRight: 8 }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  {supporter.total >= gift.price && (
                    <View style={{ marginRight: 2 }}>
                      <MaterialIcon
                        color={Color.green}
                        icon="check_circle"
                        size={17}
                      />
                    </View>
                  )}
                  <Text
                    style={{
                      color: Color.slate,
                      fontSize: 11,
                      fontWeight: 'bold'
                    }}
                  >
                    {gift.title}
                  </Text>
                </View>
                <Paragraph style={{ fontSize: 10 }}>
                  {gift.description}
                </Paragraph>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'baseline',
                justifyContent: 'flex-end'
              }}
            >
              <Text
                style={{
                  color: Color.slate,
                  fontWeight: 'bold',
                  fontSize: 24
                }}
              >
                {numeral(gift.price).format('0,0')}
              </Text>
              <Text
                style={{
                  color: Color.slate,
                  fontWeight: 'bold',
                  fontSize: 11
                }}
              >
                円
              </Text>
            </View>
          </PanelCard>
        );
      })}
    </View>
  );
}
