import {
  AirplaneTakeoffIconSvg,
  BookOpenPageVariantIconSvg,
  BriefcaseIconSvg,
  CashMultipleIconSvg,
  CreationIconSvg,
  DesktopTowerMonitorIconSvg,
  DomainIconSvg,
  EarthIconSvg,
  FireIconSvg,
  FountainPenTipIconSvg,
  GamepadVariantIconSvg,
  GavelIconSvg,
  GoogleFitIconSvg,
  GraveStoneIconSvg,
  HatFedoraIconSvg,
  JudaismIconSvg,
  LeafIconSvg,
  MedicalBagIconSvg,
  MusicIconSvg,
  OfficeBuildingIconSvg,
  PaletteIconSvg,
  PawIconSvg,
  PineTreeIconSvg,
  SchoolIconSvg,
  SecurityIconSvg,
  ShieldCheckIconSvg,
  SilverwareForkKnifeIconSvg,
  SoccerIconSvg,
  TelevisionClassicIconSvg,
  TrainCarIconSvg,
  TruckFastIconSvg,
  VideoVintageIconSvg,
  WebIconSvg,
  WrenchIconSvg
} from '../utils/MaterialIconSvg';

export default class BusinessCategory {
  static getItems(withDefault = true) {
    const items = [
      {
        label: '医療',
        value: 'medical',
        iconName: 'MedicalBagIcon',
        icon: MedicalBagIconSvg
      },
      {
        label: '福祉・セラピー',
        iconName: 'LeafIcon',
        icon: LeafIconSvg,
        value: 'welfare'
      },
      {
        label: '美容',
        iconName: 'CreationIcon',
        icon: CreationIconSvg,
        value: 'beauty'
      },
      {
        label: 'ファッション',
        iconName: 'HatFedoraIcon',
        icon: HatFedoraIconSvg,
        value: 'fashion'
      },
      {
        label: '旅行',
        iconName: 'AirplaneTakeoffIcon',
        icon: AirplaneTakeoffIconSvg,
        value: 'tour'
      },
      {
        label: '飲食',
        iconName: 'SilverwareForkKnifeIcon',
        icon: SilverwareForkKnifeIconSvg,
        value: 'restaurant',
        headerImage: require('../assets/img/categories/restaurant/header.jpg')
      },
      {
        label: '教育',
        iconName: 'SchoolIcon',
        icon: SchoolIconSvg,
        value: 'education'
      },
      {
        label: '自然',
        iconName: 'PineTreeIcon',
        icon: PineTreeIconSvg,
        value: 'nature'
      },
      {
        label: '動物',
        iconName: 'PawIcon',
        icon: PawIconSvg,
        value: 'animal'
      },
      {
        label: '運輸',
        iconName: 'TruckFastIcon',
        icon: TruckFastIconSvg,
        value: 'transportation'
      },
      {
        label: '乗り物',
        iconName: 'TrainCarIcon',
        icon: TrainCarIconSvg,
        value: 'vehicle'
      },
      {
        label: '出版',
        iconName: 'BookOpenPageVariantIcon',
        icon: BookOpenPageVariantIconSvg,
        value: 'publication'
      },
      {
        label: 'メディア',
        iconName: 'TelevisionClassicIcon',
        icon: TelevisionClassicIconSvg,
        value: 'media'
      },
      {
        label: '映像',
        iconName: 'VideoVintageIcon',
        icon: VideoVintageIconSvg,
        value: 'movie'
      },
      {
        label: '音楽',
        iconName: 'MusicIcon',
        icon: MusicIconSvg,
        value: 'music'
      },
      {
        label: '芸能',
        iconName: 'FireIcon',
        icon: FireIconSvg,
        value: 'entertainment'
      },
      {
        label: 'スポーツ',
        iconName: 'SoccerIcon',
        icon: SoccerIconSvg,
        value: 'sport'
      },
      {
        label: '娯楽',
        iconName: 'GamepadVariantIcon',
        icon: GamepadVariantIconSvg,
        value: 'sport'
      },
      {
        label: 'アート',
        iconName: 'PaletteIcon',
        icon: PaletteIconSvg,
        value: 'art'
      },
      {
        label: 'デザイン',
        iconName: 'FountainPenTipIcon',
        icon: FountainPenTipIconSvg,
        value: 'design'
      },
      {
        label: 'IT',
        iconName: 'WebIcon',
        icon: WebIconSvg,
        value: 'it'
      },
      {
        label: 'メカニカル',
        iconName: 'WrenchIcon',
        icon: WrenchIconSvg,
        value: 'mechanical'
      },
      {
        label: '保安',
        iconName: 'ShieldCheckIcon',
        icon: ShieldCheckIconSvg,
        value: 'security'
      },
      {
        label: '法律',
        iconName: 'GavelIcon',
        icon: GavelIconSvg,
        value: 'law'
      },
      {
        label: '国際',
        iconName: 'EarthIcon',
        icon: EarthIconSvg,
        value: 'international'
      },
      {
        label: '金融',
        iconName: 'CashMultipleIcon',
        icon: CashMultipleIconSvg,
        value: 'finance'
      },
      {
        label: '建築',
        iconName: 'OfficeBuildingIcon',
        icon: OfficeBuildingIconSvg,
        value: 'building'
      },
      {
        label: '事務',
        iconName: 'DesktopTowerMonitorIcon',
        icon: DesktopTowerMonitorIconSvg,
        value: 'officeWork'
      },
      {
        label: '販売',
        iconName: 'BriefcaseIcon',
        icon: BriefcaseIconSvg,
        value: 'sales'
      },
      {
        label: '文化',
        iconName: 'GoogleFitIcon',
        icon: GoogleFitIconSvg,
        value: 'calture'
      },
      {
        label: '公務',
        iconName: 'SecurityIcon',
        icon: SecurityIconSvg,
        value: 'officialBusiness'
      },
      {
        label: '葬祭',
        iconName: 'GraveStoneIcon',
        icon: GraveStoneIconSvg,
        value: 'funeral'
      },
      {
        label: '宗教',
        iconName: 'JudaismIcon',
        icon: JudaismIconSvg,
        value: 'religion'
      },
      {
        label: 'その他',
        iconName: 'DomainIcon',
        icon: DomainIconSvg,
        value: 'other'
      }
    ];

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getItem(value) {
    return BusinessCategory.getItems().find(item => item.value === value);
  }

  static getLabel(value) {
    return BusinessCategory.getItems().find(item => item.value === value).label;
  }
}
