import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import ActivityHistoryList from '../../../containers/activity/ActivityHistoryList';
import Color from '../../../constants/Color';
import Headline from '../../../components/Headline';
import PageHeader from '../../../containers/page/PageHeader';
import Section from '../../../components/Section/Section';
import SectionTransitItem from '../../../components/Section/SectionTransitItem';
import SignOutButton from '../../../containers/auth/SignOutButton';
import Style from '../../../constants/Style';
import Text from '../../../components/Text';
import UserIcon from '../../../components/Icons/UserIcon';
import View from '../../../components/View';
import actions from '../../../actions';

class MypageIndex extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getMyselfUser();

    this.props.getPatronContracts({ reset: true });
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <View
          style={{
            backgroundColor: Color.peach,
            height: 100,
            marginBottom: -48
          }}
        >
          <View
            style={{
              flex: 1,
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              padding: 8
            }}
          >
            <SignOutButton />
          </View>
        </View>
        <View style={{ alignItems: 'center', marginBottom: 24 }}>
          <UserIcon
            source={myself.iconImage}
            size={96}
            style={{ ...Style.shadow.large }}
          />
        </View>
        <View style={{ marginBottom: 40 }}>
          <Headline
            size={3}
            style={{ fontSize: 15, marginLeft: 16, marginBottom: 8 }}
          >
            プロフィール
          </Headline>
          <Section>
            <Link to="/mypage/name/edit">
              <SectionTransitItem title="ユーザー名" content={myself.name} />
            </Link>
            <Link to="/mypage/profile/edit">
              <SectionTransitItem title="自己紹介" content={myself.profile} />
            </Link>
            <Link to="/mypage/twitterId/edit">
              <SectionTransitItem
                title="Twitter ID"
                content={`@${myself.twitterId}`}
              />
            </Link>
            {false && (
              <Link to="/mypage/patron">
                <SectionTransitItem title="パトロン" />
              </Link>
            )}
          </Section>
        </View>
        <View style={{ marginBottom: 40 }}>
          <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
            <Headline
              size={3}
              style={{ fontSize: 15, marginLeft: 16, marginBottom: 8 }}
            >
              お届先情報
            </Headline>
            <Text style={{ color: Color.grey, fontSize: 12 }}>
              （コース申込時に必要です。）
            </Text>
          </View>
          <Section>
            <Link to="/mypage/email/edit">
              <SectionTransitItem
                title="メール"
                content={myself.email ? myself.email : '未入力'}
              />
            </Link>
            <Link to="/mypage/shippingInfo">
              <SectionTransitItem title="住所情報" />
            </Link>
          </Section>
        </View>
        <View style={{ marginBottom: 40 }}>
          <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
            <Headline
              size={3}
              style={{ fontSize: 15, marginLeft: 16, marginBottom: 8 }}
            >
              お支払い情報
            </Headline>
            <Text style={{ color: Color.grey, fontSize: 12 }}>
              （ご支払い時に必要です。）
            </Text>
          </View>
          <Section>
            <Link to="/mypage/paymentInfo">
              <SectionTransitItem
                title="カード情報"
                content={`${myself.paymentInfos.length}件`}
              />
            </Link>
          </Section>
        </View>
        <View style={{ marginBottom: 40 }}>
          <View style={{ flexDirrection: 'row', alignItems: 'baseline' }}>
            <Headline
              size={3}
              style={{ fontSize: 15, marginLeft: 16, marginBottom: 8 }}
            >
              パトロン
            </Headline>
          </View>
          <Section>
            <Link to="/mypage/patronContract">
              <SectionTransitItem
                title="パトロン支援"
                content={`${this.props.patronContract.list.length}件`}
              />
            </Link>
          </Section>
        </View>
        <View style={{ paddingBottom: 32 }}>
          <Headline
            size={3}
            style={{ fontSize: 15, marginLeft: 16, marginBottom: 16 }}
          >
            アクティビティ
          </Headline>
          <ActivityHistoryList style={{ padding: '0 8px' }} />
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypageIndex);
