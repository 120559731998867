import { Link } from 'react-router-dom';
import React from 'react';

import Button from '../Button';
import Color from '../../constants/Color';
import MaterialIcon from '../../utils/MaterialIcon';
import Paragraph from '../Paragraph';
import Text from '../Text';
import TopLogo from './TopLogo';
import View from '../View';

export default function TopHeader({ children, style = {} }) {
  return (
    <View style={style}>
      <View
        style={{
          backgroundImage: `url(${require('../../assets/img/title-first-header.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <View
          style={{
            position: 'relative',
            alignItems: 'center',
            marginBottom: 80
          }}
        >
          <TopLogo style={{ marginBottom: 24 }} />
          <View style={{ alignItems: 'center', marginBottom: 32 }}>
            <Link to={`/document/beta`}>
              <Button tiny variant="flat">
                <Text
                  style={{ color: Color.slate, fontSize: 13, marginRight: 8 }}
                >
                  ベータ版
                </Text>
                <MaterialIcon
                  icon="arrow_forward_ios"
                  color={Color.slate}
                  size={17}
                />
              </Button>
            </Link>
          </View>
          <View
            style={{
              borderRadius: 16,
              border: `2px solid ${Color.white}`,
              padding: '16px 24px 32px 24px'
            }}
          >
            <Paragraph
              style={{
                color: Color.white,
                textAlign: 'center',
                fontWeight: 'bold'
              }}
            >
              {`クラウドファンディング 
              「VISIONS」は
              夢を叶える人々の活動を支援します`}
            </Paragraph>
          </View>
          <View
            style={{
              position: 'absolute',
              bottom: -12
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'baseline',
                backgroundColor: Color.white,
                borderRadius: 8,
                padding: '4px 24px'
              }}
            >
              <Text
                style={{ fontSize: 10, color: Color.slate, fontWeight: 'bold' }}
              >
                業界最安価
              </Text>
              <Text
                style={{ fontSize: 10, color: Color.slate, marginRight: 4 }}
              >
                の手数料
              </Text>
              <Text
                style={{
                  fontSize: 15,
                  color: Color.pink,
                  fontWeight: 'bold',
                  marginRight: 2
                }}
              >
                3.6
              </Text>
              <Text
                style={{ fontSize: 10, color: Color.slate, fontWeight: 'bold' }}
              >
                %
              </Text>
            </View>
          </View>
        </View>
      </View>
      {children}
      <View
        style={{
          backgroundImage: `url(${require('../../assets/img/title-second-header.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: '48px 0 56px 0',
          alignItems: 'center'
        }}
      >
        <Text style={{ color: Color.white, fontSize: 12, fontWeight: 'bold' }}>
          個人が輝ける時代へ
        </Text>
      </View>
    </View>
  );
}
