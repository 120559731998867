import React from 'react';

import ArticleHeadline from './ArticleHeadline';
import ArticleSubHeadline from './ArticleSubHeadline';
import Paragraph from '../Paragraph';
import View from '../View';

export default function ArticleTemplate({
  title,
  number,
  description,
  sections = [],
  style = {}
}) {
  return (
    <View style={style}>
      <ArticleHeadline number={number} style={{ marginBottom: 16 }}>
        {title}
      </ArticleHeadline>
      <Paragraph style={{ fontSize: 17 }} containerStyle={{ marginBottom: 32 }}>
        {description}
      </Paragraph>
      {sections.map((section, index) => {
        return (
          <View>
            <ArticleSubHeadline style={{ marginBottom: 16 }}>
              ({index + 1}){section.title}
            </ArticleSubHeadline>
            <Paragraph
              style={{ fontSize: 17 }}
              containerStyle={{ marginBottom: 32 }}
            >
              {section.content}
            </Paragraph>
          </View>
        );
      })}
    </View>
  );
}
