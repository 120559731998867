export default class SupplyClass {
  static getItems(withDefault = true) {
    const items = [
      {
        label: 'ハイエンド',
        value: 'highEnd'
      },
      {
        label: '汎用品',
        value: 'general'
      },
      {
        label: 'ローエンド',
        value: 'lowEnd'
      }
    ];

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getLabel(value) {
    return SupplyClass.getItems().find(item => item.value === value).label;
  }
}
