import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import DreamGifts from '../../components/Dream/DreamGifts';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class GiftList extends React.Component {
  render() {
    const { data, style = {} } = this.props;

    if (data.gifts && data.gifts.length < 1) {
      return (
        <View
          style={{
            ...style
          }}
        >
          <View style={{ padding: 8 }}>
            <Text>お礼はありません。</Text>
          </View>
        </View>
      );
    }

    return <DreamGifts data={data} style={style} />;
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftList);
