import React from 'react';

import Color from '../constants/Color';
import Text from './Text';

export default function EmptyText({ children, style = {} }) {
  return (
    <Text
      style={{
        color: Color.lightSlate,
        fontSize: 15,
        ...style
      }}
    >
      {children}
    </Text>
  );
}
