import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Headline from '../../components/Headline';
import Paragraph from '../../components/Paragraph';
import RegisterModal from '../RegisterModal';
import View from '../../components/View';
import actions from '../../actions';

class ProductPurchaseLink extends React.Component {
  state = {
    isOpenRegisterModal: false
  };

  renderContent() {
    const { children, stockCount, to } = this.props;

    if (!this.props.auth.uid) {
      return (
        <View
          onClick={() => {
            this.setState({ isOpenRegisterModal: true });
          }}
        >
          {children}
        </View>
      );
    }

    return (
      <Link
        onClick={event => {
          if (stockCount < 1) {
            event.preventDefault();
          }
        }}
        to={to}
      >
        {children}
      </Link>
    );
  }

  render() {
    return (
      <View>
        {this.renderContent()}
        <RegisterModal
          visible={this.state.isOpenRegisterModal}
          onClose={() => this.setState({ isOpenRegisterModal: false })}
          content={
            <View style={{ marginBottom: 8 }}>
              <Headline size={3} style={{ marginBottom: 16 }}>
                コースへお申し込みする前に
              </Headline>
              <Paragraph>
                コースへ申し込みするためには、新規登録/ログインをする必要があります。
              </Paragraph>
            </View>
          }
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPurchaseLink);
