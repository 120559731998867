import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { getErrorMessage } from '../utils/ErrorMessage';
import Button from '../components/Button';
import Color from '../constants/Color';
import ModalBox from '../components/Modal/ModalBox';
import ModalBoxHeader from '../components/Modal/ModalBoxHeader';
import Paragraph from '../components/Paragraph';
import ResponseDispatcher from '../components/ResponseDispatcher';
import Text from '../components/Text';
import View from '../components/View';
import actions from '../actions';

class RegisterModal extends React.Component {
  render() {
    const { content } = this.props;

    return (
      <ModalBox
        visible={this.props.visible}
        onClose={() => this.props.onClose()}
      >
        <ModalBoxHeader
          title="ログイン"
          left={
            <View onClick={() => this.props.onClose()}>
              <Text style={{ color: Color.slate }}>キャンセル</Text>
            </View>
          }
          style={{ marginBottom: 16 }}
        />
        {content}
        <Button
          variant="contained"
          color={Color.twitterBlue}
          onClick={() => {
            this.props.authWithTwitter();
          }}
          style={{ marginBottom: 8 }}
        >
          <Text
            style={{
              color: Color.white,
              fontSize: 15,
              fontWeight: 'bold'
            }}
          >
            Twitterログイン
          </Text>
        </Button>
        <Paragraph onerow style={{ marginBottom: 24 }}>
          上記ボタンからログインで、
          <Link
            to="/document/privacyPolicy"
            style={{ textDecoration: 'underline', display: 'inline' }}
          >
            プライバシーポリシー
          </Link>
          、
          <Link
            to="/document/term"
            style={{ textDecoration: 'underline', display: 'inline' }}
          >
            利用規約
          </Link>
          に同意したものとみなします。
        </Paragraph>
        <ResponseDispatcher
          isLoading={this.props.auth.isAuthLoading}
          isFailed={this.props.auth.isAuthFailed}
          onComplete={() => this.props.onClose()}
          onError={() => {
            const error = this.props.auth.authError;

            if (
              error.code === 'auth/invalid-email' ||
              error.code === 'auth/email-already-in-use'
            ) {
              this.setState({ emailErrorMessage: getErrorMessage(error) });
            }

            if (error.code === 'auth/weak-password') {
              this.setState({ passwordErrorMessage: getErrorMessage(error) });
            }

            this.props.recoverAuthWithTwitter();
          }}
        />
      </ModalBox>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterModal);
