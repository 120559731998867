import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import CommentItem from '../../components/Dream/CommentItem';
import LoadMoreList from '../../components/List/LoadMoreList';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class CommentList extends React.Component {
  render() {
    const { onClickItem, style = {} } = this.props;

    const dream = this.props.dream.hash[this.props.dreamId];

    if (!dream || this.props.dream.isGetCommentLoading) {
      return null;
    }

    if (dream.comments.length < 1) {
      return (
        <View style={{ padding: 16 }}>
          <Text>コメントはありません。</Text>
        </View>
      );
    }

    return (
      <View
        style={{
          ...style
        }}
      >
        <LoadMoreList
          data={dream.comments}
          renderItem={({ item, index }) => {
            return (
              <CommentItem
                key={`comment-${item.id}`}
                data={item}
                founderId={dream.founder.id}
                onClick={() => onClickItem(item.id)}
                users={this.props.user.list.map(id => this.props.user.hash[id])}
              />
            );
          }}
          onFetchMore={lastItem => {
            // this.props.getPatronContracts({ lastItem });
          }}
          isLoading={this.props.dream.isGetLoading}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentList);
