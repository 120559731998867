import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalBusinessProcessFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.buying}
          name="buying"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={buying => {
            onChangeParams({
              buying
            });
          }}
          label="(1) 仕入れ・供給ルート"
          errorMessage={
            rules &&
            validate({
              label: '仕入れ・供給ルート',
              value: params.buying,
              rule: rules.buying
            })
          }
          limitLength={rules.buying.maxLength}
          helpText="仕入れ・供給ルートについて記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.production}
          name="production"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={production => {
            onChangeParams({
              production
            });
          }}
          label="(2) 製造・外注ルート"
          errorMessage={
            rules &&
            validate({
              label: '製造・外注ルート',
              value: params.production,
              rule: rules.production
            })
          }
          limitLength={rules.production.maxLength}
          helpText="製造・外注するものについて記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.distribution}
          name="distribution"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={distribution => {
            onChangeParams({
              distribution
            });
          }}
          label="(3) 販売・流通ルート"
          errorMessage={
            rules &&
            validate({
              label: '販売・流通ルート',
              value: params.distribution,
              rule: rules.distribution
            })
          }
          limitLength={rules.distribution.maxLength}
          helpText="販売ルートについて記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.marketing}
          name="marketing"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={marketing => {
            onChangeParams({
              marketing
            });
          }}
          label="(4) 販促・PRルート"
          errorMessage={
            rules &&
            validate({
              label: '販促・PRルート',
              value: params.marketing,
              rule: rules.marketing
            })
          }
          limitLength={rules.marketing.maxLength}
          helpText="販促やPRの計画を記述してください。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalBusinessProcessFormField);
