import React from 'react';

import Color from '../../constants/Color';
import Image from '../Image';
import Paragraph from '../Paragraph';
import Text from '../Text';
import View from '../View';

export default function DreamDesktopItem({ data }) {
  return (
    <View
      style={{
        width: 240,
        alignSelf: 'center',
        textAlign: 'center'
      }}
    >
      <Image
        source={data.cardBackgroundImage}
        style={{
          width: 240,
          height: 240,
          borderRadius: 120,
          marginBottom: 24,
          objectFit: 'cover'
        }}
      />
      <Text
        style={{
          color: Color.slate,
          fontSize: 15,
          fontWeight: 'bold',
          marginBottom: 8
        }}
      >
        {data.title}
      </Text>
      <Paragraph
        style={{
          color: Color.slate,
          fontSize: 13
        }}
      >
        {data.subTitle}
      </Paragraph>
    </View>
  );
}
