import * as types from '../constants/ActionTypes';

export function updateSchemeFormParams({ scheme, viewPassword, editPassword }) {
  return {
    type: types.UPDATE_SCHEME_FORM_PARAMS,
    state: {
      params: {
        scheme,
        viewPassword,
        editPassword
      }
    }
  };
}
