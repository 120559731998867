import {
  signOut as firebaseSignOut,
  getCollection,
  getTimestamp,
  signInWithTwitter
} from '../utils/Firebase';
import * as types from '../constants/ActionTypes';

// twitter login
function authWithTwitterEntry() {
  return {
    type: types.AUTH_WITH_TWITTER_ENTRY
  };
}

function authWithTwitterSuccess({ user, credential }) {
  return {
    type: types.AUTH_WITH_TWITTER_SUCCESS,
    state: {
      uid: user.uid,
      info: {
        accessToken: credential.accessToken,
        secret: credential.secret
      }
    }
  };
}

function authWithTwitterFailed(error) {
  return {
    type: types.AUTH_WITH_TWITTER_FAILED,
    error
  };
}

export function recoverAuthWithTwitter() {
  return {
    type: types.AUTH_WITH_TWITTER_RECOVER
  };
}

export function authWithTwitter() {
  return dispatch => {
    dispatch(authWithTwitterEntry());

    let authResult;

    return signInWithTwitter()
      .then(result => {
        authResult = result;
        console.log(authResult);

        return result;
      })
      .then(async result => {
        const {
          additionalUserInfo: {
            profile: { description, screen_name: screenName }
          }
        } = result;

        const users = getCollection('users');

        const userRef = users.doc(result.user.uid);

        const doc = await userRef.get();

        if (doc.exist) {
          return new Promise(resolve => resolve());
        } else {
          return userRef
            .set({
              name: result.user.displayName,
              iconImage: { uri: result.user.photoURL.replace('_normal', '') },
              createdAt: getTimestamp()
            })
            .then(() => {
              const consumers = getCollection('consumers').doc(result.user.uid);

              return consumers.set({
                profile: description,
                twitterId: screenName,
                createdAt: getTimestamp()
              });
            });
        }
      })
      .then(() => {
        return dispatch(authWithTwitterSuccess(authResult));
      })
      .catch(error => {
        if (error) {
          return dispatch(authWithTwitterFailed(error));
        }
      });
  };
}

// sign out
function authSignOutEntry() {
  return {
    type: types.AUTH_SIGN_OUT_ENTRY
  };
}

function authSignOutSuccess() {
  return {
    type: types.AUTH_SIGN_OUT_SUCCESS
  };
}

function authSignOutFailed(error) {
  return {
    type: types.AUTH_SIGN_OUT_FAILED,
    error
  };
}

export function recoverAuthSignOut() {
  return {
    type: types.AUTH_SIGN_OUT_RECOVER
  };
}

export function signOut() {
  return dispatch => {
    dispatch(authSignOutEntry());

    return firebaseSignOut()
      .then(() => {
        return dispatch(authSignOutSuccess());
      })
      .catch(error => {
        if (error) {
          return dispatch(authSignOutFailed(error));
        }
      });
  };
}
