import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { getGradient, getGradientCss } from '../utils/ColorJs';
import Button from './Button';
import Color from '../constants/Color';
import Headline from './Headline';
import MaterialIcon from '../utils/MaterialIcon';
import Paragraph from './Paragraph';
import SponsorItem from './Sponsor/SponsorItem';
import Text from './Text';
import TopLogo from './Top/TopLogo';
import View from '../components/View';
import actions from '../actions';

class Footer extends React.Component {
  componentDidMount() {
    this.props.getSponsors();
  }

  render() {
    const sponsors = this.props.sponsor.list.map(
      id => this.props.sponsor.hash[id]
    );

    return (
      <View
        style={{
          backgroundImage: getGradientCss(getGradient(Color.navyGradient), 0),
          padding: '64px 16px 40px 16px'
        }}
      >
        <View style={{ textAlign: 'center' }}>
          <Headline style={{ color: Color.white, marginBottom: 16 }}>
            VISIONSとは
          </Headline>
          <Paragraph
            style={{ color: Color.white }}
            containerStyle={{ marginBottom: 32 }}
          >
            VISIONSは、個人の活動を通して
            {'\n'}
            次の世代へ「夢」を与えるため
            {'\n'}
            制作されたクラウドファンディングです。
            {'\n'}
            発案者であるファウンダーは
            {'\n'}
            皆様からいただいたお金で
            {'\n'}
            様々な活動しその記録を
            {'\n'}
            レポートとして残していきます。
          </Paragraph>
        </View>
        <Headline style={{ color: Color.white, marginBottom: 16 }}>
          スポンサー
        </Headline>
        <Paragraph
          style={{ color: Color.white }}
          containerStyle={{ marginBottom: 16 }}
        >
          VISIONSに共感・支援してくれているスポンサーの方々です。
        </Paragraph>
        {sponsors.map(sponsor => (
          <SponsorItem data={sponsor} style={{ marginBottom: 16 }} />
        ))}
        <View style={{ marginBottom: 32 }}>
          {/*<Button
            shadow={false}
            variant="flat"
            tiny
            style={{
              justifyContent: 'space-between',
              textAlign: 'left',
              backgroundColor: 'rgba(18,18,18,0.5)',
              marginBottom: 8
            }}
          >
            <View style={{ flex: 1 }}>
              <Text style={{ color: Color.white, fontSize: 13 }}>
                その他スポンサーの方々はこちら
              </Text>
            </View>
            <View>
              <MaterialIcon
                icon="arrow_forward_ios"
                color={Color.white}
                size={13}
              />
            </View>
          </Button>*/}
          <Link to={`/founder/JQapSO4V1gUYSlAqxMxjxAVaGsw1`}>
            <Button
              shadow={false}
              variant="flat"
              tiny
              style={{
                justifyContent: 'space-between',
                textAlign: 'left',
                backgroundColor: 'rgba(18,18,18,0.5)'
              }}
            >
              <View style={{ flex: 1 }}>
                <Text style={{ color: Color.white, fontSize: 13 }}>
                  スポンサーをご希望の方はこちら
                </Text>
              </View>
              <View>
                <MaterialIcon
                  icon="arrow_forward_ios"
                  color={Color.white}
                  size={13}
                />
              </View>
            </Button>
          </Link>
        </View>
        <View
          style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 32 }}
        >
          <Link
            to="/document/privacyPolicy"
            style={{ marginRight: 8, marginBottom: 8 }}
          >
            <Text
              style={{ color: Color.white, fontSize: 11, fontWeight: 'bold' }}
            >
              プライバシーポリシー
            </Text>
          </Link>
          <Link to="/document/term" style={{ marginRight: 8, marginBottom: 8 }}>
            <Text
              style={{ color: Color.white, fontSize: 11, fontWeight: 'bold' }}
            >
              利用規約
            </Text>
          </Link>
          <Link
            to="/document/guideline"
            style={{ marginRight: 8, marginBottom: 8 }}
          >
            <Text
              style={{ color: Color.white, fontSize: 11, fontWeight: 'bold' }}
            >
              ガイドライン
            </Text>
          </Link>
          <Link to="/document/scta" style={{ marginBottom: 8 }}>
            <Text
              style={{ color: Color.white, fontSize: 11, fontWeight: 'bold' }}
            >
              特定商取引法に基づく表記
            </Text>
          </Link>
        </View>
        <View style={{ alignItems: 'center' }}>
          <TopLogo />
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
