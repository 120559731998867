import React from 'react';
import marked from 'marked';

marked.setOptions({ breaks: true });

export default function Markdown({ children, large = false, style = {} }) {
  if (!children) {
    return null;
  }

  return (
    <div
      className={large ? 'markdown large' : 'markdown'}
      dangerouslySetInnerHTML={{ __html: marked(children) }}
      style={style}
    />
  );
}
