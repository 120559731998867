import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

export default function SponsorItem({
  data: { iconImage, name, url },
  style = {}
}) {
  return (
    <View style={style}>
      <a rel="noopener noreferrer" href={url} target="_blank">
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <UserIcon source={iconImage} size={40} style={{ marginRight: 16 }} />
          <Text
            style={{ color: Color.white, fontSize: 15, fontWeight: 'bold' }}
          >
            {name}
          </Text>
        </View>
      </a>
    </View>
  );
}
