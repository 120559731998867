import React from 'react';

import Color from '../constants/Color';

export default function Text({ children, style = {} }) {
  return (
    <span
      style={{
        color: Color.black,
        lineHeight: 1.5,
        ...style
      }}
    >
      {children}
    </span>
  );
}
