import { Elements } from 'react-stripe-elements';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../components/Button';
import CardForm from '../payment/CardForm';
import Color from '../../constants/Color';
import Hr from '../../components/Hr';
import Label from '../../components/Form/Label';
import ModalBox from '../../components/Modal/ModalBox';
import ModalBoxHeader from '../../components/Modal/ModalBoxHeader';
import PatronizeAmount from '../../models/PatronizeAmount';
import PaymentInfo from '../../models/PaymentInfo';
import ResponseDispatcher from '../../components/ResponseDispatcher';
import Select from '../../utils/Select';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class PatronizeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: PatronizeAmount.defaultItem,
      isClicked: false,
      cardFormError: null,
      paymentInfoId:
        (props.user.myself && props.user.myself.defaultPaymentInfoId) || null
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.user.myself !== this.props.user.myself &&
      nextProps.user.myself
    ) {
      this.setState({
        paymentInfoId: nextProps.user.myself.defaultPaymentInfoId || null
      });
    }

    return true;
  }

  render() {
    const paymentInfos =
      (this.props.user.myself && this.props.user.myself.paymentInfos) || [];

    const values = paymentInfos.map(info => new PaymentInfo(info).getItem());

    const selectedPaymentInfo = paymentInfos.find(
      info => info.id === this.state.paymentInfoId
    );

    const selectedPaymentInfoOption = (selectedPaymentInfo &&
      new PaymentInfo(selectedPaymentInfo).getItem()) || {
      label: '新しいお支払元',
      value: null
    };

    const isNewPaymentInfo = this.state.paymentInfoId === null;

    return (
      <ModalBox
        visible={this.props.visible}
        onClose={() => this.props.onClose()}
      >
        <ModalBoxHeader
          title="パトロン"
          left={
            <View onClick={() => this.props.onClose()}>
              <Text style={{ color: Color.slate }}>キャンセル</Text>
            </View>
          }
          style={{ marginBottom: 16 }}
        />
        <Label style={{ marginBottom: 8 }}>ファウンダーを支援する金額</Label>
        <View style={{ marginBottom: 16 }}>
          <Select
            value={this.state.amount}
            onChange={amount => {
              this.setState({ amount });
            }}
            options={PatronizeAmount.getItems()}
          />
        </View>
        {paymentInfos.length > 0 && (
          <View>
            <Label style={{ marginBottom: 8 }}>お支払い情報</Label>
            <View style={{ marginBottom: 16 }}>
              <Select
                value={selectedPaymentInfoOption}
                onChange={paymentInfoOption => {
                  this.setState({ paymentInfoId: paymentInfoOption.value });
                }}
                options={[
                  {
                    label: '新しいお支払先',
                    value: null
                  },
                  ...values
                ]}
              />
            </View>
          </View>
        )}
        {isNewPaymentInfo ? (
          <View>
            <Hr style={{ marginBottom: 16 }} />
            <Elements>
              <CardForm
                fontSize={17}
                disable={this.state.isClicked}
                error={this.state.cardFormError}
                onClick={() => this.setState({ isClicked: true })}
                onCreateToken={payload => {
                  if (payload.error) {
                    return this.setState({
                      cardFormError: payload.error,
                      isClicked: false
                    });
                  }

                  this.props.createPatronContract({
                    founderId: this.props.founderId,
                    token: payload.token.id,
                    amount: Number(this.state.amount.value),
                    paymentInfoId: null
                  });

                  this.props.onClose();
                }}
              />
            </Elements>
          </View>
        ) : (
          <Button
            onClick={() => {
              this.props.createPatronContract({
                founderId: this.props.founderId,
                token: null,
                amount: Number(this.state.amount.value),
                paymentInfoId: this.state.paymentInfoId
              });

              this.props.onClose();
            }}
          >
            決済する
          </Button>
        )}
        <ResponseDispatcher
          isLoading={this.props.founder.isPatronLoading}
          isFailed={this.props.founder.isPatronFailed}
          onComplete={() =>
            this.setState(
              {
                amount: PatronizeAmount.defaultItem,
                isClicked: false,
                cardFormError: null
              },
              () => this.props.onClose()
            )
          }
        />
      </ModalBox>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatronizeModal);
