import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';

import Image from '../Image';
import Slider from '../../utils/Slider';
import View from '../View';
import actions from '../../actions';

class FounderSlider extends React.Component {
  render() {
    const { data, style = {} } = this.props;

    if (data.length < 1) {
      return (
        <View style={style}>
          <ReactPlaceholder
            showLoadingAnimation
            type="rect"
            style={{ width: '100%', height: 160 }}
          />
        </View>
      );
    }

    return (
      <View style={style}>
        <Slider
          autoplay
          autoplaySpeed={3000}
          arrows={false}
          slidesToShow={1}
          slidesToScroll={1}
          speed={500}
        >
          {data.filter(datum => datum.uri).map((item, index) => {
            const key = index;

            return <Image key={key} source={item} />;
          })}
        </Slider>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FounderSlider);
