import React from 'react';

import { getGradient, getGradientCss } from '../../utils/ColorJs';
import Style from '../../constants/Style';
import View from '../View';

export default function PanelCard({
  gradient,
  panel,
  panelLeft = 8,
  children,
  after,
  panelSize = 64,
  panelWidth,
  panelHeight,
  style = {},
  contentContainerStyle = {}
}) {
  return (
    <View
      style={{
        position: 'relative',
        paddingTop: 10,
        ...style
      }}
    >
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: panelLeft,
          width: panelWidth || panelSize,
          height: panelHeight || panelSize,
          borderRadius: 8,
          backgroundImage:
            gradient && getGradientCss(getGradient(gradient), 135),
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {panel}
      </View>
      <View
        style={{
          borderRadius: 8,
          padding: 8,
          ...Style.shadow.large,
          ...contentContainerStyle
        }}
      >
        <View
          style={{
            minHeight: panelHeight || panelSize,
            paddingLeft: (panelWidth || panelSize) + 16
          }}
        >
          {children}
        </View>
        <View>{after}</View>
      </View>
    </View>
  );
}
