import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updatedDiff } from 'deep-object-diff';
import React from 'react';

import {
  MYPAGE_ACCOUNT_LABELS,
  MYPAGE_ACCOUNT_VALIDATION_RULE
} from '../../../../constants/Validations';
import { validateAll } from '../../../../utils/Validation';
import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import Column from '../../../../components/Column';
import ContentContainer from '../../../../components/Page/ContentContainer';
import FooterContainer from '../../../../components/FooterContainer';
import Headline from '../../../../components/Headline';
import MypageAccountFormField from '../../../../components/Mypage/Account/MypageAccountFormField';
import MypageMenu from '../../../../containers/mypage/MypageMenu';
import PageHeader from '../../../../containers/page/PageContaineredHeader';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypageIndex extends React.Component {
  state = {
    params: {
      email: '',
      name: '',
      profile: '',
      twitterId: ''
    }
  };

  shouldComponentUpdate(nextProps) {
    if (
      this.props.user.isGetMyselfLoading &&
      !nextProps.user.isGetMyselfLoading
    ) {
      const { myself } = nextProps.user;

      this.setState({
        params: {
          email: myself.email,
          name: myself.name,
          profile: myself.profile,
          twitterId: myself.twitterId
        }
      });
    }

    return true;
  }

  render() {
    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <ContentContainer>
          <Row style={{ margin: '40px 0' }}>
            <Column xs={4}>
              <MypageMenu />
            </Column>
            <Column xs={8}>
              <Headline style={{ fontSize: 32, marginBottom: 40 }}>
                プロフィール設定
              </Headline>
              <MypageAccountFormField
                params={this.state.params}
                onChangeParams={state => {
                  this.setState({
                    params: {
                      ...this.state.params,
                      ...state
                    }
                  });
                }}
                rules={MYPAGE_ACCOUNT_VALIDATION_RULE}
              />
              <Button
                disable={
                  this.props.user.isCreateLoading ||
                  this.props.user.isUpdateLoading
                }
                onClick={() => {
                  const errorMessage = validateAll({
                    labels: MYPAGE_ACCOUNT_LABELS,
                    params: this.state.params,
                    rules: MYPAGE_ACCOUNT_VALIDATION_RULE
                  });

                  if (errorMessage) {
                    alert(errorMessage);

                    return;
                  }

                  const diffObject = updatedDiff(
                    this.props.user.myself,
                    this.state.params
                  );

                  console.log(diffObject);

                  let userData = {};

                  if (diffObject.hasOwnProperty('email')) {
                    userData = {
                      ...userData,
                      userMetadata: { email: diffObject.email }
                    };
                  }

                  if (diffObject.hasOwnProperty('name')) {
                    userData = {
                      ...userData,
                      name: diffObject.name
                    };
                  }

                  if (diffObject.hasOwnProperty('profile')) {
                    userData = {
                      ...userData,
                      profile: diffObject.profile
                    };
                  }

                  if (diffObject.hasOwnProperty('twitterId')) {
                    userData = {
                      ...userData,
                      twitterId: diffObject.twitterId
                    };
                  }

                  this.props.updateUser(this.props.auth.uid, userData);
                }}
              >
                更新する
              </Button>
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypageIndex);
