import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  MARKETING_CREATE_FORM_LABELS,
  MARKETING_CREATE_FORM_VALIDATION_RULE,
  MARKETING_METADATA_LABELS,
  MARKETING_METADATA_VALIDATION_RULE
} from '../../../constants/Validations';
import { validate, validateAll } from '../../../utils/Validation';
import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import ContentContainer from '../../../components/Page/ContentContainer';
import FooterContainer from '../../../components/FooterContainer';
import Headline from '../../../components/Headline';
import PageHeader from '../../../containers/page/PageContaineredHeader';
import Panel from '../../../components/Panel';
import Paragraph from '../../../components/Paragraph';
import Marketing from '../../../models/Marketing';
import MarketingFormField from '../../../components/Marketing/MarketingFormField';
import ResponseDispatcher from '../../../components/ResponseDispatcher';
import SectionCard from '../../../components/Card/SectionCard';
import Text from '../../../components/Text';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class MarketingNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      marketing: new Marketing(props.marketingForm.formParams.marketing).object,
      editPassword: '',
      viewPassword: ''
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      this.props.updateMarketingFormParams(nextState);

      return true;
    }

    if (nextProps.marketing !== this.props.marketing) {
      return true;
    }

    if (nextProps) return false;
  }

  render() {
    // if (this.props.marketing.isGetLoading) {
    //   return null;
    // }

    return (
      <View>
        <PageHeader />
        <ContentContainer>
          <Row>
            <Column offset={{ xs: 1 }} xs={10}>
              <View style={{ padding: '16px 16px 0px 16px', marginBottom: 24 }}>
                <Headline style={{ marginBottom: 16 }}>
                  マーケティング計画書
                </Headline>
                <Paragraph>
                  入力フォームに入力して、あなたのマーケティング計画について明らかにしましょう。
                </Paragraph>
              </View>
              <MarketingFormField
                params={this.state}
                onChangeParams={state => this.setState(state)}
              />
              <SectionCard
                title="パスワード"
                panel={
                  <Panel size={40} style={{ backgroundColor: Color.pink }}>
                    <Text
                      style={{
                        color: Color.white,
                        fontWeight: 'bold',
                        fontSize: 24
                      }}
                    >
                      07
                    </Text>
                  </Panel>
                }
                style={{ marginBottom: 24 }}
              >
                <TextFormField
                  value={this.state.viewPassword}
                  name="viewPassword"
                  direction="column"
                  type="password"
                  verification
                  onChangeText={viewPassword => {
                    this.setState({
                      viewPassword
                    });
                  }}
                  label="閲覧パスワード"
                  errorMessage={validate({
                    label: MARKETING_METADATA_LABELS.viewPassword,
                    value: this.state.viewPassword,
                    rule: MARKETING_METADATA_VALIDATION_RULE.viewPassword
                  })}
                  limitLength={
                    MARKETING_METADATA_VALIDATION_RULE.viewPassword.maxLength
                  }
                  helpText="閲覧のためのパスワードを設定する場合は、パスワードを設定してください。"
                  style={{ marginBottom: 16 }}
                />
                <TextFormField
                  value={this.state.editPassword}
                  name="editPassword"
                  direction="column"
                  type="password"
                  verification
                  onChangeText={editPassword => {
                    this.setState({
                      editPassword
                    });
                  }}
                  label="編集パスワード"
                  errorMessage={validate({
                    label: MARKETING_METADATA_LABELS.editPassword,
                    value: this.state.editPassword,
                    rule: MARKETING_METADATA_VALIDATION_RULE.editPassword
                  })}
                  limitLength={
                    MARKETING_METADATA_VALIDATION_RULE.editPassword.maxLength
                  }
                  helpText="編集のためのパスワードを設定する場合は、パスワードを設定してください。"
                  style={{ marginBottom: 16 }}
                />
              </SectionCard>
              <Button
                disable={this.props.marketing.isCreateLoading}
                onClick={() => {
                  const errorMessage = validateAll({
                    labels: MARKETING_CREATE_FORM_LABELS,
                    params: this.state,
                    rules: MARKETING_CREATE_FORM_VALIDATION_RULE
                  });

                  if (errorMessage) {
                    alert(errorMessage);

                    return;
                  }

                  this.props.createMarketing(
                    this.state.marketing,
                    this.state.viewPassword,
                    this.state.editPassword
                  );
                }}
                style={{ marginBottom: 24 }}
              >
                マーケティング計画書を生成する
              </Button>
              <ResponseDispatcher
                isLoading={this.props.marketing.isCreateLoading}
                isFailed={this.props.marketing.isCreateFailed}
                error={this.props.marketing.createError}
                onComplete={() =>
                  this.props.history.push(
                    `/marketing/${this.props.marketing.list[0]}/result`
                  )
                }
                onError={() => {
                  alert(this.props.marketing.createError.message);

                  this.props.recoverCreateMarketing();
                }}
              />
              <Paragraph
                containerStyle={{ marginBottom: 24 }}
                style={{ textAlign: 'center' }}
              >
                ボタンを押すことでプライバシーポリシーと
                {'\n'}
                利用規約に同意したものとみなします。
              </Paragraph>
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingNew);
