import React from 'react';

import { getGradient, getGradientCss } from '../utils/ColorJs';
import View from './View';

export default function Panel({ children, size, gradient = null, style = {} }) {
  return (
    <View
      style={{
        width: size,
        height: size,
        borderRadius: 8,
        backgroundImage: gradient && getGradientCss(getGradient(gradient), 135),
        alignItems: 'center',
        justifyContent: 'center',
        ...style
      }}
    >
      {children}
    </View>
  );
}
