export const DREAM_APPLICATION_ARTICLES = [
  {
    title: '応募',
    number: '01',
    description:
      'このページの下部より、必要事項をフォームへ入力して応募を行ってください。簡易審査は、通常1〜3日で完了いたします。必ず利用規約に同意してから応募を行ってください。',
    sections: []
  },
  {
    title: '簡易審査',
    number: '02',
    description:
      '2〜3営業日後、簡易審査の結果を通知します。その後、WEB上から簡単な事業計画書を作成していただきます。',
    sections: []
  },
  {
    title: '事業計画書作成',
    number: '03',
    description:
      'WEB上から決められた質問に回答していただき、かんたんな事業計画書を作成していただきます。',
    sections: []
  },
  {
    title: '計画書の審査',
    number: '04',
    description:
      '事業計画書の審査結果を通知します。ここで落ちても、再審査を受けることができます。',
    sections: []
  }
];
