import { SketchPicker } from 'react-color';
import React from 'react';

import TextFormField from './TextFormField';
import View from '../View';

export default class ColorFormField extends React.Component {
  render() {
    const { style, ...rest } = this.props;

    return (
      <View style={style}>
        <TextFormField {...rest} />
        <SketchPicker
          color={rest.value}
          onChangeComplete={color => rest.onChangeText(color.hex.slice(1))}
        />
      </View>
    );
  }
}
