import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import uuid from 'uuid';

import { validate } from '../../../utils/Validation';
import AdditionalFormField from '../../../components/Form/AdditionalFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class SchemeBusinessModelFormField extends React.Component {
  render() {
    const {
      params,
      schemeData,
      onChangeParams,
      rules,
      style = {}
    } = this.props;

    return (
      <View style={{ ...style }}>
        <AdditionalFormField
          staticControl={!Boolean(rules)}
          data={params.models}
          name="models"
          schemeData={schemeData.model}
          direction="column"
          verification
          onChangeData={(index, key, value) => {
            onChangeParams({
              models: params.models.map((model, modelIndex) => {
                if (index === modelIndex) {
                  // TODO traverse model data tree
                  return {
                    ...model,
                    [key]: value
                  };
                }

                return model;
              })
            });
          }}
          onAddData={() => {
            onChangeParams({
              models: [
                ...params.models,
                {
                  id: uuid.v4(),
                  modelType: 'unselected',
                  modelLabel: `モデル${params.models.length + 1}`,
                  modelLevel: 1
                }
              ]
            });
          }}
          onDeleteData={(index, pathes) => {
            // switch delete logic for nested path data
            onChangeParams({
              models: params.models.reduce((acc, model, modelIndex) => {
                if (index === modelIndex) {
                  return acc;
                }

                return [...acc, model];
              }, [])
            });
          }}
          label="(1) モデル"
          errorMessage={
            rules &&
            validate({
              label: 'モデル',
              value: params.models,
              rule: rules.models
            })
          }
          style={{ marginBottom: 16 }}
        />
        <AdditionalFormField
          staticControl={!Boolean(rules)}
          data={params.relations}
          name="relations"
          schemeData={schemeData.relation}
          direction="column"
          verification
          onChangeData={(index, key, value) => {
            onChangeParams({
              relations: params.relations.map((relation, modelIndex) => {
                if (index === modelIndex) {
                  // TODO traverse relation data tree
                  return {
                    ...relation,
                    [key]: value
                  };
                }

                return relation;
              })
            });
          }}
          onAddData={() => {
            onChangeParams({
              relations: [
                ...params.relations,
                {
                  id: uuid.v4(),
                  relationLabel: '関係',
                  relationType: 'action',
                  relationSituation: 'normal',
                  relationSource: 'unselected',
                  relationDestination: 'unselected',
                  relationDetourDistance: 0,
                  relationDetourWay: 'straight'
                }
              ]
            });
          }}
          onDeleteData={(index, pathes) => {
            // switch delete logic for nested path data
            onChangeParams({
              relations: params.relations.reduce(
                (acc, relation, modelIndex) => {
                  if (index === modelIndex) {
                    return acc;
                  }

                  return [...acc, relation];
                },
                []
              )
            });
          }}
          label="(2) 関係"
          errorMessage={
            rules &&
            validate({
              label: '関係',
              value: params.relations,
              rule: rules.relations
            })
          }
          style={{ marginBottom: 16 }}
        />
        <AdditionalFormField
          staticControl={!Boolean(rules)}
          data={params.levelOptions}
          name="levelOptions"
          schemeData={schemeData.levelOptions}
          direction="column"
          verification
          onChangeData={(index, key, value) => {
            onChangeParams({
              levelOptions: params.levelOptions.map((relation, levelIndex) => {
                if (index === levelIndex) {
                  // TODO traverse relation data tree
                  return {
                    ...relation,
                    [key]: value
                  };
                }

                return relation;
              })
            });
          }}
          label="(3) オプション（レベル）"
          errorMessage={
            rules &&
            validate({
              label: 'オプション（レベル）',
              value: params.levelOptions,
              rule: rules.levelOptions
            })
          }
        />
        {/*<TextFormField
          staticControl={!Boolean(rules)}
          value={params.data}
          name="data"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={data => {
            onChangeParams({
              data
            });
          }}
          label="(5) KPI1"
          errorMessage={
            rules &&
            validate({
              label: 'KPI1',
              value: params.data,
              rule: rules.data
            })
          }
          limitLength={rules.data.maxLength}
          helpText="あなたの事業を運営する際、最終的なゴールであるKGIを計測する上で最も重要なKPIを記述してください。また、そのKPIを満たすための施策についても触れてください。"
          style={{ marginBottom: 16 }}
        />*/}
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchemeBusinessModelFormField);
