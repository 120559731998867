import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { DREAM_APPLICATION_ARTICLES } from '../../../constants/Articles/DreamApplication';
import {
  DREAM_APPLICATION_LABELS,
  DREAM_APPLICATION_VALIDATION_RULE
} from '../../../constants/Validations';
import { validateAll } from '../../../utils/Validation';
import ArrowStream from '../../../components/Icons/ArrowStream';
import ArticleCardTemplate from '../../../components/Article/ArticleCardTemplate';
import Button from '../../../components/Button';
import CardPrimitive from '../../../components/Card/CardPrimitive';
import Color from '../../../constants/Color';
import DreamApplication from '../../../models/DreamApplication';
import DreamApplicationFormField from '../../../components/Dream/DreamApplicationFormField';
import Footer from '../../../components/Footer';
import Headline from '../../../components/Headline';
import PageHeader from '../../../containers/page/PageHeader';
import Paragraph from '../../../components/Paragraph';
import ResponseDialog from '../../../components/ResponseDialog';
import View from '../../../components/View';
import actions from '../../../actions';

class DreamApplicationIndex extends React.Component {
  state = {
    ...new DreamApplication().object
  };

  render() {
    return (
      <View>
        <PageHeader />
        <View style={{ padding: '24px 24px 0 24px', marginBottom: 16 }}>
          <Headline size={1} style={{ marginBottom: 16 }}>
            応募の流れ
          </Headline>
          <Paragraph>
            下記の4
            STEPで、プロジェクト事業者（ファウンダー）へ応募することができます。
          </Paragraph>
        </View>
        <View>
          {DREAM_APPLICATION_ARTICLES.map((article, index) => {
            const key = `article-${index}`;

            return (
              <ArticleCardTemplate
                key={key}
                title={article.title}
                number={article.number}
                description={article.description}
                sections={article.sections}
              />
            );
          })}
        </View>
        <View style={{ padding: '24px 24px 0 24px', marginBottom: 16 }}>
          <Paragraph>
            審査に合格後、数回のミーティングでプロジェクトの開始時期、内容について精査していき、プロジェクトを公開します。
          </Paragraph>
        </View>
        <View style={{ alignItems: 'center', padding: '0 24px' }}>
          <ArrowStream color={Color.slate} style={{ marginBottom: 24 }} />
          <Paragraph
            style={{
              color: Color.slate,
              fontSize: 17,
              fontWeight: 'bold',
              textAlign: 'center'
            }}
            containerStyle={{ marginBottom: 24 }}
          >
            フォームに入力して、プロジェクトへ応募してください。
          </Paragraph>
        </View>
        <View style={{ padding: '0 16px' }}>
          <CardPrimitive style={{ padding: 16, marginBottom: 16 }}>
            <Headline style={{ color: Color.lightSlate, marginBottom: 16 }}>
              応募フォーム
            </Headline>
            <DreamApplicationFormField
              params={this.state}
              onChangeParams={state => this.setState(state)}
              rules={DREAM_APPLICATION_VALIDATION_RULE}
            />
          </CardPrimitive>
          <Button
            disable={this.props.dreamApplication.isCreateLoading}
            onClick={() => {
              const errorMessage = validateAll({
                labels: DREAM_APPLICATION_LABELS,
                params: this.state,
                rules: DREAM_APPLICATION_VALIDATION_RULE
              });

              if (errorMessage) {
                alert(errorMessage);

                return;
              }

              this.props.createDreamApplication(this.state);
            }}
            style={{ marginBottom: 16 }}
          >
            プロジェクトを応募する
          </Button>
          <ResponseDialog
            isLoading={this.props.dreamApplication.isCreateLoading}
            isFailed={this.props.dreamApplication.isCreateFailed}
            error={this.props.dreamApplication.createError}
            completeMessage="応募が完了しました。"
            onComplete={() =>
              this.setState({
                ...new DreamApplication().object
              })
            }
            onError={() => {
              this.props.recoverCreateDreamApplication();
            }}
          />
        </View>
        <Footer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamApplicationIndex);
