import uuid from 'uuid';

import Model from './Model';

export default class Scheme extends Model {
  constructor(state = {}) {
    super();

    this.params = {
      ...state,
      basicInfo: {
        title: '',
        name: '',
        profileImage: {},
        birthday: '',
        job: '',
        biography: '',
        businessCategoryId: 'unselected',
        primaryColor: 'EE5465',
        secondaryColor: '6DA5BF',
        ...state.basicInfo
      },
      businessModel: {
        models: [
          {
            id: uuid.v4(),
            modelType: 'unselected',
            modelLabel: 'モデル1',
            modelLevel: 0
          }
        ],
        relations: [],
        levelOptions: [
          {
            id: uuid.v4(),
            levelLabel: 'レベル1',
            levelLevel: 1,
            levelAngle: 0,
            levelRadius: 0
          },
          {
            id: uuid.v4(),
            levelLabel: 'レベル2',
            levelLevel: 2,
            levelAngle: 0,
            levelRadius: 0
          },
          {
            id: uuid.v4(),
            levelLabel: 'レベル3',
            levelLevel: 3,
            levelAngle: 0,
            levelRadius: 0
          }
        ],
        ...state.businessModel
      },
      canvas: {
        problem: '',
        solution: '',
        uniqueValueProposition: '',
        ...state.canvas
      },
      processStream: {
        detail: '',
        ...state.processStream
      },
      asset: {
        revenue: '',
        cost: '',
        blandishment: '',
        criticism: '',
        ...state.asset
      },
      indicator: {
        kpi1: '',
        kpi2: '',
        kpi3: '',
        kgi: '',
        ...state.indicator
      }
    };
  }

  get object() {
    return this.params;
  }

  get fields() {
    return {
      basicInfo: this.params.basicInfo,
      businessModel: this.params.businessModel,
      canvas: this.params.canvas,
      processStream: this.params.processStream,
      asset: this.params.asset,
      indicator: this.params.indicator
    };
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
