import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Footer from '../../../components/Footer';
import FounderDreamList from '../../../components/Founder/FounderDreamList';
import FounderProfile from '../../../components/Founder/FounderProfile';
import FounderSlider from '../../../components/Dream/FounderSlider';
import Headline from '../../../components/Headline';
import Markdown from '../../../components/Markdown';
import PageHeader from '../../../containers/page/PageHeader';
import Paragraph from '../../../components/Paragraph';
import PatronizeModal from '../../../containers/patron/PatronizeModal';
import RegisterModal from '../../../containers/RegisterModal';
import Style from '../../../constants/Style';
import View from '../../../components/View';
import actions from '../../../actions';

class FounderDetail extends React.Component {
  state = {
    isOpenPatronizeModal: false,
    isOpenRegisterModal: false
  };

  componentDidMount() {
    this.props.getDreams({ reset: true });

    this.props.getFounder(this.props.match.params.id);
  }

  render() {
    const founder = this.props.founder.hash[this.props.match.params.id];

    if (!founder) {
      return null;
    }

    const dreams = this.props.dream.list
      .map(id => this.props.dream.hash[id])
      .filter(dream => dream.founder.id === this.props.match.params.id);

    return (
      <View>
        <PageHeader />
        <FounderSlider data={founder.images} />
        <FounderProfile
          data={founder}
          style={{ marginTop: -120, marginBottom: 32, zIndex: 1 }}
        />
        <Headline style={{ padding: '0 24px', marginBottom: 16 }}>
          パトロン特典
        </Headline>
        <View
          style={{
            borderRadius: 8,
            padding: 16,
            margin: '0 24px 0 24px',
            backgroundColor: Color.white,
            ...Style.shadow.large,
            marginBottom: 16
          }}
        >
          <Markdown>{founder.patronGiftText}</Markdown>
        </View>
        <View
          style={{
            padding: '0 16px 32px 16px'
          }}
        >
          <Button
            onClick={() => {
              if (this.props.auth.uid) {
                this.setState({ isOpenPatronizeModal: true });
              } else {
                this.setState({ isOpenRegisterModal: true });
              }
            }}
          >
            パトロンになる
          </Button>
        </View>
        <Headline style={{ padding: '0 24px', marginBottom: 16 }}>
          {founder.screenName}
          の夢
        </Headline>
        <FounderDreamList
          name={founder.screenName}
          data={dreams}
          style={{ padding: '0 16px 0 16px', marginBottom: 16 }}
        />
        <RegisterModal
          visible={this.state.isOpenRegisterModal}
          onClose={() => this.setState({ isOpenRegisterModal: false })}
          content={
            <View style={{ marginBottom: 8 }}>
              <Headline size={3} style={{ marginBottom: 16 }}>
                パトロンになるためには
              </Headline>
              <Paragraph>
                パトロンになるためには、新規登録/ログインをする必要があります。
              </Paragraph>
            </View>
          }
        />
        <PatronizeModal
          founderId={founder.id}
          visible={this.state.isOpenPatronizeModal}
          onClose={() => this.setState({ isOpenPatronizeModal: false })}
        />
        <Footer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FounderDetail);
