import React from 'react';

import Color from '../constants/Color';
import View from './View';

export default function Hr({ style = {} }) {
  return (
    <View style={{ height: 1, backgroundColor: Color.lightGrey, ...style }} />
  );
}
