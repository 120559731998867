import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Auth from '../../containers/Auth';
import CardOuterTitle from '../../components/Card/CardOuterTitle';
import Color from '../../constants/Color';
import DocumentDetail from './document/DocumentDetail';
import DreamApplicationIndex from './dream/application';
import DreamDetail from './dream/detail';
import DreamProductComplete from './dream/product/complete';
import DreamProductConfirm from './dream/product/confirm';
import DreamProductIndex from './dream/product/index';
import DreamProductInput from './dream/product/input';
import DreamReportDetail from './dream/report/detail';
import DreamReportIndex from './dream/report/index';
import DreamThank from './dream/thank';
import FounderDetail from './founder/detail';
import JobDetail from './job/detail';
import MarketingEdit from './marketing/edit';
import MarketingNew from './marketing/new';
import MarketingResult from './marketing/result';
import MypageAccountEdit from './mypage/account/edit';
import MypageEmailEdit from './mypage/email/edit';
import MypageIndex from './mypage/index';
import MypageNameEdit from './mypage/name/edit';
import MypagePatronContractDetail from './mypage/patronContract/detail';
import MypagePatronContractIndex from './mypage/patronContract';
import MypagePaymentInfoDetail from './mypage/paymentInfo/detail';
import MypagePaymentInfoIndex from './mypage/paymentInfo';
import MypageProfileEdit from './mypage/profile/edit';
import MypageShippingInfoDetail from './mypage/shippingInfo/detail';
import MypageShippingInfoIndex from './mypage/shippingInfo';
import MypageTwitterIdEdit from './mypage/twitterId/edit';
import ProposalDownload from './proposal/download';
import ProposalEdit from './proposal/edit';
import ProposalIndex from './proposal/index';
import ProposalNew from './proposal/new';
import ProposalResult from './proposal/result';
import SchemeDownload from './scheme/download';
import SchemeEdit from './scheme/edit';
import SchemeNew from './scheme/new';
import SchemeResult from './scheme/result';
import ScrollToTop from '../../components/ScrollToTop';
import Text from '../../components/Text';
import Top from './top';
import View from '../../components/View';
import actions from '../../actions';

function WaitingComponent(Component) {
  return props => (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </React.Suspense>
  );
}

class Routes extends React.Component {
  render() {
    if (this.props.config.hash.isMaintenance) {
      return (
        <View style={{ padding: 8 }}>
          <CardOuterTitle>メンテナンス中</CardOuterTitle>
          <Text style={{ color: Color.white }}>
            しばらく経ってからアクセスをお願いします。
          </Text>
        </View>
      );
    }

    return (
      <BrowserRouter>
        <ScrollToTop>
          <div>
            <Switch>
              <Route exact path="/founder/:id" component={FounderDetail} />
              <Route
                exact
                path="/dream/application"
                component={DreamApplicationIndex}
              />
              <Route exact path="/dream/:id/thank" component={DreamThank} />
              <Route
                exact
                path="/dream/:dreamId/report/:reportId"
                component={DreamReportDetail}
              />
              <Route
                exact
                path="/dream/:id/report"
                component={DreamReportIndex}
              />
              <Route exact path="/dream/:id" component={DreamDetail} />
              <Route
                exact
                path="/document/:documentType"
                component={DocumentDetail}
              />
              <Route exact path="/job/:id" component={JobDetail} />
              <Route exact path="/scheme/new" component={SchemeNew} />
              <Route exact path="/scheme/:id/edit" component={SchemeEdit} />
              <Route exact path="/scheme/:id/result" component={SchemeResult} />
              <Route
                exact
                path="/scheme/:id/download"
                component={SchemeDownload}
              />
              <Route exact path="/marketing/new" component={MarketingNew} />
              <Route
                exact
                path="/marketing/:id/edit"
                component={MarketingEdit}
              />
              <Route
                exact
                path="/marketing/:id/result"
                component={MarketingResult}
              />
              {/*<Route
                exact
                path="/marketing/:id/download"
                component={MarketingDownload}
              />*/}
              <Route exact path="/proposal/new" component={ProposalNew} />
              <Route exact path="/proposal/:id/edit" component={ProposalEdit} />
              <Route
                exact
                path="/proposal/:id/result"
                component={ProposalResult}
              />
              <Route
                exact
                path="/proposal/:id/download"
                component={ProposalDownload}
              />
              <Route
                exact
                path="/dream/:id/product"
                component={DreamProductIndex}
              />
              <Route exact path="/proposal" component={ProposalIndex} />
              <Route exact path="/" component={Top} />
              <Auth>
                <Switch>
                  <Route
                    exact
                    path="/dream/:dreamId/product/:productId/input"
                    component={DreamProductInput}
                  />
                  <Route
                    exact
                    path="/dream/:dreamId/product/:productId/confirm/:shippingInfoId"
                    component={DreamProductConfirm}
                  />
                  <Route
                    exact
                    path="/dream/:dreamId/product/:productId/complete"
                    component={DreamProductComplete}
                  />
                  <Route
                    exact
                    path="/mypage/account/edit"
                    component={MypageAccountEdit}
                  />
                  <Route
                    exact
                    path="/mypage/name/edit"
                    component={MypageNameEdit}
                  />
                  <Route
                    exact
                    path="/mypage/profile/edit"
                    component={MypageProfileEdit}
                  />
                  <Route
                    exact
                    path="/mypage/twitterId/edit"
                    component={MypageTwitterIdEdit}
                  />
                  <Route
                    exact
                    path="/mypage/email/edit"
                    component={MypageEmailEdit}
                  />
                  <Route
                    exact
                    path="/mypage/patronContract/:id"
                    component={MypagePatronContractDetail}
                  />
                  <Route
                    exact
                    path="/mypage/patronContract"
                    component={MypagePatronContractIndex}
                  />
                  <Route
                    exact
                    path="/mypage/paymentInfo/:id"
                    component={MypagePaymentInfoDetail}
                  />
                  <Route
                    exact
                    path="/mypage/paymentInfo"
                    component={MypagePaymentInfoIndex}
                  />
                  <Route
                    exact
                    path="/mypage/shippingInfo/:id"
                    component={MypageShippingInfoDetail}
                  />
                  <Route
                    exact
                    path="/mypage/shippingInfo"
                    component={MypageShippingInfoIndex}
                  />
                  <Route exact path="/mypage" component={MypageIndex} />
                </Switch>
              </Auth>
            </Switch>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes);
