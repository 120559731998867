import { getCollection, getDb } from '../utils/Firebase';

export default class Consumer {
  constructor(state = {}) {
    this.params = {
      profile: state.profile,
      twitterId: state.twitterId,
      defaultPaymentInfoId: state.defaultPaymentInfoId || null
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }

  static async update(id, params) {
    const db = getDb();

    const consumers = getCollection('consumers').doc(id);

    return db.runTransaction(transaction => {
      return transaction.get(consumers).then(doc => {
        if (!doc.exists) {
          throw new Error('update/not-exist');
        }

        const { createdAt, updatedAt, ...rest } = params;

        return transaction.update(consumers, rest);
      });
    });
  }
}
