import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import Column from '../../../../components/Column';
import ContentContainer from '../../../../components/Page/ContentContainer';
import FooterContainer from '../../../../components/FooterContainer';
import Headline from '../../../../components/Headline';
import Label from '../../../../components/Form/Label';
import Markdown from '../../../../components/Markdown';
import MypageMenu from '../../../../containers/mypage/MypageMenu';
import PageHeader from '../../../../containers/page/PageContaineredHeader';
import ResponseDispatcher from '../../../../components/ResponseDispatcher';
import Section from '../../../../components/Section/Section';
import TextFormField from '../../../../components/Form/TextFormField';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypagePaymentInfoIndex extends React.Component {
  componentDidMount() {
    this.props.getPatronContract(this.props.match.params.id);
  }

  render() {
    const patronContract = this.props.patronContract.hash[
      this.props.match.params.id
    ];

    if (!patronContract) {
      return null;
    }

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <ContentContainer>
          <Row style={{ margin: '40px 0' }}>
            <Column xs={4}>
              <MypageMenu />
            </Column>
            <Column xs={8}>
              <Headline style={{ fontSize: 32, marginBottom: 40 }}>
                パトロン
              </Headline>
              <View style={{ marginBottom: 32 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 16
                  }}
                >
                  <Headline>パトロン支援</Headline>
                  <Button
                    tiny
                    color={Color.red}
                    onClick={() => {
                      const result = window.confirm(
                        'パトロン支援を解除します。よろしいですか？'
                      );

                      if (result) {
                        this.props.deletePatronContract(
                          this.props.match.params.id
                        );
                      }
                    }}
                  >
                    解除する
                  </Button>
                </View>
                <Section
                  sideline
                  style={{
                    padding: 16,
                    marginBottom: 16,
                    borderBottom: `1px solid ${Color.lightGrey}`
                  }}
                >
                  <TextFormField
                    staticControl
                    labelWidth={120}
                    label="ファウンダー"
                    value={patronContract.founder.screenName}
                    style={{ marginBottom: 24 }}
                  />
                  <TextFormField
                    staticControl
                    labelWidth={120}
                    label="月額"
                    value={patronContract.amount}
                  />
                </Section>
                <Section
                  sideline
                  style={{
                    padding: 16,
                    marginBottom: 16,
                    borderBottom: `1px solid ${Color.lightGrey}`
                  }}
                >
                  <Label style={{ marginBottom: 16 }}>パトロン特典</Label>
                  <Markdown>{patronContract.founder.patronGiftText}</Markdown>
                </Section>
                <View style={{ padding: '0 16px', marginBottom: 16 }}>
                  <Link to={`/founder/${patronContract.founder.id}`}>
                    <Button>ファウンダーのページへ</Button>
                  </Link>
                </View>
              </View>
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
        <ResponseDispatcher
          isLoading={this.props.patronContract.isDeleteLoading}
          isFailed={this.props.patronContract.isDeleteFailed}
          onComplete={() => {
            this.props.getMyselfUser(this.props.auth.uid);

            this.props.history.push('/mypage/patronContract');
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypagePaymentInfoIndex);
