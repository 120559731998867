import React from 'react';

import Color from '../../constants/Color';
import Markdown from '../Markdown';
import Paragraph from '../Paragraph';
import ReportSecretSlider from './ReportSecretSlider';
import ReportSlider from './ReportSlider';
import Style from '../../constants/Style';
import Text from '../Text';
import View from '../../components/View';

export default class ReportItem extends React.Component {
  render() {
    const { data, style = {} } = this.props;

    return (
      <View style={style}>
        <ReportSlider data={data.images} style={{ width: 320, height: 320 }} />
        <View
          style={{
            borderRadius: 8,
            padding: 16,
            margin: '-24px 24px 0 24px',
            zIndex: 1,
            backgroundColor: Color.white,
            ...Style.shadow.large,
            ...style
          }}
        >
          <Text
            style={{ color: Color.slate, fontSize: 17, fontWeight: 'bold' }}
          >
            {data.title}
          </Text>
          <Markdown style={{ marginBottom: 16 }}>{data.content}</Markdown>
          {data.secrets
            .filter(secret => secret.isExist)
            .map(secret => {
              return (
                <View key={`secret-${secret.id}`}>
                  {!secret.isLocked && (
                    <Text
                      style={{
                        color: Color.lightSlate,
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginBottom: 8
                      }}
                    >
                      ここから{secret.gift.title}
                    </Text>
                  )}
                  {secret.images.length > 0 && (
                    <ReportSecretSlider
                      data={secret.images}
                      style={{
                        marginBottom: secret.images.length > 1 ? 32 : 8
                      }}
                    />
                  )}
                  {secret.isLocked ? (
                    <Paragraph
                      onerow
                      style={{ color: Color.slate, fontSize: 13 }}
                    >
                      {secret.gift.title}({secret.gift.price}
                      )の特典です。
                    </Paragraph>
                  ) : (
                    <Markdown>{secret.content}</Markdown>
                  )}
                </View>
              );
            })}
        </View>
      </View>
    );
  }
}
