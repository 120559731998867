import React from 'react';

export default function HeadlineDescSvg({ id, fill }) {
  return (
    <svg
      id={id}
      width="7px"
      height="14px"
      viewBox="0 0 7 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Plans"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="A4"
          transform="translate(-40.000000, -187.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <g id="Group-10" transform="translate(40.000000, 184.000000)">
            <polygon id="Path-2" points="0 3 7 10 0 17" />
          </g>
        </g>
      </g>
    </svg>
  );
}
