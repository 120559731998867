import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalIntroductionFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.story}
          name="story"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={story => {
            onChangeParams({
              story
            });
          }}
          label="(1) 創業理由やストーリー"
          errorMessage={
            rules &&
            validate({
              label: '創業理由やストーリー',
              value: params.story,
              rule: rules.story
            })
          }
          limitLength={rules.story.maxLength}
          helpText="創業までのストーリーを短く書いていきましょう。あなたがこの事業を始めようと思った「きっかけ」のエピソードを書いてください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.ux}
          name="ux"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={ux => {
            onChangeParams({
              ux
            });
          }}
          label="(2) ターゲットと提供する体験"
          errorMessage={
            rules &&
            validate({
              label: 'ターゲットと提供する体験',
              value: params.ux,
              rule: rules.ux
            })
          }
          limitLength={rules.ux.maxLength}
          helpText="年代や男女などを交え、ターゲット設定と何を提供するかを具体的に記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.factor}
          name="factor"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={factor => {
            onChangeParams({
              factor
            });
          }}
          label="(3) 懸念点と実現のポイント"
          errorMessage={
            rules &&
            validate({
              label: '懸念点と実現のポイント',
              value: params.factor,
              rule: rules.factor
            })
          }
          limitLength={rules.factor.maxLength}
          helpText="懸念点と実現方法を、具体的に記述してください。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalIntroductionFormField);
