import React from 'react';

import Color from '../../constants/Color';
import View from '../View';

export default function TopLogo({ style = {} }) {
  return (
    <View
      style={{
        borderBottom: `3px solid ${Color.white}`,
        padding: '0 4px 4px 4px',
        ...style
      }}
    >
      <View
        style={{
          borderBottom: `2px solid ${Color.white}`
        }}
      >
        <h2
          style={{
            color: Color.white,
            fontSize: 24,
            fontWeight: 'bold',
            letterSpacing: 4,
            paddingBottom: 2
          }}
        >
          VISIONS
        </h2>
      </View>
    </View>
  );
}
