import React from 'react';

import Color from '../../constants/Color';
import Label from './Label';
import MaterialIcon from '../../utils/MaterialIcon';
import Paragraph from '../Paragraph';
import Select from '../../utils/Select';
import View from '../View';

export default class SelectFormField extends React.Component {
  render() {
    const {
      label,
      labelWidth = 70,
      name,
      value,
      direction = 'row',
      verification = false,
      errorMessage,
      onChangeValue = () => {},
      items,
      staticControl,
      style = {}
    } = this.props;

    const item = items.find(item => item.value === value);

    const containerStyle =
      direction === 'row' ? { flexDirection: 'row', alignItems: 'center' } : {};

    const verifyColor = !Boolean(errorMessage) ? Color.green : Color.warmGrey;

    return (
      <View style={{ ...style }}>
        <View style={containerStyle}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: direction === 'row' ? 0 : 8
            }}
          >
            <Label
              style={{
                minWidth: labelWidth
              }}
            >
              {label}
            </Label>
            {!staticControl &&
              verification && (
                <View style={{ marginLeft: 4 }}>
                  <MaterialIcon
                    icon="check_circle"
                    size={20}
                    color={verifyColor}
                  />
                </View>
              )}
          </View>
          <View style={{ flex: 1 }}>
            {staticControl ? (
              <View>
                <Paragraph style={{ fontWeight: 'bold' }}>
                  {item.label}
                </Paragraph>
              </View>
            ) : (
              <View>
                <Select
                  name={name}
                  value={item}
                  onChange={item => {
                    onChangeValue(item.value);
                  }}
                  options={items}
                />
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}
