import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Color from '../../constants/Color';
import CommentList from './CommentList';
import CommentModal from '../CommentModal';
import Headline from '../../components/Headline';
import Paragraph from '../../components/Paragraph';
import RegisterModal from '../RegisterModal';
import ReplyModal from '../ReplyModal';
import Text from '../../components/Text';
import UserCard from '../../components/Card/UserCard';
import View from '../../components/View';
import actions from '../../actions';

class DreamCommentContent extends React.Component {
  state = {
    isOpenRegisterModal: false,
    isOpenCommentModal: false,
    isOpenReplyModal: false
  };

  render() {
    const { data: dream } = this.props;

    return (
      <View>
        <View style={{ marginBottom: 16 }}>
          <Headline>コメント</Headline>
        </View>
        <View style={{ marginBottom: 16 }}>
          <View
            onClick={() => {
              if (this.props.auth.uid) {
                this.setState({ isOpenCommentModal: true });
              } else {
                this.setState({ isOpenRegisterModal: true });
              }
            }}
          >
            <UserCard
              source={
                this.props.user.myself && this.props.user.myself.iconImage
              }
            >
              <Text
                style={{
                  color: Color.slate,
                  fontSize: 15
                }}
              >
                コメントを書く
              </Text>
            </UserCard>
          </View>
          <CommentList
            dreamId={dream.id}
            onClickItem={id => {
              if (this.props.auth.uid) {
                this.setState({
                  isOpenReplyModal: true,
                  replyCommentId: id
                });
              } else {
                this.setState({ isOpenRegisterModal: true });
              }
            }}
          />
        </View>
        <CommentModal
          visible={this.state.isOpenCommentModal}
          onSubmit={text => {
            this.setState({ isOpenCommentModal: false }, () => {
              this.props.createDreamComment({ dreamId: dream.id, text });
            });
          }}
          onClose={() => this.setState({ isOpenCommentModal: false })}
        />
        <ReplyModal
          founderId={dream.founder.id}
          comment={dream.comments.find(
            comment => comment.id === this.state.replyCommentId
          )}
          visible={this.state.isOpenReplyModal}
          onSubmit={text => {
            this.setState({ isOpenReplyModal: false }, () => {
              this.props.createDreamCommentReply({
                dreamId: dream.id,
                commentId: this.state.replyCommentId,
                text
              });
            });
          }}
          onClose={() => this.setState({ isOpenReplyModal: false })}
        />
        <RegisterModal
          visible={this.state.isOpenRegisterModal}
          onClose={() => this.setState({ isOpenRegisterModal: false })}
          content={
            <View style={{ marginBottom: 8 }}>
              <Headline size={3} style={{ marginBottom: 16 }}>
                VISIONSをご利用になるために
              </Headline>
              <Paragraph>
                VISIONSの各機能をご利用になるためには、新規登録/ログインをする必要があります。
              </Paragraph>
            </View>
          }
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamCommentContent);
