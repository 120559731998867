import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import Column from '../../../../components/Column';
import ContentContainer from '../../../../components/Page/ContentContainer';
import FooterContainer from '../../../../components/FooterContainer';
import Headline from '../../../../components/Headline';
import MypageMenu from '../../../../containers/mypage/MypageMenu';
import PageHeader from '../../../../containers/page/PageContaineredHeader';
import ResponseDispatcher from '../../../../components/ResponseDispatcher';
import Section from '../../../../components/Section/Section';
import TextFormField from '../../../../components/Form/TextFormField';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypagePaymentInfoDetail extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getMyselfUser();
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    const paymentInfo = myself.paymentInfos.find(
      info => info.id === this.props.match.params.id
    );

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <ContentContainer>
          <Row style={{ margin: '40px 0' }}>
            <Column xs={4}>
              <MypageMenu />
            </Column>
            <Column xs={8}>
              <Headline style={{ fontSize: 32, marginBottom: 40 }}>
                パトロン
              </Headline>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 16
                }}
              >
                <Headline size={3}>カード情報</Headline>
                <Button
                  tiny
                  color={Color.red}
                  onClick={() => {
                    const result = window.confirm(
                      'カード情報を削除します。よろしいですか？'
                    );

                    if (result) {
                      this.props.deletePaymentInfo(this.props.match.params.id);
                    }
                  }}
                >
                  削除する
                </Button>
              </View>
              <Section
                sideline
                style={{
                  padding: 16,
                  marginBottom: 40,
                  borderBottom: `1px solid ${Color.lightGrey}`
                }}
              >
                <TextFormField
                  staticControl
                  labelWidth={120}
                  label="ブランド"
                  value={paymentInfo.source.brand}
                  style={{ marginBottom: 24 }}
                />
                <TextFormField
                  staticControl
                  labelWidth={120}
                  label="カード番号"
                  value={`**** ${paymentInfo.source.last4}`}
                  style={{ marginBottom: 24 }}
                />
                <TextFormField
                  staticControl
                  labelWidth={120}
                  label="有効期限"
                  value={`${paymentInfo.source.expYear}/${
                    paymentInfo.source.expMonth
                  }`}
                  style={{ marginBottom: 24 }}
                />
                <TextFormField
                  staticControl
                  labelWidth={120}
                  label="デフォルト利用"
                  value={`${
                    paymentInfo.id === myself.defaultPaymentInfoId
                      ? 'はい'
                      : 'いいえ'
                  }`}
                />
              </Section>
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
        <ResponseDispatcher
          isLoading={this.props.paymentInfo.isDeleteLoading}
          isFailed={this.props.paymentInfo.isDeleteFailed}
          onComplete={() => {
            this.props.getMyselfUser(this.props.auth.uid);

            this.props.history.push('/mypage/paymentInfo');
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypagePaymentInfoDetail);
