import { getRemovedHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isDeleteLoading: false,
  isDeleteFailed: false,
  deleteError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    // delete patron contract
    case types.FETCH_DELETE_PAYMENT_INFO:
      return {
        ...state,
        isDeleteLoading: true
      };

    case types.FETCH_DELETE_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        hash: getRemovedHash(action.state.id, state.hash),
        list: state.list.filter(id => id !== action.state.id),
        isDeleteLoading: false,
        isDeleteFailed: false
      };

    case types.FETCH_DELETE_PAYMENT_INFO_FAILED:
      return {
        ...state,
        isDeleteLoading: false,
        isDeleteFailed: true,
        deleteError: action.error
      };

    case types.FETCH_DELETE_PAYMENT_INFO_RECOVER:
      return {
        ...state,
        isDeleteFailed: false
      };

    default:
      return state;
  }
};
