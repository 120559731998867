export default class PaymentInfo {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      source: state.source
    };
  }

  getItem() {
    return {
      label: `${this.params.source.brand} **** ${this.params.source.last4}`,
      value: this.params.id
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
