export default class TimeOfDeliverClass {
  static getItems(withDefault = true) {
    const items = [
      {
        label: '長期',
        value: 'long'
      },
      {
        label: '通常',
        value: 'middle'
      },
      {
        label: '短期',
        value: 'short'
      },
      {
        label: '最速',
        value: 'fastest'
      }
    ];

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getLabel(value) {
    return TimeOfDeliverClass.getItems().find(item => item.value === value)
      .label;
  }
}
