import { request } from '../utils/Network';
import * as types from '../constants/ActionTypes';

// create review
function fetchCreateReview() {
  return {
    type: types.FETCH_CREATE_REVIEW
  };
}

function fetchCreateReviewSuccess(id, params) {
  return {
    type: types.FETCH_CREATE_REVIEW_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateReviewFailed(error) {
  return {
    type: types.FETCH_CREATE_REVIEW_FAILED,
    error
  };
}

export function recoverCreateReview() {
  return {
    type: types.FETCH_CREATE_REVIEW_RECOVER
  };
}

export function createReview({ proposalId, email, phoneNumber, editPassword }) {
  return dispatch => {
    dispatch(fetchCreateReview());

    return request(dispatch, 'POST', '/createReview', {
      params: {
        proposalId,
        data: {
          email,
          phoneNumber
        },
        editPassword
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchCreateReviewFailed(error));
      }

      return dispatch(fetchCreateReviewSuccess(response.id));
    });
  };
}
