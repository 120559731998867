import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../utils/Validation';
import Gender from '../../models/Gender';
import Prefecture from '../../models/Prefecture';
import SelectFormField from '../../components/Form/SelectFormField';
import TextFormField from '../../components/Form/TextFormField';
import View from '../../components/View';
import actions from '../../actions';

class ShippingInfoFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.kanjiSei}
          onChangeText={kanjiSei => {
            onChangeParams({ kanjiSei });
          }}
          label="姓(全角)"
          errorMessage={
            rules &&
            validate({
              label: '姓(全角)',
              value: params.kanjiSei,
              rule: rules.kanjiSei
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.kanjiMei}
          onChangeText={kanjiMei => {
            onChangeParams({ kanjiMei });
          }}
          label="名(全角)"
          errorMessage={
            rules &&
            validate({
              label: '名(全角)',
              value: params.kanjiMei,
              rule: rules.kanjiMei
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.furiganaSei}
          onChangeText={furiganaSei => {
            onChangeParams({ furiganaSei });
          }}
          label="セイ(全角)"
          errorMessage={
            rules &&
            validate({
              label: 'セイ(全角)',
              value: params.furiganaSei,
              rule: rules.furiganaSei
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.furiganaMei}
          onChangeText={furiganaMei => {
            onChangeParams({ furiganaMei });
          }}
          label="メイ(全角)"
          errorMessage={
            rules &&
            validate({
              label: 'メイ(全角)',
              value: params.furiganaMei,
              rule: rules.furiganaMei
            })
          }
          style={{ marginBottom: 16 }}
        />
        <SelectFormField
          staticControl={!Boolean(rules)}
          label="性別"
          name="gender"
          items={Gender.getItems()}
          value={params.gender}
          onChangeValue={gender => onChangeParams({ gender })}
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.birthday}
          type="date"
          onChangeText={birthday => {
            onChangeParams({ birthday });
          }}
          label="生年月日"
          errorMessage={
            rules &&
            validate({
              label: '生年月日',
              value: params.birthday,
              rule: rules.birthday
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.phoneNumber}
          onChangeText={phoneNumber => {
            onChangeParams({ phoneNumber });
          }}
          label="電話番号"
          errorMessage={
            rules &&
            validate({
              label: '電話番号',
              value: params.phoneNumber,
              rule: rules.phoneNumber
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.postalCode}
          onChangeText={postalCode => {
            onChangeParams({ postalCode });
          }}
          label="郵便番号"
          errorMessage={
            rules &&
            validate({
              label: '郵便番号',
              value: params.postalCode,
              rule: rules.postalCode
            })
          }
          style={{ marginBottom: 16 }}
        />
        <SelectFormField
          staticControl={!Boolean(rules)}
          label="都道府県"
          name="stateOrRegion"
          items={Prefecture.getItems()}
          value={params.prefecture}
          onChangeValue={prefecture => onChangeParams({ prefecture })}
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.city}
          name="city"
          onChangeText={city => {
            onChangeParams({ city });
          }}
          label="市区町村"
          errorMessage={
            rules &&
            validate({
              label: '市区町村',
              value: params.city,
              rule: rules.city
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.addressLine1}
          name="addressLine1"
          onChangeText={addressLine1 => {
            onChangeParams({ addressLine1 });
          }}
          label="番地"
          errorMessage={
            rules &&
            validate({
              label: '番地',
              value: params.addressLine1,
              rule: rules.addressLine1
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.addressLine2}
          name="addressLine2"
          onChangeText={addressLine2 => {
            onChangeParams({ addressLine2 });
          }}
          label="建物名"
          errorMessage={
            rules &&
            validate({
              label: '建物名',
              value: params.addressLine2,
              rule: rules.addressLine2
            })
          }
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingInfoFormField);
