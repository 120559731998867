import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { svgAsPngUri } from '../../utils/saveSvgAsPng';
import BusinessCategory from '../../models/BusinessCategory';
import Color from '../../constants/Color';
import PricingClass from '../../models/PricingClass';
import Proposal from '../../components/Pdf/Proposal';
import ProposalBackgroundSvg from '../../components/Proposal/ProposalBackgroundSvg';
import HeadlineDescSvg from '../../components/Proposal/HeadlineDescSvg';
import SupplyClass from '../../models/SupplyClass';
import TimeOfDeliverClass from '../../models/TimeOfDeliverClass';
import View from '../../components/View';
import actions from '../../actions';

const image2base64 = require('image-to-base64');

const SIZE_TIMES = 2;

class ProposalDownloadLink extends React.Component {
  state = {
    assets: null,
    icons: null
  };

  async componentDidMount() {
    const {
      data: {
        basicInfo: { primaryColor, secondaryColor, businessCategoryId }
      }
    } = this.props;

    const category = BusinessCategory.getItems().find(
      item => item.value === businessCategoryId
    );

    const mainColor = `#${primaryColor}`;

    const subColor = `#${secondaryColor}`;

    const ICON = [
      {
        id: 'category',
        componentName: `${category.iconName}Svg`,
        color: Color.white,
        size: 64
      },
      {
        id: 'menuRight',
        componentName: 'MenuRightIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'book',
        componentName: 'BookIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'account',
        componentName: 'AccountIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'checkboxMarkedCircleOutline',
        componentName: 'CheckboxMarkedCircleOutlineIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'cart',
        componentName: 'CartIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'cartPlus',
        componentName: 'CartPlusIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'factory',
        componentName: 'FactoryIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'briefcaseUpload',
        componentName: 'BriefcaseUploadIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'store',
        componentName: 'StoreIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'basket',
        componentName: 'BasketIconSvg',
        color: subColor,
        size: 64
      },
      {
        id: 'chartBar',
        componentName: 'ChartBarIconSvg',
        color: subColor,
        size: 64
      },
      {
        id: 'findReplace',
        componentName: 'FindReplaceIconSvg',
        color: subColor,
        size: 64
      },
      {
        id: 'alert',
        componentName: 'AlertIconSvg',
        color: subColor,
        size: 64
      },
      {
        id: 'thumbUpActive',
        componentName: 'ThumbUpIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'thumbUpInactive',
        componentName: 'ThumbUpIconSvg',
        color: Color.warmGrey,
        size: 64
      },
      {
        id: 'alertActive',
        componentName: 'AlertIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'alertInactive',
        componentName: 'AlertIconSvg',
        color: Color.warmGrey,
        size: 64
      },
      {
        id: 'earth',
        componentName: 'EarthIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'trendingUp',
        componentName: 'TrendingUpIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'playlistCheck',
        componentName: 'PlaylistCheckIconSvg',
        color: Color.white,
        size: 24
      },
      {
        id: 'clipboardCheck',
        componentName: 'ClipboardCheckIconSvg',
        color: Color.white,
        size: 24
      }
    ];

    const icons = await Promise.all(
      ICON.map(async icon => {
        const module = await import('../../utils/MaterialIconSvg');

        const component = module[icon.componentName];

        return { ...icon, component };
      })
    );

    this.setState({ icons });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.icons && !prevState.icons) {
      const assetData = await Promise.all([
        ...this.state.icons.map(async icon => {
          const data = await svgAsPngUri(
            document.getElementById(`${icon.id}Icon`),
            {
              scale: SIZE_TIMES
            }
          );

          return {
            id: icon.id,
            data
          };
        })
      ]);

      const backgroundData = await svgAsPngUri(
        document.getElementById(`proposalBackground`),
        {
          scale: SIZE_TIMES
        }
      );

      const headlineDescData = await svgAsPngUri(
        document.getElementById(`proposalHeadlineDesc`),
        {
          scale: SIZE_TIMES
        }
      );

      const profileImageMimeType = await this.getMimeType(
        this.props.data.basicInfo.profileImage.uri
      );

      const profileImageData = await image2base64(
        this.props.data.basicInfo.profileImage.uri
      );

      const assets = assetData.reduce(
        (acc, asset) => {
          return {
            ...acc,
            [asset.id]: asset.data
          };
        },
        {
          profileImage: `data:${profileImageMimeType};base64,${profileImageData}`,
          proposalBackground: backgroundData,
          proposalHeadlineDesc: headlineDescData
        }
      );

      this.setState({ assets });

      return true;
    }
  }

  getMimeType(url) {
    return new Promise(resolve => {
      const request = new XMLHttpRequest();

      request.open('GET', url);

      request.addEventListener('load', function() {
        const type = this.getResponseHeader('Content-Type');

        resolve(type);
      });

      request.send();
    });
  }

  render() {
    const { data, preview = false, style = {}, previewStyle = {} } = this.props;

    const {
      data: {
        basicInfo: { primaryColor, businessCategoryId },
        specification: { supplyClassId, pricingClassId, timeOfDeliverClassId }
      }
    } = this.props;

    const mainColor = `#${primaryColor}`;

    if (!this.state.icons) {
      return null;
    }

    if (this.state.icons && !this.state.assets) {
      return (
        <View style={{ display: 'none' }}>
          <ProposalBackgroundSvg id="proposalBackground" fill={mainColor} />
          <HeadlineDescSvg id="proposalHeadlineDesc" fill={mainColor} />
          {this.state.icons.map(icon => {
            const Icon = icon.component;

            return (
              <Icon
                id={`${icon.id}Icon`}
                key={icon.id}
                width={icon.size * SIZE_TIMES}
                height={icon.size * SIZE_TIMES}
                viewBox="0 0 24 24"
                style={{ fill: icon.color }}
              />
            );
          })}
        </View>
      );
    }

    const category = BusinessCategory.getItems().find(
      item => item.value === businessCategoryId
    );

    const supplyClass = SupplyClass.getItems().find(
      item => item.value === supplyClassId
    );

    const pricingClass = PricingClass.getItems().find(
      item => item.value === pricingClassId
    );

    const timeOfDeliverClass = TimeOfDeliverClass.getItems().find(
      item => item.value === timeOfDeliverClassId
    );

    return (
      <View style={style}>
        <Proposal
          preview={preview}
          data={{
            ...data,
            categoryName: category.label,
            specification: {
              ...data.specification,
              supplyClassName: supplyClass.label,
              pricingClassName: pricingClass.label,
              timeOfDeliverClassName: timeOfDeliverClass.label
            },
            assets: this.state.assets
          }}
          previewStyle={previewStyle}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalDownloadLink);
