import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import LoadMoreList from '../List/LoadMoreList';
import Text from '../Text';
import ThankTimelineItem from './ThankTimelineItem';
import View from '../View';
import actions from '../../actions';

class ThankTimelineList extends React.Component {
  componentDidMount() {
    this.props.getDreamThanks(this.props.dreamId, { reset: true });
  }

  render() {
    const { style = {} } = this.props;

    if (
      this.props.dreamThank.list.length < 1 &&
      !this.props.dreamThank.isGetLoading
    ) {
      return (
        <View
          style={{
            ...style
          }}
        >
          <Text>履歴がありません。</Text>
        </View>
      );
    }

    return (
      <View
        style={{
          ...style
        }}
      >
        <LoadMoreList
          data={this.props.dreamThank.list.map(
            id => this.props.dreamThank.hash[id]
          )}
          renderItem={({ item, index }) => {
            const isFirst = index === 0;

            const isLast = index === this.props.dreamThank.list.length - 1;

            return (
              <ThankTimelineItem data={item} first={isFirst} last={isLast} />
            );
          }}
          onFetchMore={lastItem => {
            this.props.getDreamThanks(this.props.dreamId, { lastItem });
          }}
          isLoading={this.props.dreamThank.isGetLoading}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankTimelineList);
