import { Font, Image, Text, View } from '@react-pdf/renderer';
import React from 'react';

const regularFontPath = require('../assets/font/yugothil.ttf');

const boldFontPath = require('../assets/font/yugothib.ttf');

Font.register(`${window.location.origin}${boldFontPath}`, {
  family: 'YuGothic-Bold'
});

Font.register(`${window.location.origin}${regularFontPath}`, {
  family: 'YuGothic'
});

Font.registerHyphenationCallback(word => word.split(''));

export const PdfView = View;

export const PdfImage = Image;

export function PdfText({ children, style = {}, ...props }) {
  let fontFamily = `YuGothic`;

  if (style.fontWeight === 'bold') {
    fontFamily = `YuGothic-Bold`;
  }

  return (
    <Text
      {...props}
      style={{
        fontFamily,
        lineHeight: 1,
        hyphenationCallback: 10000,
        ...style
      }}
    >
      {children}
    </Text>
  );
}
