import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { SHIPPING_INFO_VALIDATION_RULE } from '../../../../constants/Validations';
import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import Headline from '../../../../components/Headline';
import PageHeader from '../../../../containers/page/PageHeader';
import ResponseDispatcher from '../../../../components/ResponseDispatcher';
import Section from '../../../../components/Section/Section';
import ShippingInfo from '../../../../models/ShippingInfo';
import ShippingInfoFormField from '../../../../containers/form/ShippingInfoFormField';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypageShippingInfoDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: new ShippingInfo().object
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.shippingInfo.isGetLoading &&
      !nextProps.shippingInfo.isGetLoading
    ) {
      const shippingInfo =
        nextProps.shippingInfo.hash[this.props.match.params.id];

      this.setState({
        params: shippingInfo
      });
    }

    return true;
  }

  componentDidMount() {
    this.props.getShippingInfo(this.props.match.params.id);
  }

  render() {
    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <View style={{ paddingTop: 32, marginBottom: 32 }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 16px 0 16px',
              marginBottom: 16
            }}
          >
            <Headline size={3} style={{ fontSize: 15 }}>
              お届先情報
            </Headline>
            <Button
              tiny
              color={Color.red}
              onClick={() => {
                const result = window.confirm(
                  'お届先情報を削除します。よろしいですか？'
                );

                if (result) {
                  this.props.deleteShippingInfo(this.props.match.params.id);
                }
              }}
            >
              削除する
            </Button>
          </View>
          <Section
            style={{
              padding: 16,
              marginBottom: 40,
              borderBottom: `1px solid ${Color.lightGrey}`
            }}
          >
            <ShippingInfoFormField
              params={this.state.params}
              onChangeParams={state =>
                this.setState({
                  params: { ...this.state.params, ...state }
                })
              }
              rules={SHIPPING_INFO_VALIDATION_RULE}
            />
          </Section>
          <View style={{ padding: '0 16px' }}>
            <Button
              disable={this.props.shippingInfo.isUpdateLoading}
              onClick={() =>
                this.props.updateShippingInfo(
                  this.props.match.params.id,
                  new ShippingInfo(this.state.params).fields
                )
              }
              style={{ marginBottom: 24 }}
            >
              更新する
            </Button>
            <Link to="/mypage/shippingInfo">
              <Button variant="flat">戻る</Button>
            </Link>
          </View>
        </View>
        <ResponseDispatcher
          isLoading={this.props.shippingInfo.isUpdateLoading}
          isFailed={this.props.shippingInfo.isUpdateFailed}
          onComplete={() => {
            this.props.getShippingInfos();

            this.props.history.push('/mypage/shippingInfo');
          }}
        />
        <ResponseDispatcher
          isLoading={this.props.shippingInfo.isDeleteLoading}
          isFailed={this.props.shippingInfo.isDeleteFailed}
          onComplete={() => {
            this.props.getShippingInfos();

            this.props.history.push('/mypage/shippingInfo');
          }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypageShippingInfoDetail);
