import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Color from '../../constants/Color';
import Style from '../../constants/Style';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class SignOutButton extends React.Component {
  state = {
    isRedirecting: false
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.auth.uid && !nextProps.auth.uid) {
      this.setState({ isRedirecting: true }, () =>
        this.setState({ isRedirecting: false })
      );
    }

    return true;
  }

  render() {
    const { style = {} } = this.props;

    return (
      <View
        onClick={() => this.props.signOut()}
        style={{
          borderRadius: 16,
          backgroundColor: Color.white,
          padding: '4px 8px',
          ...Style.shadow.medium,
          ...style
        }}
      >
        <Text style={{ color: Color.slate, fontSize: 11 }}>ログアウト</Text>
        {this.state.isRedirecting && <Redirect to="/" />}
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignOutButton);
