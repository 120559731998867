import { Link } from 'react-router-dom';
import React from 'react';

import Color from '../../constants/Color';
import FounderProperty from './FounderProperty';
import Hr from '../Hr';
import Paragraph from '../Paragraph';
import Style from '../../constants/Style';
import Text from '../Text';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

export default function FounderProfile({ data, style = {} }) {
  return (
    <View>
      <View style={{ padding: '0 24px 0 24px', marginBottom: 24, ...style }}>
        <View style={{ alignItems: 'center', marginBottom: -64, zIndex: 1 }}>
          <Link to={`/founder/${data.id}`}>
            <UserIcon
              source={data.iconImage}
              size={128}
              style={{ ...Style.shadow.large }}
            />
          </Link>
        </View>
        <View
          style={{
            backgroundColor: Color.white,
            borderRadius: 8,
            padding: '88px 16px 16px 16px',
            ...Style.shadow.large
          }}
        >
          <Text
            style={{
              color: Color.slate,
              fontSize: 15,
              alignSelf: 'center',
              marginBottom: 8
            }}
          >
            {data.screenName}
          </Text>
          <Hr style={{ marginBottom: 16 }} />
          <FounderProperty data={data} />
          <Hr style={{ marginBottom: 16 }} />
          <Paragraph style={{ color: Color.slate, fontSize: 13 }}>
            {data.profile}
          </Paragraph>
        </View>
      </View>
    </View>
  );
}
