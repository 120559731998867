import React from 'react';

import Color from '../constants/Color';

export default function Headline({ size = 1, children, style = {} }) {
  const componentStyle = {
    fontWeight: 'bold',
    color: Color.slate
  };

  switch (size) {
    case 1:
      return (
        <h1 style={{ ...componentStyle, fontSize: 20, ...style }}>
          {children}
        </h1>
      );
    case 2:
      return (
        <h2 style={{ ...componentStyle, fontSize: 17, ...style }}>
          {children}
        </h2>
      );
    case 3:
    default:
      return (
        <h3 style={{ ...componentStyle, fontSize: 15, ...style }}>
          {children}
        </h3>
      );
  }
}
