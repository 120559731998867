import React from 'react';

import Color from '../../constants/Color';
import Headline from '../Headline';
import View from '../View';

export default function ModalHeader({
  title,
  left,
  right,
  color = Color.slate,
  style = {}
}) {
  return (
    <View style={{ position: 'relative', ...style }}>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Headline style={{ color }}>{title}</Headline>
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 1
        }}
      >
        <View>{left}</View>
        <View>{right}</View>
      </View>
    </View>
  );
}
