import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { svgAsPngUri } from '../../utils/saveSvgAsPng';
import BusinessCategory from '../../models/BusinessCategory';
import BusinessModelPreview from '../../components/Scheme/BusinessModelPreview';
import Color from '../../constants/Color';
import HeadlineDescSvg from '../../components/Proposal/HeadlineDescSvg';
import Scheme from '../../components/Pdf/Scheme';
import View from '../../components/View';
import actions from '../../actions';

const image2base64 = require('image-to-base64');

const SIZE_TIMES = 2;

class SchemeDownloadLink extends React.Component {
  state = {
    assets: null,
    icons: null
  };

  async componentDidMount() {
    const {
      data: {
        basicInfo: { primaryColor, businessCategoryId }
      }
    } = this.props;

    const category = BusinessCategory.getItems().find(
      item => item.value === businessCategoryId
    );

    const mainColor = `#${primaryColor}`;

    const ICON = [
      {
        id: 'category',
        componentName: `${category.iconName}Svg`,
        color: Color.white,
        size: 64
      },
      {
        id: 'alert',
        componentName: 'AlertIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'lightbulbOnOutline',
        componentName: 'LightbulbOnOutlineIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'gift',
        componentName: 'GiftIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'thumbUpActive',
        componentName: 'ThumbUpIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'thumbUpInactive',
        componentName: 'ThumbUpIconSvg',
        color: Color.warmGrey,
        size: 64
      },
      {
        id: 'alertActive',
        componentName: 'AlertIconSvg',
        color: mainColor,
        size: 64
      },
      {
        id: 'alertInactive',
        componentName: 'AlertIconSvg',
        color: Color.warmGrey,
        size: 64
      },
      {
        id: 'key',
        componentName: 'KeyVariantIconSvg',
        color: Color.white,
        size: 64
      },
      {
        id: 'lockAlert',
        componentName: 'LockAlertIconSvg',
        color: Color.white,
        size: 24
      }
    ];

    const icons = await Promise.all(
      ICON.map(async icon => {
        const module = await import('../../utils/MaterialIconSvg');

        const component = module[icon.componentName];

        return { ...icon, component };
      })
    );

    this.setState({ icons });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.icons && !prevState.icons) {
      const assetData = await Promise.all([
        ...this.state.icons.map(async icon => {
          const data = await svgAsPngUri(
            document.getElementById(`${icon.id}Icon`),
            {
              scale: SIZE_TIMES
            }
          );

          return {
            id: icon.id,
            data
          };
        })
      ]);

      const headlineDescData = await svgAsPngUri(
        document.getElementById(`headlineDesc`),
        {
          scale: SIZE_TIMES
        }
      );

      const businessModelPreviewData = await svgAsPngUri(
        document.getElementById(`businessModelPreview`),
        {
          scale: SIZE_TIMES
        }
      );

      const profileImageMimeType = await this.getMimeType(
        this.props.data.basicInfo.profileImage.uri
      );

      const profileImageData = await image2base64(
        this.props.data.basicInfo.profileImage.uri
      );

      const assets = assetData.reduce(
        (acc, asset) => {
          return {
            ...acc,
            [asset.id]: asset.data
          };
        },
        {
          profileImage: `data:${profileImageMimeType};base64,${profileImageData}`,
          businessModel: businessModelPreviewData,
          headlineDesc: headlineDescData
        }
      );

      this.setState({ assets });

      return true;
    }
  }

  getMimeType(url) {
    return new Promise(resolve => {
      const request = new XMLHttpRequest();

      request.open('GET', url);

      request.addEventListener('load', function() {
        const type = this.getResponseHeader('Content-Type');

        resolve(type);
      });

      request.send();
    });
  }

  render() {
    const { data, preview = false, style = {}, previewStyle = {} } = this.props;

    const {
      data: {
        basicInfo: { primaryColor, businessCategoryId }
      }
    } = this.props;

    const mainColor = `#${primaryColor}`;

    if (!this.state.icons) {
      return null;
    }

    if (this.state.icons && !this.state.assets) {
      return (
        <View style={{ display: 'none' }}>
          <HeadlineDescSvg id="headlineDesc" fill={mainColor} />
          <BusinessModelPreview
            id="businessModelPreview"
            models={data.businessModel.models}
            relations={data.businessModel.relations}
            levelOptions={data.businessModel.levelOptions}
            width={555}
            height={555}
          />
          {this.state.icons.map(icon => {
            const Icon = icon.component;

            return (
              <Icon
                id={`${icon.id}Icon`}
                key={icon.id}
                width={icon.size * SIZE_TIMES}
                height={icon.size * SIZE_TIMES}
                viewBox="0 0 24 24"
                style={{ fill: icon.color }}
              />
            );
          })}
        </View>
      );
    }

    const category = BusinessCategory.getItems().find(
      item => item.value === businessCategoryId
    );

    return (
      <View style={style}>
        <Scheme
          preview={preview}
          data={{
            ...data,
            categoryName: category.label,
            assets: this.state.assets
          }}
          previewStyle={previewStyle}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchemeDownloadLink);
