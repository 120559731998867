import { getCollection } from '../utils/Firebase';
import ActivityHistory from './ActivityHistory';
import Color from '../constants/Color';
import Dream from './Dream';
import Founder from './Founder';

export default class ProductHistory {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      type: state.type,
      data: state.data,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt
    };
  }

  get object() {
    return this.params;
  }

  static getLabel(type) {
    switch (type) {
      case 'support':
        return 'サポート';
      default:
        return '';
    }
  }

  static getColor(type) {
    switch (type) {
      case 'support':
        return Color.pinkGradient;
      case 'comment':
        return Color.orangeGradient;
      default:
        return '';
    }
  }

  static async join({ id, type, data, updatedAt, createdAt }) {
    let dream = null;

    let founder = null;

    if (data.founderId) {
      founder = await Founder.get(data.founderId);
    }

    if (data.dreamId) {
      const dreamDoc = await getCollection('dreams')
        .doc(data.dreamId)
        .get();

      const dreamData = dreamDoc.data();

      const founder = await Founder.get(dreamData.founderId);

      dream = new Dream({
        id: data.dreamId,
        founder,
        ...dreamData
      }).fromFirestore();
    }

    let product = {};

    if (type === 'product') {
      const productDoc = await getCollection('dreams')
        .doc(data.dreamId)
        .collection('products')
        .doc(data.productId)
        .get();

      product = {
        product: {
          id: productDoc.id,
          ...productDoc.data()
        }
      };
    }

    return new ActivityHistory({
      id,
      type,
      data: {
        ...data,
        dream,
        founder,
        ...product
      },
      updatedAt,
      createdAt
    }).fromFirestore();
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
