import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import View from '../View';

export default function FounderProperty({ data, style = {} }) {
  return (
    <View style={{ flexDirection: 'row', ...style }}>
      <View style={{ marginRight: 16 }}>
        <Text
          style={{
            color: Color.lightSlate,
            fontSize: 13,
            fontWeight: 'bold',
            marginBottom: 8
          }}
        >
          誕生日
        </Text>
        <Text
          style={{
            color: Color.slate,
            fontSize: 15
          }}
        >
          {data.birthday}
        </Text>
      </View>
      <View style={{ marginRight: 16 }}>
        <Text
          style={{
            color: Color.lightSlate,
            fontSize: 13,
            fontWeight: 'bold',
            marginBottom: 8
          }}
        >
          血液型
        </Text>
        <Text
          style={{
            color: Color.slate,
            fontSize: 15
          }}
        >
          {data.bloodType}
        </Text>
      </View>
      <View>
        <Text
          style={{
            color: Color.lightSlate,
            fontSize: 13,
            fontWeight: 'bold',
            marginBottom: 8
          }}
        >
          出身
        </Text>
        <Text
          style={{
            color: Color.slate,
            fontSize: 15
          }}
        >
          {data.birthplace}
        </Text>
      </View>
    </View>
  );
}
