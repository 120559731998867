import { Col, Row } from 'react-grid-system';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import moment from 'moment';

import AmountCardBody from '../../../../components/Card/AmountCardBody';
import BusinessCategory from '../../../../models/BusinessCategory';
import CardPrimitive from '../../../../components/Card/CardPrimitive';
import Color from '../../../../constants/Color';
import Column from '../../../../components/Column';
import ContentContainer from '../../../../components/Page/ContentContainer';
import Deadline from '../../../../utils/Deadline';
import DreamCommentContent from '../../../../containers/dream/DreamCommentContent';
import DreamDesktopHeader from '../../../../components/Dream/DreamDesktopHeader';
import DreamProgressCard from '../../../../components/Dream/DreamProgressCard';
import DreamPurchaseContent from '../../../../containers/dream/DreamPurchaseContent';
import FooterContainer from '../../../../components/FooterContainer';
import FounderCard from '../../../../components/Founder/FounderCard';
import Headline from '../../../../components/Headline';
import Image from '../../../../components/Image';
import PageContaineredHeader from '../../../../containers/page/PageContaineredHeader';
import Text from '../../../../components/Text';
import View from '../../../../components/View';
import actions from '../../../../actions';

class DreamReportIndex extends React.Component {
  componentDidMount() {
    this.props.getDream(this.props.match.params.id);
  }

  render() {
    const dream = this.props.dream.hash[this.props.match.params.id];

    if (!dream) {
      return null;
    }

    const businessCategory = BusinessCategory.getItem(dream.businessCategoryId);

    const deadline = new Deadline(dream.deadlineAt);

    return (
      <View>
        <PageContaineredHeader />
        <DreamDesktopHeader data={dream} businessCategory={businessCategory} />
        <ContentContainer>
          <Row>
            <Column xs={8}>
              <Headline
                size={2}
                style={{
                  color: Color.lightSlate,
                  marginTop: 40,
                  marginBottom: 16
                }}
              >
                {dream.title}
              </Headline>
              <Headline
                size={2}
                style={{
                  color: Color.slate,
                  fontSize: 32,
                  marginBottom: 24
                }}
              >
                活動レポート一覧
              </Headline>
              <Row style={{ marginBottom: 32 }}>
                {dream.reports.map(report => {
                  return (
                    <Col xs={4}>
                      <Link to={`/dream/${dream.id}/report/${report.id}`}>
                        <View style={{ marginBottom: 8 }}>
                          <Text
                            style={{
                              color: Color.lightSlate,
                              fontSize: 17,
                              fontWeight: 'bold'
                            }}
                          >
                            {moment(report.startAt).format('YYYY.MM.DD')}
                          </Text>
                        </View>
                        <Image
                          source={report.images[0]}
                          style={{
                            width: '100%',
                            height: 'auto',
                            marginBottom: 8
                          }}
                        />
                        <Text
                          style={{
                            color: Color.slate,
                            fontSize: 17,
                            fontWeight: 'bold'
                          }}
                        >
                          {report.title}
                        </Text>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
              <DreamCommentContent data={dream} />
            </Column>
            <Column xs={4}>
              <Link to={`/founder/${dream.founder.id}`}>
                <FounderCard
                  data={dream.founder}
                  style={{ marginTop: -120, marginBottom: 24 }}
                />
              </Link>
              <DreamProgressCard data={dream} style={{ marginBottom: 24 }} />
              <View style={{ flexDirection: 'row', marginBottom: 40 }}>
                <CardPrimitive style={{ flex: 1, marginRight: 16 }}>
                  <AmountCardBody
                    style={{ padding: '16px 0 16px 0' }}
                    title="支援者"
                    amount={dream.supporterCount}
                    unit="人"
                  />
                </CardPrimitive>
                <CardPrimitive style={{ flex: 1 }}>
                  <AmountCardBody
                    style={{ padding: '16px 0 16px 0' }}
                    title="募集締切まで"
                    amount={deadline.leastAmount}
                    unit={deadline.leastUnit}
                  />
                </CardPrimitive>
              </View>
              <DreamPurchaseContent data={dream} style={{ marginBottom: 24 }} />
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamReportIndex);
