export const PROPOSAL_ARTICLES = [
  {
    title: '基本情報',
    number: '01',
    description:
      'あなたの事業の基盤となる情報をまとめましょう。このセクションで一番大事なのは、あなたがこの事業を成功させるための唯一の人間であると示すことです。特に略歴は、事業によく関連するように書きましょう。',
    sections: [
      {
        title: '事業タイトル',
        content: '事業のタイトルを、一言でわかりやすく表現しましょう。'
      },
      {
        title: '事業カテゴリ',
        content:
          '事業のカテゴリを選択して、事業計画書を見る人へ、この事業が社会から見てどの産業にあたるのかを伝えましょう。'
      },
      {
        title: '名前',
        content:
          'この事業を誰が推進するのかを明示して、事業内容を信頼できるものにします。'
      },
      {
        title: 'プロフィール画像',
        content:
          'プロフィール画像にあなたの顔を載せ、事業計画書により現実味を与えましょう。'
      },
      {
        title: '生年月日',
        content:
          'あなたの年齢を示すことで、どのような時代背景にあなたが育ったのかを伝えます。'
      },
      {
        title: '職業',
        content:
          'あなたがどのような職業についているかを示すことで、それがこの事業にどのように作用するかを読み手に想起させましょう。'
      },
      {
        title: '略歴',
        content:
          'あなたのこれまでの略歴を書き、この事業を推進するのにふさわしい人物であることをアピールしましょう。'
      },
      {
        title: 'メインカラー・サブカラー',
        content:
          'この事業のイメージカラーを設定して、事業のイメージを色で伝えましょう。'
      }
    ]
  },
  {
    title: '導入',
    number: '02',
    description:
      '事業を始めるにあたっての背景を記述し、事業計画書を読んでもらうための事前情報や、事業のターゲット・ポイントを明確にしておきましょう。',
    sections: [
      {
        title: '創業理由やストーリー',
        content:
          'この事業の核となる背景、ストーリーを記述して、この事業を思いつくにあたった経緯を記述しましょう。'
      },
      {
        title: 'ターゲットと提供する体験',
        content:
          'ユーザー層と、そのユーザーへ提供する体験をわかりやすく記述しましょう。'
      },
      {
        title: '懸念点と実現のポイント',
        content:
          'ユーザーに体験を提供するにあたって、懸念事項とその懸念事項に対する対応を、実現のポイントとして示しましょう。'
      }
    ]
  },
  {
    title: '事業内容',
    number: '03',
    description:
      '事業の内容について、5つの観点から事業の内容を簡潔に説明しましょう。',
    sections: [
      {
        title: '仕様・特徴',
        content: '事業で扱う商品の仕様を、特徴を交えて記述しましょう。'
      },
      {
        title: '研究内容・新企画状況',
        content:
          'ユーザーに対して新しい価値を提供するため、どのような研究をしてくか、新しい企画は何を考えているのかの状況を記述しましょう。'
      },
      {
        title: '提供種別',
        content:
          'この事業が提供する商品は、ハイエンド・汎用品・ローエンドのどれにあたるかを示しましょう。'
      }
    ]
  },
  {
    title: '業務プロセス',
    number: '04',
    description:
      'ユーザーに商品・体験を提供するまでの業務プロセスについて、仕入、製造、販売、PRまでを順を追って記述しましょう。',
    sections: [
      {
        title: '仕入れ・供給',
        content:
          '製造のために必要なものをどこから仕入れるか、どのようにして供給されるかを記述しましょう。'
      },
      {
        title: '製造・外注',
        content:
          'どのようにして商品を製造するか、外注をする場合はどこに外注するのか、そのルートを記述しましょう。'
      },
      {
        title: '販売・流通ルート',
        content: '商品を販売するための流通路について記述しましょう。'
      },
      {
        title: '販促・PRルート',
        content:
          '事業を認知させるために使用する広告媒体、ルートを、計画とともに記述しましょう。'
      }
    ]
  },
  {
    title: '経営環境',
    number: '05',
    description:
      'ユーザーに商品・体験を提供するまでの業務プロセスについて、仕入、製造、販売、PRまでを順を追って記述しましょう。',
    sections: [
      {
        title: 'マーケット動向',
        content:
          '市場規模、市場予測を交え、あなたの事業がどのようなマーケットの中にあるか示しましょう。'
      },
      {
        title: '競合状況',
        content: '競合他社の進めている施策を記述しましょう。'
      },
      {
        title: '代替状況',
        content: 'あなたの事業の他に、どのような代替品があるか記述しましょう。'
      },
      {
        title: '事業リスク',
        content:
          '事業をやる上で明確になっている事業リスクについて記述しましょう。'
      }
    ]
  },
  {
    title: '特徴',
    number: '06',
    description:
      '事業の特徴を書きとめ、あなたの事業内容をより明確なものにしていきましょう。',
    sections: [
      {
        title: '強み',
        content:
          'あなたの事業における、活かすべき強みについて記述してください。ここでは、事業の周囲の状況（競合・マーケット）に触れず、内部からの視点でのみ「強み」について記述しましょう。'
      },
      {
        title: '弱み',
        content:
          'あなたの事業における、克服すべき弱みについて記述してください。「強み」と同様に、事業の周囲の状況については、ここでは触れないでください。'
      },
      {
        title: '機会',
        content:
          'あなたの事業を取り巻く環境における、あなたの事業が社会に受け入れられる理由を記述してください。'
      },
      {
        title: '脅威',
        content:
          'あなたの事業を取り巻く環境における、あなたの事業に対する脅威の存在を記述してください。この脅威は、今存在しているものでなくてもかまいません。'
      },
      {
        title: '独自性',
        content:
          'ほかの競合にはない、あなたのサービスのみが持つ独自性について記述してください。特に、ビジネスモデルの構造のユニークさについて記述しましょう。'
      },
      {
        title: '成長性',
        content:
          '事業が成長する具体的な理由を記述しましょう。その成長に合わせ、どのように事業を成長させるかも記述してください。'
      },
      {
        title: '成功ポイント',
        content:
          'この事業が成功するための一番重要なポイントを簡潔に記述してください。'
      },
      {
        title: '優先課題',
        content:
          '事業を成功させるために解決すべき課題を、重要（成功に必要）な順に列挙してください。この時点で何をしたらよいか明らかになっていない、抽象的な課題は、具体的な施策まで落とし込んでおきましょう。'
      }
    ]
  },
  {
    title: '営利',
    number: '07',
    description:
      '事業を行うに当たり、何をするとどれだけ利益が出るのかの概算をしましょう。ここがプラスにならなければ、その事業はやる価値がありません。',
    sections: [
      {
        title: '売上に関する項目を3つ',
        content:
          '売上に関する項目を3つ列挙し、一日あたり、あるいは、イベントであれば一回あたりの売上金額、および目標数について記述してください。例えば、飲食店を例にすると、（１）客単価の平均、（２）一日の客数、（３）一日の売上の予想、の３つにするとまとまりが良いです。それぞれ、（１）3,000円、（２）50人とすると（３）は15万円となります。'
      },
      {
        title: '売上の小計',
        content:
          '上記で出した3つの項目について、年間や、一回のイベントあたりの売上を記述しましょう。飲食店の例で、定休日が週に一回だとすると、30日 - 4日 = 26日が営業日なので、15万円 × 26日 = 390万円 となります。'
      },
      {
        title: 'コストに関する項目を3つ',
        content:
          'コストに関する項目を3つ列挙し、一日あたり、あるいは、イベントであれば一回あたりのコストを算出しましょう。また、コストに関する重要な数字もここで記しましょう。飲食店を例にすると、（１）場所代/月、（２）人権費/月、（３）食材代/月などになります。'
      },
      {
        title: 'コストの小計',
        content:
          '試算したコストを、年間や、一回のイベントあたりのコストとして記述しましょう。'
      },
      {
        title: '営利',
        content:
          '売上の小計と、コストの小計を足し合わせ、この事業を行ったとき、概算でどれくらいの利益になるかということを示しましょう。一年あたり、あるいは複数回のイベントでの営利を出しましょう。'
      }
    ]
  }
];
