import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import ArrowStream from '../../../components/Icons/ArrowStream';
import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Footer from '../../../components/Footer';
import FounderRecruit from '../../../containers/FounderRecruit';
import ImageIntroduction from '../../../components/ImageIntroduction';
import MaterialIcon from '../../../utils/MaterialIcon';
import PageHeader from '../../../containers/page/PageHeader';
import Panel from '../../../components/Panel';
import Paragraph from '../../../components/Paragraph';
import SectionCard from '../../../components/Card/SectionCard';
import SectionPointPanel from '../../../components/Card/SectionPointPanel';
import SuccessProjectSectionCard from '../../../components/Job/SuccessProjectSectionCard';
import Text from '../../../components/Text';
import View from '../../../components/View';
import actions from '../../../actions';

class JobDetail extends React.Component {
  componentDidMount() {
    this.props.getJob(this.props.match.params.id);
  }

  render() {
    if (!this.props.job.hash[this.props.match.params.id]) {
      return null;
    }

    const {
      name,
      iconName,
      alphabet,
      detail,
      bannerImage,
      yearlyProfitAmount,
      experiencedYears,
      fundAmount,
      planContent,
      skillContent,
      fundContent,
      successProjects
    } = this.props.job.hash[this.props.match.params.id];

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <View
          style={{
            padding: '16px 24px',
            backgroundImage: `url(${bannerImage.uri})`,
            backgroundSize: 'contain',
            height: 180
          }}
        >
          <Text
            style={{
              color: Color.white,
              fontSize: 20,
              fontWeight: 'bold',
              textShadow: `${Color.black} 0 2px 4px`
            }}
          >
            {alphabet}
          </Text>
          <Text
            style={{
              color: Color.white,
              fontSize: 13,
              fontWeight: 'bold',
              textShadow: `${Color.black} 0 2px 4px`
            }}
          >
            {name}
          </Text>
        </View>
        <View style={{ padding: '0 16px', marginTop: -24, zIndex: 1 }}>
          <SectionCard
            title={name}
            panel={
              <Panel gradient={Color.pinkGradient} size={40}>
                <MaterialIcon icon={iconName} color={Color.white} size={32} />
              </Panel>
            }
          >
            <Paragraph>{detail}</Paragraph>
          </SectionCard>
        </View>
        <ImageIntroduction
          title={`${name}で開業する前に`}
          description={`${name}で開業をする前に、３つのポイントをチェックしておきましょう。`}
          gradient={Color.nightGradient}
          source={{
            uri: require('../../../assets/img/night-forest.jpg')
          }}
          style={{
            marginTop: -16
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0 16px',
              marginBottom: 80
            }}
          >
            <SectionPointPanel
              title="計画"
              number="01"
              labelComponent={
                <Text style={{ color: Color.white, fontSize: 11 }}>
                  年利
                  <Text
                    style={{
                      color: Color.white,
                      fontWeight: 'bold',
                      fontSize: 11
                    }}
                  >
                    {yearlyProfitAmount}
                  </Text>
                  万〜
                </Text>
              }
            />
            <SectionPointPanel
              title="スキル"
              number="02"
              labelComponent={
                <Text style={{ color: Color.white, fontSize: 11 }}>
                  実務
                  <Text
                    style={{
                      color: Color.white,
                      fontWeight: 'bold',
                      fontSize: 11
                    }}
                  >
                    {experiencedYears}
                  </Text>
                  年〜
                </Text>
              }
            />
            <SectionPointPanel
              title="資金"
              number="03"
              labelComponent={
                <Text style={{ color: Color.white, fontSize: 11 }}>
                  <Text
                    style={{
                      color: Color.white,
                      fontWeight: 'bold',
                      fontSize: 11
                    }}
                  >
                    {fundAmount}
                  </Text>
                  万〜
                </Text>
              }
            />
          </View>
        </ImageIntroduction>
        <View style={{ padding: '0 16px', zIndex: 1 }}>
          <SectionCard
            title="計画"
            panel={
              <Panel size={40} style={{ backgroundColor: Color.pink }}>
                <Text
                  style={{
                    color: Color.white,
                    fontSize: 24,
                    fontWeight: 'bold'
                  }}
                >
                  01
                </Text>
              </Panel>
            }
            style={{ marginTop: -48, marginBottom: 24 }}
          >
            <Paragraph>{planContent}</Paragraph>
          </SectionCard>
          <SectionCard
            title="スキル"
            panel={
              <Panel size={40} style={{ backgroundColor: Color.pink }}>
                <Text
                  style={{
                    color: Color.white,
                    fontSize: 24,
                    fontWeight: 'bold'
                  }}
                >
                  02
                </Text>
              </Panel>
            }
            style={{ marginBottom: 24 }}
          >
            <Paragraph>{skillContent}</Paragraph>
          </SectionCard>
          <SectionCard
            title="資金"
            panel={
              <Panel size={40} style={{ backgroundColor: Color.pink }}>
                <Text
                  style={{
                    color: Color.white,
                    fontSize: 24,
                    fontWeight: 'bold'
                  }}
                >
                  03
                </Text>
              </Panel>
            }
          >
            <Paragraph>{fundContent}</Paragraph>
          </SectionCard>
        </View>
        <ImageIntroduction
          title={`${name}の資金調達事例`}
          description={`${name}がクラウドファンディングで事業資金を集め、成功している事例をご紹介します。`}
          gradient={Color.sunriseGradient}
          source={{
            uri: require('../../../assets/img/sunrise.jpg')
          }}
          style={{ marginTop: -16 }}
        >
          {successProjects.map((project, index) => {
            return (
              <SuccessProjectSectionCard
                key={project.id}
                number={`0${index + 1}`}
                data={project}
              />
            );
          })}
          <View style={{ alignItems: 'center', marginBottom: 40 }}>
            <Text
              style={{
                color: Color.white,
                fontSize: 17,
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              あなたもクラウドファンディングで
              <br />
              事業を始めてみませんか？
            </Text>
            <ArrowStream />
            <Text
              style={{
                color: Color.white,
                fontSize: 17,
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 24
              }}
            >
              まずは事業計画を立ててみましょう！
            </Text>
          </View>
        </ImageIntroduction>
        <View style={{ padding: '0 16px', marginTop: -24, zIndex: 1 }}>
          <SectionCard
            title="事業計画書PDFサンプル"
            panel={
              <Panel size={40} style={{ backgroundColor: Color.pink }}>
                <MaterialIcon
                  icon="insert_drive_file"
                  color={Color.white}
                  size={32}
                />
              </Panel>
            }
          >
            <Paragraph containerStyle={{ marginBottom: 16 }}>
              あなたの事業をフォームに入力していくだけで、事業計画書が完成します。
            </Paragraph>
            <Link to={`/proposal/new`}>
              <Button mini style={{ marginBottom: 16 }}>
                無料でかんたん事業計画
              </Button>
            </Link>
            <Text
              style={{
                color: Color.slate,
                fontSize: 13,
                textAlign: 'center'
              }}
            >
              会員登録は不要です。入力後に
              <br />
              <Text
                style={{
                  color: Color.slate,
                  fontSize: 13,
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                事業計画
              </Text>
              PDFがダウンロードできます。
            </Text>
          </SectionCard>
        </View>
        <FounderRecruit style={{ marginTop: -16 }} />
        <Footer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDetail);
