import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import moment from 'moment';

import {
  SHIPPING_INFO_LABELS,
  SHIPPING_INFO_VALIDATION_RULE
} from '../../../../constants/Validations';
import { validateAll } from '../../../../utils/Validation';
import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import Column from '../../../../components/Column';
import ContentContainer from '../../../../components/Page/ContentContainer';
import FooterContainer from '../../../../components/FooterContainer';
import Headline from '../../../../components/Headline';
import Hr from '../../../../components/Hr';
import PageContaineredHeader from '../../../../containers/page/PageContaineredHeader';
import Paragraph from '../../../../components/Paragraph';
import Prefecture from '../../../../models/Prefecture';
import ResponseDispatcher from '../../../../components/ResponseDispatcher';
import SelectFormField from '../../../../components/Form/SelectFormField';
import ShippingInfo from '../../../../models/ShippingInfo';
import ShippingInfoFormField from '../../../../containers/form/ShippingInfoFormField';
import Stepper from '../../../../components/Stepper';
import Style from '../../../../constants/Style';
import TextField from '../../../../components/Form/TextField';
import View from '../../../../components/View';
import actions from '../../../../actions';

class DreamProductInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      params: {
        kanjiSei: '',
        kanjiMei: '',
        furiganaSei: '',
        furiganaMei: '',
        gender: 'unselected',
        birthday: moment().format('YYYY-MM-DD'),
        phoneNumber: '',
        postalCode: '',
        prefecture: 'unselected',
        city: '',
        addressLine1: '',
        addressLine2: '',
        email: (props.user.myself && props.user.myself.email) || ''
      },
      selectedShippingInfoId: 'unselected'
    };
  }

  componentDidMount() {
    this.props.getDream(this.props.match.params.dreamId);

    this.props.getShippingInfos();
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.user.isGetMyselfLoading &&
      !nextProps.user.isGetMyselfLoading
    ) {
      const { myself } = nextProps.user;

      this.setState({
        params: {
          ...this.state.params,
          email: myself.email
        }
      });
    }

    return true;
  }

  renderShippingSelect() {
    if (this.props.shippingInfo.list.length < 1) {
      return;
    }

    return (
      <View>
        <SelectFormField
          label="お届け先"
          name="shippingInfo"
          items={[
            { label: '新しい届け先', value: 'unselected' },
            ...this.props.shippingInfo.list
              .map(id => this.props.shippingInfo.hash[id])
              .map(info => ({
                label: `${info.kanjiSei} ${info.kanjiMei}|${Prefecture.getLabel(
                  info.prefecture
                )} ${info.city}`,
                value: info.id
              }))
          ]}
          value={this.state.selectedShippingInfoId}
          onChangeValue={selectedShippingInfoId => {
            const shippingInfo = this.props.shippingInfo.hash[
              selectedShippingInfoId
            ];

            this.setState({
              selectedShippingInfoId,
              params: {
                ...this.state.params,
                ...new ShippingInfo(shippingInfo).fields
              }
            });
          }}
          style={{ marginBottom: 16 }}
        />
        <Hr style={{ marginBottom: 16 }} />
      </View>
    );
  }

  render() {
    const errorMessage = validateAll({
      labels: SHIPPING_INFO_LABELS,
      params: this.state.params,
      rules: SHIPPING_INFO_VALIDATION_RULE
    });

    if (this.props.shippingInfo.isGetLoading) {
      return null;
    }

    const to = `/dream/${this.props.match.params.dreamId}/product/${
      this.props.match.params.productId
    }/confirm/${this.state.selectedShippingInfoId}`;

    return (
      <View>
        <PageContaineredHeader />
        <ContentContainer>
          <Row>
            <Column offset={{ xs: 1 }} xs={10}>
              <Stepper
                steps={['コースの選択', '必要情報の入力', '購入', '完了']}
                activeIndex={1}
                style={{ padding: '24px 8px 16px 8px' }}
              />
              <View style={{ padding: '0 24px', marginBottom: 24 }}>
                <Headline style={{ marginBottom: 16 }}>
                  2. 必要情報の入力
                </Headline>
                <Paragraph>
                  購入後の連絡用メールアドレスと、お届け先の住所を入力してください。
                </Paragraph>
              </View>
              <View
                style={{
                  borderRadius: 8,
                  padding: 16,
                  ...Style.shadow.large,
                  margin: '0 16px 24px 16px'
                }}
              >
                <Headline
                  size={2}
                  style={{ color: Color.lightSlate, marginBottom: 16 }}
                >
                  メールアドレス
                </Headline>
                <TextField
                  value={this.state.params.email}
                  onChange={email => {
                    this.setState({
                      params: {
                        ...this.state.params,
                        email
                      }
                    });
                  }}
                />
              </View>
              <View
                style={{
                  borderRadius: 8,
                  padding: 16,
                  ...Style.shadow.large,
                  margin: '0 16px 24px 16px'
                }}
              >
                <Headline
                  size={2}
                  style={{ color: Color.lightSlate, marginBottom: 24 }}
                >
                  お届先情報
                </Headline>
                {this.renderShippingSelect()}
                <ShippingInfoFormField
                  params={this.state.params}
                  onChangeParams={state =>
                    this.setState({
                      params: { ...this.state.params, ...state }
                    })
                  }
                  rules={SHIPPING_INFO_VALIDATION_RULE}
                />
              </View>
              <View style={{ padding: '0 16px', marginBottom: 32 }}>
                <Link
                  to={to}
                  onClick={e => {
                    e.preventDefault();

                    if (errorMessage) {
                      alert(errorMessage);

                      return;
                    }

                    if (
                      this.props.shippingInfo.list.length < 1 ||
                      this.state.selectedShippingInfoId === 'unselected'
                    ) {
                      this.props.createShippingInfo(this.state.params);
                    } else {
                      this.props.updateShippingInfo(
                        this.state.selectedShippingInfoId,
                        this.state.params
                      );
                    }
                  }}
                >
                  <Button
                    disable={
                      this.props.shippingInfo.isCreateLoading ||
                      this.props.shippingInfo.isUpdateLoading
                    }
                  >
                    お届け先を確定する
                  </Button>
                </Link>
                <ResponseDispatcher
                  isLoading={this.props.shippingInfo.isCreateLoading}
                  isFailed={this.props.shippingInfo.isCreateFailed}
                  onComplete={() => {
                    const shippingInfoId = this.props.shippingInfo.list[
                      this.props.shippingInfo.list.length - 1
                    ];

                    const to = `/dream/${
                      this.props.match.params.dreamId
                    }/product/${
                      this.props.match.params.productId
                    }/confirm/${shippingInfoId}`;

                    this.props.history.push(to);
                  }}
                  onError={() => {
                    const error = this.props.shippingInfo.createError;

                    alert(error.message);

                    this.props.recoverCreateShippingInfo();
                  }}
                />
                <ResponseDispatcher
                  isLoading={this.props.shippingInfo.isUpdateLoading}
                  isFailed={this.props.shippingInfo.isUpdateFailed}
                  onComplete={() => {
                    this.props.history.push(to);
                  }}
                  onError={() => {
                    const error = this.props.shippingInfo.updateError;

                    alert(error.message);

                    this.props.recoverUpdateShippingInfo();
                  }}
                />
              </View>
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamProductInput);
