import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class MarketingRealityValuesFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.product}
          name="product"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={product => {
            onChangeParams({
              product
            });
          }}
          label="(1) 商品・サービス"
          errorMessage={
            rules &&
            validate({
              label: '商品・サービス',
              value: params.product,
              rule: rules.product
            })
          }
          limitLength={rules.product.maxLength}
          helpText="価値の定義で定めた4つの価値の中で、重要度の高いものを「商品・サービス」として具体的に記述しましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.support}
          name="support"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={support => {
            onChangeParams({
              support
            });
          }}
          label="(2) 付加価値・サポート"
          errorMessage={
            rules &&
            validate({
              label: '付加価値・サポート',
              value: params.support,
              rule: rules.support
            })
          }
          limitLength={rules.support.maxLength}
          helpText="価値の定義で定めた4つの価値の中で、重要度の高いものを「付加価値・サポート」として具体的に記述しましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.communication}
          name="communication"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={communication => {
            onChangeParams({
              communication
            });
          }}
          label="(3) コミュニケーション"
          errorMessage={
            rules &&
            validate({
              label: 'コミュニケーション',
              value: params.communication,
              rule: rules.communication
            })
          }
          limitLength={rules.communication.maxLength}
          helpText="価値の定義で定めた4つの価値の中で、重要度の高いものを「コミュニケーション」として具体的に記述しましょう。広告のテーマや、タグライン、キャラクターを作るなど。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.concept}
          name="concept"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={concept => {
            onChangeParams({
              concept
            });
          }}
          label="(4) 広告コンセプト・チームの文化"
          errorMessage={
            rules &&
            validate({
              label: '広告コンセプト・チームの文化',
              value: params.concept,
              rule: rules.concept
            })
          }
          limitLength={rules.concept.maxLength}
          helpText="創造した価値、定義した価値について、広告コンセプトや、チームの文化「かけ声」としてまとめ、記述しましょう。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingRealityValuesFormField);
