import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import ContentContainer from '../../../components/Page/ContentContainer';
import FooterContainer from '../../../components/FooterContainer';
import Markdown from '../../../components/Markdown';
import PageHeader from '../../../containers/page/PageContaineredHeader';
import View from '../../../components/View';
import actions from '../../../actions';

class DocumentDetail extends React.Component {
  componentDidMount() {
    this.props.getDocumentHistories({
      type: this.props.match.params.documentType
    });
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.location !== nextProps.location) {
      this.props.getDocumentHistories({
        type: nextProps.match.params.documentType
      });
    }

    return true;
  }

  render() {
    const documents = this.props.documentHistory.list
      .map(id => this.props.documentHistory.hash[id])
      .filter(item => item.type === this.props.match.params.documentType);

    if (documents.length < 1) {
      return null;
    }

    return (
      <View>
        <PageHeader />
        <ContentContainer>
          <Markdown style={{ padding: 16 }}>{documents[0].content}</Markdown>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentDetail);
