export default class Job {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      iconImage: state.iconImage,
      name: state.name,
      url: state.url
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
