import React from 'react';
import numeral from 'numeral';

import Color from '../../constants/Color';
import Headline from '../Headline';
import Text from '../Text';
import View from '../View';

export default function AmountCardBody({ amount, title, unit, style = {} }) {
  return (
    <View
      style={{
        alignItems: 'center',
        ...style
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'baseline',
          marginBottom: 8
        }}
      >
        <Text
          style={{
            color: Color.slate,
            fontWeight: 'bold',
            fontSize: 32
          }}
        >
          {numeral(amount).format('0,0')}
        </Text>
        <Text
          style={{
            color: Color.slate,
            fontWeight: 'bold',
            fontSize: 20
          }}
        >
          {unit}
        </Text>
      </View>
      <Headline size={3} style={{ color: Color.lightSlate, fontSize: 15 }}>
        {title}
      </Headline>
    </View>
  );
}
