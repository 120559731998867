import React from 'react';

import Color from '../../constants/Color';
import View from '../View';

export default function Section({ children, sideline = false, style = {} }) {
  return (
    <View
      style={{
        backgroundColor: Color.white,
        borderRight: sideline ? `1px solid ${Color.lightGrey}` : '',
        borderLeft: sideline ? `1px solid ${Color.lightGrey}` : '',
        borderTop: `1px solid ${Color.lightGrey}`,
        ...style
      }}
    >
      {children}
    </View>
  );
}
