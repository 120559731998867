import React from 'react';
import moment from 'moment';

import Color from '../../constants/Color';
import FormField from './FormField';
import Paragraph from '../Paragraph';
import Text from '../Text';
import TextArea from './TextArea';
import TextField from './TextField';
import View from '../View';

export default class TextFormField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isStartInput: false
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ isStartInput: true });
    }

    return true;
  }

  render() {
    const {
      label,
      labelWidth = 70,
      value,
      multiline = false,
      direction = 'row',
      verification = false,
      errorMessage,
      helpText,
      onChangeText = () => {},
      staticControl,
      style = {},
      limitLength = null,
      ...rest
    } = this.props;

    let formValue = value;

    if (rest.type === 'datetime-local') {
      formValue = moment(value).format('YYYY-MM-DDTHH:mm:ss');
    }

    return (
      <View style={{ ...style }}>
        <FormField
          label={label}
          labelWidth={labelWidth}
          direction={direction}
          verification={verification}
          errorMessage={errorMessage}
          staticControl={staticControl}
        >
          {staticControl ? (
            <View>
              {rest.type === 'text' ||
              rest.type === 'date' ||
              rest.type === 'datetime-local' ? (
                formValue.split('\n').map((line, lineIndex) => (
                  <Paragraph
                    key={`text-line-${lineIndex}`}
                    style={{ fontWeight: 'bold' }}
                  >
                    {line}
                  </Paragraph>
                ))
              ) : (
                <Paragraph style={{ fontWeight: 'bold' }}>
                  {formValue}
                </Paragraph>
              )}
            </View>
          ) : (
            <View>
              {multiline ? (
                <TextArea
                  value={formValue}
                  onChange={text => {
                    let changedValue = text;

                    if (rest.type === 'datetime-local') {
                      changedValue = moment(changedValue).toDate();
                    }

                    if (rest.type === 'number') {
                      changedValue = Number(changedValue);
                    }

                    onChangeText(changedValue);
                  }}
                  limitLength={limitLength}
                  {...rest}
                />
              ) : (
                <TextField
                  value={formValue}
                  onChange={text => {
                    let changedValue = text;

                    if (rest.type === 'datetime-local') {
                      changedValue = moment(changedValue).toDate();
                    }

                    if (rest.type === 'number') {
                      changedValue = Number(changedValue);
                    }

                    onChangeText(changedValue);
                  }}
                  {...rest}
                />
              )}
            </View>
          )}
        </FormField>
        {this.state.isStartInput && errorMessage && (
          <Text
            style={{
              marginTop: 4,
              fontSize: 11,
              color: errorMessage ? Color.red : Color.lightGrey,
              paddingLeft: direction === 'row' ? labelWidth : 0
            }}
          >
            {this.state.isStartInput && errorMessage}
          </Text>
        )}
        {helpText && (
          <Text
            style={{
              marginTop: 4,
              fontSize: 11,
              color: Color.grey,
              paddingLeft: direction === 'row' ? labelWidth : 0
            }}
          >
            {helpText}
          </Text>
        )}
      </View>
    );
  }
}
