import {
  AccountIconSvg,
  CashIconSvg,
  CellphoneIconSvg,
  CubeIconSvg,
  DrawingBoxIconSvg,
  FileIconSvg,
  OfficeBuildingIconSvg,
  StoreIconSvg
} from '../utils/MaterialIconSvg';

export default class BusinessModelType {
  static getItems(withDefault = true) {
    const items = [
      {
        label: '未選択',
        icon: null,
        value: 'unselected'
      },
      {
        label: '人間',
        icon: AccountIconSvg,
        value: 'person'
      },
      {
        label: '会社',
        icon: OfficeBuildingIconSvg,
        value: 'company'
      },
      {
        label: 'お金',
        icon: CashIconSvg,
        value: 'money'
      },
      {
        label: 'もの',
        icon: DrawingBoxIconSvg,
        value: 'object'
      },
      {
        label: 'データ',
        icon: FileIconSvg,
        value: 'data'
      },
      {
        label: 'スマホ',
        icon: CellphoneIconSvg,
        value: 'device'
      },
      {
        label: '店舗',
        icon: StoreIconSvg,
        value: 'store'
      },
      {
        label: 'その他',
        icon: CubeIconSvg,
        vlaue: 'other'
      }
    ];

    if (withDefault) {
      items.unshift({ label: '未選択', value: 'unselected', isDefault: true });
    }

    return items;
  }

  static getItem(value) {
    return BusinessModelType.getItems().find(item => item.value === value);
  }

  static getLabel(value) {
    return BusinessModelType.getItems().find(item => item.value === value)
      .label;
  }
}
