import React from 'react';
import numeral from 'numeral';

import Color from '../../constants/Color';
import Text from '../Text';
import View from '../View';

export default function SupporterProgress({ supporter, style = {} }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...style
      }}
    >
      <Text
        style={{
          color: Color.lightSlate,
          fontWeight: 'bold',
          fontSize: 13
        }}
      >
        あなたの支援額
      </Text>
      <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
        <Text style={{ color: Color.slate, fontWeight: 'bold', fontSize: 17 }}>
          {numeral(supporter.total).format('0,0')}
        </Text>
        <Text style={{ color: Color.slate, fontSize: 13 }}>円</Text>
      </View>
    </View>
  );
}
