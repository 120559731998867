import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  PROPOSAL_METADATA_LABELS,
  PROPOSAL_METADATA_VALIDATION_RULE
} from '../../constants/Validations';
import { validate } from '../../utils/Validation';
import Button from '../../components/Button';
import Color from '../../constants/Color';
import MaterialIcon from '../../utils/MaterialIcon';
import Panel from '../../components/Panel';
import Paragraph from '../../components/Paragraph';
import SectionCard from '../../components/Card/SectionCard';
import TextFormField from '../../components/Form/TextFormField';
import View from '../../components/View';
import actions from '../../actions';

class PasswordForm extends React.Component {
  state = {
    password: ''
  };

  render() {
    const { description, passwordType } = this.props;

    const key = `${passwordType}Password`;

    return (
      <View style={{ padding: '16px 16px 0px 16px', marginBottom: 24 }}>
        <SectionCard
          title="パスワード"
          panel={
            <Panel size={40} style={{ backgroundColor: Color.pink }}>
              <MaterialIcon icon="lock" color={Color.white} size={24} />
            </Panel>
          }
          style={{ marginBottom: 24 }}
        >
          <Paragraph onerow containerStyle={{ marginBottom: 16 }}>
            {description}
          </Paragraph>
          <TextFormField
            value={this.state.password}
            name="viewPassword"
            direction="column"
            type="password"
            verification
            onChangeText={password => {
              this.setState({
                password
              });
            }}
            label="パスワード"
            errorMessage={validate({
              label: PROPOSAL_METADATA_LABELS[key],
              value: this.state.password,
              rule: PROPOSAL_METADATA_VALIDATION_RULE[key]
            })}
            limitLength={PROPOSAL_METADATA_VALIDATION_RULE[key].maxLength}
            style={{ marginBottom: 16 }}
          />
          <Button
            disable={this.props.isLoading}
            onClick={() => this.props.onSubmit(this.state.password)}
          >
            送信する
          </Button>
        </SectionCard>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordForm);
