import React from 'react';

import Color from '../../constants/Color';
import Image from '../Image';
import MaterialIcon from '../../utils/MaterialIcon';
import View from '../View';

export default function UserIcon({ source, size = 24, style = {} }) {
  if (!source) {
    return (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: size / 2,
          width: size,
          height: size,
          backgroundColor: Color.lightGrey,
          ...style
        }}
      >
        <MaterialIcon icon="person" size={size * 0.8} />
      </View>
    );
  }

  return (
    <Image
      source={source}
      style={{ borderRadius: size / 2, width: size, height: size, ...style }}
    />
  );
}
