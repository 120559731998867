import * as types from '../constants/ActionTypes';

export function updateMarketingFormParams({
  marketing,
  viewPassword,
  editPassword
}) {
  return {
    type: types.UPDATE_MARKETING_FORM_PARAMS,
    state: {
      params: {
        marketing,
        viewPassword,
        editPassword
      }
    }
  };
}
