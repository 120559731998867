import React from 'react';

import { toNumeral } from '../../utils/Format';
import Color from '../../constants/Color';
import Headline from '../Headline';
import Label from '../Form/Label';
import Panel from '../Panel';
import Paragraph from '../Paragraph';
import SectionCard from '../Card/SectionCard';
import Style from '../../constants/Style';
import Text from '../Text';
import View from '../View';

export default function({
  number,
  data: { title, rewards, supporterCount, fundAmount, detail }
}) {
  const minPrice = rewards.reduce((acc, reward) => {
    return Math.min(reward.price, acc);
  }, rewards[0].price);

  const maxPrice = rewards.reduce((acc, reward) => {
    return Math.min(reward.price, acc);
  }, rewards[0].price);

  return (
    <SectionCard
      title={title}
      panel={
        <Panel size={40} style={{ backgroundColor: Color.pink }}>
          <Text
            style={{ color: Color.white, fontWeight: 'bold', fontSize: 24 }}
          >
            {number}
          </Text>
        </Panel>
      }
      style={{ padding: '0 16px', marginBottom: 24 }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 8
        }}
      >
        <View>
          <Text
            style={{
              color: Color.lightSlate,
              fontSize: 10,
              fontWeight: 'bold'
            }}
          >
            コース単価
          </Text>
          <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
            <Text
              style={{
                color: Color.pink,
                fontSize: 14,
                fontWeight: 'bold'
              }}
            >
              {toNumeral(minPrice)}
            </Text>
            <Text
              style={{
                color: Color.slate,
                fontSize: 10,
                fontWeight: 'bold'
              }}
            >
              円〜
            </Text>
            <Text
              style={{
                color: Color.pink,
                fontSize: 14,
                fontWeight: 'bold'
              }}
            >
              {toNumeral(maxPrice)}
            </Text>
            <Text
              style={{
                color: Color.slate,
                fontSize: 10,
                fontWeight: 'bold'
              }}
            >
              円
            </Text>
          </View>
        </View>
        <View>
          <Text
            style={{
              color: Color.lightSlate,
              fontSize: 10,
              fontWeight: 'bold'
            }}
          >
            支援者数
          </Text>
          <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
            <Text
              style={{
                color: Color.pink,
                fontSize: 14,
                fontWeight: 'bold'
              }}
            >
              {toNumeral(supporterCount)}
            </Text>
            <Text
              style={{
                color: Color.slate,
                fontSize: 10,
                fontWeight: 'bold'
              }}
            >
              人以上
            </Text>
          </View>
        </View>
        <View>
          <Text
            style={{
              color: Color.lightSlate,
              fontSize: 10,
              fontWeight: 'bold'
            }}
          >
            達成金額
          </Text>
          <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
            <Text
              style={{
                color: Color.pink,
                fontSize: 14,
                fontWeight: 'bold'
              }}
            >
              {toNumeral(fundAmount)}
            </Text>
            <Text
              style={{
                color: Color.slate,
                fontSize: 10,
                fontWeight: 'bold'
              }}
            >
              万円以上
            </Text>
          </View>
        </View>
      </View>
      <Paragraph style={{ marginBottom: 16 }}>{detail}</Paragraph>
      <Headline size={3} style={{ marginBottom: 8 }}>
        コース一覧
      </Headline>
      {rewards.map((reward, index) => {
        return (
          <View
            key={index}
            style={{ borderRadius: 8, padding: 8, ...Style.shadow.medium }}
          >
            <Label style={{ marginRight: 8, width: 160, marginBottom: 4 }}>
              {reward.title}
            </Label>
            <Paragraph style={{ marginBottom: 8 }}>{reward.detail}</Paragraph>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'baseline',
                justifyContent: 'space-between'
              }}
            >
              <View>
                <Text
                  style={{
                    color: Color.lightSlate,
                    fontSize: 10,
                    fontWeight: 'bold'
                  }}
                >
                  単価
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                  <Text
                    style={{
                      color: Color.pink,
                      fontSize: 14,
                      fontWeight: 'bold'
                    }}
                  >
                    {toNumeral(reward.price)}
                  </Text>
                  <Text
                    style={{
                      color: Color.slate,
                      fontSize: 10,
                      fontWeight: 'bold'
                    }}
                  >
                    円
                  </Text>
                </View>
              </View>
              <View>
                <Text
                  style={{
                    color: Color.lightSlate,
                    fontSize: 10,
                    fontWeight: 'bold'
                  }}
                >
                  支援者数
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                  <Text
                    style={{
                      color: Color.pink,
                      fontSize: 14,
                      fontWeight: 'bold'
                    }}
                  >
                    {toNumeral(reward.supporterCount)}
                  </Text>
                  <Text
                    style={{
                      color: Color.slate,
                      fontSize: 10,
                      fontWeight: 'bold'
                    }}
                  >
                    人以上
                  </Text>
                </View>
              </View>
              <View>
                <Text
                  style={{
                    color: Color.lightSlate,
                    fontSize: 10,
                    fontWeight: 'bold'
                  }}
                >
                  合計
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
                  <Text
                    style={{
                      color: Color.pink,
                      fontSize: 14,
                      fontWeight: 'bold'
                    }}
                  >
                    {toNumeral(reward.price * reward.supporterCount)}
                  </Text>
                  <Text
                    style={{
                      color: Color.slate,
                      fontSize: 10,
                      fontWeight: 'bold'
                    }}
                  >
                    円以上
                  </Text>
                </View>
              </View>
            </View>
          </View>
        );
      })}
    </SectionCard>
  );
}
