export default class Model {
  set params(obj) {
    this._params = Object.keys(obj).reduce((acc, key) => {
      if (obj[key] === undefined) {
        return acc;
      }

      return {
        ...acc,
        [key]: obj[key]
      };
    }, {});
  }

  get params() {
    return this._params;
  }
}
