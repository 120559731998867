import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import EmptyText from '../../../../components/EmptyText';
import Headline from '../../../../components/Headline';
import PageHeader from '../../../../containers/page/PageHeader';
import Section from '../../../../components/Section/Section';
import SectionTransitItem from '../../../../components/Section/SectionTransitItem';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypagePaymentInfoIndex extends React.Component {
  componentDidMount() {
    this.props.getMyselfUser();
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <View style={{ paddingTop: 32 }}>
          <Headline
            size={3}
            style={{ fontSize: 15, marginLeft: 16, marginBottom: 16 }}
          >
            カード情報
          </Headline>
          <View style={{ marginBottom: 40 }}>
            {myself.paymentInfos.length < 1 ? (
              <EmptyText style={{ paddingLeft: 16 }}>
                カード情報がありません。
              </EmptyText>
            ) : (
              <Section>
                {myself.paymentInfos.map(info => {
                  const isDefault = info.id === myself.defaultPaymentInfoId;

                  return (
                    <Link to={`/mypage/paymentInfo/${info.id}`}>
                      <SectionTransitItem
                        title={`${info.source.brand}${
                          isDefault ? ' [デフォルト]' : ''
                        }`}
                        content={`${info.source.expYear}/${
                          info.source.expMonth
                        } | **** ${info.source.last4}`}
                      />
                    </Link>
                  );
                })}
              </Section>
            )}
          </View>
          <View style={{ padding: '0 16px' }}>
            <Link to="/mypage">
              <Button variant="flat">戻る</Button>
            </Link>
          </View>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypagePaymentInfoIndex);
