import { getCollection } from '../utils/Firebase';
import ActivityHistory from '../models/ActivityHistory';
import * as types from '../constants/ActionTypes';

// get activity histories
function fetchGetActivityHistories({ reset = false }) {
  return {
    type: types.FETCH_GET_ACTIVITY_HISTORIES,
    state: {
      reset
    }
  };
}

function fetchGetActivityHistoriesSuccess(list, reset = false) {
  return {
    type: types.FETCH_GET_ACTIVITY_HISTORIES_SUCCESS,
    state: {
      reset,
      list
    }
  };
}

function fetchGetActivityHistoriesFailed(error) {
  return {
    type: types.FETCH_GET_ACTIVITY_HISTORIES_FAILED,
    error
  };
}

export function recoverGetActivityHistories() {
  return {
    type: types.FETCH_GET_ACTIVITY_HISTORIES_RECOVER
  };
}

export function getActivityHistories(
  options = { reset: false, lastItem: null }
) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchGetActivityHistories({ reset: options.reset }));

    const activityHistories = getCollection('consumers')
      .doc(auth.uid)
      .collection('activityHistories');

    let query = activityHistories.orderBy('createdAt', 'desc');

    if (options.lastItem) {
      query = query.startAfter(options.lastItem.createdAt);
    }

    query = query.limit(10);

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(doc => {
            const { type, data, updatedAt, createdAt } = doc.data();

            return ActivityHistory.join({
              id: doc.id,
              type,
              data,
              updatedAt,
              createdAt
            });
          })
        );

        return dispatch(fetchGetActivityHistoriesSuccess(list, options.reset));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetActivityHistoriesFailed(error));
        }
      });
  };
}
