import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';

export default function Label({ children, style = {} }) {
  return (
    <Text
      style={{
        color: Color.lightSlate,
        fontWeight: 'bold',
        fontSize: 13,
        ...style
      }}
    >
      {children}
    </Text>
  );
}
