import React from 'react';

import Button from '../Button';
import View from '../View';

export default class FileInput extends React.Component {
  state = {
    imageId: 0
  };

  render() {
    const { onChangeFile } = this.props;

    return (
      <View>
        <Button
          tiny
          onClick={() => {
            this.refs.fileInput.click();
          }}
        >
          アップロード
        </Button>
        <input
          ref="fileInput"
          key={`file-upload-${this.state.imageId}`}
          onChange={e => {
            const reader = new FileReader();

            const file = e.target.files[0];

            reader.onload = fileEvent => {
              const data = fileEvent.target.result;

              onChangeFile({ file, data });
            };

            reader.readAsDataURL(file);
          }}
          style={{ display: 'none' }}
          type="file"
        />
      </View>
    );
  }
}
