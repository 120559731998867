import Icon from 'material-icons-react';

import React from 'react';

export default class MaterialIcon extends React.Component {
  render() {
    return (
      <Icon
        key={`${this.props.color}${this.props.icon}${this.props.size}`}
        {...this.props}
      />
    );
  }
}
