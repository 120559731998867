import React from 'react';

import Color from '../../constants/Color';
import Image from '../../components/Image';
import Style from '../../constants/Style';
import Text from '../../components/Text';
import View from '../../components/View';

export default function FounderRecruitTargetItem({
  source,
  label,
  size = 72,
  fontSize = 11,
  style = {}
}) {
  return (
    <View style={{ ...style }}>
      <View>
        <View
          style={{
            width: size,
            height: size,
            borderRadius: size / 2,
            backgroundColor: Color.white,
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 8,
            ...Style.shadow.large
          }}
        >
          <Image
            source={source}
            style={{
              width: size - 8,
              height: size - 8,
              borderRadius: size / 2
            }}
          />
        </View>
        <Text style={{ color: Color.white, fontSize, textAlign: 'center' }}>
          {label}
        </Text>
      </View>
    </View>
  );
}
