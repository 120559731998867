import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { PROPOSAL_ARTICLES } from '../../../constants/Articles/Proposal';
import ArrowStream from '../../../components/Icons/ArrowStream';
import ArticleTemplate from '../../../components/Article/ArticleTemplate';
import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import Container from '../../../components/Container';
import ContentContainer from '../../../components/Page/ContentContainer';
import FooterContainer from '../../../components/FooterContainer';
import Headline from '../../../components/Headline';
import PageContaineredHeader from '../../../containers/page/PageContaineredHeader';
import Paragraph from '../../../components/Paragraph';
import Text from '../../../components/Text';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalIndex extends React.Component {
  render() {
    return (
      <View>
        <PageContaineredHeader />
        <ContentContainer>
          <Container>
            <Row style={{ marginTop: 64 }}>
              <Column>
                <View style={{ marginBottom: 24 }}>
                  <Headline style={{ fontSize: 32, marginBottom: 16 }}>
                    事業計画書
                  </Headline>
                  <Paragraph
                    style={{ fontSize: 17 }}
                    containerStyle={{ marginBottom: 40 }}
                  >
                    事業計画書は、あなたの事業の要となる書類です。あなたの頭の中の事業を各項目に対して記述していくだけで、事業の姿をより明確に、はっきりとさせていくことができます。
                  </Paragraph>
                  <Headline size={2} style={{ fontSize: 24, marginBottom: 16 }}>
                    事業計画書テンプレート
                  </Headline>
                  <Paragraph
                    style={{ fontSize: 17 }}
                    containerStyle={{ marginBottom: 40 }}
                  >
                    7つの観点から、あなたの事業の輪郭をはっきりさせていきましょう。
                    7つの項目は、自己紹介、導入、仕様、業務プロセス、経営環境、特徴、営利で構成されます。
                    このページは、以下の事業計画書を構成する7つの要素について解説していきます。
                  </Paragraph>
                </View>
              </Column>
            </Row>
            <Row style={{ marginBottom: 64 }}>
              <Column offset={{ xs: 1 }} xs={10}>
                <Paragraph
                  style={{ fontSize: 17 }}
                  containerStyle={{ marginBottom: 40 }}
                >
                  すぐに事業計画書を作りたい方は、下記のページからさっそく事業計画書を作ってみましょう。
                  最後まで入力すると、上記の画像のような事業計画書のPDFファイルがダウンロードできます。
                </Paragraph>
                <Link to="/proposal/new">
                  <Button>事業計画書をつくる</Button>
                </Link>
              </Column>
            </Row>
            <Row>
              <Column>
                <View>
                  {PROPOSAL_ARTICLES.map(article => {
                    return (
                      <ArticleTemplate
                        title={article.title}
                        number={article.number}
                        description={article.description}
                        sections={article.sections}
                        style={{ marginBottom: 32 }}
                      />
                    );
                  })}
                </View>
              </Column>
            </Row>
            <Row>
              <Column offset={{ xs: 1 }} xs={10}>
                <View style={{ alignItems: 'center' }}>
                  <ArrowStream
                    color={Color.slate}
                    style={{ marginBottom: 40 }}
                  />
                  <Text
                    style={{
                      color: Color.slate,
                      fontSize: 24,
                      fontWeight: 'bold',
                      marginBottom: 32
                    }}
                  >
                    さっそく事業計画書を作ってみましょう！
                  </Text>
                </View>
                <Link to="/proposal/new" style={{ marginBottom: 24 }}>
                  <Button>事業計画書をつくる</Button>
                </Link>
                <View style={{ alignItems: 'center' }}>
                  <Text
                    style={{
                      color: Color.slate,
                      fontSize: 17,
                      marginBottom: 32,
                      textAlign: 'center'
                    }}
                  >
                    会員登録は不要です。入力後に
                    <br />
                    <Text
                      style={{
                        color: Color.slate,
                        fontSize: 17,
                        fontWeight: 'bold',
                        textAlign: 'center'
                      }}
                    >
                      事業計画
                    </Text>
                    PDFがダウンロードできます。
                  </Text>
                </View>
              </Column>
            </Row>
          </Container>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalIndex);
