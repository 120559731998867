import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import BusinessCategory from '../../../models/BusinessCategory';
import ColorFormField from '../../../components/Form/ColorFormField';
import ImageFormField from '../../../components/Form/ImageFormField';
import SelectFormField from '../../../components/Form/SelectFormField';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class MarketingBasicInfoFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.title}
          name="title"
          direction="column"
          verification
          rows={1}
          multiline
          onChangeText={title => {
            onChangeParams({
              title
            });
          }}
          label="(1) 事業タイトル"
          errorMessage={
            rules &&
            validate({
              label: '事業タイトル',
              value: params.title,
              rule: rules.title
            })
          }
          limitLength={rules.title.maxLength}
          helpText="事業のタイトルを短く簡素に入力してください。"
          style={{ marginBottom: 16 }}
        />
        <SelectFormField
          staticControl={!Boolean(rules)}
          label="(2) 事業カテゴリ"
          direction="column"
          verification
          name="businessCategoryId"
          items={BusinessCategory.getItems()}
          value={params.businessCategoryId}
          errorMessage={
            rules &&
            validate({
              label: '事業カテゴリ',
              value: params.businessCategoryId,
              rule: rules.businessCategoryId
            })
          }
          onChangeValue={businessCategoryId =>
            onChangeParams({ businessCategoryId })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.name}
          name="name"
          direction="column"
          verification
          rows={1}
          multiline
          onChangeText={name => {
            onChangeParams({
              name
            });
          }}
          label="(3) 表示名を入力してください。"
          errorMessage={
            rules &&
            validate({
              label: '表示名を入力してください。',
              value: params.name,
              rule: rules.name
            })
          }
          limitLength={rules.name.maxLength}
          helpText="事業計画書に載せるあなたの名前を入力してください。芸名やハンドルネームでも構いません。"
          style={{ marginBottom: 16 }}
        />
        <ImageFormField
          staticControl={!Boolean(rules)}
          value={params.profileImage}
          name="profileImage"
          direction="column"
          verification
          onChangeImage={({ file, data }) => {
            onChangeParams({
              profileImage: {
                file,
                data
              }
            });
          }}
          label="(4) プロフィール画像を入力してください。"
          errorMessage={
            rules &&
            validate({
              label: 'プロフィール画像を入力してください。',
              value: params.profileImage,
              rule: rules.profileImage
            })
          }
          helpText="事業計画書に載せるあなたの写真を掲載してください。笑顔で顔のわかるものをアップロードしましょう。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.birthday}
          name="birthday"
          direction="column"
          verification
          rows={1}
          multiline
          onChangeText={birthday => {
            onChangeParams({
              birthday
            });
          }}
          label="(5) 生年月日"
          errorMessage={
            rules &&
            validate({
              label: '生年月日',
              value: params.birthday,
              rule: rules.birthday
            })
          }
          limitLength={rules.birthday.maxLength}
          helpText="生年月日を入力してください。「1989年5月5日生」など。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.job}
          name="job"
          direction="column"
          verification
          rows={1}
          multiline
          onChangeText={job => {
            onChangeParams({
              job
            });
          }}
          label="(6) 職業"
          errorMessage={
            rules &&
            validate({
              label: '職業',
              value: params.job,
              rule: rules.job
            })
          }
          limitLength={rules.job.maxLength}
          helpText="あなたの職業を入力してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.biography}
          name="biography"
          direction="column"
          verification
          rows={3}
          multiline
          onChangeText={biography => {
            onChangeParams({
              biography
            });
          }}
          label="(7) 略歴"
          errorMessage={
            rules &&
            validate({
              label: '略歴',
              value: params.biography,
              rule: rules.biography
            })
          }
          limitLength={rules.biography.maxLength}
          helpText="学歴、職歴などを簡潔に入力してください。事業を活かせる経歴あることをアピールしましょう。"
          style={{ marginBottom: 16 }}
        />
        <ColorFormField
          staticControl={!Boolean(rules)}
          value={params.primaryColor}
          name="primaryColor"
          direction="column"
          verification
          onChangeText={primaryColor => {
            onChangeParams({
              primaryColor
            });
          }}
          label="(8) メインカラー"
          errorMessage={
            rules &&
            validate({
              label: 'メインカラー',
              value: params.primaryColor,
              rule: rules.primaryColor
            })
          }
          limitLength={rules.primaryColor.maxLength}
          helpText="メインカラーを設定してください。"
          style={{ marginBottom: 16 }}
        />
        <ColorFormField
          staticControl={!Boolean(rules)}
          value={params.secondaryColor}
          name="secondaryColor"
          direction="column"
          verification
          onChangeText={secondaryColor => {
            onChangeParams({
              secondaryColor
            });
          }}
          label="(9) サブカラー"
          errorMessage={
            rules &&
            validate({
              label: 'サブカラー',
              value: params.secondaryColor,
              rule: rules.secondaryColor
            })
          }
          limitLength={rules.secondaryColor.maxLength}
          helpText="サブカラーを設定してください。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketingBasicInfoFormField);
