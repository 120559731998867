import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import CardPrimitive from '../../components/Card/CardPrimitive';
import Color from '../../constants/Color';
import EdgedUserIcon from '../../components/Icons/EdgedUserIcon';
import Headline from '../../components/Headline';
import Section from '../../components/Section/Section';
import SectionTransitItem from '../../components/Section/SectionTransitItem';
import SignOutButton from '../auth/SignOutButton';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class MypageIndex extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getMyselfUser();

    this.props.getPatronContracts({ reset: true });
  }

  render() {
    const { style = {} } = this.props;

    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View style={{ paddingTop: 80, ...style }}>
        <CardPrimitive style={{ backgroundColor: Color.smokeWhite }}>
          <View style={{ marginBottom: 24 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginTop: -80,
                marginBottom: 16
              }}
            >
              <View style={{ flex: 1 }} />
              <EdgedUserIcon
                style={{ flex: 2 }}
                size={160}
                source={myself.iconImage}
              />
              <View style={{ flex: 1, alignItems: 'center' }}>
                <SignOutButton />
              </View>
            </View>
          </View>
          <Headline
            size={3}
            style={{ fontSize: 15, marginLeft: 16, marginBottom: 8 }}
          >
            アカウント情報
          </Headline>
          <Section style={{ marginBottom: 40 }}>
            <Link to="/mypage/account/edit">
              <SectionTransitItem title="プロフィール設定" />
            </Link>
          </Section>
          <View style={{ marginBottom: 40 }}>
            <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
              <Headline
                size={3}
                style={{
                  fontSize: 15,
                  marginLeft: 16,
                  marginBottom: 8
                }}
              >
                お支払い情報
              </Headline>
              <Text style={{ color: Color.grey, fontSize: 12 }}>
                （ご支払い時に必要です。）
              </Text>
            </View>
            <Section>
              <Link to="/mypage/paymentInfo">
                <SectionTransitItem
                  title="カード情報"
                  content={`${myself.paymentInfos.length}件`}
                />
              </Link>
              <Link to="/mypage/shippingInfo">
                <SectionTransitItem title="お届先情報" />
              </Link>
            </Section>
          </View>
          <View style={{ marginBottom: 40 }}>
            <View style={{ flexDirrection: 'row', alignItems: 'baseline' }}>
              <Headline
                size={3}
                style={{
                  fontSize: 15,
                  marginLeft: 16,
                  marginBottom: 8
                }}
              >
                パトロン
              </Headline>
            </View>
            <Section>
              <Link to="/mypage/patronContract">
                <SectionTransitItem
                  title="パトロン支援"
                  content={`${this.props.patronContract.list.length}件`}
                />
              </Link>
            </Section>
          </View>
        </CardPrimitive>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypageIndex);
