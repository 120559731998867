import React from 'react';

import Color from '../../constants/Color';
import Container from '../Container';
import Headline from '../Headline';
import Text from '../Text';
import View from '../View';

export default function JobDesktopHeader({ data, businessCategory }) {
  return (
    <View
      style={{
        height: 320,
        backgroundImage: `url(${data.desktopHeaderImage.uri})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '98px 0'
      }}
    >
      <Container style={{ flexDirection: 'row', alignItems: 'center' }}>
        <View
          style={{
            width: 120,
            height: 120,
            borderRadius: 60,
            backgroundColor: Color.white,
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 32
          }}
        >
          <businessCategory.icon
            width={64}
            height={64}
            viewBox="0 0 24 24"
            style={{ fill: Color.slate }}
          />
          <Text
            style={{
              color: Color.slate,
              fontSize: 15,
              fontWeight: 'bold'
            }}
          >
            {businessCategory.label}
          </Text>
        </View>
        <View style={{ justifyContent: 'center' }}>
          <Headline
            size={1}
            style={{
              color: Color.white,
              fontSize: 32,
              textShadow: `${Color.black} 0 2px 4px`,
              marginBottom: 16
            }}
          >
            {data.alphabet}
          </Headline>
          <Headline
            size={2}
            style={{
              color: Color.white,
              fontSize: 24,
              textShadow: `${Color.black} 0 2px 4px`
            }}
          >
            {data.name}
          </Headline>
        </View>
      </Container>
    </View>
  );
}
