import { getCollection } from '../utils/Firebase';
import DreamThank from '../models/DreamThank';
import User from '../models/User';
import * as types from '../constants/ActionTypes';

// get activity histories
function fetchGetDreamThanks({ reset = false }) {
  return {
    type: types.FETCH_GET_DREAM_THANKS,
    state: {
      reset
    }
  };
}

function fetchGetDreamThanksSuccess(list, reset = false) {
  return {
    type: types.FETCH_GET_DREAM_THANKS_SUCCESS,
    state: {
      reset,
      list
    }
  };
}

function fetchGetDreamThanksFailed(error) {
  return {
    type: types.FETCH_GET_DREAM_THANKS_FAILED,
    error
  };
}

export function recoverGetDreamThanks() {
  return {
    type: types.FETCH_GET_DREAM_THANKS_RECOVER
  };
}

export function getDreamThanks(
  dreamId,
  options = { reset: false, lastItem: null }
) {
  return (dispatch, getState) => {
    dispatch(fetchGetDreamThanks({ reset: options.reset }));

    const dreamThanks = getCollection('dreams')
      .doc(dreamId)
      .collection('thankHistories');

    let query = dreamThanks.orderBy('createdAt', 'desc');

    if (options.lastItem) {
      query = query.startAfter(options.lastItem.createdAt);
    }

    query = query.limit(10);

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const dreamThank = doc.data();

            const user = await User.get(dreamThank.userId);

            return new DreamThank({
              id: doc.id,
              user,
              ...dreamThank
            }).fromFirestore();
          })
        );

        return dispatch(fetchGetDreamThanksSuccess(list, options.reset));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetDreamThanksFailed(error));
        }
      });
  };
}
