import numeral from 'numeral';

export const RANK_TYPE_NONE_CLASS = 'NONE_CLASS';
export const RANK_TYPE_SPECIAL_CLASS = 'SPECIAL_CLASS';
export const RANK_TYPE_FIRST_CLASS = 'FIRST_CLASS';
export const RANK_TYPE_SECOND_CLASS = 'SECOND_CLASS';
export const RANK_TYPE_THIRD_CLASS = 'THIRD_CLASS';

const AMOUNTS = [5000, 10000, 20000, 30000, 50000, 100000];

export default class PatronizeAmount {
  static getItem(amount) {
    return {
      label: `${numeral(amount).format('0,0')}/月`,
      value: amount
    };
  }

  static getItems() {
    const amounts = AMOUNTS.map((amount, index) =>
      PatronizeAmount.getItem(amount)
    );

    return amounts;
  }

  static getLabel(value) {
    return PatronizeAmount.items.find(item => item.value === value).label;
  }

  static get defaultItem() {
    return PatronizeAmount.getItem(5000);
  }
}
