import { Link } from 'react-router-dom';
import React from 'react';

import Color from '../../constants/Color';
import Deadline from '../../utils/Deadline';
import Style from '../../constants/Style';
import Text from '../Text';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

export default function DreamItem({
  data: {
    founder,
    title,
    subTitle,
    deadlineAt,
    backgroundGradient,
    bannerBackgroundImage
  }
}) {
  const deadline = new Deadline(deadlineAt);

  return (
    <View
      style={{
        padding: 16,
        borderRadius: 16,
        backgroundImage: `${backgroundGradient}, url(${
          bannerBackgroundImage.uri
        })`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        justifyContent: 'space-between',
        ...Style.shadow.large
      }}
    >
      <View style={{ flexDirection: 'row' }}>
        <Link to={`/founder/${founder.id}`}>
          <UserIcon
            source={founder.iconImage}
            size={72}
            style={{ marginRight: 8 }}
          />
        </Link>
        <View style={{ flex: 1 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              marginBottom: 8
            }}
          >
            <Text
              style={{
                fontSize: 13,
                color: Color.white
              }}
            >
              {founder.screenName}
            </Text>
            <View
              style={{
                backgroundColor: Color.white,
                padding: '4px 8px',
                borderRadius: 16
              }}
            >
              <Text
                style={{
                  color: Color.slate,
                  fontSize: 10
                }}
              >
                {!deadline.isFinished && '募集締切まで'}
                {deadline.leastAmount}
                {!deadline.isFinished && deadline.leastUnit}
              </Text>
            </View>
          </View>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 13,
              color: Color.white,
              marginBottom: 8
            }}
          >
            {title}
          </Text>
          <Text style={{ fontSize: 11, color: Color.white }}>{subTitle}</Text>
        </View>
      </View>
    </View>
  );
}
