import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalSpecificationFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.kpi1}
          name="kpi1"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={kpi1 => {
            onChangeParams({
              kpi1
            });
          }}
          label="(5) KPI1"
          errorMessage={
            rules &&
            validate({
              label: 'KPI1',
              value: params.kpi1,
              rule: rules.kpi1
            })
          }
          limitLength={rules.kpi1.maxLength}
          helpText="あなたの事業を運営する際、最終的なゴールであるKGIを計測する上で最も重要なKPIを記述してください。また、そのKPIを満たすための施策についても触れてください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.kpi2}
          name="kpi2"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={kpi2 => {
            onChangeParams({
              kpi2
            });
          }}
          label="(6) KPI2"
          errorMessage={
            rules &&
            validate({
              label: 'KPI2',
              value: params.kpi2,
              rule: rules.kpi2
            })
          }
          limitLength={rules.kpi2.maxLength}
          helpText="あなたの事業を運営する際、最終的なゴールであるKGIを計測する上で二番目に重要なKPIを記述してください。また、そのKPIを満たすための施策についても触れてください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.kpi3}
          name="kpi3"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={kpi3 => {
            onChangeParams({
              kpi3
            });
          }}
          label="(7) KPI3"
          errorMessage={
            rules &&
            validate({
              label: 'KPI3',
              value: params.kpi3,
              rule: rules.kpi3
            })
          }
          limitLength={rules.kpi3.maxLength}
          helpText="あなたの事業を運営する際、最終的なゴールであるKGIを計測する上で三番目に重要なKPIを記述してください。また、そのKPIを満たすための施策についても触れてください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.kgi}
          name="kgi"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={kgi => {
            onChangeParams({
              kgi
            });
          }}
          label="(8) KGI"
          errorMessage={
            rules &&
            validate({
              label: 'KGI',
              value: params.kgi,
              rule: rules.kgi
            })
          }
          limitLength={rules.kgi.maxLength}
          helpText="あなたの事業の最終的なゴールであるKGIを記述してください。ほとんどの場合は売上となりますが、社会的意義を持つ事業の場合は、別の指標となります。KGIの理由についても明記してください。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalSpecificationFormField);
