import React from 'react';

import { PdfImage, PdfText, PdfView } from '../../utils/Pdf';
import Color from '../../constants/Color';

export default function PdfFlow({
  title,
  content,
  icon,
  color,
  style = {}
}) {
  return (
    <PdfView
      style={{
        ...style
      }}
    >
      <PdfView
        style={{
          flex: 1,
          backgroundColor: Color.white,
          border: `1pt solid ${Color.warmGrey}`,
          borderRadius: 16,
          marginTop: 24,
          paddingTop: 32,
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: 16
        }}
      >
        <PdfText
          style={{
            fontSize: 12,
            fontWeight: 'bold',
            color,
            marginBottom: 8,
            textAlign: 'center'
          }}
        >
          {title}
        </PdfText>
        <PdfText style={{ fontSize: 10, lineHeight: 1.3 }}>{content}</PdfText>
      </PdfView>
      <PdfView
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          alignItems: 'center'
        }}
      >
        <PdfView
          style={{
            backgroundColor: Color.white,
            border: `1pt solid ${Color.warmGrey}`,
            borderRadius: 24,
            width: 48,
            height: 48,
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <PdfImage src={icon} style={{ width: 24, height: 24 }} />
        </PdfView>
      </PdfView>
    </PdfView>
  );
}
