import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import EmptyText from '../../../../components/EmptyText';
import Headline from '../../../../components/Headline';
import PageHeader from '../../../../containers/page/PageHeader';
import Prefecture from '../../../../models/Prefecture';
import Section from '../../../../components/Section/Section';
import SectionTransitItem from '../../../../components/Section/SectionTransitItem';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypageShippingInfoIndex extends React.Component {
  componentDidMount() {
    this.props.getShippingInfos();
  }

  render() {
    const shippingInfos = this.props.shippingInfo.list.map(
      id => this.props.shippingInfo.hash[id]
    );

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <View style={{ paddingTop: 32 }}>
          <Headline
            size={3}
            style={{ fontSize: 15, marginLeft: 16, marginBottom: 16 }}
          >
            お届先情報
          </Headline>
          <View style={{ marginBottom: 40 }}>
            {shippingInfos.length < 1 ? (
              <EmptyText style={{ paddingLeft: 16 }}>
                お届先情報がありません。
              </EmptyText>
            ) : (
              <Section>
                {shippingInfos.map(info => {
                  return (
                    <Link to={`/mypage/shippingInfo/${info.id}`}>
                      <SectionTransitItem
                        title={`${info.kanjiSei} ${
                          info.kanjiMei
                        } | ${Prefecture.getLabel(info.prefecture)} ${
                          info.city
                        }`}
                      />
                    </Link>
                  );
                })}
              </Section>
            )}
          </View>
          <View style={{ padding: '0 16px' }}>
            <Link to="/mypage">
              <Button variant="flat">戻る</Button>
            </Link>
          </View>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypageShippingInfoIndex);
