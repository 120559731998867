import { getRemovedHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isCreateLoading: false,
  isCreateFailed: false,
  createError: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    // get marketing
    case types.FETCH_GET_MARKETING:
      return {
        ...state,
        hash: getRemovedHash(action.state.id, state.hash),
        isGetLoading: true
      };

    case types.FETCH_GET_MARKETING_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_MARKETING_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_MARKETING_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // create marketing
    case types.FETCH_CREATE_MARKETING:
      return {
        ...state,
        isCreateLoading: true
      };

    case types.FETCH_CREATE_MARKETING_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        list: [...state.list, action.state.id],
        isCreateLoading: false,
        isCreateFailed: false
      };

    case types.FETCH_CREATE_MARKETING_FAILED:
      return {
        ...state,
        isCreateLoading: false,
        isCreateFailed: true,
        createError: action.error
      };

    case types.FETCH_CREATE_MARKETING_RECOVER:
      return {
        ...state,
        isCreateFailed: false
      };

    // update marketing
    case types.FETCH_UPDATE_MARKETING:
      return {
        ...state,
        isUpdateLoading: true
      };

    case types.FETCH_UPDATE_MARKETING_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        isUpdateLoading: false,
        isUpdateFailed: false
      };

    case types.FETCH_UPDATE_MARKETING_FAILED:
      return {
        ...state,
        isUpdateLoading: false,
        isUpdateFailed: true,
        updateError: action.error
      };

    case types.FETCH_UPDATE_MARKETING_RECOVER:
      return {
        ...state,
        isUpdateFailed: false
      };

    default:
      return state;
  }
};
