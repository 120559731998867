import Model from './Model';

export default class Marketing extends Model {
  constructor(state = {}) {
    super();

    this.params = {
      ...state,
      basicInfo: {
        title: '',
        name: '',
        profileImage: {},
        birthday: '',
        job: '',
        biography: '',
        businessCategoryId: 'unselected',
        primaryColor: 'EE5465',
        secondaryColor: '6DA5BF',
        ...state.basicInfo
      },
      market: {
        competitorDefinition: '',
        customerDefinition: '',
        nonCustomerDefinition: '',
        scale: '',
        potential: '',
        competitorEnvironment: '',
        ability: '',
        synergy: '',
        ...state.market
      },
      idealValues: {
        utilitarianValue: '',
        utilitarianLevel: 0,
        guaranteedValue: '',
        guaranteedLevel: 0,
        reputationValue: '',
        reputationLevel: 0,
        sympathyValue: '',
        sympathyLevel: 0,
        ...state.idealValues
      },
      realityValues: {
        product: '',
        support: '',
        communication: '',
        concept: '',
        ...state.realityValues
      },
      communication: {
        toNotice: '',
        toRemember: '',
        toLike: '',
        toUnderstand: '',
        toSelect: '',
        ...state.communication
      }
    };
  }

  get object() {
    return this.params;
  }

  get fields() {
    return {
      basicInfo: this.params.basicInfo,
      market: this.params.market,
      idealValues: this.params.idealValues,
      realityValues: this.params.realityValues,
      communication: this.params.communication
    };
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
