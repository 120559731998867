import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import ActivityHistoryList from '../../../containers/activity/ActivityHistoryList';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import ContentContainer from '../../../components/Page/ContentContainer';
import FooterContainer from '../../../components/FooterContainer';
import Headline from '../../../components/Headline';
import MypageMenu from '../../../containers/mypage/MypageMenu';
import PageHeader from '../../../containers/page/PageContaineredHeader';
import View from '../../../components/View';
import actions from '../../../actions';

class MypageIndex extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getMyselfUser();

    this.props.getPatronContracts({ reset: true });
  }

  render() {
    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <ContentContainer>
          <Row style={{ margin: '40px 0' }}>
            <Column xs={4}>
              <MypageMenu />
            </Column>
            <Column xs={8}>
              <Headline style={{ fontSize: 32, marginBottom: 40 }}>
                アクティビティ
              </Headline>
              <ActivityHistoryList style={{ padding: '0 8px' }} />
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypageIndex);
