import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import ArrowStream from '../../components/Icons/ArrowStream';
import Button from '../../components/Button';
import Color from '../../constants/Color';
import MessagePlate from '../../components/MessagePlate';
import Paragraph from '../../components/Paragraph';
import SphereIcon from '../../components/Icons/SphereIcon';
import Style from '../../constants/Style';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class Wish extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getJobs();
  }

  render() {
    const { style = {} } = this.props;

    return (
      <View style={style}>
        <View
          style={{
            borderRadius: 8,
            backgroundColor: Color.white,
            margin: '-8px 0',
            ...Style.shadow.large,
            zIndex: 1
          }}
        >
          <View
            style={{
              backgroundImage: `url(${require('../../assets/img/founder-recruit-background.jpg')})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              alignItems: 'center',
              borderRadius: 8,
              padding: '24px 0',
              margin: '-16px 8px 16px 8px'
            }}
          >
            <MessagePlate title="WISH" message="VISIONSからのお願い" />
          </View>
          <View style={{ overflow: 'hidden' }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 16
              }}
            >
              <SphereIcon
                source={require('../../assets/img/wish/first.png')}
                size={120}
                style={{ marginLeft: -16 }}
              />
              <Paragraph
                onerow
                style={{ lineHeight: 2 }}
                containerStyle={{ padding: '0 16px' }}
              >
                クラウドファンディング「VISIONS」は、
                <Text style={{ color: Color.slate, fontWeight: 'bold' }}>
                  好きなことをしてお金を稼げる社会の実現のため開発されました。
                </Text>
                次の世代が夢を実現できるような社会の仕組み作りが、このシステムの目的です。
              </Paragraph>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 16
              }}
            >
              <Paragraph
                onerow
                style={{ lineHeight: 2 }}
                containerStyle={{ padding: '0 16px' }}
              >
                その社会の実現のためには
                <Text style={{ color: Color.slate, fontWeight: 'bold' }}>
                  「好きなこと」を仕事にしていく様子を、社会や次の世代に発信していき、周りを勇気づけていく必要
                </Text>
                があります。
              </Paragraph>
              <SphereIcon
                source={require('../../assets/img/wish/second.png')}
                size={120}
                style={{ marginRight: -16 }}
              />
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <SphereIcon
                source={require('../../assets/img/wish/third.png')}
                size={120}
                style={{ marginLeft: -16 }}
              />
              <Paragraph
                onerow
                style={{ lineHeight: 2 }}
                containerStyle={{ padding: '0 16px' }}
              >
                いきなり起業は大変なので、まずは、
                <Text style={{ color: Color.slate, fontWeight: 'bold' }}>
                  クラウドファンディングで小さなチャレンジをできる
                </Text>
                ようにしました。そのためのサポートもしていきます。
              </Paragraph>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Paragraph
                onerow
                style={{ lineHeight: 2 }}
                containerStyle={{ padding: '0 16px' }}
              >
                この社会の実現のため、
                <Text style={{ color: Color.slate, fontWeight: 'bold' }}>
                  VISIONSは、事業者が集めたお金から手数料を徴収しません。
                </Text>
                集めた資金は、そのまま事業にお役立てください。
              </Paragraph>
              <SphereIcon
                source={require('../../assets/img/wish/fourth.png')}
                size={120}
                style={{ marginRight: -16 }}
              />
            </View>
          </View>
          <View style={{ alignItems: 'center', padding: '24px 0' }}>
            <ArrowStream color={Color.slate} />
          </View>
          <View style={{ padding: '0 16px', marginBottom: 24 }}>
            <Paragraph
              style={{
                textAlign: 'center',
                color: Color.slate,
                fontWeight: 'bold',
                fontSize: 17
              }}
              containerStyle={{ marginBottom: 24 }}
            >
              {`夢を叶えられる社会の実現のため\n皆様の力をお貸しください。`}
            </Paragraph>
            <Link to="/dream/application">
              <Button style={{ marginBottom: 16 }}>事業者へ応募する</Button>
            </Link>
          </View>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Wish);
