import React from 'react';

import { getGradient, getGradientCss } from '../utils/ColorJs';
import Color from '../constants/Color';
import Headline from './Headline';
import Image from './Image';
import Paragraph from './Paragraph';
import View from './View';

export default function ImageIntroduction({
  source,
  title,
  description,
  children,
  gradient,
  style = {}
}) {
  return (
    <View style={{ position: 'relative', ...style }}>
      <View
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <Image
          source={source}
          style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
        />
        <View
          style={{
            flex: 1,
            backgroundImage: `${getGradientCss(getGradient(gradient), 0)}`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            marginTop: -1
          }}
        />
      </View>
      <View style={{ zIndex: 1, paddingTop: 24 }}>
        {(title || description) && (
          <View style={{ padding: 16 }}>
            {title && (
              <Headline
                size={2}
                style={{ color: Color.white, marginBottom: 8 }}
              >
                {title}
              </Headline>
            )}
            {description && (
              <Paragraph style={{ color: Color.white }}>
                {description}
              </Paragraph>
            )}
          </View>
        )}
        {children}
      </View>
    </View>
  );
}
