import React from 'react';
import numeral from 'numeral';

import Button from '../Button';
import CardPrimitive from '../Card/CardPrimitive';
import Color from '../../constants/Color';
import Image from '../Image';
import Markdown from '../Markdown';
import MaterialIcon from '../../utils/MaterialIcon';
import Paragraph from '../Paragraph';
import Style from '../../constants/Style';
import Text from '../Text';
import View from '../../components/View';

export default class ProductItem extends React.Component {
  state = {
    isCollapse: false
  };

  render() {
    const {
      data,
      submit = false,
      renderSubmit = () => null,
      style = {}
    } = this.props;

    return (
      <View style={{ paddingTop: 16, ...style }}>
        <CardPrimitive>
          <View style={{ marginTop: -16, padding: '0 16px' }}>
            <Image
              source={data.images[0]}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: 8,
                ...Style.shadow.medium
              }}
            />
          </View>
          <View style={{ padding: 16 }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 8
              }}
            >
              <Text
                style={{
                  color: Color.slate,
                  fontSize: 17,
                  fontWeight: 'bold'
                }}
              >
                {data.title}
              </Text>
            </View>
            <Paragraph style={{ fontSize: 15 }}>{data.subhead}</Paragraph>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <View
                style={{
                  backgroundColor: Color.pink,
                  borderRadius: 16,
                  padding: '2px 8px'
                }}
              >
                <Text
                  style={{
                    color: Color.white,
                    fontSize: 13,
                    fontWeight: 'bold'
                  }}
                >
                  残り
                  {Number(data.stockCount)}点
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'baseline',
                justifyContent: 'flex-end'
              }}
            >
              <Text
                style={{
                  color: Color.slate,
                  fontWeight: 'bold',
                  fontSize: 32,
                  marginRight: 4
                }}
              >
                {numeral(data.price).format('0,0')}
              </Text>
              <Text
                style={{ color: Color.slate, fontWeight: 'bold', fontSize: 11 }}
              >
                円（税込）
              </Text>
            </View>
            <View>
              <View
                style={{
                  borderTop: `1px solid ${Color.lightGrey}`,
                  borderBottom: submit ? `1px solid ${Color.lightGrey}` : '',
                  marginBottom: submit ? 8 : 0
                }}
              >
                {this.state.isCollapsed ? (
                  <Button
                    variant="text"
                    onClick={event => {
                      this.setState({
                        isCollapsed: !this.state.isCollapsed
                      });

                      event.stopPropagation();
                    }}
                  >
                    <View>
                      <Text style={{ color: Color.slate, fontWeight: 'bold' }}>
                        詳細を見る
                      </Text>
                      <View style={{ position: 'absolute', right: 8 }}>
                        <MaterialIcon
                          icon={
                            this.state.isCollapsed
                              ? 'keyboard_arrow_up'
                              : 'keyboard_arrow_down'
                          }
                          color={Color.slate}
                          size={24}
                        />
                      </View>
                    </View>
                  </Button>
                ) : (
                  <View style={{ padding: '16px 0' }}>
                    <Markdown>{data.detailContent}</Markdown>
                  </View>
                )}
              </View>
              {submit &&
                renderSubmit(
                  <Button mini disable={data.stockCount < 1}>
                    このコースを申し込む
                  </Button>
                )}
            </View>
          </View>
        </CardPrimitive>
      </View>
    );
  }
}
