import React from 'react';

import Color from '../../constants/Color';
import MaterialIcon from '../../utils/MaterialIcon';
import View from '../View';

export default function ArrowStream({ color = Color.white, style = {} }) {
  return (
    <View style={style}>
      {new Array(3).fill(true).map((_, index, arr) => {
        let marginBottom = -22;

        if (index === arr.length - 1) {
          marginBottom = 0;
        }

        return (
          <View key={`arrow-${index}`} style={{ marginBottom }}>
            <MaterialIcon icon="keyboard_arrow_down" color={color} size={32} />
          </View>
        );
      })}
    </View>
  );
}
