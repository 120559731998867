import { getHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null
};

export default (state = initialState, action) => {
  let newHash;

  let newList;

  switch (action.type) {
    // get product histories
    case types.FETCH_GET_ACTIVITY_HISTORIES:
      return {
        ...state,
        list: action.state.reset ? [] : state.list,
        isGetLoading: true
      };

    case types.FETCH_GET_ACTIVITY_HISTORIES_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      newList = action.state.reset
        ? action.state.list.map(item => item.id)
        : [...state.list, ...action.state.list.map(item => item.id)];

      return {
        ...state,
        list: newList,
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_ACTIVITY_HISTORIES_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_ACTIVITY_HISTORIES_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    default:
      return state;
  }
};
