import React from 'react';

import Color from '../../constants/Color';
import Style from '../../constants/Style';
import UserIcon from './UserIcon';
import View from '../View';

export default function EdgedUserIcon({ source, size = 160, style = {} }) {
  return (
    <View
      style={{
        width: size,
        height: size,
        maxWidth: size,
        maxHeight: size,
        borderRadius: size / 2,
        backgroundColor: Color.white,
        alignItems: 'center',
        justifyContent: 'center',
        ...Style.shadow.large,
        ...style
      }}
    >
      <UserIcon source={source} size={size - 8} />
    </View>
  );
}
