import React from 'react';

export default function ArrowDownSvg({ id, fill }) {
  return (
    <svg
      id={id}
      width="15px"
      height="8px"
      viewBox="0 0 15 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Plans"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="A4-Copy"
          transform="translate(-887.000000, -519.000000)"
          fill={fill}
          fillRule="nonzero"
        >
          <polygon
            id="Path-2"
            transform="translate(894.500000, 523.000000) rotate(-270.000000) translate(-894.500000, -523.000000) "
            points="891 516 898 523 891 530"
          />
        </g>
      </g>
    </svg>
  );
}
