import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Color from '../../../../constants/Color';
import Column from '../../../../components/Column';
import ContentContainer from '../../../../components/Page/ContentContainer';
import EmptyText from '../../../../components/EmptyText';
import FooterContainer from '../../../../components/FooterContainer';
import Headline from '../../../../components/Headline';
import MypageMenu from '../../../../containers/mypage/MypageMenu';
import PageHeader from '../../../../containers/page/PageContaineredHeader';
import Section from '../../../../components/Section/Section';
import SectionTransitItem from '../../../../components/Section/SectionTransitItem';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypagePatronContractIndex extends React.Component {
  componentDidMount() {
    this.props.getPatronContracts({ reset: true });
  }

  render() {
    const patronContracts = this.props.patronContract.list.map(
      id => this.props.patronContract.hash[id]
    );

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <ContentContainer>
          <Row style={{ margin: '40px 0' }}>
            <Column xs={4}>
              <MypageMenu />
            </Column>
            <Column xs={8}>
              <Headline style={{ fontSize: 32, marginBottom: 40 }}>
                パトロン
              </Headline>
              <Headline style={{ marginBottom: 16 }}>パトロン支援一覧</Headline>
              <View style={{ marginBottom: 40 }}>
                {patronContracts.length < 1 ? (
                  <EmptyText style={{ paddingLeft: 16 }}>
                    パトロン支援がありません。
                  </EmptyText>
                ) : (
                  <Section sideline>
                    {patronContracts.map(contract => {
                      return (
                        <Link to={`/mypage/patronContract/${contract.id}`}>
                          <SectionTransitItem
                            title={`${contract.founder.name}`}
                            content={`${contract.amount}/月`}
                          />
                        </Link>
                      );
                    })}
                  </Section>
                )}
              </View>
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypagePatronContractIndex);
