import React from 'react';

import Markdown from '../Markdown';
import View from '../View';

export default function DreamDetailContent({
  data: { detailTitle, detailContent },
  style = {}
}) {
  return (
    <View style={style}>
      <Markdown>{detailContent}</Markdown>
    </View>
  );
}
