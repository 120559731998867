import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import EmptyText from '../../../../components/EmptyText';
import Headline from '../../../../components/Headline';
import PageHeader from '../../../../containers/page/PageHeader';
import Section from '../../../../components/Section/Section';
import SectionTransitItem from '../../../../components/Section/SectionTransitItem';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypagePatronContractIndex extends React.Component {
  componentDidMount() {
    this.props.getPatronContracts({ reset: true });
  }

  render() {
    const patronContracts = this.props.patronContract.list.map(
      id => this.props.patronContract.hash[id]
    );

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <View style={{ paddingTop: 32 }}>
          <Headline
            size={3}
            style={{ fontSize: 15, marginLeft: 16, marginBottom: 16 }}
          >
            パトロン支援一覧
          </Headline>
          <View style={{ marginBottom: 40 }}>
            {patronContracts.length < 1 ? (
              <EmptyText style={{ paddingLeft: 16 }}>
                パトロン支援がありません。
              </EmptyText>
            ) : (
              <Section>
                {patronContracts.map(contract => {
                  return (
                    <Link to={`/mypage/patronContract/${contract.id}`}>
                      <SectionTransitItem
                        title={`${contract.founder.name}`}
                        content={`${contract.amount}/月`}
                      />
                    </Link>
                  );
                })}
              </Section>
            )}
          </View>
          <View style={{ padding: '0 16px' }}>
            <Link to="/mypage">
              <Button variant="flat">戻る</Button>
            </Link>
          </View>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypagePatronContractIndex);
