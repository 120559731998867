import React from 'react';

import { PdfImage, PdfText, PdfView } from '../../utils/Pdf';

export default function PdfEssenceSection({
  title,
  content,
  icon,
  color,
  subColor,
  style = {}
}) {
  return (
    <PdfView
      style={{
        flexDirection: 'row',
        ...style
      }}
    >
      <PdfView
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 21,
          width: 42,
          height: 42,
          border: `1pt solid ${subColor}`,
          marginRight: 16
        }}
      >
        <PdfImage src={icon} style={{ width: 20, height: 20 }} />
      </PdfView>
      <PdfView style={{ flex: 1 }}>
        <PdfText
          style={{
            fontSize: 12,
            fontWeight: 'bold',
            color,
            marginBottom: 8
          }}
        >
          {title}
        </PdfText>
        <PdfText style={{ fontSize: 10, lineHeight: 1.3 }}>{content}</PdfText>
      </PdfView>
    </PdfView>
  );
}
