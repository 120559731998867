import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import ArrowStream from '../../components/Icons/ArrowStream';
import Button from '../../components/Button';
import Color from '../../constants/Color';
import Column from '../../components/Column';
import Container from '../../components/Container';
import ContentContainer from '../../components/Page/ContentContainer';
import Headline from '../../components/Headline';
import Paragraph from '../../components/Paragraph';
import SphereIcon from '../../components/Icons/SphereIcon';
import Style from '../../constants/Style';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class WishContainer extends React.Component {
  render() {
    const { style = {} } = this.props;

    return (
      <View style={style}>
        <ContentContainer>
          <Headline
            style={{
              fontSize: 32,
              textAlign: 'center',
              marginTop: 88,
              marginBottom: 32
            }}
          >
            WISH
          </Headline>
          <Headline
            style={{
              fontSize: 17,
              textAlign: 'center',
              marginBottom: 88
            }}
          >
            VISIONSからのお願い
          </Headline>
          <Row style={{ marginTop: -32 }}>
            <Column xs={3} offset={{ xs: 1 }}>
              <View style={{ alignItems: 'flex-end' }}>
                <SphereIcon
                  source={require('../../assets/img/wish/first.png')}
                />
              </View>
            </Column>
            <Column verticalAlign="center" xs={6}>
              <Paragraph onerow style={{ fontSize: 17, lineHeight: 2 }}>
                クラウドファンディング「VISIONS」は、
                <Text style={{ color: Color.slate, fontWeight: 'bold' }}>
                  好きなことをしてお金を稼げる社会の実現のため開発されました。
                </Text>
                次の世代が夢を実現できるような社会の仕組み作りが、このシステムの目的です。
              </Paragraph>
            </Column>
          </Row>
          <Row>
            <Column verticalAlign="center" xs={6} offset={{ xs: 2 }}>
              <Paragraph onerow style={{ fontSize: 17, lineHeight: 2 }}>
                その社会の実現のためには
                <Text style={{ color: Color.slate, fontWeight: 'bold' }}>
                  「好きなこと」を仕事にしていく様子を、社会や次の世代に発信していき、周りを勇気づけていく必要
                </Text>
                があります。
              </Paragraph>
            </Column>
            <Column xs={3}>
              <View>
                <SphereIcon
                  source={require('../../assets/img/wish/second.png')}
                />
              </View>
            </Column>
          </Row>
          <Row>
            <Column xs={3} offset={{ xs: 1 }}>
              <View style={{ alignItems: 'flex-end' }}>
                <SphereIcon
                  source={require('../../assets/img/wish/third.png')}
                />
              </View>
            </Column>
            <Column verticalAlign="center" xs={6}>
              <Paragraph onerow style={{ fontSize: 17, lineHeight: 2 }}>
                いきなり起業は大変なので、まずは、
                <Text style={{ color: Color.slate, fontWeight: 'bold' }}>
                  クラウドファンディングで小さなチャレンジをできる
                </Text>
                ようにしました。そのためのサポートもしていきます。
              </Paragraph>
            </Column>
          </Row>
          <Row>
            <Column verticalAlign="center" xs={6} offset={{ xs: 2 }}>
              <Paragraph onerow style={{ fontSize: 17, lineHeight: 2 }}>
                この社会の実現のため、
                <Text style={{ color: Color.slate, fontWeight: 'bold' }}>
                  VISIONSは、事業者が集めたお金から手数料を徴収しません。
                </Text>
                集めた資金は、そのまま事業にお役立てください。
              </Paragraph>
            </Column>
            <Column xs={3}>
              <View>
                <SphereIcon
                  source={require('../../assets/img/wish/fourth.png')}
                />
              </View>
            </Column>
          </Row>
          <View style={{ alignItems: 'center', marginBottom: -44 }}>
            <View
              style={{
                width: 88,
                height: 88,
                borderRadius: 44,
                backgroundColor: Color.white,
                alignItems: 'center',
                justifyContent: 'center',
                ...Style.shadow.large
              }}
            >
              <ArrowStream color={Color.slate} />
            </View>
          </View>
        </ContentContainer>
        <View
          style={{
            backgroundImage: `url(${require('../../assets/img/wish/building.jpg')})`,
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom'
          }}
        >
          <Container>
            <View style={{ padding: '88px 0' }}>
              <Paragraph
                style={{
                  textAlign: 'center',
                  color: Color.white,
                  fontWeight: 'bold',
                  fontSize: 24
                }}
                containerStyle={{ marginBottom: 48 }}
              >
                {`夢を叶えられる社会の実現のため\n皆様の力をお貸しください。`}
              </Paragraph>
              <Link to="/dream/application">
                <Button>事業者へ応募する</Button>
              </Link>
            </View>
          </Container>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WishContainer);
