import React from 'react';
import ReactModal from 'react-modal';

import Color from '../../constants/Color';

const customStyles = {
  content: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 0,
    borderRadius: 0,
    borderWidth: 0,
    backgroundColor: 'transparent'
  },
  overlay: {
    backgroundColor: Color.printBlack,
    zIndex: 10
  }
};

export default class Modal extends React.Component {
  render() {
    const {
      visible,
      children,
      onClose = () => {},
      style = { content: {}, overlay: {} }
    } = this.props;

    return (
      <ReactModal
        isOpen={visible}
        onRequestClose={onClose}
        style={{
          content: {
            width: 320,
            margin: '0 auto',
            ...customStyles.content,
            ...style.content
          },
          overlay: {
            ...customStyles.overlay,
            ...style.overlay
          }
        }}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <div
          style={{
            overlfow: 'scroll'
          }}
        >
          {children}
        </div>
      </ReactModal>
    );
  }
}
