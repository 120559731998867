import { getHash, getRemovedHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isCreateLoading: false,
  isCreateFailed: false,
  createError: null
};

export default (state = initialState, action) => {
  let newHash, newList;

  switch (action.type) {
    // get dreams
    case types.FETCH_GET_PATRON_CONTRACTS:
      return {
        ...state,
        list: action.state.reset ? [] : state.list,
        isGetLoading: true
      };

    case types.FETCH_GET_PATRON_CONTRACTS_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      newList = action.state.reset
        ? action.state.list.map(item => item.id)
        : [...state.list, ...action.state.list.map(item => item.id)];

      return {
        ...state,
        list: Array.from(new Set(newList)),
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_PATRON_CONTRACTS_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_PATRON_CONTRACTS_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // get patron contract
    case types.FETCH_GET_PATRON_CONTRACT:
      return {
        ...state,
        isGetLoading: true
      };

    case types.FETCH_GET_PATRON_CONTRACT_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        list: [action.state.id],
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_PATRON_CONTRACT_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_PATRON_CONTRACT_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // create patron contract
    case types.FETCH_CREATE_PATRON_CONTRACT:
      return {
        ...state,
        isCreateLoading: true
      };

    case types.FETCH_CREATE_PATRON_CONTRACT_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        list: [...state.list, action.state.id],
        isCreateLoading: false,
        isCreateFailed: false
      };

    case types.FETCH_CREATE_PATRON_CONTRACT_FAILED:
      return {
        ...state,
        isCreateLoading: false,
        isCreateFailed: true,
        createError: action.error
      };

    case types.FETCH_CREATE_PATRON_CONTRACT_RECOVER:
      return {
        ...state,
        isCreateFailed: false
      };

    // delete patron contract
    case types.FETCH_DELETE_PATRON_CONTRACT:
      return {
        ...state,
        isDeleteLoading: true
      };

    case types.FETCH_DELETE_PATRON_CONTRACT_SUCCESS:
      return {
        ...state,
        hash: getRemovedHash(action.state.id, state.hash),
        list: state.list.filter(id => id !== action.state.id),
        isDeleteLoading: false,
        isDeleteFailed: false
      };

    case types.FETCH_DELETE_PATRON_CONTRACT_FAILED:
      return {
        ...state,
        isDeleteLoading: false,
        isDeleteFailed: true,
        createError: action.error
      };

    case types.FETCH_DELETE_PATRON_CONTRACT_RECOVER:
      return {
        ...state,
        isDeleteFailed: false
      };

    default:
      return state;
  }
};
