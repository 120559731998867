import { request } from '../utils/Network';
import { uploadImage } from '../utils/Firebase';
import * as types from '../constants/ActionTypes';

// get proposal
function fetchGetProposal(id) {
  return {
    type: types.FETCH_GET_PROPOSAL,
    state: {
      id
    }
  };
}

function fetchGetProposalSuccess(id, params) {
  return {
    type: types.FETCH_GET_PROPOSAL_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetProposalFailed(error) {
  return {
    type: types.FETCH_GET_PROPOSAL_FAILED,
    error
  };
}

export function recoverGetProposal() {
  return {
    type: types.FETCH_GET_PROPOSAL_RECOVER
  };
}

export function getProposal(id, password) {
  return dispatch => {
    dispatch(fetchGetProposal(id));

    return request(dispatch, 'POST', '/getProposal', {
      params: {
        id,
        password
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchGetProposalFailed(error));
      }

      return dispatch(fetchGetProposalSuccess(response.id, response.proposal));
    });
  };
}

// create proposal
function fetchCreateProposal() {
  return {
    type: types.FETCH_CREATE_PROPOSAL
  };
}

function fetchCreateProposalSuccess(id, params) {
  return {
    type: types.FETCH_CREATE_PROPOSAL_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateProposalFailed(error) {
  return {
    type: types.FETCH_CREATE_PROPOSAL_FAILED,
    error
  };
}

export function recoverCreateProposal() {
  return {
    type: types.FETCH_CREATE_PROPOSAL_RECOVER
  };
}

export function createProposal(
  { basicInfo: { profileImage, ...restBasicInfo }, ...rest },
  viewPassword,
  editPassword
) {
  return dispatch => {
    dispatch(fetchCreateProposal());

    let proposalData = {};

    return uploadImage('/proposals/profileImages', profileImage)
      .then(path => {
        proposalData = {
          ...rest,
          basicInfo: {
            ...restBasicInfo,
            profileImage: { uri: path }
          }
        };

        return request(dispatch, 'POST', '/createProposal', {
          params: {
            proposal: proposalData,
            viewPassword,
            editPassword
          }
        });
      })
      .then(({ response, error }) => {
        if (error) {
          return dispatch(fetchCreateProposalFailed(error));
        }

        return dispatch(fetchCreateProposalSuccess(response.id, proposalData));
      });
  };
}

// update proposal
function fetchUpdateProposal() {
  return {
    type: types.FETCH_UPDATE_PROPOSAL
  };
}

function fetchUpdateProposalSuccess(id, params) {
  return {
    type: types.FETCH_UPDATE_PROPOSAL_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchUpdateProposalFailed(error) {
  return {
    type: types.FETCH_UPDATE_PROPOSAL_FAILED,
    error
  };
}

export function recoverUpdateProposal() {
  return {
    type: types.FETCH_UPDATE_PROPOSAL_RECOVER
  };
}

export function updateProposal(
  id,
  { basicInfo: { profileImage, ...restBasicInfo }, ...rest },
  viewPassword,
  editPassword
) {
  return dispatch => {
    dispatch(fetchUpdateProposal());

    let proposalData = {};

    return uploadImage('/proposals/profileImages', profileImage)
      .then(path => {
        proposalData = {
          ...rest,
          basicInfo: {
            ...restBasicInfo,
            profileImage: { uri: path }
          }
        };

        return request(dispatch, 'POST', '/updateProposal', {
          params: {
            id,
            proposal: proposalData,
            viewPassword,
            editPassword
          }
        });
      })
      .then(({ response, error }) => {
        if (error) {
          return dispatch(fetchUpdateProposalFailed(error));
        }

        return dispatch(fetchUpdateProposalSuccess(response.id, proposalData));
      });
  };
}
