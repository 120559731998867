import { getHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isCreateCommentLoading: false,
  isCreateCommentFailed: false,
  createCommentError: null,
  isCreateCommentReplyLoading: false,
  isCreateCommentReplyFailed: false,
  createCommentReplyError: null,
  isSupportLoading: false,
  isSupportFailed: false,
  supportError: null,
  isPurchaseProductLoading: false,
  isPurchaseProductFailed: false,
  purchaseProductError: null
};

export default (state = initialState, action) => {
  let newHash, newList;

  switch (action.type) {
    // get dreams
    case types.FETCH_GET_DREAMS:
      return {
        ...state,
        list: action.state.reset ? [] : state.list,
        isGetLoading: true
      };

    case types.FETCH_GET_DREAMS_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      newList = action.state.reset
        ? action.state.list.map(item => item.id)
        : [...state.list, ...action.state.list.map(item => item.id)];

      return {
        ...state,
        list: newList,
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_DREAMS_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_DREAMS_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // get dream
    case types.FETCH_GET_DREAM:
      return {
        ...state,
        isGetLoading: true
      };

    case types.FETCH_GET_DREAM_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.item
        },
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_DREAM_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_DREAM_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // support dream
    case types.FETCH_SUPPORT_DREAM:
      return {
        ...state,
        isSupportLoading: true
      };

    case types.FETCH_SUPPORT_DREAM_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.dreamId]: {
            ...state.hash[action.state.dreamId],
            supporter: {
              ...state.hash[action.state.dreamId].supporter,
              total:
                (state.hash[action.state.dreamId].supporter.total || 0) +
                action.state.amount
            }
          }
        },
        isSupportLoading: false,
        isSupportFailed: false
      };

    case types.FETCH_SUPPORT_DREAM_FAILED:
      return {
        ...state,
        isSupportLoading: false,
        isSupportFailed: true,
        supportError: action.error
      };

    // purchase dream product
    case types.FETCH_PURCHASE_DREAM_PRODUCT:
      return {
        ...state,
        isPurchaseProductLoading: true
      };

    case types.FETCH_PURCHASE_DREAM_PRODUCT_SUCCESS:
      return {
        ...state,
        isPurchaseProductLoading: false,
        isPurchaseProductFailed: false
      };

    case types.FETCH_PURCHASE_DREAM_PRODUCT_FAILED:
      return {
        ...state,
        isPurchaseProductLoading: false,
        isPurchaseProductFailed: true,
        purchaseProductError: action.error
      };

    case types.FETCH_PURCHASE_DREAM_PRODUCT_RECOVER:
      return {
        ...state,
        isPurchaseProductFailed: false
      };

    // create dream comment
    case types.FETCH_CREATE_DREAM_COMMENT:
      return {
        ...state,
        isCreateCommentLoading: true
      };

    case types.FETCH_CREATE_DREAM_COMMENT_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: {
            ...state.hash[action.state.id],
            comments: [
              action.state.params,
              ...state.hash[action.state.id].comments
            ]
          }
        },
        isCreateCommentLoading: false,
        isCreateCommentFailed: false
      };

    case types.FETCH_CREATE_DREAM_COMMENT_FAILED:
      return {
        ...state,
        isCreateCommentLoading: false,
        isCreateCommentFailed: true,
        createCommentError: action.error
      };

    case types.FETCH_CREATE_DREAM_COMMENT_RECOVER:
      return {
        ...state,
        isCreateCommentFailed: false
      };

    // create dream comment reply
    case types.FETCH_CREATE_DREAM_COMMENT_REPLY:
      return {
        ...state,
        isCreateCommentReplyLoading: true
      };

    case types.FETCH_CREATE_DREAM_COMMENT_REPLY_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.dreamId]: {
            ...state.hash[action.state.dreamId],
            comments: state.hash[action.state.dreamId].comments.map(comment => {
              if (comment.id === action.state.commentId) {
                return {
                  ...comment,
                  replies: [...comment.replies, action.state.params]
                };
              }

              return comment;
            })
          }
        },
        isCreateCommentReplyLoading: false,
        isCreateCommentReplyFailed: false
      };

    case types.FETCH_CREATE_DREAM_COMMENT_REPLY_FAILED:
      return {
        ...state,
        isCreateCommentReplyLoading: false,
        isCreateCommentReplyFailed: true,
        createCommentReplyError: action.error
      };

    case types.FETCH_CREATE_DREAM_COMMENT_REPLY_RECOVER:
      return {
        ...state,
        isCreateCommentReplyFailed: false
      };

    default:
      return state;
  }
};
