import { getCollection } from '../utils/Firebase';
import DreamProduct from '../models/DreamProduct';
import * as types from '../constants/ActionTypes';

// get activity histories
function fetchGetDreamProducts({ reset = false }) {
  return {
    type: types.FETCH_GET_DREAM_PRODUCTS,
    state: {
      reset
    }
  };
}

function fetchGetDreamProductsSuccess(list, reset = false) {
  return {
    type: types.FETCH_GET_DREAM_PRODUCTS_SUCCESS,
    state: {
      reset,
      list
    }
  };
}

function fetchGetDreamProductsFailed(error) {
  return {
    type: types.FETCH_GET_DREAM_PRODUCTS_FAILED,
    error
  };
}

export function recoverGetDreamProducts() {
  return {
    type: types.FETCH_GET_DREAM_PRODUCTS_RECOVER
  };
}

export function getDreamProducts(
  dreamId,
  options = { reset: false, lastItem: null }
) {
  return (dispatch, getState) => {
    dispatch(fetchGetDreamProducts({ reset: options.reset }));

    const dreamProducts = getCollection('dreams')
      .doc(dreamId)
      .collection('products');

    const query = dreamProducts.orderBy('price', 'asc');

    return query
      .get()
      .then(async ({ docs }) => {
        const list = docs.map(doc => {
          const dreamProduct = doc.data();

          return new DreamProduct({
            id: doc.id,
            ...dreamProduct
          }).fromFirestore();
        });

        return dispatch(fetchGetDreamProductsSuccess(list, options.reset));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetDreamProductsFailed(error));
        }
      });
  };
}
