import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../Form/TextFormField';
import View from '../../View';

export default class MypageAccountFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          labelWidth={120}
          value={params.name}
          onChangeText={name => {
            onChangeParams({ name });
          }}
          label="名前"
          errorMessage={
            rules &&
            validate({
              label: '名前',
              value: params.name,
              rule: rules.name
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          labelWidth={120}
          value={params.profile}
          onChangeText={profile => {
            onChangeParams({ profile });
          }}
          multiline
          rows={2}
          label="プロフィール"
          errorMessage={
            rules &&
            validate({
              label: 'プロフィール',
              value: params.profile,
              rule: rules.profile
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          labelWidth={120}
          value={params.email}
          onChangeText={email => {
            onChangeParams({ email });
          }}
          label="メールアドレス"
          errorMessage={
            rules &&
            validate({
              label: 'メールアドレス',
              value: params.email,
              rule: rules.email
            })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          labelWidth={120}
          value={params.twitterId}
          onChangeText={twitterId => {
            onChangeParams({ twitterId });
          }}
          label="Twitter ID"
          errorMessage={
            rules &&
            validate({
              label: 'Twitter ID',
              value: params.twitterId,
              rule: rules.twitterId
            })
          }
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}
