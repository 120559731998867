import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../Button';
import ReportTimelineItem from './ReportTimelineItem';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class DreamReportTimeline extends React.Component {
  render() {
    const { style = {} } = this.props;

    const dream = this.props.dream.hash[this.props.dreamId];

    if (!dream || this.props.dream.isGetLoading) {
      return null;
    }

    if (dream.reports.length < 1) {
      return (
        <View style={{ ...style }}>
          <View style={{ padding: 8 }}>
            <Text>レポートはありません。</Text>
          </View>
        </View>
      );
    }

    return (
      <View
        style={{
          ...style
        }}
      >
        {dream.reports.map((item, index) => {
          const isFirst = index === 0;

          const isLast = index === dream.reports.length - 1;

          return (
            <Link
              key={`report-${item.id}`}
              to={`/dream/${this.props.dreamId}/report/${item.id}`}
            >
              <ReportTimelineItem data={item} first={isFirst} last={isLast} />
            </Link>
          );
        })}
        <Link to={`/dream/${this.props.dreamId}/report`}>
          <Button variant="text">もっと見る</Button>
        </Link>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamReportTimeline);
