import React from 'react';

import Color from '../../constants/Color';
import Label from './Label';
import MaterialIcon from '../../utils/MaterialIcon';
import View from '../View';

export default class FormField extends React.Component {
  render() {
    const {
      label,
      labelWidth = 70,
      direction = 'row',
      verification = false,
      errorMessage,
      staticControl,
      children
    } = this.props;

    const containerStyle =
      direction === 'row'
        ? {
            flexDirection: 'row',
            alignItems: 'center'
          }
        : {
            flexDirection: 'column'
          };

    const verifyColor = !Boolean(errorMessage) ? Color.green : Color.warmGrey;

    return (
      <View style={containerStyle}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: direction === 'row' ? 0 : 8
          }}
        >
          <Label
            style={{
              minWidth: labelWidth
            }}
          >
            {label}
          </Label>
          {!staticControl && verification && (
            <View style={{ marginLeft: 4 }}>
              <MaterialIcon icon="check_circle" size={20} color={verifyColor} />
            </View>
          )}
        </View>
        <View style={{ flex: 1 }}>{children}</View>
      </View>
    );
  }
}
