import React from 'react';

import AdditionalFormCard from './AdditionalFormCard';
import Button from '../Button';
import Color from '../../constants/Color';
import FormField from './FormField';
import SelectFormField from './SelectFormField';
import Text from '../Text';
import TextFormField from './TextFormField';
import View from '../View';

export default class AdditionalFormField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isStartInput: false
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ isStartInput: true });
    }

    return true;
  }

  dispatchOnChangeData(path, key, value) {
    const { data, onChangeData } = this.props;

    onChangeData(data);
  }

  renderNode(scheme, datum, index, pathes) {
    const {
      staticControl,
      labelWidth = 120,
      onChangeData = () => {},
      onDeleteData = null
    } = this.props;

    return (
      <AdditionalFormCard
        key={`form-${index}`}
        scheme={scheme}
        data={datum}
        staticControl={staticControl}
        labelWidth={labelWidth}
        onChangeData={(key, value) => {
          onChangeData(index, key, value);
        }}
      >
        <View style={{ padding: 16 }}>
          {scheme.params.map(param => {
            if (param.control === 'hidden') {
              return null;
            }

            switch (param.type) {
              case 'text':
              case 'number':
                return (
                  <TextFormField
                    key={param.key}
                    name={param.key}
                    type={param.type}
                    staticControl={staticControl}
                    labelWidth={labelWidth}
                    value={datum[param.key]}
                    onChangeText={value =>
                      onChangeData(index, param.key, value, pathes)
                    }
                    label={param.label}
                    style={{ marginBottom: 16 }}
                  />
                );
              case 'select':
                return (
                  <SelectFormField
                    key={param.key}
                    staticControl={staticControl}
                    labelWidth={labelWidth}
                    label={param.label}
                    name={param.key}
                    items={param.items}
                    value={datum[param.key]}
                    onChangeValue={value =>
                      onChangeData(index, param.key, value, pathes)
                    }
                    style={{ marginBottom: 16 }}
                  />
                );
              default:
                return null;
            }
          })}
          {onDeleteData && (
            <Button
              variant="contained"
              color={Color.peach}
              mini
              onClick={() => {
                onDeleteData(index, pathes);
              }}
            >
              {`削除する`}
            </Button>
          )}
        </View>
      </AdditionalFormCard>
    );
  }

  renderInTraversal(scheme, datum, index, pathes) {
    switch (scheme.type) {
      case 'node':
        return (
          <View key={`${datum.id}`}>
            {this.renderNode(scheme, datum, index, pathes)}
          </View>
        );
      default:
        return null;
    }
  }

  /*

  scheme: {
    type: 'node',
    label: 'モデル',
    labelKey: 'modelLabel',
    params: [
      { key: 'modelType', label: 'モデルタイプ', type: 'select', items: [] },
      { key: 'modelLabel', label: 'ラベル', type: 'text' },
      { key: 'modelLevel', label: '階層', type: 'number' }
    ]
  }

  data: [
      {
        modelType: 'unselected',
        modelLabel: 'モデル1',
        modelLevel: 0
      }
  ]

  */

  render() {
    const {
      label,
      labelWidth = 70,
      data,
      schemeData,
      direction = 'row',
      verification = false,
      errorMessage,
      helpText,
      onAddData = null,
      staticControl,
      style = {}
    } = this.props;

    return (
      <View style={{ ...style }}>
        <FormField
          label={label}
          labelWidth={labelWidth}
          direction={direction}
          verification={verification}
          errorMessage={errorMessage}
          staticControl={staticControl}
        >
          {data.map((datum, index) => (
            <View key={datum.id}>
              {this.renderInTraversal(schemeData, datum, index, [])}
            </View>
          ))}
        </FormField>
        {onAddData && (
          <Button
            variant="contained"
            color={Color.lightSlate}
            onClick={() => {
              onAddData();
            }}
          >
            {`${schemeData.label}を追加する`}
          </Button>
        )}
        {this.state.isStartInput && errorMessage && (
          <Text
            style={{
              marginTop: 4,
              fontSize: 11,
              color: errorMessage ? Color.red : Color.lightGrey,
              paddingLeft: direction === 'row' ? labelWidth : 0
            }}
          >
            {this.state.isStartInput && errorMessage}
          </Text>
        )}
        {helpText && (
          <Text
            style={{
              marginTop: 4,
              fontSize: 11,
              color: Color.grey,
              paddingLeft: direction === 'row' ? labelWidth : 0
            }}
          >
            {helpText}
          </Text>
        )}
      </View>
    );
  }
}
