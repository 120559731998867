import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class SchemeProcessStreamFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.detail}
          name="detail"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={detail => {
            onChangeParams({
              detail
            });
          }}
          label="(1) 一連の流れ"
          errorMessage={
            rules &&
            validate({
              label: '一連の流れ',
              value: params.detail,
              rule: rules.detail
            })
          }
          limitLength={rules.detail.maxLength}
          helpText="このビジネスモデルの、それぞれの関係について、一番最初から最後まで全てを文章で表してください。全ての関係を網羅するように記述しましょう。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchemeProcessStreamFormField);
