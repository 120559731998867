import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import View from '../View';

export default function TextArea({
  value,
  onChange,
  style = {},
  inputStyle,
  errorMessage = '',
  limitLength = null,
  ...rest
}) {
  return (
    <View
      style={{
        backgroundColor: Color.white,
        borderRadius: 8,
        border: `solid 1px ${Color.lightGrey}`,
        padding: 4,
        ...style
      }}
    >
      <textarea
        type="text"
        value={value}
        onChange={event => onChange(event.target.value)}
        style={{ fontSize: 12, lineHeight: 2, padding: 4 }}
        {...rest}
        {...inputStyle}
      />
      {errorMessage && (
        <Text style={{ color: Color.red, fontSize: 11, padding: 4 }}>
          {errorMessage}
        </Text>
      )}
      {limitLength !== null && (
        <View style={{ alignItems: 'flex-end', padding: 4 }}>
          <Text
            style={{
              fontSize: 13,
              color: value.length > limitLength ? Color.red : Color.slate
            }}
          >
            {value.length}/{limitLength}
          </Text>
        </View>
      )}
    </View>
  );
}
