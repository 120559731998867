import * as activityHistory from './activityHistory';
import * as auth from './auth';
import * as config from './config';
import * as documentHistory from './documentHistory';
import * as dream from './dream';
import * as dreamApplication from './dreamApplication';
import * as dreamProduct from './dreamProduct';
import * as dreamReport from './dreamReport';
import * as dreamThank from './dreamThank';
import * as founder from './founder';
import * as job from './job';
import * as layout from './layout';
import * as marketing from './marketing';
import * as marketingForm from './marketingForm';
import * as patronContract from './patronContract';
import * as paymentInfo from './paymentInfo';
import * as proposal from './proposal';
import * as proposalForm from './proposalForm';
import * as review from './review';
import * as scheme from './scheme';
import * as schemeForm from './schemeForm';
import * as shippingInfo from './shippingInfo';
import * as sponsor from './sponsor';
import * as user from './user';

export default {
  ...activityHistory,
  ...auth,
  ...config,
  ...documentHistory,
  ...dream,
  ...dreamApplication,
  ...dreamProduct,
  ...dreamReport,
  ...dreamThank,
  ...founder,
  ...job,
  ...layout,
  ...marketing,
  ...marketingForm,
  ...patronContract,
  ...paymentInfo,
  ...proposal,
  ...proposalForm,
  ...review,
  ...scheme,
  ...schemeForm,
  ...shippingInfo,
  ...sponsor,
  ...user
};
