import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import ExemplariesSlider from '../../../components/Dream/ExemplariesSlider';
import Feature from '../../../components/Feature/Feature';
import Footer from '../../../components/Footer';
import Logo from '../../../components/Icons/Logo';
import MaterialIcon from '../../../utils/MaterialIcon';
import PageHeader from '../../../containers/page/PageHeader';
import Text from '../../../components/Text';
import TopHeader from '../../../components/Top/TopHeader';
import View from '../../../components/View';
import Wish from '../../../containers/wish/Wish';
import actions from '../../../actions';

class Top extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getDreams({ reset: true });
  }

  render() {
    const dreams = this.props.dream.list.map(id => this.props.dream.hash[id]);

    const exemplaries = dreams.filter(dream => dream.level === 'exemplary');

    // const pickups = dreams.filter(dream => dream.level === 'pickup');

    // const defaults = dreams.filter(dream => dream.level === 'default');

    return (
      <View>
        <PageHeader />
        <View
          style={{
            backgroundColor: Color.deepBlack,
            alignItems: 'center',
            padding: '48px 0 24px 0',
            marginBottom: -1
          }}
        >
          <Logo fill={Color.white} size={80} />
        </View>
        <TopHeader>
          <ExemplariesSlider
            data={exemplaries}
            style={{ margin: '-32px 0 -32px 0' }}
          />
        </TopHeader>
        {/*<View
          style={{
            borderRadius: 8,
            backgroundColor: Color.white,
            margin: '-8px 0',
            ...Style.shadow.large,
            zIndex: 1
          }}
        >
          {pickups.length > 0 && (
            <View style={{ marginTop: 24 }}>
              <Headline style={{ paddingLeft: 24, marginBottom: 16 }}>
                ピックアップ
              </Headline>
              <View style={{ marginBottom: 32 }}>
                <DreamCardSlider data={pickups} />
              </View>
            </View>
          )}
          {defaults.length > 0 && (
            <View style={{ marginTop: 16 }}>
              <Headline style={{ paddingLeft: 24, marginBottom: 16 }}>
                こちらもおすすめ
              </Headline>
              <FounderDreamList
                data={defaults}
                style={{ padding: '0 16px 0 16px' }}
              />
            </View>
          )}
          {pickups.length === 0 && defaults.length === 0 && (
            <View style={{ marginTop: 16 }}>
              <Headline style={{ paddingLeft: 16, marginBottom: 16 }}>
                プロジェクト募集中
              </Headline>
              <View style={{ padding: '0 16px 16px 16px' }}>
                <Text style={{ fontSize: 15, color: Color.lightSlate }}>
                  プロジェクトはありません。
                </Text>
              </View>
            </View>
          )}
        </View>*/}
        {/* <FounderRecruit style={{ marginBottom: -1 }} /> */}
        <View style={{ backgroundColor: Color.navyBlack }}>
          <Feature style={{ marginBottom: -8 }} />
          <View
            style={{
              backgroundImage: `url(${require('../../../assets/img/title-second-header.jpg')})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '48px 0 56px 0',
              alignItems: 'center'
            }}
          />
          <Wish />
        </View>
        <Footer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Top);
