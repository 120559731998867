import React from 'react';

import { getGradient, getGradientCss } from '../../utils/ColorJs';
import Color from '../../constants/Color';
import Deadline from '../../utils/Deadline';
import FundingResult from '../../utils/FundingResult';
import Text from '../Text';
import View from '../View';

export default function({
  data: { currentFundingPrice, fundingGoalPrice, deadlineAt },
  style = {},
  textStyle = {}
}) {
  const deadline = new Deadline(deadlineAt);

  const result = new FundingResult(
    currentFundingPrice,
    fundingGoalPrice,
    deadline
  );

  if (result.status === 'funding') {
    return null;
  }

  let containerStyle = {};

  if (result.status === 'success') {
    containerStyle = {
      backgroundImage: getGradientCss(getGradient(Color.pinkGradient), 135)
    };
  } else if (result.status === 'failed') {
    containerStyle = {
      backgroundColor: Color.slate
    };
  }

  let label = '';

  if (result.status === 'success') {
    label = 'SUCCESS !!';
  } else if (result.status === 'failed') {
    label = 'NOT FUNDED...';
  }

  return (
    <View
      style={{
        padding: '4px 12px',
        ...containerStyle,
        ...style
      }}
    >
      <Text
        style={{
          color: Color.white,
          fontSize: 17,
          fontWeight: 'bold',
          ...textStyle
        }}
      >
        {label}
      </Text>
    </View>
  );
}
