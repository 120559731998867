import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../../components/Button';
import Color from '../../../../constants/Color';
import Label from '../../../../components/Form/Label';
import PageHeader from '../../../../containers/page/PageContaineredHeader';
import TextArea from '../../../../components/Form/TextArea';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypageProfileEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: (props.user.myself && props.user.myself.profile) || ''
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.user.isGetMyselfLoading &&
      !nextProps.user.isGetMyselfLoading
    ) {
      const { myself } = nextProps.user;

      this.setState({
        profile: myself.profile
      });
    }

    return true;
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <View style={{ padding: 16 }}>
          <Label style={{ marginBottom: 8 }}>ユーザー名</Label>
          <TextArea
            value={this.state.profile}
            onChange={text => {
              this.setState({ profile: text });
            }}
            style={{ marginBottom: 16 }}
          />
          <Button
            onClick={() => {
              this.props.updateUser(this.props.auth.uid, {
                consumer: { profile: this.state.profile }
              });
            }}
            disable={this.props.user.isUpdateLoading}
            style={{ marginBottom: 16 }}
          >
            更新する
          </Button>
          <Link to="/mypage">
            <Button variant="flat">戻る</Button>
          </Link>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypageProfileEdit);
