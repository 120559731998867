import { UPDATE_WINDOW_SIZE } from '../constants/ActionTypes';

export function updateWindowSize({ width, height }) {
  return {
    type: UPDATE_WINDOW_SIZE,
    state: {
      width,
      height
    }
  };
}
