import React from 'react';

import Color from '../constants/Color';
import View from './View';

export default function Paragraph({
  onerow = false,
  children,
  containerStyle = {},
  style = {}
}) {
  let content = children;

  if (Array.isArray(children)) {
    // content = [children.reduce((acc, str) => acc + str, '')];
  } else {
    content = String(children).split('\n');
  }

  return (
    <View style={containerStyle}>
      {onerow ? (
        <p
          style={{
            color: Color.slate,
            fontSize: 13,
            lineHeight: 1.5,
            ...style
          }}
        >
          {children}
        </p>
      ) : (
        content.map((line, index) => (
          <p
            key={`paragraph-${index}`}
            style={{
              color: Color.slate,
              fontSize: 13,
              lineHeight: 1.5,
              ...style
            }}
          >
            {line}
          </p>
        ))
      )}
    </View>
  );
}
