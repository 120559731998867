import { getCollection } from '../utils/Firebase';

export default class JobSuccessProject {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      title: state.title,
      detail: state.detail,
      supporterCount: state.supporterCount,
      fundAmount: state.fundAmount,
      fundingType: state.fundingType,
      rewards: state.rewards
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }

  static async get(jobId, successProjectId) {
    const jobDoc = await getCollection('jobs')
      .doc(jobId)
      .collection('successProjects')
      .doc(successProjectId)
      .get();

    const founder = new JobSuccessProject({
      id: jobDoc.id,
      ...jobDoc.data()
    }).fromFirestore();

    return founder;
  }
}
