import { getCollection } from '../utils/Firebase';
import { request } from '../utils/Network';
import Founder from '../models/Founder';
import * as types from '../constants/ActionTypes';

// get patron contracts
function fetchGetPatronContracts({ reset = false }) {
  return {
    type: types.FETCH_GET_PATRON_CONTRACTS,
    state: { reset }
  };
}

function fetchGetPatronContractsSuccess(list) {
  return {
    type: types.FETCH_GET_PATRON_CONTRACTS_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetPatronContractsFailed(error) {
  return {
    type: types.FETCH_GET_PATRON_CONTRACTS_FAILED,
    error
  };
}

export function recoverGetPatronContracts() {
  return {
    type: types.FETCH_GET_PATRON_CONTRACTS_RECOVER
  };
}

export function getPatronContracts(options = { reset: false, lastItem: null }) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchGetPatronContracts({ reset: options.reset }));

    let query = getCollection('consumers')
      .doc(auth.uid)
      .collection('patronContracts');

    if (options.lastItem) {
      query = query.startAfter(options.lastItem.replyedAt);
    }

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(async doc => {
            const data = doc.data();

            const founder = await Founder.get(data.founderId);

            return {
              id: doc.id,
              founder,
              ...data
            };
          })
        );

        return dispatch(fetchGetPatronContractsSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetPatronContractsFailed(error));
        }
      });
  };
}

// get
function fetchGetPatronContract() {
  return {
    type: types.FETCH_GET_PATRON_CONTRACT
  };
}

function fetchGetPatronContractSuccess(id, params) {
  return {
    type: types.FETCH_GET_PATRON_CONTRACT_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetPatronContractFailed(error) {
  return {
    type: types.FETCH_GET_PATRON_CONTRACT_FAILED,
    error
  };
}

export function recoverGetPatronContract() {
  return {
    type: types.FETCH_GET_PATRON_CONTRACT_RECOVER
  };
}

export function getPatronContract(id) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchGetPatronContract());

    let query = getCollection('consumers')
      .doc(auth.uid)
      .collection('patronContracts')
      .doc(id);

    return query
      .get()
      .then(async doc => {
        const data = doc.data();

        const founder = await Founder.get(data.founderId);

        return dispatch(
          fetchGetPatronContractSuccess(id, {
            id: doc.id,
            founder,
            ...data
          })
        );
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetPatronContractFailed(error));
        }
      });
  };
}

// create patron contract
function fetchCreatePatronContract() {
  return {
    type: types.FETCH_CREATE_PATRON_CONTRACT
  };
}

function fetchCreatePatronContractSuccess(contractId, { activityHistories }) {
  return {
    type: types.FETCH_CREATE_PATRON_CONTRACT_SUCCESS,
    state: {
      contractId,
      reset: true,
      list: activityHistories
    }
  };
}

function fetchCreatePatronContractFailed(error) {
  return {
    type: types.FETCH_CREATE_PATRON_CONTRACT_FAILED,
    error
  };
}

export function recoverCreatePatronContract() {
  return {
    type: types.FETCH_CREATE_PATRON_CONTRACT_RECOVER
  };
}

export function createPatronContract({
  founderId,
  amount,
  token,
  paymentInfoId
}) {
  return dispatch => {
    dispatch(fetchCreatePatronContract());

    return request(dispatch, 'POST', '/createPatronContract', {
      params: {
        founderId,
        amount,
        token,
        paymentInfoId
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchCreatePatronContractFailed(error));
      }

      return dispatch(fetchCreatePatronContractSuccess(response.id, response));
    });
  };
}

// delete patron contract
function fetchDeletePatronContract() {
  return {
    type: types.FETCH_DELETE_PATRON_CONTRACT
  };
}

function fetchDeletePatronContractSuccess(contractId, { activityHistories }) {
  return {
    type: types.FETCH_DELETE_PATRON_CONTRACT_SUCCESS,
    state: {
      contractId,
      reset: true,
      list: activityHistories
    }
  };
}

function fetchDeletePatronContractFailed(error) {
  return {
    type: types.FETCH_DELETE_PATRON_CONTRACT_FAILED,
    error
  };
}

export function recoverDeletePatronContract() {
  return {
    type: types.FETCH_DELETE_PATRON_CONTRACT_RECOVER
  };
}

export function deletePatronContract(patronContractId) {
  return dispatch => {
    dispatch(fetchDeletePatronContract());

    return request(dispatch, 'POST', '/deletePatronContract', {
      params: {
        patronContractId
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchDeletePatronContractFailed(error));
      }

      return dispatch(fetchDeletePatronContractSuccess(response.id, response));
    });
  };
}
