import React, { Component } from 'react';

import Button from '../Button';
import View from '../View';

export default class LoadMoreList extends Component {
  state = {
    isLoading: false,
    isRefreshing: false,
    isReached: false
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoading && this.props.isLoading) {
      this.setState({ isRefreshing: false });
    }
    if (
      !nextProps.isLoading &&
      this.props.isLoading &&
      !this.state.isRefreshing &&
      nextProps.data.length === this.props.data.length
    ) {
      this.setState({ isReached: true });
    }
  }

  render() {
    const {
      data,
      renderItem,
      isLoading = false,
      onFetchMore = null
    } = this.props;

    return (
      <View>
        {data.map((datum, index) => {
          return renderItem({ item: datum, index });
        })}
        {!this.state.isReached && onFetchMore && (
          <Button
            variant="text"
            loading={isLoading}
            onClick={() => {
              if (isLoading || this.state.isLoading || this.state.isReached) {
                return;
              }

              onFetchMore(data[data.length - 1]);
            }}
          >
            もっと見る
          </Button>
        )}
      </View>
    );
  }
}
