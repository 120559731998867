import {
  Document,
  PDFDownloadLink,
  PDFViewer,
  Page
} from '@react-pdf/renderer';
import React from 'react';
import moment from 'moment';

import { PdfImage, PdfText, PdfView } from '../../utils/Pdf';
import Button from '../Button';
import Color from '../../constants/Color';
import PdfCard from './PdfCard';
import PdfCategoryCard from './PdfCategoryCard';
import PdfEssenceSection from './PdfEssenceSection';
import PdfFeatureCard from './PdfFeatureCard';
import PdfFeaturePanel from './PdfFeaturePanel';
import PdfFlow from './PdfFlow';
import PdfHeadline from './PdfHeadline';
import PdfIntroduction from './PdfIntroduction';
import PdfProfitCard from './PdfProfitCard';
import View from '../View';

const HEADER_IMAGE = require('../../assets/img/pdf/proposal/header.png');
const PROCESS_IMAGE = require('../../assets/img/pdf/proposal/process.png');
const PROFIT_IMAGE = require('../../assets/img/pdf/proposal/profit.png');

export default class Proposal extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { data, preview = false, style = {}, previewStyle = {} } = this.props;

    const primaryColor = `#${data.basicInfo.primaryColor}`;

    const secondaryColor = `#${data.basicInfo.secondaryColor}`;

    const ProposalComponent = (
      <Document>
        <Page
          size="A3"
          orientation="landscape"
          style={{
            flexDirection: 'row'
          }}
          wrap={false}
        >
          <PdfView
            style={{
              width: 595,
              height: 842,
              position: 'relative'
            }}
          >
            <PdfView
              style={{
                flex: 1,
                justifyContent: 'space-between',
                position: 'relative'
              }}
            >
              <PdfImage src={HEADER_IMAGE} />
              <PdfImage
                src={data.assets.proposalBackground}
                style={{
                  width: 1190,
                  height: 317,
                  top: 36
                }}
              />
              <PdfImage src={PROCESS_IMAGE} />
            </PdfView>
            <PdfView
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
              }}
            >
              <PdfView
                style={{
                  flexDirection: 'row',
                  width: 595,
                  height: 145,
                  marginBottom: 40
                }}
              >
                <PdfView style={{ flex: 1 }}>
                  <PdfText
                    style={{
                      fontSize: 24,
                      fontWeight: 'bold',
                      color: Color.white,
                      textDecoration: 'underline',
                      paddingTop: 40,
                      paddingLeft: 40,
                      marginBottom: 16
                    }}
                  >
                    {data.basicInfo.title}
                  </PdfText>
                  <PdfView style={{ flexDirection: 'row' }}>
                    <PdfView
                      style={{
                        width: 80,
                        height: 80,
                        padding: 3,
                        backgroundColor: Color.white,
                        border: `1pt solid ${Color.lightGrey}`,
                        marginLeft: 40
                      }}
                    >
                      <PdfImage
                        src={data.assets.profileImage}
                        style={{ width: 72, height: 72 }}
                      />
                    </PdfView>
                    <PdfView
                      style={{
                        paddingTop: 8,
                        paddingLeft: 16
                      }}
                    >
                      <PdfText
                        style={{
                          fontSize: 10,
                          fontWeight: 'bold',
                          color: Color.white,
                          marginBottom: 8
                        }}
                      >
                        {data.basicInfo.name} （{data.basicInfo.birthday}
                        生）
                        {data.basicInfo.job}
                      </PdfText>
                      <PdfText
                        style={{
                          fontSize: 10,
                          fontWeight: 'bold',
                          color: Color.white,
                          width: 280,
                          lineHeight: 1.3
                        }}
                      >
                        {data.basicInfo.biography}
                      </PdfText>
                    </PdfView>
                  </PdfView>
                </PdfView>
                <PdfView
                  style={{
                    alignItems: 'flex-end'
                  }}
                >
                  <PdfView
                    style={{
                      width: 145,
                      height: 145,
                      backgroundColor: primaryColor,
                      marginRight: 16,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <PdfImage
                      src={data.assets.category}
                      style={{ width: 64, height: 64, marginBottom: 8 }}
                    />
                    <PdfText
                      style={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: Color.white
                      }}
                    >
                      {data.categoryName}
                    </PdfText>
                  </PdfView>
                </PdfView>
              </PdfView>
              <PdfView style={{ paddingLeft: 40 }}>
                {/* INTRODUCTION */}
                <PdfView style={{ height: 144, marginBottom: 24 }}>
                  <PdfHeadline
                    title="INTRODUCTION"
                    descImage={data.assets.proposalHeadlineDesc}
                    subTitle="（導入）"
                    style={{ marginBottom: 16 }}
                  />
                  <PdfView
                    style={{
                      flexDirection: 'row',
                      paddingRight: 20
                    }}
                  >
                    <PdfIntroduction
                      title={`創業までの\nストーリー`}
                      content={data.introduction.story}
                      icon={data.assets.book}
                      color={primaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                    <PdfIntroduction
                      title={`ターゲットと\n提供する体験`}
                      content={data.introduction.ux}
                      icon={data.assets.account}
                      color={primaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                    <PdfIntroduction
                      title={`懸念点と\n実現のポイント`}
                      content={data.introduction.factor}
                      icon={data.assets.checkboxMarkedCircleOutline}
                      color={primaryColor}
                      style={{ flex: 1 }}
                    />
                  </PdfView>
                </PdfView>
                {/* SPECIFICATION */}
                <PdfView style={{ height: 225, marginBottom: 24 }}>
                  <PdfHeadline
                    title="SPECIFICATION"
                    descImage={data.assets.proposalHeadlineDesc}
                    subTitle="（事業内容）"
                    style={{ marginBottom: 16 }}
                  />
                  <PdfView
                    style={{
                      flexDirection: 'row',
                      paddingRight: 20,
                      marginBottom: 8
                    }}
                  >
                    <PdfCard
                      title="仕様・特徴"
                      content={data.specification.specification}
                      color={primaryColor}
                      subColor={secondaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                    <PdfCard
                      title="研究内容・新企画状況"
                      content={data.specification.plan}
                      color={primaryColor}
                      subColor={secondaryColor}
                      style={{ flex: 1 }}
                    />
                  </PdfView>
                  <PdfView
                    style={{
                      flexDirection: 'row',
                      paddingRight: 20
                    }}
                  >
                    <PdfCard
                      title="提供"
                      content={data.specification.supply}
                      label={data.specification.supplyClassName}
                      color={primaryColor}
                      subColor={secondaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                    <PdfCard
                      title="価格帯"
                      content={data.specification.pricing}
                      label={data.specification.pricingClassName}
                      color={primaryColor}
                      subColor={secondaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                    <PdfCard
                      title="納期"
                      content={data.specification.timeOfDeliver}
                      label={data.specification.timeOfDeliverClassName}
                      color={primaryColor}
                      subColor={secondaryColor}
                      style={{ flex: 1 }}
                    />
                  </PdfView>
                </PdfView>
                {/* PROCESS */}
                <PdfView style={{ height: 201 }}>
                  <PdfHeadline
                    title="PROCESS"
                    descImage={data.assets.proposalHeadlineDesc}
                    subTitle="（業務プロセス）"
                    style={{ marginBottom: 16 }}
                  />
                  <PdfView style={{ height: 173, flexDirection: 'row' }}>
                    <PdfFlow
                      title={`仕入・供給\nルート`}
                      content={data.businessProcess.buying}
                      icon={data.assets.cartPlus}
                      color={primaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                    <PdfFlow
                      title={`製造・外注\nルート`}
                      content={data.businessProcess.production}
                      icon={data.assets.factory}
                      color={primaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                    <PdfFlow
                      title={`販売・流通\nルート`}
                      content={data.businessProcess.distribution}
                      icon={data.assets.store}
                      color={primaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                    <PdfFlow
                      title={`販促・PR\nルート`}
                      content={data.businessProcess.marketing}
                      icon={data.assets.briefcaseUpload}
                      color={primaryColor}
                      style={{ flex: 1, marginRight: 16 }}
                    />
                  </PdfView>
                </PdfView>
              </PdfView>
            </PdfView>
          </PdfView>
          <PdfView
            style={{
              width: 595,
              height: 842,
              position: 'relative'
            }}
          >
            <PdfView style={{ flex: 1, justifyContent: 'flex-end' }}>
              <PdfImage src={PROFIT_IMAGE} />
            </PdfView>
            <PdfView
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                paddingTop: 40,
                paddingLeft: 20,
                paddingBottom: 40,
                paddingRight: 20
              }}
            >
              {/* ENVIRONMENT */}
              <PdfView style={{ height: 178, marginBottom: 24 }}>
                <PdfHeadline
                  title="ENVIRONMENT"
                  descImage={data.assets.proposalHeadlineDesc}
                  subTitle="（経営環境）"
                  style={{ marginBottom: 16 }}
                />
                <PdfView style={{ flexDirection: 'row', marginBottom: 16 }}>
                  <PdfEssenceSection
                    title="マーケット動向"
                    content={data.environment.market}
                    icon={data.assets.basket}
                    color={primaryColor}
                    subColor={secondaryColor}
                    style={{ flex: 1, marginRight: 16 }}
                  />
                  <PdfEssenceSection
                    title="競合状況"
                    content={data.environment.competitor}
                    icon={data.assets.chartBar}
                    color={primaryColor}
                    subColor={secondaryColor}
                    style={{ flex: 1 }}
                  />
                </PdfView>
                <PdfView style={{ flexDirection: 'row' }}>
                  <PdfEssenceSection
                    title="代替状況"
                    content={data.environment.substitute}
                    icon={data.assets.findReplace}
                    color={primaryColor}
                    subColor={secondaryColor}
                    style={{ flex: 1, marginRight: 16 }}
                  />
                  <PdfEssenceSection
                    title="事業リスク"
                    content={data.environment.risk}
                    icon={data.assets.alert}
                    color={primaryColor}
                    subColor={secondaryColor}
                    style={{ flex: 1 }}
                  />
                </PdfView>
              </PdfView>
              {/* FEATURE */}
              <PdfView
                style={{
                  height: 357,
                  marginBottom: 40
                }}
              >
                <PdfHeadline
                  title="FEATURE"
                  descImage={data.assets.proposalHeadlineDesc}
                  subTitle="（特徴）"
                  style={{ marginBottom: 16 }}
                />
                <PdfView style={{ flexDirection: 'row', marginBottom: 8 }}>
                  <PdfCategoryCard
                    title="内部特質"
                    color={secondaryColor}
                    style={{ marginRight: 8 }}
                  />
                  <PdfFeatureCard
                    title="STRENGTHS"
                    ruby="強み"
                    importanceLevel={4}
                    importanceActiveIcon={data.assets.thumbUpActive}
                    importanceInactiveIcon={data.assets.thumbUpInactive}
                    color={primaryColor}
                    subColor={secondaryColor}
                    content={data.feature.strengths}
                    style={{ flex: 1, marginRight: 8 }}
                  />
                  <PdfFeatureCard
                    title="WEAKNESSES"
                    ruby="弱み"
                    importanceLevel={3}
                    importanceActiveIcon={data.assets.alertActive}
                    importanceInactiveIcon={data.assets.alertInactive}
                    color={primaryColor}
                    subColor={secondaryColor}
                    content={data.feature.weaknesses}
                    style={{ flex: 1 }}
                  />
                </PdfView>
                <PdfView style={{ flexDirection: 'row', marginBottom: 8 }}>
                  <PdfCategoryCard
                    title="外部特質"
                    color={secondaryColor}
                    style={{ marginRight: 8 }}
                  />
                  <PdfFeatureCard
                    title="OPPORTUNITIES"
                    ruby="機会"
                    importanceLevel={1}
                    importanceActiveIcon={data.assets.thumbUpActive}
                    importanceInactiveIcon={data.assets.thumbUpInactive}
                    color={primaryColor}
                    subColor={secondaryColor}
                    content={data.feature.opportunities}
                    style={{ flex: 1, marginRight: 8 }}
                  />
                  <PdfFeatureCard
                    title="THREATS"
                    ruby="脅威"
                    importanceLevel={2}
                    importanceActiveIcon={data.assets.alertActive}
                    importanceInactiveIcon={data.assets.alertInactive}
                    color={primaryColor}
                    subColor={secondaryColor}
                    content={data.feature.threats}
                    style={{ flex: 1 }}
                  />
                </PdfView>
                <PdfView style={{ flexDirection: 'row', marginBottom: 24 }}>
                  <PdfFeaturePanel
                    title="独自性"
                    icon={data.assets.earth}
                    content={data.feature.identity}
                    color={secondaryColor}
                    style={{ flex: 1, marginRight: 8 }}
                  />
                  <PdfFeaturePanel
                    title="成長性"
                    icon={data.assets.trendingUp}
                    content={data.feature.potential}
                    color={secondaryColor}
                    style={{ flex: 1, marginRight: 8 }}
                  />
                  <PdfFeaturePanel
                    title="成功ポイント"
                    icon={data.assets.playlistCheck}
                    content={data.feature.keyFactor}
                    color={secondaryColor}
                    style={{ flex: 1, marginRight: 8 }}
                  />
                  <PdfFeaturePanel
                    title="優先課題"
                    icon={data.assets.clipboardCheck}
                    content={data.feature.issue}
                    color={secondaryColor}
                    style={{ flex: 1 }}
                  />
                </PdfView>
              </PdfView>
              {/* PROFIT */}
              <PdfView style={{ height: 164 }}>
                <PdfHeadline
                  title="PROFIT"
                  descImage={data.assets.proposalHeadlineDesc}
                  subTitle="（営利）"
                  style={{ marginBottom: 16 }}
                />
                <PdfView style={{ flexDirection: 'row' }}>
                  <PdfView
                    style={{
                      flex: 6,
                      justifyContent: 'space-between',
                      marginRight: 16
                    }}
                  >
                    <PdfView style={{ flexDirection: 'row', marginBottom: 16 }}>
                      <PdfProfitCard
                        title={data.profit.salesTitle1}
                        amount={data.profit.salesAmount1}
                        unit={data.profit.salesUnit1}
                        color={primaryColor}
                        style={{ flex: 1, marginRight: 4 }}
                      />
                      <PdfProfitCard
                        title={data.profit.salesTitle2}
                        amount={data.profit.salesAmount2}
                        unit={data.profit.salesUnit2}
                        color={primaryColor}
                        style={{ flex: 1, marginRight: 4 }}
                      />
                      <PdfProfitCard
                        title={data.profit.salesTitle3}
                        amount={data.profit.salesAmount3}
                        unit={data.profit.salesUnit3}
                        color={primaryColor}
                        style={{ flex: 1 }}
                      />
                    </PdfView>
                    <PdfView style={{ flexDirection: 'row' }}>
                      <PdfProfitCard
                        title={data.profit.costTitle1}
                        amount={data.profit.costAmount1}
                        unit={data.profit.costUnit1}
                        color={primaryColor}
                        style={{ flex: 1, marginRight: 4 }}
                      />
                      <PdfProfitCard
                        title={data.profit.costTitle2}
                        amount={data.profit.costAmount2}
                        unit={data.profit.costUnit2}
                        color={primaryColor}
                        style={{ flex: 1, marginRight: 4 }}
                      />
                      <PdfProfitCard
                        title={data.profit.costTitle3}
                        amount={data.profit.costAmount3}
                        unit={data.profit.costUnit3}
                        color={primaryColor}
                        style={{ flex: 1 }}
                      />
                    </PdfView>
                  </PdfView>
                  <PdfView
                    style={{
                      flex: 2,
                      justifyContent: 'space-between',
                      marginRight: 16
                    }}
                  >
                    <PdfProfitCard
                      title={data.profit.salesTotalTitle}
                      amount={data.profit.salesTotalAmount}
                      unit={data.profit.salesTotalUnit}
                      color={primaryColor}
                      style={{
                        border: `2pt solid ${primaryColor}`,
                        marginBottom: 16
                      }}
                    />
                    <PdfProfitCard
                      title={data.profit.costTotalTitle}
                      amount={data.profit.costTotalAmount}
                      unit={data.profit.costTotalUnit}
                      color={primaryColor}
                      style={{ border: `2pt solid ${Color.lightSlate}` }}
                    />
                  </PdfView>
                  <PdfProfitCard
                    title={data.profit.profitTitle}
                    amount={data.profit.profitAmount}
                    unit={data.profit.profitUnit}
                    color={primaryColor}
                    style={{
                      backgroundColor: primaryColor,
                      flex: 3
                    }}
                    titleStyle={{ color: Color.white, fontSize: 14 }}
                    amountStyle={{ color: Color.white, fontSize: 42 }}
                    unitStyle={{ color: Color.white, fontSize: 20 }}
                  />
                </PdfView>
              </PdfView>
            </PdfView>
          </PdfView>
        </Page>
      </Document>
    );

    const timestamp = moment().format('YYYYMMDD_HHmm');

    return (
      <div style={style}>
        {preview && (
          <View style={{ marginBottom: 16 }}>
            <PDFViewer width={previewStyle.width} height={previewStyle.height}>
              {ProposalComponent}
            </PDFViewer>
          </View>
        )}
        {!preview && (
          <PDFDownloadLink
            document={ProposalComponent}
            fileName={`${timestamp}_${data.basicInfo.title}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <Button disable>生成中</Button>
              ) : (
                <Button>ダウンロード</Button>
              )
            }
          </PDFDownloadLink>
        )}
      </div>
    );
  }
}
