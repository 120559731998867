import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { PROPOSAL_APPLICATION_VALIDATION_RULE } from '../../../constants/Validations';
import ApplicationFormField from '../../../components/ApplicationFormField';
import ArrowStream from '../../../components/Icons/ArrowStream';
import Button from '../../../components/Button';
import CardPrimitive from '../../../components/Card/CardPrimitive';
import Color from '../../../constants/Color';
import Footer from '../../../components/Footer';
import Headline from '../../../components/Headline';
import ImageIntroduction from '../../../components/ImageIntroduction';
import MaterialIcon from '../../../utils/MaterialIcon';
import PageHeader from '../../../containers/page/PageHeader';
import Panel from '../../../components/Panel';
import Paragraph from '../../../components/Paragraph';
import PasswordForm from '../../../components/Form/PasswordForm';
import ProposalDownloadLink from '../../../containers/proposal/ProposalDownloadLink';
import SectionCard from '../../../components/Card/SectionCard';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalResult extends React.Component {
  state = {
    application: {
      email: '',
      phoneNumber: '',
      editPassword: ''
    },
    viewPassword: ''
  };

  componentDidMount() {
    this.props.getProposal(this.props.match.params.id);
  }

  render() {
    const proposal = this.props.proposal.hash[this.props.match.params.id];

    if (!proposal) {
      return (
        <View>
          <PageHeader />
          <PasswordForm
            description="この事業計画書はダウンロードに閲覧パスワードが必要です。"
            passwordType="view"
            isLoading={this.props.proposal.isGetLoading}
            onSubmit={password =>
              this.props.getProposal(this.props.match.params.id, password)
            }
          />
          <Footer />
        </View>
      );
    }

    return (
      <View>
        <PageHeader />
        <View style={{ padding: '16px 16px 0px 16px', marginBottom: 24 }}>
          <Headline style={{ marginBottom: 16 }}>
            事業計画書のダウンロード
          </Headline>
          <Paragraph containerStyle={{ marginBottom: 16 }}>
            利用規約に同意して、事業計画書をダウンロードすることができます。
          </Paragraph>
        </View>
        <ProposalDownloadLink
          data={proposal}
          style={{ zIndex: 1, padding: '0 16px' }}
        />
        <ImageIntroduction
          gradient={Color.deepBlueGradient}
          source={{
            uri: require('../../../assets/img/building.jpg')
          }}
          style={{
            marginTop: -32,
            pointerEvents: 'none'
          }}
        >
          <View style={{ alignItems: 'center', paddingTop: 16 }}>
            <ArrowStream color={Color.navyBlack} />
          </View>
          <View style={{ padding: '24px 16px 0 16px' }}>
            <Paragraph
              style={{
                color: Color.white,
                fontSize: 20,
                fontWeight: 'bold'
              }}
              containerStyle={{ marginBottom: 24 }}
            >
              その事業、
              {'\n'}
              クラウドファンディングで
              {'\n'}
              始めませんか？
            </Paragraph>
            <SectionCard
              title="クラウドファンディング"
              panel={
                <Panel gradient={Color.pinkGradient} size={40}>
                  <MaterialIcon icon="cloud" color={Color.white} size={24} />
                </Panel>
              }
              style={{ marginBottom: 24 }}
            >
              <Paragraph>
                インターネット上であなたの事業の概要を掲載し、共感や協賛してくださった方からお金を集める仕組みです。
                集めるお金に対するリターンは自由に設定することができるため、リスクを少なく開業資金を集めることができます。
              </Paragraph>
            </SectionCard>
            <SectionCard
              title="メリット"
              panel={
                <Panel gradient={Color.pinkGradient} size={40}>
                  <MaterialIcon
                    icon="verified_user"
                    color={Color.white}
                    size={24}
                  />
                </Panel>
              }
              style={{ marginBottom: 24 }}
            >
              <Paragraph containerStyle={{ marginBottom: 16 }}>
                クラウドファンディングは、事業を始める上で大きな３つのメリットがあります。
              </Paragraph>
              <Headline size={3} style={{ marginBottom: 8 }}>
                1. 資金のリスク低減
              </Headline>
              <Paragraph containerStyle={{ marginBottom: 16 }}>
                ある程度お金が集まってから事業を開始することで、事業リスクを低減することができます。
                借り入れとは違い、商品の先行予約などによってお金を集めるため、商品をお届けすることができれば、お金を返す必要がありません。
              </Paragraph>
              <Headline size={3} style={{ marginBottom: 8 }}>
                2. 事業のリスク低減
              </Headline>
              <Paragraph containerStyle={{ marginBottom: 16 }}>
                賛同者が少なければ、事業を取りやめることができます。
                あなたのアイデアを公開する必要はありますが、資金を集める前に「本当に世の中に必要なのか」と世間に問いかけ、その反応を集金額で知ることができます。
              </Paragraph>
              <Headline size={3} style={{ marginBottom: 8 }}>
                3. 事業開始時の初速を向上
              </Headline>
              <Paragraph containerStyle={{ marginBottom: 16 }}>
                最初から賛同者がいる状態から事業を始められるため、事業を有利に始めることができます。
              </Paragraph>
            </SectionCard>
            <SectionCard
              title="まずは応募してみましょう"
              panel={
                <Panel gradient={Color.pinkGradient} size={40}>
                  <MaterialIcon
                    icon="local_post_office"
                    color={Color.white}
                    size={24}
                  />
                </Panel>
              }
              style={{ marginBottom: 24 }}
            >
              <Paragraph>
                作成いただいた事業計画書を応募し、クラウドファンディングで事業を始めてみませんか？
                まずは、下記のフォームへ連絡先の情報を記入いただき、ご応募ください。
              </Paragraph>
            </SectionCard>
            <View style={{ alignItems: 'center', marginBottom: 24 }}>
              <ArrowStream color={Color.white} />
            </View>
            <CardPrimitive style={{ padding: 16 }}>
              <Headline style={{ color: Color.lightSlate, marginBottom: 16 }}>
                ご連絡先
              </Headline>
              <ApplicationFormField
                params={this.state.application}
                direction="column"
                onChangeParams={state =>
                  this.setState({
                    application: { ...this.state.application, ...state }
                  })
                }
                rules={PROPOSAL_APPLICATION_VALIDATION_RULE}
                style={{ marginBottom: 32 }}
              />
              <Button>応募する</Button>
            </CardPrimitive>
          </View>
        </ImageIntroduction>
        <Footer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalResult);
