import moment from 'moment';

import Model from './Model';

export default class ShippingInfo extends Model {
  constructor(state = {}) {
    super();

    this.params = {
      id: state.id || '',
      kanjiSei: state.kanjiSei || '',
      kanjiMei: state.kanjiMei || '',
      furiganaSei: state.furiganaSei || '',
      furiganaMei: state.furiganaMei || '',
      gender: state.gender || 'unselected',
      birthday: state.birthday || moment().format('YYYY-MM-DD'),
      phoneNumber: state.phoneNumber || '',
      postalCode: state.postalCode || '',
      prefecture: state.prefecture || 'unselected',
      city: state.city || '',
      addressLine1: state.addressLine1 || '',
      addressLine2: state.addressLine2 || '',
      usedAt: state.usedAt
    };
  }

  get object() {
    return this.params;
  }

  get fields() {
    return {
      kanjiSei: this.params.kanjiSei,
      kanjiMei: this.params.kanjiMei,
      furiganaSei: this.params.furiganaSei,
      furiganaMei: this.params.furiganaMei,
      gender: this.params.gender,
      birthday: this.params.birthday,
      phoneNumber: this.params.phoneNumber,
      postalCode: this.params.postalCode,
      prefecture: this.params.prefecture,
      city: this.params.city,
      addressLine1: this.params.addressLine1,
      addressLine2: this.params.addressLine2
    };
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate(),
      usedAt: this.params.usedAt && this.params.usedAt.toDate()
    };
  }
}
