import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  formParams: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_MARKETING_FORM_PARAMS:
      const {
        basicInfo: {
          profileImage: { file, data, ...profileImageWithoutFile },
          ...otherBasicInfo
        }
      } = action.state.params.marketing;

      return {
        ...state,
        formParams: {
          ...state.formParams,
          ...action.state.params,
          marketing: {
            ...state.formParams.marketing,
            ...action.state.params.marketing,
            basicInfo: {
              ...otherBasicInfo,
              profileImage: profileImageWithoutFile
            }
          }
        }
      };

    // DEBUG
    // case types.FETCH_GET_MARKETING_SUCCESS:
    //   return {
    //     ...state,
    //     formParams: {
    //       marketing: action.state.params
    //     }
    //   };
    // DEBUG

    case types.FETCH_CREATE_MARKETING_SUCCESS:
      return {
        ...state,
        formParams: {},
        list: [...state.list, action.state.id],
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        }
      };

    case types.FETCH_UPDATE_MARKETING_SUCCESS:
      return {
        ...state,
        formParams: {},
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        }
      };

    default:
      return state;
  }
};
