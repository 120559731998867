export default class Dream {
  constructor(state = {}) {
    this.params = {
      id: state.id || '',
      founder: state.founder || {},
      fundingType: state.fundingType || null,
      businessCategoryId: state.businessCategoryId || 'unselected',
      supporter: state.supporter || {},
      level: state.level,
      status: state.status,
      title: state.title || '',
      subTitle: state.subTitle || '',
      supporterCount: state.supporterCount || 0,
      desktopHeaderImage: state.desktopHeaderImage || null,
      cardBackgroundImage: state.cardBackgroundImage || null,
      bannerBackgroundImage: state.bannerBackgroundImage || null,
      backgroundGradient: state.backgroundGradient || '',
      fundingTitle: state.fundingTitle || '',
      currentFundingPrice: state.currentFundingPrice || 0,
      fundingGoalPrice: state.fundingGoalPrice || 0,
      detailTitle: state.detailTitle || '',
      detailContent: state.detailContent || '',
      gifts: state.gifts || [],
      products: state.products || [],
      ownSupportedPrice: state.ownSupportedPrice || 0,
      comments: state.comments || [],
      reports: state.reports || [],
      startAt: state.startAt,
      deadlineAt: state.deadlineAt,
      endAt: state.endAt
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      startAt: this.params.startAt && this.params.startAt.toDate(),
      deadlineAt: this.params.deadlineAt && this.params.deadlineAt.toDate(),
      endAt: this.params.endAt && this.params.endAt.toDate(),
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
