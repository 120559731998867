import numeral from 'numeral';

export const RANK_TYPE_NONE_CLASS = 'NONE_CLASS';
export const RANK_TYPE_SPECIAL_CLASS = 'SPECIAL_CLASS';
export const RANK_TYPE_FIRST_CLASS = 'FIRST_CLASS';
export const RANK_TYPE_SECOND_CLASS = 'SECOND_CLASS';
export const RANK_TYPE_THIRD_CLASS = 'THIRD_CLASS';

const AMOUNTS = [
  500,
  1000,
  1500,
  2000,
  3000,
  4000,
  5000,
  10000,
  20000,
  30000,
  40000,
  50000,
  100000,
  150000,
  200000,
  250000,
  300000,
  400000,
  500000,
  1000000,
  2000000,
  3000000,
  4000000,
  5000000,
  10000000
];

export default class SupportAmount {
  static getItem(amount) {
    return {
      label: `${numeral(amount).format('0,0')}`,
      value: amount
    };
  }
  static getItems() {
    const amounts = AMOUNTS.map((amount, index) =>
      SupportAmount.getItem(amount)
    );

    return amounts;
  }

  static getLabel(value) {
    return SupportAmount.items.find(item => item.value === value).label;
  }

  static get defaultItem() {
    return SupportAmount.getItem(500);
  }
}
