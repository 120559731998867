import { Link } from 'react-router-dom';
import React from 'react';
import moment from 'moment';
import numeral from 'numeral';

import Color from '../../constants/Color';
import MaterialIcon from '../../utils/MaterialIcon';
import PanelCard from '../Card/PanelCard';
import Paragraph from '../Paragraph';
import Text from '../Text';
import View from '../../components/View';

export default class ActivityHistoryItem extends React.Component {
  getGradient(type) {
    switch (type) {
      case 'support':
        return Color.pinkGradient;
      case 'product':
        return Color.orangeGradient;
      case 'createPatronContract':
        return Color.yellowGreenGradient;
      case 'deletePatronContract':
        return Color.navyGradient;
      default:
        return Color.orangeGradient;
    }
  }

  getIcon(type) {
    switch (type) {
      case 'support':
        return 'favorite';
      case 'product':
        return 'shopping_cart';
      case 'createPatronContract':
        return 'verified_user';
      case 'deletePatronContract':
        return 'cancel';
      default:
        return 'money';
    }
  }

  getLinkUrl(type, data) {
    switch (type) {
      case 'support':
      case 'product':
        return `/dream/${data.dream.id}`;
      default:
        return '';
    }
  }

  renderSupportContent(data, createdAt) {
    return (
      <View style={{ flex: 1 }}>
        <View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 4
            }}
          >
            <Text
              style={{
                color: Color.slate,
                fontSize: 11,
                fontWeight: 'bold'
              }}
            >
              {data.dream.founder.screenName}
              への支援
            </Text>
            <Text
              style={{
                minWidth: 50,
                fontSize: 11,
                color: Color.lightSlate,
                textAlign: 'right'
              }}
            >
              {moment(createdAt).fromNow()}前
            </Text>
          </View>
          <Paragraph style={{ fontSize: 10 }}>
            {`「${data.dream.fundingTitle}」への支援をしました。`}
          </Paragraph>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'baseline'
          }}
        >
          <Text
            style={{
              color: Color.slate,
              fontWeight: 'bold',
              fontSize: 24
            }}
          >
            {numeral(data.amount).format('0,0')}
          </Text>
          <Text
            style={{
              color: Color.slate,
              fontWeight: 'bold',
              fontSize: 11
            }}
          >
            円
          </Text>
        </View>
      </View>
    );
  }

  renderProductContent(data, createdAt) {
    return (
      <View style={{ flex: 1 }}>
        <View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 4
            }}
          >
            <Text
              style={{
                color: Color.slate,
                fontSize: 11,
                fontWeight: 'bold'
              }}
            >
              コースへ申し込み
            </Text>
            <Text
              style={{
                minWidth: 50,
                fontSize: 11,
                color: Color.lightSlate,
                textAlign: 'right'
              }}
            >
              {moment(createdAt).fromNow()}前
            </Text>
          </View>
          <Paragraph style={{ fontSize: 10 }}>
            {`「${data.product.title}」へ申し込みました。`}
          </Paragraph>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'baseline'
          }}
        >
          <Text
            style={{
              color: Color.slate,
              fontWeight: 'bold',
              fontSize: 24
            }}
          >
            {numeral(data.amount).format('0,0')}
          </Text>
          <Text
            style={{
              color: Color.slate,
              fontWeight: 'bold',
              fontSize: 11
            }}
          >
            円
          </Text>
        </View>
      </View>
    );
  }

  renderPatronContract(data, createdAt, method) {
    return (
      <View style={{ flex: 1 }}>
        <View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 4
            }}
          >
            <Text
              style={{
                color: Color.slate,
                fontSize: 11,
                fontWeight: 'bold'
              }}
            >
              {method === 'create'
                ? 'パトロン支援'
                : 'パトロン支援をキャンセル'}
            </Text>
            <Text
              style={{
                minWidth: 50,
                fontSize: 11,
                color: Color.lightSlate,
                textAlign: 'right'
              }}
            >
              {moment(createdAt).fromNow()}前
            </Text>
          </View>
          <Paragraph style={{ fontSize: 10 }}>
            {method === 'create'
              ? `「${data.founder.screenName}」への支援を開始しました。`
              : `「${data.founder.screenName}」への支援をキャンセルしました。`}
          </Paragraph>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'baseline'
          }}
        >
          <Text
            style={{
              color: Color.slate,
              fontWeight: 'bold',
              fontSize: 24
            }}
          >
            {numeral(data.amount).format('0,0')}
          </Text>
          <Text
            style={{
              color: Color.slate,
              fontWeight: 'bold',
              fontSize: 11
            }}
          >
            円/月
          </Text>
        </View>
      </View>
    );
  }

  renderContent(type, data, createdAt) {
    switch (type) {
      case 'support':
        return this.renderSupportContent(data, createdAt);
      case 'product':
        return this.renderProductContent(data, createdAt);
      case 'createPatronContract':
        return this.renderPatronContract(data, createdAt, 'create');
      case 'deletePatronContract':
        return this.renderPatronContract(data, createdAt, 'delete');
      default:
        return null;
    }
  }

  render() {
    const { type, data, createdAt, style = {} } = this.props;

    return (
      <Link to={this.getLinkUrl(type, data)}>
        <PanelCard
          gradient={this.getGradient(type)}
          panel={
            <View style={{ alignItems: 'center' }}>
              <MaterialIcon
                icon={this.getIcon(type)}
                color={Color.white}
                size={32}
              />
            </View>
          }
          style={{ marginBottom: 16, ...style }}
        >
          {this.renderContent(type, data, createdAt)}
        </PanelCard>
      </Link>
    );
  }
}
