import React from 'react';

import Color from '../../constants/Color';
import EdgedUserIcon from '../Icons/EdgedUserIcon';
import Hr from '../Hr';
import Paragraph from '../Paragraph';
import Style from '../../constants/Style';
import Text from '../Text';
import View from '../View';

export default function FounderCard({ data, size = 160, style = {} }) {
  return (
    <View style={{ paddingTop: size / 2, ...style }}>
      <View
        style={{
          backgroundColor: Color.white,
          borderRadius: 8,
          alignItems: 'center',
          ...Style.shadow.medium
        }}
      >
        <EdgedUserIcon
          size={size}
          source={data.iconImage}
          style={{ marginTop: -80 }}
        />
        <View style={{ padding: '16px 24px 32px 24px' }}>
          <Text
            style={{
              color: Color.slate,
              fontSize: 20,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 16
            }}
          >
            {data.screenName}
          </Text>
          <Hr style={{ marginBottom: 16 }} />
          <Paragraph>{data.profile}</Paragraph>
        </View>
      </View>
    </View>
  );
}
