import React from 'react';

import Color from '../../constants/Color';
import Text from '../Text';
import View from '../View';

export default function CardOuterTitle({ children, style = {} }) {
  return (
    <View style={style}>
      <Text style={{ color: Color.white, fontWeight: 'bold' }}>{children}</Text>
    </View>
  );
}
