import React from 'react';

import { PdfImage, PdfText, PdfView } from '../../utils/Pdf';
import Color from '../../constants/Color';

export default function PdfIntroduction({
  title,
  content,
  icon,
  color,
  style = {}
}) {
  return (
    <PdfView style={style}>
      <PdfView
        style={{
          flexDirection: 'row',
          borderBottom: `1pt solid ${Color.warmGrey}`,
          marginBottom: 8
        }}
      >
        <PdfView
          style={{
            backgroundColor: color,
            width: 30,
            height: 30,
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: 16
          }}
        >
          <PdfImage src={icon} style={{ width: 20, height: 20 }} />
        </PdfView>
        <PdfText
          style={{
            fontSize: 13,
            fontWeight: 'bold',
            lineHeight: 1.3
          }}
        >
          {title}
        </PdfText>
      </PdfView>
      <PdfView>
        <PdfText style={{ fontSize: 10, lineHeight: 1.3 }}>{content}</PdfText>
      </PdfView>
    </PdfView>
  );
}
