import React from 'react';

import Color from '../../constants/Color';
import View from '../View';

export default function Field({ children, style = {} }) {
  return (
    <View
      style={{
        backgroundColor: Color.white,
        borderRadius: 8,
        border: `solid 1px ${Color.grey}`,
        padding: 4,
        ...style
      }}
    >
      {children}
    </View>
  );
}
