import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { PROPOSAL_ARTICLES } from '../../../constants/Articles/Proposal';
import ArrowStream from '../../../components/Icons/ArrowStream';
import ArticleCardTemplate from '../../../components/Article/ArticleCardTemplate';
import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Footer from '../../../components/Footer';
import Headline from '../../../components/Headline';
import PageHeader from '../../../containers/page/PageHeader';
import Paragraph from '../../../components/Paragraph';
import Text from '../../../components/Text';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalIndex extends React.Component {
  render() {
    return (
      <View>
        <PageHeader />
        <View style={{ padding: '24px 24px 0 24px', marginBottom: 24 }}>
          <Headline size={1} style={{ marginBottom: 16 }}>
            事業計画書
          </Headline>
          <Paragraph style={{}} containerStyle={{ marginBottom: 32 }}>
            事業計画書は、あなたの事業の要となる書類です。あなたの頭の中の事業を各項目に対して記述していくだけで、事業の姿をより明確に、はっきりとさせていくことができます。
          </Paragraph>
          <Headline size={2} style={{ marginBottom: 16 }}>
            事業計画書テンプレート
          </Headline>
          <Paragraph style={{}} containerStyle={{ marginBottom: 40 }}>
            7つの観点から、あなたの事業の輪郭をはっきりさせていきましょう。
            7つの項目は、自己紹介、導入、仕様、業務プロセス、経営環境、特徴、営利で構成されます。
            このページは、以下の事業計画書を構成する7つの要素について解説していきます。
          </Paragraph>
          <Paragraph>
            すぐに事業計画書を作りたい方は、下記のページからさっそく事業計画書を作ってみましょう。
            最後まで入力すると、上記の画像のような事業計画書のPDFファイルがダウンロードできます。
          </Paragraph>
        </View>
        <View style={{ padding: '0 16px', marginBottom: 48 }}>
          <Link to="/proposal/new">
            <Button>事業計画書をつくる</Button>
          </Link>
        </View>
        <View>
          {PROPOSAL_ARTICLES.map(article => {
            return (
              <ArticleCardTemplate
                title={article.title}
                number={article.number}
                description={article.description}
                sections={article.sections}
              />
            );
          })}
        </View>
        <View style={{ alignItems: 'center', padding: '0 24px' }}>
          <ArrowStream color={Color.slate} style={{ marginBottom: 24 }} />
          <Paragraph
            style={{
              color: Color.slate,
              fontSize: 17,
              fontWeight: 'bold',
              textAlign: 'center'
            }}
            containerStyle={{ marginBottom: 24 }}
          >
            さっそく事業計画書を{'\n'}作ってみましょう！
          </Paragraph>
        </View>
        <View style={{ padding: '0 16px' }}>
          <Link to="/proposal/new" style={{ marginBottom: 16 }}>
            <Button>事業計画書をつくる</Button>
          </Link>
          <View style={{ alignItems: 'center' }}>
            <Text
              style={{
                color: Color.slate,
                fontSize: 13,
                marginBottom: 32,
                textAlign: 'center'
              }}
            >
              会員登録は不要です。入力後に
              <br />
              <Text
                style={{
                  color: Color.slate,
                  fontSize: 13,
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                事業計画
              </Text>
              PDFがダウンロードできます。
            </Text>
          </View>
        </View>
        <Footer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalIndex);
