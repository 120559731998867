import React from 'react';

import Color from '../../constants/Color';
import Headline from '../Headline';
import Style from '../../constants/Style';
import View from '../View';

export default function SectionCard({
  gradient,
  title,
  panel,
  panelTop = 32,
  children,
  after,
  panelSize = 40,
  style = {},
  contentContainerStyle = {}
}) {
  const titleComponent =
    typeof title === 'string' ? <Headline size={3}>{title}</Headline> : title;

  return (
    <View style={style}>
      <View style={{ flexDirection: 'row', zIndex: 1 }}>
        <View style={{ marginLeft: 16 }}>{panel}</View>
        <View style={{ marginTop: 16, marginLeft: 8 }}>{titleComponent}</View>
      </View>
      <View
        style={{
          position: 'relative',
          marginTop: -panelTop,
          paddingTop: panelTop,
          borderRadius: 16,
          minHeight: panelSize,
          backgroundColor: Color.white,
          ...Style.shadow.large
        }}
      >
        <View style={{ padding: `8px 16px 16px 16px` }}>{children}</View>
        {after}
      </View>
    </View>
  );
}
