// activity history
export const FETCH_GET_ACTIVITY_HISTORIES = 'FETCH_GET_ACTIVITY_HISTORIES';
export const FETCH_GET_ACTIVITY_HISTORIES_SUCCESS =
  'FETCH_GET_ACTIVITY_HISTORIES_SUCCESS';
export const FETCH_GET_ACTIVITY_HISTORIES_FAILED =
  'FETCH_GET_ACTIVITY_HISTORIES_FAILED';
export const FETCH_GET_ACTIVITY_HISTORIES_RECOVER =
  'FETCH_GET_ACTIVITY_HISTORIES_RECOVER';

// auth
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_ENTRY =
  'SIGN_UP_WITH_EMAIL_AND_PASSWORD_ENTRY';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS =
  'SIGN_UP_WITH_EMAIL_AND_PASSWORD_SUCCESS';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILED =
  'SIGN_UP_WITH_EMAIL_AND_PASSWORD_FAILED';
export const SIGN_UP_WITH_EMAIL_AND_PASSWORD_RECOVER =
  'SIGN_UP_WITH_EMAIL_AND_PASSWORD_RECOVER';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_ENTRY =
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD_ENTRY';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS =
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILED =
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD_FAILED';
export const SIGN_IN_WITH_EMAIL_AND_PASSWORD_RECOVER =
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD_RECOVER';

export const AUTH_WITH_PHONE_NUMBER_ENTRY = 'AUTH_WITH_PHONE_NUMBER_ENTRY';
export const AUTH_WITH_PHONE_NUMBER_SUCCESS = 'AUTH_WITH_PHONE_NUMBER_SUCCESS';
export const AUTH_WITH_PHONE_NUMBER_FAILED = 'AUTH_WITH_PHONE_NUMBER_FAILED';
export const AUTH_WITH_PHONE_NUMBER_RECOVER = 'AUTH_WITH_PHONE_NUMBER_RECOVER';

export const AUTH_WITH_TWITTER_ENTRY = 'AUTH_WITH_TWITTER_ENTRY';
export const AUTH_WITH_TWITTER_SUCCESS = 'AUTH_WITH_TWITTER_SUCCESS';
export const AUTH_WITH_TWITTER_FAILED = 'AUTH_WITH_TWITTER_FAILED';
export const AUTH_WITH_TWITTER_RECOVER = 'AUTH_WITH_TWITTER_RECOVER';

export const AUTH_SIGN_OUT_ENTRY = 'AUTH_SIGN_OUT_ENTRY';
export const AUTH_SIGN_OUT_SUCCESS = 'AUTH_SIGN_OUT_SUCCESS';
export const AUTH_SIGN_OUT_FAILED = 'AUTH_SIGN_OUT_FAILED';
export const AUTH_SIGN_OUT_RECOVER = 'AUTH_SIGN_OUT_RECOVER';

// config
export const FETCH_GET_CONFIGS = 'FETCH_GET_CONFIGS';
export const FETCH_GET_CONFIGS_SUCCESS = 'FETCH_GET_CONFIGS_SUCCESS';
export const FETCH_GET_CONFIGS_FAILED = 'FETCH_GET_CONFIGS_FAILED';
export const FETCH_GET_CONFIGS_RECOVER = 'FETCH_GET_CONFIGS_RECOVER';

// document
export const FETCH_GET_DOCUMENT_HISTORIES = 'FETCH_GET_DOCUMENT_HISTORIES';
export const FETCH_GET_DOCUMENT_HISTORIES_SUCCESS =
  'FETCH_GET_DOCUMENT_HISTORIES_SUCCESS';
export const FETCH_GET_DOCUMENT_HISTORIES_FAILED =
  'FETCH_GET_DOCUMENT_HISTORIES_FAILED';
export const FETCH_GET_DOCUMENT_HISTORIES_RECOVER =
  'FETCH_GET_DOCUMENT_HISTORIES_RECOVER';

// dream
export const FETCH_GET_DREAMS = 'FETCH_GET_DREAMS';
export const FETCH_GET_DREAMS_SUCCESS = 'FETCH_GET_DREAMS_SUCCESS';
export const FETCH_GET_DREAMS_FAILED = 'FETCH_GET_DREAMS_FAILED';
export const FETCH_GET_DREAMS_RECOVER = 'FETCH_GET_DREAMS_RECOVER';

export const FETCH_GET_DREAM = 'FETCH_GET_DREAM';
export const FETCH_GET_DREAM_SUCCESS = 'FETCH_GET_DREAM_SUCCESS';
export const FETCH_GET_DREAM_FAILED = 'FETCH_GET_DREAM_FAILED';
export const FETCH_GET_DREAM_RECOVER = 'FETCH_GET_DREAM_RECOVER';

export const FETCH_SUPPORT_DREAM = 'FETCH_SUPPORT_DREAM';
export const FETCH_SUPPORT_DREAM_SUCCESS = 'FETCH_SUPPORT_DREAM_SUCCESS';
export const FETCH_SUPPORT_DREAM_FAILED = 'FETCH_SUPPORT_DREAM_FAILED';
export const FETCH_SUPPORT_DREAM_RECOVER = 'FETCH_SUPPORT_DREAM_RECOVER';

export const FETCH_PURCHASE_DREAM_PRODUCT = 'FETCH_PURCHASE_DREAM_PRODUCT';
export const FETCH_PURCHASE_DREAM_PRODUCT_SUCCESS =
  'FETCH_PURCHASE_DREAM_PRODUCT_SUCCESS';
export const FETCH_PURCHASE_DREAM_PRODUCT_FAILED =
  'FETCH_PURCHASE_DREAM_PRODUCT_FAILED';
export const FETCH_PURCHASE_DREAM_PRODUCT_RECOVER =
  'FETCH_PURCHASE_DREAM_PRODUCT_RECOVER';

// dream application
export const FETCH_CREATE_DREAM_APPLICATION = 'FETCH_CREATE_DREAM_APPLICATION';
export const FETCH_CREATE_DREAM_APPLICATION_SUCCESS =
  'FETCH_CREATE_DREAM_APPLICATION_SUCCESS';
export const FETCH_CREATE_DREAM_APPLICATION_FAILED =
  'FETCH_CREATE_DREAM_APPLICATION_FAILED';
export const FETCH_CREATE_DREAM_APPLICATION_RECOVER =
  'FETCH_CREATE_DREAM_APPLICATION_RECOVER';

// dream report
export const FETCH_GET_DREAM_REPORTS = 'FETCH_GET_DREAM_REPORTS';
export const FETCH_GET_DREAM_REPORTS_SUCCESS =
  'FETCH_GET_DREAM_REPORTS_SUCCESS';
export const FETCH_GET_DREAM_REPORTS_FAILED = 'FETCH_GET_DREAM_REPORTS_FAILED';
export const FETCH_GET_DREAM_REPORTS_RECOVER =
  'FETCH_GET_DREAM_REPORTS_RECOVER';

export const FETCH_GET_DREAM_REPORT = 'FETCH_GET_DREAM_REPORT';
export const FETCH_GET_DREAM_REPORT_SUCCESS = 'FETCH_GET_DREAM_REPORT_SUCCESS';
export const FETCH_GET_DREAM_REPORT_FAILED = 'FETCH_GET_DREAM_REPORT_FAILED';
export const FETCH_GET_DREAM_REPORT_RECOVER = 'FETCH_GET_DREAM_REPORT_RECOVER';

// dream product
export const FETCH_GET_DREAM_PRODUCTS = 'FETCH_GET_DREAM_PRODUCTS';
export const FETCH_GET_DREAM_PRODUCTS_SUCCESS =
  'FETCH_GET_DREAM_PRODUCTS_SUCCESS';
export const FETCH_GET_DREAM_PRODUCTS_FAILED =
  'FETCH_GET_DREAM_PRODUCTS_FAILED';
export const FETCH_GET_DREAM_PRODUCTS_RECOVER =
  'FETCH_GET_DREAM_PRODUCTS_RECOVER';

export const FETCH_GET_DREAM_PRODUCT = 'FETCH_GET_DREAM_PRODUCT';
export const FETCH_GET_DREAM_PRODUCT_SUCCESS =
  'FETCH_GET_DREAM_PRODUCT_SUCCESS';
export const FETCH_GET_DREAM_PRODUCT_FAILED = 'FETCH_GET_DREAM_PRODUCT_FAILED';
export const FETCH_GET_DREAM_PRODUCT_RECOVER =
  'FETCH_GET_DREAM_PRODUCT_RECOVER';

// dream thank
export const FETCH_GET_DREAM_THANKS = 'FETCH_GET_DREAM_THANKS';
export const FETCH_GET_DREAM_THANKS_SUCCESS = 'FETCH_GET_DREAM_THANKS_SUCCESS';
export const FETCH_GET_DREAM_THANKS_FAILED = 'FETCH_GET_DREAM_THANKS_FAILED';
export const FETCH_GET_DREAM_THANKS_RECOVER = 'FETCH_GET_DREAM_THANKS_RECOVER';

// dream comment
export const FETCH_CREATE_DREAM_COMMENT = 'FETCH_CREATE_DREAM_COMMENT';
export const FETCH_CREATE_DREAM_COMMENT_SUCCESS =
  'FETCH_CREATE_DREAM_COMMENT_SUCCESS';
export const FETCH_CREATE_DREAM_COMMENT_FAILED =
  'FETCH_CREATE_DREAM_COMMENT_FAILED';
export const FETCH_CREATE_DREAM_COMMENT_RECOVER =
  'FETCH_CREATE_DREAM_COMMENT_RECOVER';
export const FETCH_CREATE_DREAM_COMMENT_REPLY =
  'FETCH_CREATE_DREAM_COMMENT_REPLY';
export const FETCH_CREATE_DREAM_COMMENT_REPLY_SUCCESS =
  'FETCH_CREATE_DREAM_COMMENT_REPLY_SUCCESS';
export const FETCH_CREATE_DREAM_COMMENT_REPLY_FAILED =
  'FETCH_CREATE_DREAM_COMMENT_REPLY_FAILED';
export const FETCH_CREATE_DREAM_COMMENT_REPLY_RECOVER =
  'FETCH_CREATE_DREAM_COMMENT_REPLY_RECOVER';

// founder
export const FETCH_GET_FOUNDERS = 'FETCH_GET_FOUNDERS';
export const FETCH_GET_FOUNDERS_SUCCESS = 'FETCH_GET_FOUNDERS_SUCCESS';
export const FETCH_GET_FOUNDERS_FAILED = 'FETCH_GET_FOUNDERS_FAILED';
export const FETCH_GET_FOUNDERS_RECOVER = 'FETCH_GET_FOUNDERS_RECOVER';

export const FETCH_GET_FOUNDER = 'FETCH_GET_FOUNDER';
export const FETCH_GET_FOUNDER_SUCCESS = 'FETCH_GET_FOUNDER_SUCCESS';
export const FETCH_GET_FOUNDER_FAILED = 'FETCH_GET_FOUNDER_FAILED';
export const FETCH_GET_FOUNDER_RECOVER = 'FETCH_GET_FOUNDER_RECOVER';

// job
export const FETCH_GET_JOBS = 'FETCH_GET_JOBS';
export const FETCH_GET_JOBS_SUCCESS = 'FETCH_GET_JOBS_SUCCESS';
export const FETCH_GET_JOBS_FAILED = 'FETCH_GET_JOBS_FAILED';
export const FETCH_GET_JOBS_RECOVER = 'FETCH_GET_JOBS_RECOVER';

export const FETCH_GET_JOB = 'FETCH_GET_JOB';
export const FETCH_GET_JOB_SUCCESS = 'FETCH_GET_JOB_SUCCESS';
export const FETCH_GET_JOB_FAILED = 'FETCH_GET_JOB_FAILED';
export const FETCH_GET_JOB_RECOVER = 'FETCH_GET_JOB_RECOVER';

// layout
export const UPDATE_WINDOW_SIZE = 'UPDATE_WINDOW_SIZE';

// marketing
export const FETCH_GET_MARKETING = 'FETCH_GET_MARKETING';
export const FETCH_GET_MARKETING_SUCCESS = 'FETCH_GET_MARKETING_SUCCESS';
export const FETCH_GET_MARKETING_FAILED = 'FETCH_GET_MARKETING_FAILED';
export const FETCH_GET_MARKETING_RECOVER = 'FETCH_GET_MARKETING_RECOVER';

export const FETCH_CREATE_MARKETING = 'FETCH_CREATE_MARKETING';
export const FETCH_CREATE_MARKETING_SUCCESS = 'FETCH_CREATE_MARKETING_SUCCESS';
export const FETCH_CREATE_MARKETING_FAILED = 'FETCH_CREATE_MARKETING_FAILED';
export const FETCH_CREATE_MARKETING_RECOVER = 'FETCH_CREATE_MARKETING_RECOVER';

export const FETCH_UPDATE_MARKETING = 'FETCH_UPDATE_MARKETING';
export const FETCH_UPDATE_MARKETING_SUCCESS = 'FETCH_UPDATE_MARKETING_SUCCESS';
export const FETCH_UPDATE_MARKETING_FAILED = 'FETCH_UPDATE_MARKETING_FAILED';
export const FETCH_UPDATE_MARKETING_RECOVER = 'FETCH_UPDATE_MARKETING_RECOVER';

// scheme form params
export const UPDATE_MARKETING_FORM_PARAMS = 'UPDATE_MARKETING_FORM_PARAMS';

// patron contract
export const FETCH_GET_PATRON_CONTRACTS = 'FETCH_GET_PATRON_CONTRACTS';
export const FETCH_GET_PATRON_CONTRACTS_SUCCESS =
  'FETCH_GET_PATRON_CONTRACTS_SUCCESS';
export const FETCH_GET_PATRON_CONTRACTS_FAILED =
  'FETCH_GET_PATRON_CONTRACTS_FAILED';
export const FETCH_GET_PATRON_CONTRACTS_RECOVER =
  'FETCH_GET_PATRON_CONTRACTS_RECOVER';

export const FETCH_GET_PATRON_CONTRACT = 'FETCH_GET_PATRON_CONTRACT';
export const FETCH_GET_PATRON_CONTRACT_SUCCESS =
  'FETCH_GET_PATRON_CONTRACT_SUCCESS';
export const FETCH_GET_PATRON_CONTRACT_FAILED =
  'FETCH_GET_PATRON_CONTRACT_FAILED';
export const FETCH_GET_PATRON_CONTRACT_RECOVER =
  'FETCH_GET_PATRON_CONTRACT_RECOVER';

export const FETCH_CREATE_PATRON_CONTRACT = 'FETCH_CREATE_PATRON_CONTRACT';
export const FETCH_CREATE_PATRON_CONTRACT_SUCCESS =
  'FETCH_CREATE_PATRON_CONTRACT_SUCCESS';
export const FETCH_CREATE_PATRON_CONTRACT_FAILED =
  'FETCH_CREATE_PATRON_CONTRACT_FAILED';
export const FETCH_CREATE_PATRON_CONTRACT_RECOVER =
  'FETCH_CREATE_PATRON_CONTRACT_RECOVER';

export const FETCH_DELETE_PATRON_CONTRACT = 'FETCH_DELETE_PATRON_CONTRACT';
export const FETCH_DELETE_PATRON_CONTRACT_SUCCESS =
  'FETCH_DELETE_PATRON_CONTRACT_SUCCESS';
export const FETCH_DELETE_PATRON_CONTRACT_FAILED =
  'FETCH_DELETE_PATRON_CONTRACT_FAILED';
export const FETCH_DELETE_PATRON_CONTRACT_RECOVER =
  'FETCH_DELETE_PATRON_CONTRACT_RECOVER';

// payment info
export const FETCH_DELETE_PAYMENT_INFO = 'FETCH_DELETE_PAYMENT_INFO';
export const FETCH_DELETE_PAYMENT_INFO_SUCCESS =
  'FETCH_DELETE_PAYMENT_INFO_SUCCESS';
export const FETCH_DELETE_PAYMENT_INFO_FAILED =
  'FETCH_DELETE_PAYMENT_INFO_FAILED';
export const FETCH_DELETE_PAYMENT_INFO_RECOVER =
  'FETCH_DELETE_PAYMENT_INFO_RECOVER';

// proposal
export const FETCH_GET_PROPOSAL = 'FETCH_GET_PROPOSAL';
export const FETCH_GET_PROPOSAL_SUCCESS = 'FETCH_GET_PROPOSAL_SUCCESS';
export const FETCH_GET_PROPOSAL_FAILED = 'FETCH_GET_PROPOSAL_FAILED';
export const FETCH_GET_PROPOSAL_RECOVER = 'FETCH_GET_PROPOSAL_RECOVER';

export const FETCH_CREATE_PROPOSAL = 'FETCH_CREATE_PROPOSAL';
export const FETCH_CREATE_PROPOSAL_SUCCESS = 'FETCH_CREATE_PROPOSAL_SUCCESS';
export const FETCH_CREATE_PROPOSAL_FAILED = 'FETCH_CREATE_PROPOSAL_FAILED';
export const FETCH_CREATE_PROPOSAL_RECOVER = 'FETCH_CREATE_PROPOSAL_RECOVER';

export const FETCH_UPDATE_PROPOSAL = 'FETCH_UPDATE_PROPOSAL';
export const FETCH_UPDATE_PROPOSAL_SUCCESS = 'FETCH_UPDATE_PROPOSAL_SUCCESS';
export const FETCH_UPDATE_PROPOSAL_FAILED = 'FETCH_UPDATE_PROPOSAL_FAILED';
export const FETCH_UPDATE_PROPOSAL_RECOVER = 'FETCH_UPDATE_PROPOSAL_RECOVER';

// proposal form params
export const UPDATE_PROPOSAL_FORM_PARAMS = 'UPDATE_PROPOSAL_FORM_PARAMS';

// review
export const FETCH_CREATE_REVIEW = 'FETCH_CREATE_REVIEW';
export const FETCH_CREATE_REVIEW_SUCCESS = 'FETCH_CREATE_REVIEW_SUCCESS';
export const FETCH_CREATE_REVIEW_FAILED = 'FETCH_CREATE_REVIEW_FAILED';
export const FETCH_CREATE_REVIEW_RECOVER = 'FETCH_CREATE_REVIEW_RECOVER';

// scheme
export const FETCH_GET_SCHEME = 'FETCH_GET_SCHEME';
export const FETCH_GET_SCHEME_SUCCESS = 'FETCH_GET_SCHEME_SUCCESS';
export const FETCH_GET_SCHEME_FAILED = 'FETCH_GET_SCHEME_FAILED';
export const FETCH_GET_SCHEME_RECOVER = 'FETCH_GET_SCHEME_RECOVER';

export const FETCH_CREATE_SCHEME = 'FETCH_CREATE_SCHEME';
export const FETCH_CREATE_SCHEME_SUCCESS = 'FETCH_CREATE_SCHEME_SUCCESS';
export const FETCH_CREATE_SCHEME_FAILED = 'FETCH_CREATE_SCHEME_FAILED';
export const FETCH_CREATE_SCHEME_RECOVER = 'FETCH_CREATE_SCHEME_RECOVER';

export const FETCH_UPDATE_SCHEME = 'FETCH_UPDATE_SCHEME';
export const FETCH_UPDATE_SCHEME_SUCCESS = 'FETCH_UPDATE_SCHEME_SUCCESS';
export const FETCH_UPDATE_SCHEME_FAILED = 'FETCH_UPDATE_SCHEME_FAILED';
export const FETCH_UPDATE_SCHEME_RECOVER = 'FETCH_UPDATE_SCHEME_RECOVER';

// scheme form params
export const UPDATE_SCHEME_FORM_PARAMS = 'UPDATE_SCHEME_FORM_PARAMS';

// shipping info
export const FETCH_GET_SHIPPING_INFOS = 'FETCH_GET_SHIPPING_INFOS';
export const FETCH_GET_SHIPPING_INFOS_SUCCESS =
  'FETCH_GET_SHIPPING_INFOS_SUCCESS';
export const FETCH_GET_SHIPPING_INFOS_FAILED =
  'FETCH_GET_SHIPPING_INFOS_FAILED';
export const FETCH_GET_SHIPPING_INFOS_RECOVER =
  'FETCH_GET_SHIPPING_INFOS_RECOVER';

export const FETCH_GET_SHIPPING_INFO = 'FETCH_GET_SHIPPING_INFO';
export const FETCH_GET_SHIPPING_INFO_SUCCESS =
  'FETCH_GET_SHIPPING_INFO_SUCCESS';
export const FETCH_GET_SHIPPING_INFO_FAILED = 'FETCH_GET_SHIPPING_INFO_FAILED';
export const FETCH_GET_SHIPPING_INFO_RECOVER =
  'FETCH_GET_SHIPPING_INFO_RECOVER';

export const FETCH_CREATE_SHIPPING_INFO = 'FETCH_CREATE_SHIPPING_INFO';
export const FETCH_CREATE_SHIPPING_INFO_SUCCESS =
  'FETCH_CREATE_SHIPPING_INFO_SUCCESS';
export const FETCH_CREATE_SHIPPING_INFO_FAILED =
  'FETCH_CREATE_SHIPPING_INFO_FAILED';
export const FETCH_CREATE_SHIPPING_INFO_RECOVER =
  'FETCH_CREATE_SHIPPING_INFO_RECOVER';

export const FETCH_UPDATE_SHIPPING_INFO = 'FETCH_UPDATE_SHIPPING_INFO';
export const FETCH_UPDATE_SHIPPING_INFO_SUCCESS =
  'FETCH_UPDATE_SHIPPING_INFO_SUCCESS';
export const FETCH_UPDATE_SHIPPING_INFO_FAILED =
  'FETCH_UPDATE_SHIPPING_INFO_FAILED';
export const FETCH_UPDATE_SHIPPING_INFO_RECOVER =
  'FETCH_UPDATE_SHIPPING_INFO_RECOVER';

export const FETCH_DELETE_SHIPPING_INFO = 'FETCH_DELETE_SHIPPING_INFO';
export const FETCH_DELETE_SHIPPING_INFO_SUCCESS =
  'FETCH_DELETE_SHIPPING_INFO_SUCCESS';
export const FETCH_DELETE_SHIPPING_INFO_FAILED =
  'FETCH_DELETE_SHIPPING_INFO_FAILED';
export const FETCH_DELETE_SHIPPING_INFO_RECOVER =
  'FETCH_DELETE_SHIPPING_INFO_RECOVER';

// job
export const FETCH_GET_SPONSORS = 'FETCH_GET_SPONSORS';
export const FETCH_GET_SPONSORS_SUCCESS = 'FETCH_GET_SPONSORS_SUCCESS';
export const FETCH_GET_SPONSORS_FAILED = 'FETCH_GET_SPONSORS_FAILED';
export const FETCH_GET_SPONSORS_RECOVER = 'FETCH_GET_SPONSORS_RECOVER';

// user
export const FETCH_GET_MYSELF_USER = 'FETCH_GET_MYSELF_USER';
export const FETCH_GET_MYSELF_USER_SUCCESS = 'FETCH_GET_MYSELF_USER_SUCCESS';
export const FETCH_GET_MYSELF_USER_FAILED = 'FETCH_GET_MYSELF_USER_FAILED';
export const FETCH_GET_MYSELF_USER_RECOVER = 'FETCH_GET_MYSELF_USER_RECOVER';

export const FETCH_GET_USER = 'FETCH_GET_USER';
export const FETCH_GET_USER_SUCCESS = 'FETCH_GET_USER_SUCCESS';
export const FETCH_GET_USER_FAILED = 'FETCH_GET_USER_FAILED';
export const FETCH_GET_USER_RECOVER = 'FETCH_GET_USER_RECOVER';

export const FETCH_UPDATE_USER = 'FETCH_UPDATE_USER';
export const FETCH_UPDATE_USER_SUCCESS = 'FETCH_UPDATE_USER_SUCCESS';
export const FETCH_UPDATE_USER_FAILED = 'FETCH_UPDATE_USER_FAILED';
export const FETCH_UPDATE_USER_RECOVER = 'FETCH_UPDATE_USER_RECOVER';

export const FETCH_GET_USERS = 'FETCH_GET_USERS';
export const FETCH_GET_USERS_SUCCESS = 'FETCH_GET_USERS_SUCCESS';
export const FETCH_GET_USERS_FAILED = 'FETCH_GET_USERS_FAILED';
export const FETCH_GET_USERS_RECOVER = 'FETCH_GET_USERS_RECOVER';

// network
export const IN_MAINTENANCE = 'IN_MAINTENANCE';
export const NOT_AUTHENTICATED_OR_INVALIDATED =
  'NOT_AUTHENTICATED_OR_INVALIDATED';
