import { getCollection, getDb, uploadImage } from '../utils/Firebase';
import Consumer from '../models/Consumer';
import ErrorMessages from '../constants/ErrorMessages';
import PaymentInfo from '../models/PaymentInfo';
import User from '../models/User';
import UserMetadata from '../models/UserMetadata';
import * as types from '../constants/ActionTypes';

// get user
function fetchGetMyselfUser() {
  return {
    type: types.FETCH_GET_MYSELF_USER
  };
}

function fetchGetMyselfUserSuccess(id, user) {
  return {
    type: types.FETCH_GET_MYSELF_USER_SUCCESS,
    state: {
      id,
      user
    }
  };
}

function fetchGetMyselfUserFailed(error) {
  return {
    type: types.FETCH_GET_MYSELF_USER_FAILED,
    error
  };
}

export function recoverGetMyselfUser() {
  return {
    type: types.FETCH_GET_MYSELF_USER_RECOVER
  };
}

export function getMyselfUser(uid) {
  return (dispatch, getState) => {
    const { auth } = getState();

    let id = uid;

    if (!uid) {
      id = auth.uid;
    }

    dispatch(fetchGetMyselfUser());

    const users = getCollection('users');

    const query = users.doc(id);

    return query
      .get()
      .then(async doc => {
        const userMetadataDoc = await getCollection('userMetadata')
          .doc(id)
          .get();

        const consumerDoc = await getCollection('consumers')
          .doc(id)
          .get();

        const consumerData = consumerDoc.data();

        const { docs: paymentInfoDocs } = await getCollection('consumers')
          .doc(id)
          .collection('paymentInfos')
          .get();

        const paymentInfos = paymentInfoDocs.map(doc =>
          new PaymentInfo({ id: doc.id, ...doc.data() }).fromFirestore()
        );

        const user = new User({
          id: doc.id,
          paymentInfos,
          ...doc.data(),
          ...userMetadataDoc.data(),
          ...consumerData
        }).fromFirestore();

        return dispatch(fetchGetMyselfUserSuccess(id, user));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetMyselfUserFailed(error));
        }
      });
  };
}

// get user
function fetchGetUser() {
  return {
    type: types.FETCH_GET_USER
  };
}

function fetchGetUserSuccess(id, user) {
  return {
    type: types.FETCH_GET_USER_SUCCESS,
    state: {
      id,
      user
    }
  };
}

function fetchGetUserFailed(error) {
  return {
    type: types.FETCH_GET_USER_FAILED,
    error
  };
}

export function recoverGetUser() {
  return {
    type: types.FETCH_GET_USER_RECOVER
  };
}

export function getUser(id) {
  return dispatch => {
    dispatch(fetchGetUser());

    let metadata;

    return getCollection('userMetadata')
      .doc(id)
      .get()
      .then(metadataDoc => {
        metadata = metadataDoc.data();
      })
      .then(params => {
        const users = getCollection('users');

        const query = users.doc(id);

        return query.get();
      })
      .then(doc => {
        const user = {
          id: doc.id,
          ...metadata,
          ...doc.data()
        };

        return dispatch(fetchGetUserSuccess(id, user));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetUserFailed(error));
        }
      });
  };
}

// update an user
function fetchUpdateUser() {
  return {
    type: types.FETCH_UPDATE_USER
  };
}

function fetchUpdateUserSuccess(id, user) {
  return {
    type: types.FETCH_UPDATE_USER_SUCCESS,
    state: {
      id,
      user
    }
  };
}

function fetchUpdateUserFailed(error) {
  return {
    type: types.FETCH_UPDATE_USER_FAILED,
    error
  };
}

export function recoverUpdateUser() {
  return {
    type: types.FETCH_UPDATE_USER_RECOVER
  };
}

export function updateUser(id, { iconImage, ...params }) {
  return dispatch => {
    dispatch(fetchUpdateUser());

    const users = getCollection('users');

    const user = users.doc(id);

    const { consumer = null, userMetadata = null, ...updateParams } = params;

    const db = getDb();

    return uploadImage('/users/icons', iconImage).then(async imagePath => {
      if (consumer) {
        await Consumer.update(id, consumer);
      }

      if (userMetadata) {
        await UserMetadata.update(id, userMetadata);
      }

      return db
        .runTransaction(transaction => {
          return transaction.get(user).then(doc => {
            if (!doc.exists) {
              throw new Error('update/not-exist');
            }

            const iconImage = imagePath
              ? { iconImage: { uri: imagePath } }
              : {};

            return transaction.update(user, {
              ...iconImage,
              ...updateParams
            });
          });
        })
        .then(() => {
          return dispatch(fetchUpdateUserSuccess(id, params));
        })
        .catch(error => {
          return dispatch(
            fetchUpdateUserFailed({
              code: error.code,
              message: ErrorMessages[error.code]
            })
          );
        });
    });
  };
}
