import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import CommentList from '../../../containers/dream/CommentList';
import CommentModal from '../../../containers/CommentModal';
import Deadline from '../../../utils/Deadline';
import DonateButton from '../../../containers/dream/DonateButton';
import DreamDetailContent from '../../../components/Dream/DreamDetailContent';
import DreamDetailHeader from '../../../components/Dream/DreamDetailHeader';
import DreamProgress from '../../../components/Dream/DreamProgress';
import DreamReportTimeline from '../../../components/Dream/DreamReportTimeline';
import Footer from '../../../components/Footer';
import GiftList from '../../../containers/dream/GiftList';
import Headline from '../../../components/Headline';
import PageHeader from '../../../containers/page/PageHeader';
import Paragraph from '../../../components/Paragraph';
import ProductList from '../../../containers/dream/ProductList';
import ProductPurchaseLink from '../../../containers/dream/ProductPurchaseLink';
import RegisterModal from '../../../containers/RegisterModal';
import ReplyModal from '../../../containers/ReplyModal';
import SupportModal from '../../../containers/SupportModal';
import SupporterProgress from '../../../components/Dream/SupporterProgress';
import Text from '../../../components/Text';
import UserCard from '../../../components/Card/UserCard';
import View from '../../../components/View';
import actions from '../../../actions';

class DreamDetail extends React.Component {
  state = {
    isOpenRegisterModal: false,
    isOpenSupportModal: false,
    isOpenCommentModal: false,
    isOpenReplyModal: false,
    isOpenActionModal: false
  };

  componentDidMount() {
    this.props.getDream(this.props.match.params.id);
  }

  renderSupportButton(dream) {
    if (dream.fundingType !== 'donation') {
      return null;
    }

    return (
      <View style={{ padding: '0 16px 0 16px', marginBottom: 24 }}>
        <Button
          onClick={() => {
            if (this.props.auth.uid) {
              this.setState({ isOpenSupportModal: true });
            } else {
              this.setState({ isOpenRegisterModal: true });
            }
          }}
        >
          支援する
        </Button>
      </View>
    );
  }

  render() {
    const dream = this.props.dream.hash[this.props.match.params.id];

    if (!dream) {
      return null;
    }

    const deadline = new Deadline(dream.deadlineAt);

    return (
      <View>
        <PageHeader />
        <DreamDetailHeader data={dream} style={{ marginBottom: 24 }} />
        <DreamProgress
          data={dream}
          style={{ padding: '0 24px 0 24px', marginBottom: 24 }}
        />
        <DreamDetailContent
          data={dream}
          style={{ padding: '0 24px 0 24px', marginBottom: 24 }}
        />
        {dream.fundingType !== 'donation' && (
          <View>
            <View style={{ padding: '0 24px 0 24px', marginBottom: 16 }}>
              <Headline>コース</Headline>
            </View>
            <View style={{ padding: '0 16px 0 16px', marginBottom: 16 }}>
              <ProductList
                dreamId={dream.id}
                renderSubmit={({ element, item }) => (
                  <ProductPurchaseLink
                    stockCount={item.stockCount}
                    to={`/dream/${this.props.match.params.id}/product/${
                      item.id
                    }/input`}
                  >
                    {element}
                  </ProductPurchaseLink>
                )}
              />
              <Link to={`/dream/${dream.id}/product`}>
                <Button variant="text">もっと見る</Button>
              </Link>
            </View>
          </View>
        )}
        {dream.fundingType === 'donation' && !deadline.isFinished && (
          <DonateButton
            data={dream}
            style={{ marginBottom: 24, padding: '0 16px' }}
          />
        )}
        <View style={{ padding: '0 24px 0 24px', marginBottom: 16 }}>
          <Headline>活動レポート</Headline>
        </View>
        <DreamReportTimeline
          dreamId={dream.id}
          style={{ padding: '0 16px 0 16px', marginBottom: 24 }}
        />
        {dream.fundingType === 'donation' && (
          <View>
            <View style={{ padding: '0 24px 0 24px', marginBottom: 16 }}>
              <Headline>支援のお礼</Headline>
            </View>
            <View style={{ padding: '0 16px', marginBottom: 24 }}>
              <GiftList data={dream} />
              <SupporterProgress
                supporter={dream.supporter}
                style={{ marginBottom: 16 }}
              />
              <Paragraph onerow>
                支援のお礼は、支援の合計金額を超えると受け取ることができます。また、寄付をされた方の名前は、
                <Link
                  to={`/dream/${dream.id}/thank`}
                  style={{
                    display: 'inline-flex',
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}
                >
                  支援者一覧
                </Link>
                に掲載されます。
              </Paragraph>
            </View>
          </View>
        )}
        {dream.fundingType === 'donation' && !deadline.isFinished && (
          <DonateButton
            data={dream}
            style={{ marginBottom: 24, padding: '0 16px' }}
          />
        )}
        <View style={{ padding: '0 24px 0 24px', marginBottom: 16 }}>
          <Headline>コメント</Headline>
        </View>
        <View style={{ padding: '0 16px', marginBottom: 16 }}>
          <View
            onClick={() => {
              if (this.props.auth.uid) {
                this.setState({ isOpenCommentModal: true });
              } else {
                this.setState({ isOpenRegisterModal: true });
              }
            }}
          >
            <UserCard
              source={
                this.props.user.myself && this.props.user.myself.iconImage
              }
            >
              <Text
                style={{
                  color: Color.slate,
                  fontSize: 15
                }}
              >
                コメントを書く
              </Text>
            </UserCard>
          </View>
          <CommentList
            dreamId={dream.id}
            onClickItem={id => {
              if (this.props.auth.uid) {
                this.setState({ isOpenReplyModal: true, replyCommentId: id });
              } else {
                this.setState({ isOpenRegisterModal: true });
              }
            }}
          />
        </View>
        <Footer />
        <RegisterModal
          visible={this.state.isOpenRegisterModal}
          onClose={() => this.setState({ isOpenRegisterModal: false })}
          content={
            <View style={{ marginBottom: 8 }}>
              <Headline size={3} style={{ marginBottom: 16 }}>
                VISIONSをご利用になるために
              </Headline>
              <Paragraph>
                VISIONSの各機能をご利用になるためには、新規登録/ログインをする必要があります。
              </Paragraph>
            </View>
          }
        />
        <CommentModal
          visible={this.state.isOpenCommentModal}
          onSubmit={text => {
            this.setState({ isOpenCommentModal: false }, () => {
              this.props.createDreamComment({ dreamId: dream.id, text });
            });
          }}
          onClose={() => this.setState({ isOpenCommentModal: false })}
        />
        <ReplyModal
          founderId={dream.founder.id}
          comment={dream.comments.find(
            comment => comment.id === this.state.replyCommentId
          )}
          visible={this.state.isOpenReplyModal}
          onSubmit={text => {
            this.setState({ isOpenReplyModal: false }, () => {
              this.props.createDreamCommentReply({
                dreamId: dream.id,
                commentId: this.state.replyCommentId,
                text
              });
            });
          }}
          onClose={() => this.setState({ isOpenReplyModal: false })}
        />
        <SupportModal
          dreamId={dream.id}
          visible={this.state.isOpenSupportModal}
          onClose={() => this.setState({ isOpenSupportModal: false })}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamDetail);
