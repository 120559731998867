import { getCollection } from '../utils/Firebase';
import DreamGift from '../models/DreamGift';
import DreamReport from '../models/DreamReport';
import DreamReportSecret from '../models/DreamReportSecret';
import * as types from '../constants/ActionTypes';

// get activity histories
function fetchGetDreamReports({ reset = false }) {
  return {
    type: types.FETCH_GET_DREAM_REPORTS,
    state: {
      reset
    }
  };
}

function fetchGetDreamReportsSuccess(list, reset = false) {
  return {
    type: types.FETCH_GET_DREAM_REPORTS_SUCCESS,
    state: {
      reset,
      list
    }
  };
}

function fetchGetDreamReportsFailed(error) {
  return {
    type: types.FETCH_GET_DREAM_REPORTS_FAILED,
    error
  };
}

export function recoverGetDreamReports() {
  return {
    type: types.FETCH_GET_DREAM_REPORTS_RECOVER
  };
}

export function getDreamReports(
  dreamId,
  options = { reset: false, lastItem: null }
) {
  return (dispatch, getState) => {
    dispatch(fetchGetDreamReports({ reset: options.reset }));

    const dreamReports = getCollection('dreams')
      .doc(dreamId)
      .collection('reports');

    let query = dreamReports.orderBy('startAt', 'desc');

    if (options.lastItem) {
      query = query.startAfter(options.lastItem.startAt);
    }

    query = query.limit(10);

    return query
      .get()
      .then(async ({ docs }) => {
        const list = await Promise.all(
          docs.map(doc => {
            const dreamReport = doc.data();

            return new DreamReport({
              id: doc.id,
              ...dreamReport
            }).fromFirestore();
          })
        );

        return dispatch(fetchGetDreamReportsSuccess(list, options.reset));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetDreamReportsFailed(error));
        }
      });
  };
}

// get dream report
function fetchGetDreamReport() {
  return {
    type: types.FETCH_GET_DREAM_REPORT
  };
}

function fetchGetDreamReportSuccess(id, params) {
  return {
    type: types.FETCH_GET_DREAM_REPORT_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetDreamReportFailed(error) {
  return {
    type: types.FETCH_GET_DREAM_REPORT_FAILED,
    error
  };
}

export function recoverGetDreamReport() {
  return {
    type: types.FETCH_GET_DREAM_REPORT_RECOVER
  };
}

export function getDreamReport(dreamId, reportId) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchGetDreamReport());

    const reports = getCollection('dreams')
      .doc(dreamId)
      .collection('reports')
      .doc(reportId);

    return reports
      .get()
      .then(async doc => {
        const reportData = doc.data();

        let supporterDoc;

        let total = 0;

        if (auth.uid) {
          supporterDoc = await getCollection('dreams')
            .doc(dreamId)
            .collection('supporters')
            .doc(auth.uid)
            .get();
          if (supporterDoc.exists) {
            total = supporterDoc.data().total;
          }
        }

        const { docs: giftDocs } = await getCollection('dreams')
          .doc(dreamId)
          .collection('gifts')
          .get();

        const gifts = giftDocs.map(doc =>
          new DreamGift({ id: doc.id, ...doc.data() }).fromFirestore()
        );

        let secrets = [];

        if (reportData.giftIds) {
          secrets = await Promise.all(
            reportData.giftIds.map(async giftId => {
              let secretData = {};

              const gift = gifts.find(gift => gift.id === giftId);

              if (gift.price > total) {
                secretData = {
                  isExist: true,
                  isLocked: true
                };
              } else {
                try {
                  const secretDoc = await getCollection('dreams')
                    .doc(dreamId)
                    .collection('reports')
                    .doc(reportId)
                    .collection('secrets')
                    .doc(giftId)
                    .get();

                  secretData = secretDoc.exists
                    ? { isLocked: false, isExist: true, ...secretDoc.data() }
                    : { isLocked: false, isExist: false };
                } catch (error) {
                  secretData = {
                    isExist: true,
                    isLocked: true
                  };
                }
              }

              return new DreamReportSecret({
                id: gift.id,
                gift,
                ...secretData
              }).fromFirestore();
            })
          );
        }

        const report = new DreamReport({
          id: doc.id,
          secrets,
          ...doc.data()
        }).fromFirestore();

        return dispatch(fetchGetDreamReportSuccess(reportId, report));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetDreamReportFailed(error));
        }
      });
  };
}
