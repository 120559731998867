import React from 'react';

import {
  SCHEME_ASSET_LABELS,
  SCHEME_ASSET_VALIDATION_RULE,
  SCHEME_BASIC_INFO_LABELS,
  SCHEME_BASIC_INFO_VALIDATION_RULE,
  SCHEME_BUSINESS_MODEL_LABELS,
  SCHEME_BUSINESS_MODEL_VALIDATION_RULE,
  SCHEME_CANVAS_LABELS,
  SCHEME_CANVAS_VALIDATION_RULE,
  SCHEME_INDICATOR_LABELS,
  SCHEME_INDICATOR_VALIDATION_RULE,
  SCHEME_PROCESS_STREAM_LABELS,
  SCHEME_PROCESS_STREAM_VALIDATION_RULE
} from '../../constants/Validations';
import BusinessModelPreview from './BusinessModelPreview';
import BusinessModelType from '../../models/BusinessModelType';
import ProposalInputSectionCard from '../Proposal/ProposalInputSectionCard';
import SchemeAssetFormField from '../../containers/form/scheme/SchemeAssetFormField';
import SchemeBasicInfoFormField from '../../containers/form/scheme/SchemeBasicInfoFormField';
import SchemeBusinessModelFormField from '../../containers/form/scheme/SchemeBusinessModelFormField';
import SchemeCanvasFormField from '../../containers/form/scheme/SchemeCanvasFormField';
import SchemeIndicatorFormField from '../../containers/form/scheme/SchemeIndicatorFormField';
import SchemeProcessStreamFormField from '../../containers/form/scheme/SchemeProcessStreamFormField';
import View from '../View';

export default class SchemeFormField extends React.Component {
  render() {
    const {
      params,
      onChangeParams,
      style = {},
      businessModelPreviewStyle = {}
    } = this.props;

    return (
      <View style={style}>
        <ProposalInputSectionCard
          title="基本情報"
          number="01"
          description="あなたの事業の基盤となる情報をまとめましょう。"
          labels={SCHEME_BASIC_INFO_LABELS}
          rule={SCHEME_BASIC_INFO_VALIDATION_RULE}
          properties={params.scheme.basicInfo}
          style={{ marginBottom: 24 }}
        >
          <SchemeBasicInfoFormField
            params={params.scheme.basicInfo}
            onChangeParams={state => {
              onChangeParams({
                scheme: {
                  ...params.scheme,
                  basicInfo: { ...params.scheme.basicInfo, ...state }
                }
              });
            }}
            rules={SCHEME_BASIC_INFO_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <BusinessModelPreview
          models={params.scheme.businessModel.models}
          relations={params.scheme.businessModel.relations}
          levelOptions={params.scheme.businessModel.levelOptions}
          width={555}
          height={555}
          style={businessModelPreviewStyle}
        />
        <ProposalInputSectionCard
          title="ビジネスモデル"
          number="02"
          description="このビジネスモデルの関係を、モデルを用いて表現しましょう。"
          labels={SCHEME_BUSINESS_MODEL_LABELS}
          rule={SCHEME_BUSINESS_MODEL_VALIDATION_RULE}
          properties={params.scheme.businessModel}
          style={{ marginBottom: 24 }}
        >
          <SchemeBusinessModelFormField
            params={params.scheme.businessModel}
            schemeData={{
              model: {
                type: 'node',
                label: 'モデル',
                labelKey: 'modelLabel',
                params: [
                  { key: 'modelLabel', label: 'ラベル', type: 'text' },
                  {
                    key: 'modelType',
                    label: 'モデルタイプ',
                    type: 'select',
                    items: BusinessModelType.getItems(true)
                  },
                  { key: 'modelLevel', label: '階層', type: 'number' }
                ]
              },
              relation: {
                type: 'node',
                label: '関係',
                labelKey: 'relationLabel',
                params: [
                  { key: 'relationLabel', label: 'ラベル', type: 'text' },
                  {
                    key: 'relationType',
                    label: '関係の種類',
                    type: 'select',
                    items: [
                      {
                        label: 'アクション/関係',
                        value: 'action'
                      },
                      {
                        label: '起こりうるアクション/関係',
                        value: 'mightAction'
                      },
                      {
                        label: '依存',
                        value: 'dependence'
                      }
                    ]
                  },
                  {
                    key: 'relationSituation',
                    label: '関係の実態',
                    type: 'select',
                    items: [
                      {
                        label: '関係のみ',
                        value: 'normal'
                      },
                      {
                        label: 'もの',
                        value: 'object'
                      },
                      {
                        label: 'お金',
                        value: 'money'
                      },
                      {
                        label: '情報',
                        value: 'data'
                      }
                    ]
                  },
                  {
                    key: 'relationSource',
                    label: '関係元',
                    type: 'select',
                    items: [
                      { label: '未選択', value: 'unselected' },
                      ...params.scheme.businessModel.models.map(model => ({
                        label: model.modelLabel,
                        value: model.id
                      }))
                    ]
                  },
                  {
                    key: 'relationDestination',
                    label: '関係先',
                    type: 'select',
                    items: [
                      { label: '未選択', value: 'unselected' },
                      ...params.scheme.businessModel.models.map(model => ({
                        label: model.modelLabel,
                        value: model.id
                      }))
                    ]
                  },
                  {
                    key: 'relationDetourWay',
                    label: '描画の方向',
                    type: 'select',
                    items: [
                      { label: '直線', value: 'straight' },
                      { label: '右回り', value: 'right' },
                      { label: '左回り', value: 'left' }
                    ]
                  },
                  {
                    key: 'relationDetourDistance',
                    label: '迂回距離',
                    type: 'number'
                  }
                ]
              },
              levelOptions: {
                type: 'node',
                label: 'オプション（レベル）',
                labelKey: 'levelLabel',
                params: [
                  {
                    key: 'levelLabel',
                    label: 'ラベル',
                    type: 'text',
                    control: 'hidden'
                  },
                  {
                    key: 'levelLevel',
                    label: '階層',
                    type: 'number',
                    control: 'hidden'
                  },
                  { key: 'levelAngle', label: '角度', type: 'number' },
                  { key: 'levelRadius', label: '半径', type: 'number' }
                ]
              }
            }}
            onChangeParams={state =>
              onChangeParams({
                scheme: {
                  ...params.scheme,
                  businessModel: {
                    ...params.scheme.businessModel,
                    ...state
                  }
                }
              })
            }
            rules={SCHEME_BUSINESS_MODEL_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="モデルの背景"
          number="03"
          description="従来のビジネスモデルの課題と、解決方法、その解決方法を実現・実践するための独自の価値を示しましょう。"
          labels={SCHEME_CANVAS_LABELS}
          rule={SCHEME_CANVAS_VALIDATION_RULE}
          properties={params.scheme.canvas}
          style={{ marginBottom: 24 }}
        >
          <SchemeCanvasFormField
            params={params.scheme.canvas}
            onChangeParams={state =>
              onChangeParams({
                scheme: {
                  ...params.scheme,
                  canvas: {
                    ...params.scheme.canvas,
                    ...state
                  }
                }
              })
            }
            rules={SCHEME_CANVAS_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="一連の流れ"
          number="04"
          description="この事業を利用するに当たり、各利用者を主語にしてその利用の流れを記述してください。"
          labels={SCHEME_PROCESS_STREAM_LABELS}
          rule={SCHEME_PROCESS_STREAM_VALIDATION_RULE}
          properties={params.scheme.processStream}
          style={{ marginBottom: 24 }}
        >
          <SchemeProcessStreamFormField
            params={params.scheme.processStream}
            onChangeParams={state =>
              onChangeParams({
                scheme: {
                  ...params.scheme,
                  processStream: {
                    ...params.scheme.processStream,
                    ...state
                  }
                }
              })
            }
            rules={SCHEME_PROCESS_STREAM_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="資産"
          number="05"
          description="この事業を行うことで得られる収入、かかるコストについて示した後、無形の資産であるブランド、無形の負債である批判的な目のリスクについて記述してください。"
          labels={SCHEME_ASSET_LABELS}
          rule={SCHEME_ASSET_VALIDATION_RULE}
          properties={params.scheme.asset}
          style={{ marginBottom: 24 }}
        >
          <SchemeAssetFormField
            params={params.scheme.asset}
            onChangeParams={state =>
              onChangeParams({
                scheme: {
                  ...params.scheme,
                  asset: {
                    ...params.scheme.asset,
                    ...state
                  }
                }
              })
            }
            rules={SCHEME_ASSET_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="指標"
          number="06"
          description="ビジネスモデルが成立しているかどうか、改善するときに見ておきたい指標などをKPIとして、最終的にゴールとする指標をKGIとして記述してみましょう。"
          labels={SCHEME_INDICATOR_LABELS}
          rule={SCHEME_INDICATOR_VALIDATION_RULE}
          properties={params.scheme.indicator}
          style={{ marginBottom: 24 }}
        >
          <SchemeIndicatorFormField
            params={params.scheme.indicator}
            onChangeParams={state =>
              onChangeParams({
                scheme: {
                  ...params.scheme,
                  indicator: { ...params.scheme.indicator, ...state }
                }
              })
            }
            rules={SCHEME_INDICATOR_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
      </View>
    );
  }
}
