export default class DreamReportSecret {
  constructor(state = {}) {
    this.params = {
      id: state.id || '',
      isExist: state.isExist,
      isLocked: state.isLocked,
      giftId: state.giftId || '',
      images: state.images || [],
      gift: state.gift,
      content: state.content || ''
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
