import React from 'react';

import Color from '../../constants/Color';
import Image from '../Image';
import MaterialIcon from '../../utils/MaterialIcon';
import View from '../View';

function renderIcon({ source, size }) {
  if (!source) {
    return (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: size / 2,
          width: size,
          height: size,
          backgroundColor: Color.lightGrey
        }}
      >
        <MaterialIcon icon="person" size={size * 0.8} />
      </View>
    );
  }

  return (
    <Image
      source={source}
      style={{ borderRadius: size / 2, width: size, height: size }}
    />
  );
}

export default function SphereIcon({ source, size = 180, style = {} }) {
  return (
    <View style={style}>
      <View style={{ zIndex: 1 }}>{renderIcon({ source, size })}</View>
      <Image
        source={require('../../assets/img/wish/shadow.png')}
        style={{ width: size, marginTop: -size / 4 }}
      />
    </View>
  );
}
