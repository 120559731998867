import React from 'react';
import moment from 'moment';

import { getGradient, getGradientCss } from '../../utils/ColorJs';
import Color from '../../constants/Color';
import Style from '../../constants/Style';
import Text from '../Text';
import View from '../View';

export default function ReplyPlate({ text, invert = false, createdAt }) {
  const backgroundImage = invert
    ? { backgroundImage: getGradientCss(getGradient(Color.pinkGradient), 135) }
    : {};

  return (
    <View
      style={{
        flex: 1,
        borderRadius: 16,
        padding: 8,
        ...backgroundImage,
        ...Style.shadow.medium
      }}
    >
      <View style={{ flex: 1 }}>
        <Text
          style={{
            color: invert ? Color.white : Color.slate,
            fontSize: 11,
            fontWeight: invert ? 'bold' : 'normal'
          }}
        >
          {text}
        </Text>
      </View>
      <Text
        style={{
          color: invert ? Color.white : Color.lightSlate,
          textAlign: 'right',
          fontSize: 11
        }}
      >
        {moment(createdAt).format('YYYY/MM/DD HH:mm:ss')}
      </Text>
    </View>
  );
}
