import * as types from '../constants/ActionTypes';

const initialState = {
  width: window.innerWidth,
  height: window.innerHeight
};

export default (state = initialState, action) => {
  switch (action.type) {
    // window
    case types.UPDATE_WINDOW_SIZE:
      return {
        ...state,
        width: action.state.width,
        height: action.state.height
      };

    default:
      return state;
  }
};
