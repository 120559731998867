import React from 'react';

import Style from '../../constants/Style';
import UserIcon from '../Icons/UserIcon';
import View from '../View';

export default function UserCard({
  source,
  children,
  after = null,
  style = {},
  ...rest
}) {
  return (
    <View
      style={{ position: 'relative', paddingTop: 10, marginBottom: 16 }}
      {...rest}
    >
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 8,
          width: 64,
          height: 64,
          borderRadius: 8
        }}
      >
        <UserIcon
          size={64}
          source={source}
          style={{ ...Style.shadow.medium }}
        />
      </View>
      <View style={{ borderRadius: 8, padding: 8, ...Style.shadow.large }}>
        <View
          style={{
            flexDirection: 'row',
            minHeight: 64
          }}
        >
          <View style={{ flex: 1, marginLeft: 72 }}>{children}</View>
        </View>
        {after}
      </View>
    </View>
  );
}
