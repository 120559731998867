import { Component } from 'react';

export default class ResponseDispatcher extends Component {
  componentDidUpdate(prevProps) {
    if (!prevProps.isFailed && this.props.isFailed) {
      this.props.onError && this.props.onError();
    } else if (
      !prevProps.isFailed &&
      !this.props.isFailed &&
      prevProps.isLoading &&
      !this.props.isLoading
    ) {
      this.props.onComplete && this.props.onComplete();
    }
  }

  render() {
    return null;
  }
}
