import React from 'react';

import View from '../View';

export default function GradientMask({ deg = '0deg', style = {} }) {
  return (
    <View
      style={{
        backgroundImage: `linear-gradient(${deg}, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)`,
        ...style
      }}
    />
  );
}
