import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  PROPOSAL_METADATA_LABELS,
  PROPOSAL_METADATA_VALIDATION_RULE,
  PROPOSAL_UPDATE_FORM_LABELS,
  PROPOSAL_UPDATE_FORM_VALIDATION_RULE
} from '../../../constants/Validations';
import { validate, validateAll } from '../../../utils/Validation';
import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import ContentContainer from '../../../components/Page/ContentContainer';
import FooterContainer from '../../../components/FooterContainer';
import Headline from '../../../components/Headline';
import MaterialIcon from '../../../utils/MaterialIcon';
import PageHeader from '../../../containers/page/PageContaineredHeader';
import Panel from '../../../components/Panel';
import Paragraph from '../../../components/Paragraph';
import PasswordForm from '../../../components/Form/PasswordForm';
import Proposal from '../../../models/Proposal';
import ProposalFormField from '../../../components/Proposal/ProposalFormField';
import ResponseDispatcher from '../../../components/ResponseDispatcher';
import SectionCard from '../../../components/Card/SectionCard';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      proposal: new Proposal(props.proposal.hash[this.props.match.params.id])
        .object,
      editPassword: ''
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      this.props.updateProposalFormParams(nextState);

      return true;
    }

    if (
      !nextProps.proposal.isGetLoading &&
      this.props.proposal.isGetLoading &&
      nextProps.proposal.hash[this.props.match.params.id]
    ) {
      const proposal = new Proposal(
        nextProps.proposal.hash[this.props.match.params.id]
      ).object;

      this.setState({
        proposal
      });

      return true;
    }

    if (nextProps.proposal !== this.props.proposal) {
      return true;
    }

    if (nextProps) return false;
  }

  componentDidMount() {
    this.props.getProposal(this.props.match.params.id);
  }

  render() {
    const proposal =
      // (this.props.proposalForm.hash[this.props.match.params.id] &&
      //   this.props.proposalForm.hash[this.props.match.params.id].proposal) ||
      this.props.proposal.hash[this.props.match.params.id];

    if (!proposal) {
      return (
        <View>
          <PageHeader />
          <ContentContainer>
            <Row>
              <Column offset={{ xs: 1 }} xs={10}>
                <PasswordForm
                  description="この事業計画書は編集にパスワードが必要です。"
                  passwordType="edit"
                  isLoading={this.props.proposal.isGetLoading}
                  onSubmit={password =>
                    this.props.getProposal(this.props.match.params.id, password)
                  }
                />
              </Column>
            </Row>
          </ContentContainer>
          <FooterContainer />
        </View>
      );
    }

    return (
      <View>
        <PageHeader />
        <ContentContainer>
          <Row>
            <Column offset={{ xs: 1 }} xs={10}>
              <View style={{ padding: '16px 16px 0px 16px', marginBottom: 24 }}>
                <Headline style={{ marginBottom: 16 }}>
                  かんたん事業計画
                </Headline>
                <Paragraph>
                  入力フォームに情報を入力して、あなたの事業計画書を完成させましょう。
                </Paragraph>
              </View>
              <ProposalFormField
                params={this.state}
                onChangeParams={state => this.setState(state)}
              />
              <SectionCard
                title="編集パスワード"
                panel={
                  <Panel size={40} style={{ backgroundColor: Color.pink }}>
                    <MaterialIcon icon="lock" color={Color.white} size={24} />
                  </Panel>
                }
                style={{ marginBottom: 24 }}
              >
                <TextFormField
                  value={this.state.editPassword}
                  name="editPassword"
                  direction="column"
                  type="password"
                  verification
                  onChangeText={editPassword => {
                    this.setState({
                      editPassword
                    });
                  }}
                  label="編集パスワード"
                  errorMessage={validate({
                    label: PROPOSAL_METADATA_LABELS.editPassword,
                    value: this.state.editPassword,
                    rule: PROPOSAL_METADATA_VALIDATION_RULE.editPassword
                  })}
                  limitLength={
                    PROPOSAL_METADATA_VALIDATION_RULE.editPassword.maxLength
                  }
                  helpText="更新のため編集パスワードを入力してください。"
                  style={{ marginBottom: 16 }}
                />
              </SectionCard>
              <Button
                disable={this.props.proposal.isUpdateLoading}
                onClick={() => {
                  const errorMessage = validateAll({
                    labels: PROPOSAL_UPDATE_FORM_LABELS,
                    params: this.state,
                    rules: PROPOSAL_UPDATE_FORM_VALIDATION_RULE
                  });

                  if (errorMessage) {
                    alert(errorMessage);

                    return;
                  }

                  this.props.updateProposal(
                    this.props.match.params.id,
                    this.state.proposal,
                    this.state.viewPassword,
                    this.state.editPassword
                  );
                }}
                style={{ marginBottom: 24 }}
              >
                事業計画書を更新する
              </Button>
              <ResponseDispatcher
                isLoading={this.props.proposal.isUpdateLoading}
                isFailed={this.props.proposal.isUpdateFailed}
                error={this.props.proposal.updateError}
                onComplete={() =>
                  this.props.history.push(
                    `/proposal/${this.props.match.params.id}/result`
                  )
                }
                onError={() => {
                  alert(this.props.proposal.updateError.message);

                  this.props.recoverUpdateProposal();
                }}
              />
              <Paragraph
                containerStyle={{ marginBottom: 24 }}
                style={{ textAlign: 'center' }}
              >
                ボタンを押すことでプライバシーポリシーと
                {'\n'}
                利用規約に同意したものとみなします。
              </Paragraph>
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalEdit);
