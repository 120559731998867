import React from 'react';

import Color from '../../constants/Color';

export default function Logo({ fill = Color.black, size = 32 }) {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 132 132"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Artboard</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Artboard"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group-2"
          transform="translate(61.933587, 56.269892) rotate(-36.000000) translate(-61.933587, -56.269892) translate(-1.566413, -4.230108)"
          fill={fill}
          fillRule="nonzero"
        >
          <path
            d="M35.9984027,56.5442929 L31.7957516,69.1522463 L-7.10542736e-15,46 L44,46 L27,50 L35.9984027,56.5442929 Z M81.931388,89.9501004 L82,90 L81.9652179,89.9254669 L86.7404149,86.4483817 L103,121 L71.1447629,97.804439 L81.931388,89.9501004 Z M67.256339,58.4064408 L54,30 L63,-7.10542736e-15 L75,36 L66,26 L63,36 L71.9669116,55.0546872 L67.256339,58.4064408 Z"
            id="Combined-Shape"
          />
          <polygon
            id="Path"
            points="49 46 24 121 127 46 78 46 96 49 44 86 54.3448754 56.9385826"
          />
        </g>
      </g>
    </svg>
  );
}
