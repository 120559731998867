import { getHash, getRemovedHash } from '../utils/Reducer';
import * as types from '../constants/ActionTypes';

const initialState = {
  list: [],
  hash: {},
  isGetLoading: false,
  isGetFailed: false,
  getError: null,
  isCreateLoading: false,
  isCreateFailed: false,
  createError: null,
  isUpdateLoading: false,
  isUpdateFailed: false,
  updateError: null
};

export default (state = initialState, action) => {
  let newHash;

  switch (action.type) {
    // get dreams
    case types.FETCH_GET_SHIPPING_INFOS:
      return {
        ...state,
        isGetLoading: true
      };

    case types.FETCH_GET_SHIPPING_INFOS_SUCCESS:
      newHash = getHash(action.state.list, state.hash, 'id');

      return {
        ...state,
        list: action.state.list.map(item => item.id),
        hash: newHash,
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_SHIPPING_INFOS_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_SHIPPING_INFOS_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // get shipping info
    case types.FETCH_GET_SHIPPING_INFO:
      return {
        ...state,
        isGetLoading: true
      };

    case types.FETCH_GET_SHIPPING_INFO_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        list: [action.state.id],
        isGetLoading: false,
        isGetFailed: false
      };

    case types.FETCH_GET_SHIPPING_INFO_FAILED:
      return {
        ...state,
        isGetLoading: false,
        isGetFailed: true,
        getError: action.error
      };

    case types.FETCH_GET_SHIPPING_INFO_RECOVER:
      return {
        ...state,
        isGetFailed: false
      };

    // create shipping info
    case types.FETCH_CREATE_SHIPPING_INFO:
      return {
        ...state,
        isCreateLoading: true
      };

    case types.FETCH_CREATE_SHIPPING_INFO_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        list: [...state.list, action.state.id],
        isCreateLoading: false,
        isCreateFailed: false
      };

    case types.FETCH_CREATE_SHIPPING_INFO_FAILED:
      return {
        ...state,
        isCreateLoading: false,
        isCreateFailed: true,
        createError: action.error
      };

    case types.FETCH_CREATE_SHIPPING_INFO_RECOVER:
      return {
        ...state,
        isCreateFailed: false
      };

    // update shipping info
    case types.FETCH_UPDATE_SHIPPING_INFO:
      return {
        ...state,
        isUpdateLoading: true
      };

    case types.FETCH_UPDATE_SHIPPING_INFO_SUCCESS:
      return {
        ...state,
        hash: {
          ...state.hash,
          [action.state.id]: action.state.params
        },
        isUpdateLoading: false,
        isUpdateFailed: false
      };

    case types.FETCH_UPDATE_SHIPPING_INFO_FAILED:
      return {
        ...state,
        isUpdateLoading: false,
        isUpdateFailed: true,
        updateError: action.error
      };

    case types.FETCH_UPDATE_SHIPPING_INFO_RECOVER:
      return {
        ...state,
        isUpdateFailed: false
      };

    // delete shipping info
    case types.FETCH_DELETE_SHIPPING_INFO:
      return {
        ...state,
        isDeleteLoading: true
      };

    case types.FETCH_DELETE_SHIPPING_INFO_SUCCESS:
      return {
        ...state,
        hash: getRemovedHash(action.state.id, state.hash),
        list: state.list.filter(id => id !== action.state.id),
        isDeleteLoading: false,
        isDeleteFailed: false
      };

    case types.FETCH_DELETE_SHIPPING_INFO_FAILED:
      return {
        ...state,
        isDeleteLoading: false,
        isDeleteFailed: true,
        deleteError: action.error
      };

    case types.FETCH_DELETE_SHIPPING_INFO_RECOVER:
      return {
        ...state,
        isDeleteFailed: false
      };

    default:
      return state;
  }
};
