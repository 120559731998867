import moment from 'moment';

export default class DreamReport {
  constructor(state = {}) {
    this.params = {
      id: state.id,
      images: state.images || [],
      secrets: state.secrets || [],
      title: state.title || '',
      content: state.content || '',
      status: state.status || 'draft',
      startAt: state.startAt || moment().toDate()
    };
  }

  get object() {
    return this.params;
  }

  fromFirestore() {
    return {
      ...this.params,
      startAt: this.params.startAt && this.params.startAt.toDate(),
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
