import React from 'react';

import { PdfImage, PdfText, PdfView } from '../../utils/Pdf';
import Color from '../../constants/Color';

export default function PdfFeaturePanel({
  title,
  color,
  icon,
  content,
  style = {}
}) {
  return (
    <PdfView
      style={{
        backgroundColor: color,
        padding: 8,
        ...style
      }}
    >
      <PdfView
        style={{
          flexDirection: 'row',
          borderBottom: `1pt solid ${Color.white}`,
          paddingBottom: 4,
          marginBottom: 4
        }}
      >
        <PdfImage
          src={icon}
          style={{ width: 14, height: 14, marginRight: 4 }}
        />
        <PdfText
          style={{
            fontSize: 12,
            color: Color.white,
            fontWeight: 'bold'
          }}
        >
          {title}
        </PdfText>
      </PdfView>
      <PdfView>
        <PdfText
          style={{
            fontSize: 10,
            color: Color.white,
            fontWeight: 'bold',
            lineHeight: 1.3
          }}
        >
          {content}
        </PdfText>
      </PdfView>
    </PdfView>
  );
}
