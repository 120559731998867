import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import ReactPlaceholder from 'react-placeholder';

import Color from '../../constants/Color';
import GradientMask from '../Card/GradientMask';
import Image from '../Image';
import Slider from '../../utils/Slider';
import Style from '../../constants/Style';
import Text from '../Text';
import View from '../View';
import actions from '../../actions';

class ExemplariesSlider extends React.Component {
  render() {
    const { data, style = {} } = this.props;

    if (data.length < 1) {
      return (
        <View style={{ ...style, margin: 0 }}>
          <ReactPlaceholder
            showLoadingAnimation
            type="rect"
            style={{ width: '100%', height: 208 }}
          />
        </View>
      );
    }

    return (
      <View style={style}>
        <Slider
          autoplay
          autoplaySpeed={3000}
          arrows={false}
          slidesToShow={1}
          slidesToScroll={1}
          speed={500}
        >
          {data.map((item, index) => {
            const key = index;

            return (
              <Link key={`exemplaries-${key}`} to={`/dream/${item.id}`}>
                <View>
                  <View
                    style={{
                      borderRadius: 8,
                      overflow: 'hidden',
                      height: 240,
                      backgroundColor: Color.white,
                      margin: '16px 16px 16px 16px',
                      ...Style.shadow.large
                    }}
                  >
                    <View style={{ flexDirection: 'row', minHeight: 120 }}>
                      <View
                        style={{
                          width: 120,
                          height: 120,
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: 16
                        }}
                      >
                        <Text
                          style={{
                            color: Color.slate,
                            fontSize: 11,
                            fontWeight: 'bold'
                          }}
                        >
                          {item.title}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          backgroundImage: `url(${
                            item.bannerBackgroundImage.uri
                          })`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                        }}
                      />
                    </View>
                    <View style={{ flexDirection: 'row', minHeight: 120 }}>
                      <Link to={`/founder/${item.founder.id}`}>
                        <View
                          style={{
                            width: 120,
                            height: 120,
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          <Image
                            source={item.founder.iconImage}
                            style={{ width: 120, height: 120 }}
                          />
                        </View>
                      </Link>
                      <View
                        style={{
                          padding: 16,
                          position: 'relative'
                        }}
                      >
                        <Text
                          style={{
                            color: Color.slate,
                            fontSize: 11,
                            fontWeight: 'bold'
                          }}
                        >
                          {item.founder.screenName}
                        </Text>
                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              color: Color.slate,
                              overflow: 'hidden',
                              fontSize: 11
                            }}
                          >
                            {item.founder.profile}
                          </Text>
                        </View>
                        <GradientMask
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            left: 0,
                            width: '100%',
                            height: 32
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </Link>
            );
          })}
        </Slider>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExemplariesSlider);
