import React from 'react';

import ArticleSubHeadline from './ArticleSubHeadline';
import Color from '../../constants/Color';
import Panel from '../Panel';
import Paragraph from '../Paragraph';
import SectionCard from '../Card/SectionCard';
import Text from '../Text';
import View from '../View';

export default function ArticleCardTemplate({
  title,
  number,
  description,
  sections = [],
  style = {}
}) {
  return (
    <View style={style}>
      <SectionCard
        title={title}
        panel={
          <Panel size={40} style={{ backgroundColor: Color.pink }}>
            <Text
              style={{ color: Color.white, fontWeight: 'bold', fontSize: 24 }}
            >
              {number}
            </Text>
          </Panel>
        }
        style={{ padding: '0 16px', marginBottom: 24 }}
      >
        <Paragraph containerStyle={{ marginBottom: 16 }}>
          {description}
        </Paragraph>
        {sections.map((section, index) => {
          return (
            <View>
              <ArticleSubHeadline style={{ fontSize: 15, marginBottom: 16 }}>
                ({index + 1}){section.title}
              </ArticleSubHeadline>
              <Paragraph containerStyle={{ marginBottom: 24 }}>
                {section.content}
              </Paragraph>
            </View>
          );
        })}
      </SectionCard>
    </View>
  );
}
