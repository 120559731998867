import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  CashIconSvg,
  ShieldCheckIconSvg,
  TrendingUpIconSvg
} from '../../../utils/MaterialIconSvg';
import {
  PROPOSAL_APPLICATION_VALIDATION_RULE,
  REVIEW_LABELS,
  REVIEW_VALIDATION_RULE
} from '../../../constants/Validations';
import { getGradient, getGradientCss } from '../../../utils/ColorJs';
import { validateAll } from '../../../utils/Validation';
import ApplicationFormField from '../../../components/ApplicationFormField';
import ArrowStream from '../../../components/Icons/ArrowStream';
import Button from '../../../components/Button';
import CardPrimitive from '../../../components/Card/CardPrimitive';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import Container from '../../../components/Container';
import ContentContainer from '../../../components/Page/ContentContainer';
import FooterContainer from '../../../components/FooterContainer';
import Headline from '../../../components/Headline';
import MaterialIcon from '../../../utils/MaterialIcon';
import PageHeader from '../../../containers/page/PageContaineredHeader';
import Panel from '../../../components/Panel';
import Paragraph from '../../../components/Paragraph';
import PasswordForm from '../../../components/Form/PasswordForm';
import ProposalDownloadLink from '../../../containers/proposal/ProposalDownloadLink';
import ResponseDialog from '../../../components/ResponseDialog';
import SectionCard from '../../../components/Card/SectionCard';
import SeparatedCard from '../../../components/Card/SeparatedCard';
import Text from '../../../components/Text';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalResult extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      application: {
        email: '',
        phoneNumber: '',
        editPassword: ''
      },
      viewPassword: ''
    };
  }

  componentDidMount() {
    this.props.getProposal(this.props.match.params.id);
  }

  render() {
    const proposal = this.props.proposal.hash[this.props.match.params.id];

    if (!proposal) {
      return (
        <View>
          <PageHeader />
          <ContentContainer>
            <Row>
              <Column offset={{ xs: 1 }} xs={10}>
                <PasswordForm
                  description="この事業計画書は閲覧にパスワードが必要です。"
                  passwordType="view"
                  isLoading={this.props.proposal.isGetLoading}
                  onSubmit={password =>
                    this.props.getProposal(this.props.match.params.id, password)
                  }
                />
              </Column>
            </Row>
          </ContentContainer>
          <FooterContainer />
        </View>
      );
    }

    return (
      <View>
        <PageHeader />
        <ContentContainer>
          <Row>
            <Column>
              <View style={{ padding: '40px', marginBottom: 24 }}>
                <Headline style={{ fontSize: 32, marginBottom: 16 }}>
                  かんたん事業計画
                </Headline>
                <Paragraph
                  style={{ fontSize: 17 }}
                  containerStyle={{ marginBottom: 16 }}
                >
                  ダウンロードした事業計画書は、A3で印刷することができます。
                </Paragraph>
                <View style={{ alignItems: 'center' }}>
                  <ProposalDownloadLink
                    preview
                    previewStyle={{ width: 960, height: 740 }}
                    data={proposal}
                    style={{ zIndex: 1 }}
                  />
                </View>
              </View>
            </Column>
          </Row>
          <Row style={{ marginBottom: 40 }}>
            <Column>
              <View>
                <View style={{ marginBottom: 24 }}>
                  <Link to={`/proposal/${this.props.match.params.id}/edit`}>
                    <Button variant="text" textStyle={{ color: Color.slate }}>
                      編集する
                    </Button>
                  </Link>
                </View>
                <Link to={`/proposal/${this.props.match.params.id}/download`}>
                  <Button>ダウンロード</Button>
                </Link>
                <View style={{ alignItems: 'center', paddingTop: 16 }}>
                  <ArrowStream color={Color.navyBlack} />
                </View>
              </View>
            </Column>
          </Row>
        </ContentContainer>
        <View
          style={{
            backgroundImage: `url(${require('../../../assets/img/building-large.jpg')})`,
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom'
          }}
        >
          <Container>
            <Row style={{ margin: '144px 0' }}>
              <Column
                xs={7}
                containerStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center'
                }}
              >
                <Paragraph
                  style={{
                    color: Color.white,
                    fontSize: 20,
                    fontWeight: 'bold'
                  }}
                >
                  その事業、
                  {'\n'}
                  クラウドファンディングで
                  {'\n'}
                  始めませんか？
                </Paragraph>
              </Column>
              <Column xs={5}>
                <SectionCard
                  title={
                    <Headline
                      size={1}
                      style={{ fontSize: 20, paddingTop: 16, paddingLeft: 8 }}
                    >
                      クラウドファンディング
                    </Headline>
                  }
                  panelTop={48}
                  panel={
                    <Panel gradient={Color.pinkGradient} size={64}>
                      <MaterialIcon
                        icon="language"
                        color={Color.white}
                        size={48}
                      />
                    </Panel>
                  }
                  style={{ marginBottom: 24 }}
                >
                  <Paragraph style={{ fontSize: 17, padding: '0 8px' }}>
                    インターネット上であなたの事業の概要を掲載し、共感や協賛してくださった方からお金を集める仕組みです。
                    集めるお金に対するリターンは自由に設定することができるため、リスクを少なく開業資金を集めることができます。
                  </Paragraph>
                </SectionCard>
              </Column>
            </Row>
          </Container>
        </View>
        <View
          style={{
            backgroundImage: `${getGradientCss(
              getGradient(Color.deepBlueGradient),
              0
            )}`
          }}
        >
          <Container>
            <Row>
              <Column style={{ alignItems: 'center', padding: '40px 0' }}>
                <Headline
                  size={1}
                  style={{ color: Color.white, fontSize: 20, marginBottom: 24 }}
                >
                  クラウドファンディングなら…
                </Headline>
                <Paragraph style={{ color: Color.white, fontSize: 17 }}>
                  クラウドファンディングは、事業を始める上で大きな３つのメリットがあります。
                </Paragraph>
              </Column>
            </Row>
            <Row>
              <Column
                xs={4}
                containerStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1
                }}
                style={{ flex: 1 }}
              >
                <SeparatedCard
                  head={
                    <View
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '24px 0'
                      }}
                    >
                      <CashIconSvg
                        fill={Color.lightSlate}
                        width={96}
                        height={96}
                        viewBox="0 0 24 24"
                        style={{ marginBottom: 16 }}
                      />
                      <Text
                        style={{
                          color: Color.lightSlate,
                          fontSize: 17,
                          fontWeight: 'bold'
                        }}
                      >
                        事業のリスク低減
                      </Text>
                    </View>
                  }
                  body={
                    <Paragraph
                      style={{ fontSize: 13 }}
                      containerStyle={{ padding: 24 }}
                    >
                      ある程度お金が集まってから事業を開始することで、事業リスクを低減することができます。借り入れとは違い、商品の先行予約などによってお金を集めるため、商品をお届けすることができれば、お金を返す必要がありません。
                    </Paragraph>
                  }
                  bodyStyle={{ flex: 1 }}
                  style={{ flex: 1 }}
                />
              </Column>
              <Column
                xs={4}
                containerStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1
                }}
                style={{ flex: 1 }}
              >
                <SeparatedCard
                  head={
                    <View
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '24px 0'
                      }}
                    >
                      <ShieldCheckIconSvg
                        fill={Color.lightSlate}
                        width={96}
                        height={96}
                        viewBox="0 0 24 24"
                        style={{ marginBottom: 16 }}
                      />
                      <Text
                        style={{
                          color: Color.lightSlate,
                          fontSize: 17,
                          fontWeight: 'bold'
                        }}
                      >
                        資金リスクの低減
                      </Text>
                    </View>
                  }
                  body={
                    <Paragraph
                      style={{ fontSize: 13 }}
                      containerStyle={{ padding: 24 }}
                    >
                      賛同者が少なければ、事業を取りやめることができます。あなたのアイデアを公開する必要はありますが、資金を集める前に「本当に世の中に必要なのか」と世間に問いかけ、その反応を集金額で知ることができます。
                    </Paragraph>
                  }
                  bodyStyle={{ flex: 1 }}
                  style={{ flex: 1 }}
                />
              </Column>
              <Column
                xs={4}
                containerStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1
                }}
                style={{ flex: 1 }}
              >
                <SeparatedCard
                  head={
                    <View
                      style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '24px 0'
                      }}
                    >
                      <TrendingUpIconSvg
                        fill={Color.lightSlate}
                        width={96}
                        height={96}
                        viewBox="0 0 24 24"
                        style={{ marginBottom: 16 }}
                      />
                      <Text
                        style={{
                          color: Color.lightSlate,
                          fontSize: 17,
                          fontWeight: 'bold'
                        }}
                      >
                        事業開始時の初速を向上
                      </Text>
                    </View>
                  }
                  body={
                    <Paragraph
                      style={{ fontSize: 13 }}
                      containerStyle={{ padding: 24 }}
                    >
                      最初から賛同者がいる状態から事業を始められるため、事業を有利に始めることができます。
                    </Paragraph>
                  }
                  bodyStyle={{ flex: 1 }}
                  style={{ flex: 1 }}
                />
              </Column>
            </Row>
            <Row style={{ paddingTop: 80, marginBottom: 120 }}>
              <Column
                xs={7}
                containerStyle={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  justifyContent: 'center'
                }}
              >
                <View style={{ flexDirection: 'row' }}>
                  <Panel
                    gradient={Color.pinkGradient}
                    size={40}
                    style={{ marginRight: 16 }}
                  >
                    <MaterialIcon
                      icon="local_post_office"
                      color={Color.white}
                      size={24}
                    />
                  </Panel>
                  <View style={{ flex: 1, paddingTop: 8 }}>
                    <Headline
                      style={{
                        color: Color.white,
                        fontSize: 24,
                        marginBottom: 16
                      }}
                    >
                      まずは応募してみましょう
                    </Headline>
                    <Paragraph style={{ color: Color.white, fontSize: 17 }}>
                      作成いただいた事業計画書を応募し、クラウドファンディングで事業を始めてみませんか？まずは、下記のフォームへ連絡先の情報を記入いただき、ご応募ください。
                    </Paragraph>
                  </View>
                </View>
              </Column>
              <Column xs={5}>
                <CardPrimitive style={{ padding: 16 }}>
                  <Headline
                    style={{ color: Color.lightSlate, marginBottom: 16 }}
                  >
                    ご連絡先
                  </Headline>
                  <ApplicationFormField
                    params={this.state.application}
                    onChangeParams={state =>
                      this.setState({
                        application: { ...this.state.application, ...state }
                      })
                    }
                    rules={PROPOSAL_APPLICATION_VALIDATION_RULE}
                    style={{ marginBottom: 32 }}
                  />
                  <Button
                    disable={this.props.review.isCreateLoading}
                    onClick={() => {
                      const errorMessage = validateAll({
                        labels: REVIEW_LABELS,
                        params: this.state.application,
                        rules: REVIEW_VALIDATION_RULE
                      });

                      if (errorMessage) {
                        alert(errorMessage);

                        return;
                      }

                      this.props.createReview({
                        proposalId: this.props.match.params.id,
                        ...this.state.application
                      });
                    }}
                  >
                    応募する
                  </Button>
                  <ResponseDialog
                    isLoading={this.props.review.isCreateLoading}
                    isFailed={this.props.review.isCreateFailed}
                    error={this.props.review.createError}
                    completeMessage="応募が完了しました。"
                    onComplete={() =>
                      this.setState({
                        application: {
                          email: '',
                          phoneNumber: '',
                          editPassword: ''
                        }
                      })
                    }
                    onError={() => {
                      this.props.recoverCreateReview();
                    }}
                  />
                </CardPrimitive>
              </Column>
            </Row>
          </Container>
        </View>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalResult);
