import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../components/Button';
import Color from '../constants/Color';
import FounderRecruitTargetItem from './recruit/FounderRecruitTargetItem';
import Headline from '../components/Headline';
import Paragraph from '../components/Paragraph';
import Text from '../components/Text';
import View from '../components/View';
import actions from '../actions';

class FoudnerRecruit extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getJobs();
  }

  render() {
    const { style = {} } = this.props;

    return (
      <View style={style}>
        <View
          style={{
            backgroundImage: `url(${require('../assets/img/founder-recruit-background.jpg')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <View style={{ padding: '48px 16px 0 16px' }}>
            <Headline size={2} style={{ color: Color.white, marginBottom: 8 }}>
              ファウンダー募集中
            </Headline>
            <Paragraph style={{ color: Color.white, marginBottom: 16 }}>
              VISIONSは、夢を叶えたい「ファウンダー（創業者）」を探しています。
            </Paragraph>
          </View>
          <View
            style={{
              flexDirection: 'row',
              overflow: 'scroll',
              paddingBottom: 8
            }}
          >
            {this.props.job.list.map(id => this.props.job.hash[id]).map(job => {
              return (
                <Link key={job.id} to={`/job/${job.id}`}>
                  <FounderRecruitTargetItem
                    source={job.iconImage}
                    label={job.name}
                    style={{ paddingLeft: 24, marginRight: 8 }}
                  />
                </Link>
              );
            })}
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: '0 24px'
            }}
          >
            <Text style={{ color: Color.white, fontSize: 11 }}>…など</Text>
          </View>
        </View>
        <View
          style={{
            padding: '32px 16px 0 16px',
            backgroundColor: Color.navyBlack
          }}
        >
          <Paragraph style={{ color: Color.white, marginBottom: 16 }}>
            ファウンダーは、TwitterのDMにて募集しています。あなたのご応募をお待ちしております。
          </Paragraph>
          <a
            rel="noopener noreferrer"
            href="https://twitter.com/visions_fund"
            target="_blank"
          >
            <Button variant="flat">Twitterへ</Button>
          </a>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FoudnerRecruit);
