import React, { Component } from 'react';

import { getErrorMessage } from '../utils/ErrorMessage';
import Button from './Button';
import Card from './Card/Card';
import Color from '../constants/Color';
import MaterialIcon from '../utils/MaterialIcon';
import Modal from './Modal/Modal';
import ModalHeader from './Modal/ModalHeader';
import Text from './Text';
import View from './View';

export default class ResponseDialog extends Component {
  state = {
    isOpen: false,
    isError: false
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isFailed && this.props.isFailed) {
      this.props.onError && this.props.onError();

      this.setState({ isOpen: true, isError: true });
    } else if (
      !prevProps.isFailed &&
      !this.props.isFailed &&
      prevProps.isLoading &&
      !this.props.isLoading
    ) {
      this.props.onComplete && this.props.onComplete();

      this.setState({ isOpen: true, isError: false });
    }
  }

  render() {
    const message = this.state.isError
      ? getErrorMessage(this.props.error)
      : this.props.completeMessage;

    if (!message) {
      return null;
    }

    return (
      <Modal visible={this.state.isOpen} style={{ overlay: { zIndex: 10000 } }}>
        <Card style={{ margin: 8 }}>
          <ModalHeader
            right={
              <View onClick={() => this.setState({ isOpen: false })}>
                <MaterialIcon icon="close" color={Color.black} size={24} />
              </View>
            }
            style={{ padding: 16 }}
          />
          <View style={{ padding: 16, alignItems: 'center' }}>
            <View
              className={this.state.isError ? 'gradient-red' : 'gradient-green'}
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                padding: 16,
                width: 152,
                height: 152,
                borderRadius: 76,
                marginBottom: 16
              }}
            >
              <MaterialIcon
                icon={this.state.isError ? 'priority_high' : 'check'}
                color={Color.white}
                size={64}
              />
            </View>
            <Text
              style={{ color: Color.black, fontWeight: 'bold', fontSize: 17 }}
            >
              {message}
            </Text>
          </View>

          <Button
            color={Color.pink}
            onClick={() => {
              this.setState({ isOpen: false });
            }}
            style={{ margin: 16 }}
          >
            OK
          </Button>
        </Card>
      </Modal>
    );
  }
}
