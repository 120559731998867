import { getCollection, getDb, getTimestamp } from '../utils/Firebase';
import ErrorMessages from '../constants/ErrorMessages';
import ShippingInfo from '../models/ShippingInfo';
import UserMetadata from '../models/UserMetadata';
import * as types from '../constants/ActionTypes';

// get activity histories
function fetchGetShippingInfos() {
  return {
    type: types.FETCH_GET_SHIPPING_INFOS
  };
}

function fetchGetShippingInfosSuccess(list) {
  return {
    type: types.FETCH_GET_SHIPPING_INFOS_SUCCESS,
    state: {
      list
    }
  };
}

function fetchGetShippingInfosFailed(error) {
  return {
    type: types.FETCH_GET_SHIPPING_INFOS_FAILED,
    error
  };
}

export function recoverGetShippingInfos() {
  return {
    type: types.FETCH_GET_SHIPPING_INFOS_RECOVER
  };
}

export function getShippingInfos() {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchGetShippingInfos());

    const shippingInfos = getCollection('consumers')
      .doc(auth.uid)
      .collection('shippingInfos');

    return shippingInfos
      .get()
      .then(async ({ docs }) => {
        const list = docs.map(doc => {
          const shippingInfo = doc.data();

          return new ShippingInfo({
            id: doc.id,
            ...shippingInfo
          }).fromFirestore();
        });

        return dispatch(fetchGetShippingInfosSuccess(list));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetShippingInfosFailed(error));
        }
      });
  };
}

// get shipping info
function fetchGetShippingInfo() {
  return {
    type: types.FETCH_GET_SHIPPING_INFO
  };
}

function fetchGetShippingInfoSuccess(id, params) {
  return {
    type: types.FETCH_GET_SHIPPING_INFO_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetShippingInfoFailed(error) {
  return {
    type: types.FETCH_GET_SHIPPING_INFO_FAILED,
    error
  };
}

export function recoverGetShippingInfo() {
  return {
    type: types.FETCH_GET_SHIPPING_INFO_RECOVER
  };
}

export function getShippingInfo(id) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchGetShippingInfo());

    const shippingInfoDoc = getCollection('consumers')
      .doc(auth.uid)
      .collection('shippingInfos')
      .doc(id);

    return shippingInfoDoc
      .get()
      .then(doc => {
        const data = doc.data();

        const shippingInfo = new ShippingInfo({
          id: doc.id,
          ...data
        }).fromFirestore();

        return dispatch(fetchGetShippingInfoSuccess(id, shippingInfo));
      })
      .catch(error => {
        if (error) {
          return dispatch(fetchGetShippingInfoFailed(error));
        }
      });
  };
}

// create an dream
function fetchCreateShippingInfo() {
  return {
    type: types.FETCH_CREATE_SHIPPING_INFO
  };
}

function fetchCreateShippingInfoSuccess(id, params) {
  return {
    type: types.FETCH_CREATE_SHIPPING_INFO_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateShippingInfoFailed(error) {
  return {
    type: types.FETCH_CREATE_SHIPPING_INFO_FAILED,
    error
  };
}

export function recoverCreateShippingInfo() {
  return {
    type: types.FETCH_CREATE_SHIPPING_INFO_RECOVER
  };
}

export function createShippingInfo(params) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchCreateShippingInfo());

    const shippingInfo = getCollection('consumers')
      .doc(auth.uid)
      .collection('shippingInfos');

    const { email, ...shippingInfoParams } = params;

    return shippingInfo
      .add({
        ...shippingInfoParams,
        usedAt: getTimestamp(),
        createdAt: getTimestamp()
      })
      .then(async result => {
        await UserMetadata.update(auth.uid, {
          email
        });

        return dispatch(
          fetchCreateShippingInfoSuccess(result.id, shippingInfoParams)
        );
      })
      .catch(error => {
        return dispatch(
          fetchCreateShippingInfoFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}

// update shipping info
function fetchUpdateShippingInfo() {
  return {
    type: types.FETCH_UPDATE_SHIPPING_INFO
  };
}

function fetchUpdateShippingInfoSuccess(id, params) {
  return {
    type: types.FETCH_UPDATE_SHIPPING_INFO_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchUpdateShippingInfoFailed(error) {
  return {
    type: types.FETCH_UPDATE_SHIPPING_INFO_FAILED,
    error
  };
}

export function recoverUpdateShippingInfo() {
  return {
    type: types.FETCH_UPDATE_SHIPPING_INFO_RECOVER
  };
}

export function updateShippingInfo(id, params) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchUpdateShippingInfo());

    const shippingInfoDoc = getCollection('consumers')
      .doc(auth.uid)
      .collection('shippingInfos')
      .doc(id);

    const db = getDb();

    const { email, ...shippingInfoParams } = params;

    const timestamp = getTimestamp();

    return db
      .runTransaction(transaction => {
        return transaction.get(shippingInfoDoc).then(doc => {
          if (!doc.exists) {
            throw new Error('update/not-exist');
          }

          return transaction.update(shippingInfoDoc, {
            updatedAt: timestamp,
            usedAt: timestamp,
            ...shippingInfoParams
          });
        });
      })
      .then(async () => {
        if (email) {
          await UserMetadata.update(auth.uid, {
            email
          });
        }

        return dispatch(fetchUpdateShippingInfoSuccess(id, shippingInfoParams));
      })
      .catch(error => {
        return dispatch(
          fetchUpdateShippingInfoFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}

// delete shipping info
function fetchDeleteShippingInfo() {
  return {
    type: types.FETCH_DELETE_SHIPPING_INFO
  };
}

function fetchDeleteShippingInfoSuccess(id) {
  return {
    type: types.FETCH_DELETE_SHIPPING_INFO_SUCCESS,
    state: {
      id
    }
  };
}

function fetchDeleteShippingInfoFailed(error) {
  return {
    type: types.FETCH_DELETE_SHIPPING_INFO_FAILED,
    error
  };
}

export function recoverDeleteShippingInfo() {
  return {
    type: types.FETCH_DELETE_SHIPPING_INFO_RECOVER
  };
}

export function deleteShippingInfo(shippingInfoId) {
  return (dispatch, getState) => {
    const { auth } = getState();

    dispatch(fetchDeleteShippingInfo());

    const shippingInfoDoc = getCollection('consumers')
      .doc(auth.uid)
      .collection('shippingInfos')
      .doc(shippingInfoId);

    shippingInfoDoc
      .delete()
      .then(() => {
        return dispatch(fetchDeleteShippingInfoSuccess(shippingInfoId));
      })
      .catch(error => {
        return dispatch(
          fetchDeleteShippingInfoFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}
