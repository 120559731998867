import React from 'react';

import { PdfText, PdfView } from '../../utils/Pdf';
import Color from '../../constants/Color';

export default function PdfFeatureColumnCard({ title, color, style = {} }) {
  return (
    <PdfView
      style={{
        backgroundColor: Color.white,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        border: `1pt solid ${Color.warmGrey}`,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4,
        paddingBottom: 4,
        ...style
      }}
    >
      <PdfText
        style={{
          fontSize: 10,
          color,
          fontWeight: 'bold',
          letterSpacing: 32,
          textAlign: 'center'
        }}
      >
        {title}
      </PdfText>
    </PdfView>
  );
}
