import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import PricingClass from '../../../models/PricingClass';
import SelectFormField from '../../../components/Form/SelectFormField';
import SupplyClass from '../../../models/SupplyClass';
import TextFormField from '../../../components/Form/TextFormField';
import TimeOfDeliverClass from '../../../models/TimeOfDeliverClass';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalSpecificationFormField extends React.Component {
  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    return (
      <View style={{ ...style }}>
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.specification}
          name="specification"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={specification => {
            onChangeParams({
              specification
            });
          }}
          label="(1) 仕様・特徴"
          errorMessage={
            rules &&
            validate({
              label: '仕様・特徴',
              value: params.specification,
              rule: rules.specification
            })
          }
          limitLength={rules.specification.maxLength}
          helpText="商品やサービスの仕様を、特徴を交え簡潔に記述してください。"
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.plan}
          name="plan"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={plan => {
            onChangeParams({
              plan
            });
          }}
          label="(2) 研究内容・新企画状況"
          errorMessage={
            rules &&
            validate({
              label: '研究内容・新企画状況',
              value: params.plan,
              rule: rules.plan
            })
          }
          limitLength={rules.plan.maxLength}
          helpText="どのように新しいコンテンツを提供していくかについて、具体的なイメージを記述してください。"
          style={{ marginBottom: 16 }}
        />
        <SelectFormField
          staticControl={!Boolean(rules)}
          label="(3) 提供種別"
          direction="column"
          verification
          name="supplyClassId"
          items={SupplyClass.getItems()}
          value={params.supplyClassId}
          errorMessage={
            rules &&
            validate({
              label: '提供種別',
              value: params.supplyClassId,
              rule: rules.supplyClassId
            })
          }
          onChangeValue={supplyClassId => onChangeParams({ supplyClassId })}
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.supply}
          name="supply"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={supply => {
            onChangeParams({
              supply
            });
          }}
          label="(3) 提供"
          errorMessage={
            rules &&
            validate({
              label: '提供',
              value: params.supply,
              rule: rules.supply
            })
          }
          limitLength={rules.supply.maxLength}
          helpText="どのようなサービスや商品を提供するか、この事業ならではの特徴を交えて記述する。"
          style={{ marginBottom: 16 }}
        />
        <SelectFormField
          staticControl={!Boolean(rules)}
          label="(4) 価格帯種別"
          direction="column"
          verification
          name="pricingClassId"
          items={PricingClass.getItems()}
          value={params.pricingClassId}
          errorMessage={
            rules &&
            validate({
              label: '価格帯種別',
              value: params.pricingClassId,
              rule: rules.pricingClassId
            })
          }
          onChangeValue={pricingClassId => onChangeParams({ pricingClassId })}
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.pricing}
          name="pricing"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={pricing => {
            onChangeParams({
              pricing
            });
          }}
          label="(4) 価格帯"
          errorMessage={
            rules &&
            validate({
              label: '価格帯',
              value: params.pricing,
              rule: rules.pricing
            })
          }
          limitLength={rules.pricing.maxLength}
          helpText="価格帯と、なぜその価格帯にするのかの理由について記述する。"
          style={{ marginBottom: 16 }}
        />
        <SelectFormField
          staticControl={!Boolean(rules)}
          label="(5) 納期種別"
          direction="column"
          verification
          name="timeOfDeliverClassId"
          items={TimeOfDeliverClass.getItems()}
          value={params.timeOfDeliverClassId}
          errorMessage={
            rules &&
            validate({
              label: '納期種別',
              value: params.timeOfDeliverClassId,
              rule: rules.timeOfDeliverClassId
            })
          }
          onChangeValue={timeOfDeliverClassId =>
            onChangeParams({ timeOfDeliverClassId })
          }
          style={{ marginBottom: 16 }}
        />
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params.timeOfDeliver}
          name="timeOfDeliver"
          direction="column"
          verification
          rows={2}
          multiline
          onChangeText={timeOfDeliver => {
            onChangeParams({
              timeOfDeliver
            });
          }}
          label="(5) 納品時期"
          errorMessage={
            rules &&
            validate({
              label: '納品時期',
              value: params.timeOfDeliver,
              rule: rules.timeOfDeliver
            })
          }
          limitLength={rules.timeOfDeliver.maxLength}
          helpText="そのサービスや商品がお客様に届くまでの時間と、その理由を記述する。"
          style={{ marginBottom: 16 }}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalSpecificationFormField);
