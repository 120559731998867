import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import {
  SCHEME_METADATA_LABELS,
  SCHEME_METADATA_VALIDATION_RULE,
  SCHEME_UPDATE_FORM_LABELS,
  SCHEME_UPDATE_FORM_VALIDATION_RULE
} from '../../../constants/Validations';
import { validate, validateAll } from '../../../utils/Validation';
import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Footer from '../../../components/Footer';
import Headline from '../../../components/Headline';
import MaterialIcon from '../../../utils/MaterialIcon';
import PageHeader from '../../../containers/page/PageHeader';
import Panel from '../../../components/Panel';
import Paragraph from '../../../components/Paragraph';
import PasswordForm from '../../../components/Form/PasswordForm';
import Scheme from '../../../models/Scheme';
import SchemeFormField from '../../../components/Scheme/SchemeFormField';
import ResponseDispatcher from '../../../components/ResponseDispatcher';
import SectionCard from '../../../components/Card/SectionCard';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class SchemeEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scheme: new Scheme(props.scheme.hash[this.props.match.params.id]).object,
      editPassword: ''
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) {
      this.props.updateSchemeFormParams(nextState);

      return true;
    }

    if (
      !nextProps.scheme.isGetLoading &&
      this.props.scheme.isGetLoading &&
      nextProps.scheme.hash[this.props.match.params.id]
    ) {
      const scheme = new Scheme(
        nextProps.scheme.hash[this.props.match.params.id]
      ).object;

      this.setState({
        scheme
      });

      return true;
    }

    if (nextProps.scheme !== this.props.scheme) {
      return true;
    }

    if (nextProps) return false;
  }

  componentDidMount() {
    this.props.getScheme(this.props.match.params.id);
  }

  render() {
    const scheme =
      // (this.props.schemeForm.hash[this.props.match.params.id] &&
      //   this.props.schemeForm.hash[this.props.match.params.id].scheme) ||
      this.props.scheme.hash[this.props.match.params.id];

    if (!scheme) {
      return (
        <View>
          <PageHeader />
          <PasswordForm
            description="この事業計画書は編集にパスワードが必要です。"
            passwordType="edit"
            isLoading={this.props.scheme.isGetLoading}
            onSubmit={password =>
              this.props.getScheme(this.props.match.params.id, password)
            }
          />
          <Footer />
        </View>
      );
    }

    return (
      <View>
        <PageHeader />
        <View style={{ padding: '16px 16px 0px 16px', marginBottom: 24 }}>
          <Headline style={{ marginBottom: 16 }}>ビジネス設計</Headline>
          <Paragraph>
            入力フォームに情報を入力して、あなたのビジネス設計書を完成させましょう。
          </Paragraph>
        </View>
        <View style={{ padding: '0 16px' }}>
          <SchemeFormField
            params={this.state}
            onChangeParams={state => this.setState(state)}
            businessModelPreviewStyle={{
              width: 320,
              height: 320,
              overflow: 'scroll',
              marginLeft: -16
            }}
          />
          <SectionCard
            title="編集パスワード"
            panel={
              <Panel size={40} style={{ backgroundColor: Color.pink }}>
                <MaterialIcon icon="lock" color={Color.white} size={24} />
              </Panel>
            }
            style={{ marginBottom: 24 }}
          >
            <TextFormField
              value={this.state.editPassword}
              name="editPassword"
              direction="column"
              type="password"
              verification
              onChangeText={editPassword => {
                this.setState({
                  editPassword
                });
              }}
              label="編集パスワード"
              errorMessage={validate({
                label: SCHEME_METADATA_LABELS.editPassword,
                value: this.state.editPassword,
                rule: SCHEME_METADATA_VALIDATION_RULE.editPassword
              })}
              limitLength={
                SCHEME_METADATA_VALIDATION_RULE.editPassword.maxLength
              }
              helpText="更新のため編集パスワードを入力してください。"
              style={{ marginBottom: 16 }}
            />
          </SectionCard>
          <Button
            disable={this.props.scheme.isUpdateLoading}
            onClick={() => {
              const errorMessage = validateAll({
                labels: SCHEME_UPDATE_FORM_LABELS,
                params: this.state,
                rules: SCHEME_UPDATE_FORM_VALIDATION_RULE
              });

              if (errorMessage) {
                alert(errorMessage);

                return;
              }

              this.props.updateScheme(
                this.props.match.params.id,
                this.state.scheme,
                this.state.viewPassword,
                this.state.editPassword
              );
            }}
            style={{ marginBottom: 24 }}
          >
            事業計画書を更新する
          </Button>
        </View>
        <ResponseDispatcher
          isLoading={this.props.scheme.isUpdateLoading}
          isFailed={this.props.scheme.isUpdateFailed}
          error={this.props.scheme.updateError}
          onComplete={() =>
            this.props.history.push(
              `/scheme/${this.props.match.params.id}/result`
            )
          }
          onError={() => {
            alert(this.props.scheme.updateError.message);

            this.props.recoverUpdateScheme();
          }}
        />
        <Paragraph
          containerStyle={{ marginBottom: 24 }}
          style={{ textAlign: 'center' }}
        >
          ボタンを押すことでプライバシーポリシーと
          {'\n'}
          利用規約に同意したものとみなします。
        </Paragraph>
        <Footer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchemeEdit);
