import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Headline from '../../../../components/Headline';
import PageHeader from '../../../../containers/page/PageHeader';
import Paragraph from '../../../../components/Paragraph';
import ProductList from '../../../../containers/dream/ProductList';
import ProductPurchaseLink from '../../../../containers/dream/ProductPurchaseLink';
import Stepper from '../../../../components/Stepper';
import View from '../../../../components/View';
import actions from '../../../../actions';

class DreamProductIndex extends React.Component {
  componentDidMount() {
    this.props.getDream(this.props.match.params.id);
  }

  render() {
    return (
      <View>
        <PageHeader />
        <Stepper
          steps={['コースの選択', '必要情報の入力', '購入', '完了']}
          activeIndex={0}
          style={{ padding: '24px 8px 16px 8px' }}
        />
        <View style={{ padding: '0 24px' }}>
          <Headline style={{ marginBottom: 16 }}>1. コースの選択</Headline>
          <Paragraph>お申込みするコースを選択してください。</Paragraph>
        </View>
        <ProductList
          dreamId={this.props.match.params.id}
          style={{ padding: 16 }}
          renderSubmit={({ element, item }) => (
            <ProductPurchaseLink
              to={`/dream/${this.props.match.params.id}/product/${
                item.id
              }/input`}
              stockCount={item.stockCount}
            >
              {element}
            </ProductPurchaseLink>
          )}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamProductIndex);
