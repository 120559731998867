import React from 'react';

import BusinessModelType from '../../models/BusinessModelType';
import Color from '../../constants/Color';
import View from '../View';

const BASE_RADIAN = -Math.PI / 2;

const MODEL_RADIUS = 20;

const MODEL_FONT_SIZE = 17;

const PATH_DASH_THICKNESS = 8;

const PATH_DASH_WIDTH = 16;

export default class BusinessModelPreview extends React.Component {
  getLevelRadius(level) {
    const { levelOptions } = this.props;

    const option = levelOptions.find(option => option.levelLevel === level);

    const offset = option ? option.levelRadius : 0;

    const radiuses = {
      0: 0,
      1: 100,
      2: 170,
      3: 240
    };

    return radiuses[level] + offset;
  }

  getInitialRadian(model) {
    const { levelOptions } = this.props;

    const option = levelOptions.find(
      option => option.levelLevel === model.modelLevel
    );

    const levelInitialAngle = option ? option.levelAngle : 0;

    return (Math.PI * levelInitialAngle) / 180;
  }

  getPosition(id, model, models, levelMap, centerX, centerY) {
    const dstIndex = levelMap[model.modelLevel].findIndex(
      model => model.id === id
    );

    const dstLength = levelMap[model.modelLevel].length;

    const angleRate = dstIndex / dstLength;

    const x =
      Math.cos(
        2 * Math.PI * angleRate + BASE_RADIAN + this.getInitialRadian(model)
      ) *
        this.getLevelRadius(model.modelLevel) +
      centerX;

    const y =
      Math.sin(
        2 * Math.PI * angleRate + BASE_RADIAN + this.getInitialRadian(model)
      ) *
        this.getLevelRadius(model.modelLevel) +
      centerY;

    return { x, y };
  }

  renderRelation(models, levelMap, centerX, centerY, relation) {
    const dstId = relation.relationDestination;

    const dstModel = models.find(model => model.id === dstId);

    const srcId = relation.relationSource;

    const srcModel = models.find(model => model.id === srcId);

    if (!dstModel || !srcModel) {
      return null;
    }

    const dstPosition = this.getPosition(
      dstId,
      dstModel,
      models,
      levelMap,
      centerX,
      centerY
    );

    const srcPosition = this.getPosition(
      srcId,
      srcModel,
      models,
      levelMap,
      centerX,
      centerY
    );

    const distance = Math.sqrt(
      (dstPosition.x - srcPosition.x) ** 2 +
        (dstPosition.y - srcPosition.y) ** 2
    );

    const rx = distance / 2;

    const ry =
      relation.relationDetourWay === 'straight'
        ? 0
        : relation.relationDetourDistance;

    const dx = dstPosition.x - srcPosition.x;

    const dy = dstPosition.y - srcPosition.y;

    const theta = Math.atan2(dy, dx);

    const isRight = relation.relationDetourWay === 'right';

    const flag = isRight ? 1 : 0;

    const path =
      relation.relationDetourWay === 'straight'
        ? `M ${srcPosition.x},${srcPosition.y} L ${dstPosition.x} ${
            dstPosition.y
          }`
        : `M ${srcPosition.x},${srcPosition.y} A ${rx} ${ry} ${(theta * 180) /
            Math.PI} 0 ${flag} ${dstPosition.x} ${dstPosition.y}`;

    const pathOptions =
      relation.relationType === 'mightAction'
        ? {
            strokeDasharray: 5
          }
        : {};

    const middlePosition = {
      x: (dstPosition.x + srcPosition.x) / 2,
      y: (dstPosition.y + srcPosition.y) / 2
    };

    const radian = isRight ? -Math.PI / 2 : Math.PI / 2;

    const x = ry * Math.cos(theta + radian) + middlePosition.x;

    const y = ry * Math.sin(theta + radian) + middlePosition.y;

    const a = (17 * relation.relationLabel.length) / 2 + 16;

    const b = 16 + 4;

    const r1 = (Math.cos(theta) / a) ** 2;

    const r2 = (Math.sin(theta) / b) ** 2;

    const r = Math.sqrt(1 / (r1 + r2));

    const wayIndicatorX = r * Math.cos(theta);

    const wayIndicatorY = r * Math.sin(theta);

    let color;

    switch (relation.relationSituation) {
      case 'normal':
        color = Color.slate;
        break;
      case 'object':
        color = Color.mossGreen;
        break;
      case 'money':
        color = Color.gold;
        break;
      case 'data':
        color = Color.emeraldGreen;
        break;
      default:
        color = Color.slate;
    }

    return (
      <g key={`relation-${relation.id}`}>
        <path
          d={path}
          fill={Color.transparent}
          stroke={Color.lightSlate}
          {...pathOptions}
          strokeWidth={3}
        />
        <g
          transform={`translate(${x + wayIndicatorX},${y +
            wayIndicatorY}) rotate(${(theta * 180) / Math.PI - 90})`}
        >
          {relation.relationType !== 'dependence' ? (
            <path
              d={`M ${0},${PATH_DASH_THICKNESS} L ${PATH_DASH_WIDTH /
                2},0 L ${-PATH_DASH_WIDTH / 2},0 Z`}
              fill={Color.slate}
            />
          ) : (
            <circle cx={0} cy={0} fill={Color.slate} r={5} />
          )}
        </g>
        <foreignObject x={x - 250} y={y - 16} width={500} height={32}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <div
              style={{
                borderRadius: 16,
                backgroundColor: color,
                padding: `4px 8px`
              }}
            >
              <span style={{ fontSize: 15, color: Color.white }}>
                {relation.relationLabel}
              </span>
            </div>
          </div>
        </foreignObject>
      </g>
    );
  }

  render() {
    const {
      id = 'businessModelPreview',
      models,
      relations,
      width = 320,
      height = 320,
      style = {}
    } = this.props;

    const centerX = width / 2;

    const centerY = height / 2;

    const levelMap = models.reduce((acc, model) => {
      if (acc[model.modelLevel]) {
        return {
          ...acc,
          [model.modelLevel]: [...acc[model.modelLevel], model]
        };
      }

      return {
        ...acc,
        [model.modelLevel]: [model]
      };
    }, {});

    const levelKeys = Object.keys(levelMap).map(key => Number(key));

    return (
      <View style={style}>
        <svg
          id={id}
          width={`${width}px`}
          height={`${height}px`}
          viewBox={`0 0 555 555`}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          {levelKeys.map(key => {
            return (
              <g key={`level-${key}`}>
                <circle
                  cx={centerX}
                  cy={centerY}
                  r={this.getLevelRadius(key)}
                  fill="none"
                  stroke={Color.lightGrey}
                />
              </g>
            );
          })}
          {relations.map(relation =>
            this.renderRelation(models, levelMap, centerX, centerY, relation)
          )}
          {levelKeys.map(key => {
            const models = levelMap[key];

            const modelLength = models.length;

            return models.map((model, modelIndex) => {
              const angleRate = modelIndex / modelLength;

              const x =
                Math.cos(
                  2 * Math.PI * angleRate +
                    BASE_RADIAN +
                    this.getInitialRadian(model)
                ) *
                  this.getLevelRadius(model.modelLevel) +
                centerX;

              const y =
                Math.sin(
                  2 * Math.PI * angleRate +
                    BASE_RADIAN +
                    this.getInitialRadian(model)
                ) *
                  this.getLevelRadius(model.modelLevel) +
                centerY;

              const modelType = BusinessModelType.getItem(model.modelType);

              return (
                <g key={`model-${model.id}`}>
                  <circle
                    cx={x}
                    cy={y}
                    r={MODEL_RADIUS}
                    fill={Color.white}
                    stroke={Color.warmGrey}
                  />
                  {modelType.icon && (
                    <g transform={`translate(${x - 16},${y - 16})`}>
                      <modelType.icon
                        width={32}
                        height={32}
                        viewBox="0 0 24 24"
                        fill={Color.slate}
                      />
                    </g>
                  )}
                  <rect
                    x={x - (model.modelLabel.length / 2) * MODEL_FONT_SIZE}
                    y={y + MODEL_RADIUS + MODEL_FONT_SIZE * 0.3}
                    fill={Color.white}
                    fillOpacity={0.8}
                    width={model.modelLabel.length * MODEL_FONT_SIZE}
                    height={MODEL_FONT_SIZE + 4}
                  />
                  <text
                    fontSize={MODEL_FONT_SIZE}
                    fontFamily="'Yu Gothic', '游ゴシック', YuGothic, '游ゴシック体',
    'ヒラギノ角ゴ Pro W3', 'メイリオ', sans-serif"
                    fill={Color.slate}
                    x={x}
                    y={
                      y + MODEL_RADIUS + MODEL_FONT_SIZE + MODEL_FONT_SIZE * 0.3
                    }
                    textAnchor="middle"
                    paintOrder="stroke"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeWidth={4}
                  >
                    {model.modelLabel}
                  </text>
                </g>
              );
            });
          })}
        </svg>
      </View>
    );
  }
}
