import React from 'react';

import Color from '../../constants/Color';
import Headline from '../Headline';
import View from '../View';

export default function ModalHeader({
  title,
  right,
  color = Color.slate,
  style = {}
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 4,
        ...style
      }}
    >
      <Headline style={{ color }}>{title}</Headline>
      <View>{right}</View>
    </View>
  );
}
