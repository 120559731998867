import {
  WebIcon,
  MenuRightIcon,
  BookIcon,
  CartIcon,
  MedicalBagIcon,
  AccountIcon,
  AccountOffIcon,
  CheckboxMarkedCircleOutlineIcon,
  FactoryIcon,
  CartPlusIcon,
  BriefcaseUploadIcon,
  StoreIcon,
  BasketIcon,
  ChartBarIcon,
  FindReplaceIcon,
  AlertIcon,
  ThumbUpIcon,
  ThumbDownIcon,
  EarthIcon,
  TrendingUpIcon,
  PlaylistCheckIcon,
  ClipboardCheckIcon,
  LeafIcon,
  CreationIcon,
  HatFedoraIcon,
  AirplaneTakeoffIcon,
  SilverwareForkKnifeIcon,
  SchoolIcon,
  PineTreeIcon,
  PawIcon,
  TruckFastIcon,
  TrainCarIcon,
  BookOpenPageVariantIcon,
  TelevisionClassicIcon,
  VideoVintageIcon,
  MusicIcon,
  FireIcon,
  SoccerIcon,
  GamepadVariantIcon,
  PaletteIcon,
  FountainPenTipIcon,
  WrenchIcon,
  ShieldCheckIcon,
  GavelIcon,
  CashMultipleIcon,
  OfficeBuildingIcon,
  DesktopTowerMonitorIcon,
  BriefcaseIcon,
  GoogleFitIcon,
  SecurityIcon,
  GraveStoneIcon,
  JudaismIcon,
  DomainIcon,
  CashIcon,
  DrawingBoxIcon,
  FileIcon,
  FileDocumentIcon,
  CellphoneIcon,
  CubeIcon,
  KeyVariantIcon,
  LockAlertIcon,
  LightbulbOnOutlineIcon,
  GiftIcon,
  HeartMultipleIcon,
  BookmarkPlusIcon,
  BellIcon,
  BrainIcon,
  BookOpenVariantIcon,
  CheckDecagramIcon,
  CompassOutlineIcon,
  CalendarStarIcon
} from 'react-material-icon-svg';

export const WebIconSvg = WebIcon;

export const MenuRightIconSvg = MenuRightIcon;

export const BookIconSvg = BookIcon;

export const CartIconSvg = CartIcon;

export const MedicalBagIconSvg = MedicalBagIcon;

export const AccountIconSvg = AccountIcon;

export const AccountOffIconSvg = AccountOffIcon;

export const FactoryIconSvg = FactoryIcon;

export const CheckboxMarkedCircleOutlineIconSvg = CheckboxMarkedCircleOutlineIcon;

export const CartPlusIconSvg = CartPlusIcon;

export const BriefcaseUploadIconSvg = BriefcaseUploadIcon;

export const StoreIconSvg = StoreIcon;

export const BasketIconSvg = BasketIcon;

export const ChartBarIconSvg = ChartBarIcon;

export const FindReplaceIconSvg = FindReplaceIcon;

export const AlertIconSvg = AlertIcon;

export const ThumbUpIconSvg = ThumbUpIcon;

export const ThumbDownIconSvg = ThumbDownIcon;

export const EarthIconSvg = EarthIcon;

export const TrendingUpIconSvg = TrendingUpIcon;

export const PlaylistCheckIconSvg = PlaylistCheckIcon;

export const ClipboardCheckIconSvg = ClipboardCheckIcon;

export const LeafIconSvg = LeafIcon;

export const CreationIconSvg = CreationIcon;

export const HatFedoraIconSvg = HatFedoraIcon;

export const AirplaneTakeoffIconSvg = AirplaneTakeoffIcon;

export const SilverwareForkKnifeIconSvg = SilverwareForkKnifeIcon;

export const SchoolIconSvg = SchoolIcon;

export const PineTreeIconSvg = PineTreeIcon;

export const PawIconSvg = PawIcon;

export const TruckFastIconSvg = TruckFastIcon;

export const TrainCarIconSvg = TrainCarIcon;

export const BookOpenPageVariantIconSvg = BookOpenPageVariantIcon;

export const TelevisionClassicIconSvg = TelevisionClassicIcon;

export const VideoVintageIconSvg = VideoVintageIcon;

export const MusicIconSvg = MusicIcon;

export const FireIconSvg = FireIcon;

export const SoccerIconSvg = SoccerIcon;

export const GamepadVariantIconSvg = GamepadVariantIcon;

export const PaletteIconSvg = PaletteIcon;

export const FountainPenTipIconSvg = FountainPenTipIcon;

export const WrenchIconSvg = WrenchIcon;

export const ShieldCheckIconSvg = ShieldCheckIcon;

export const GavelIconSvg = GavelIcon;

export const CashMultipleIconSvg = CashMultipleIcon;

export const OfficeBuildingIconSvg = OfficeBuildingIcon;

export const DesktopTowerMonitorIconSvg = DesktopTowerMonitorIcon;

export const BriefcaseIconSvg = BriefcaseIcon;

export const GoogleFitIconSvg = GoogleFitIcon;

export const SecurityIconSvg = SecurityIcon;

export const GraveStoneIconSvg = GraveStoneIcon;

export const JudaismIconSvg = JudaismIcon;

export const DomainIconSvg = DomainIcon;

export const CashIconSvg = CashIcon;

export const DrawingBoxIconSvg = DrawingBoxIcon;

export const FileIconSvg = FileIcon;

export const FileDocumentIconSvg = FileDocumentIcon;

export const CellphoneIconSvg = CellphoneIcon;

export const CubeIconSvg = CubeIcon;

export const KeyVariantIconSvg = KeyVariantIcon;

export const LockAlertIconSvg = LockAlertIcon;

export const LightbulbOnOutlineIconSvg = LightbulbOnOutlineIcon;

export const GiftIconSvg = GiftIcon;

export const HeartMultipleIconSvg = HeartMultipleIcon;

export const BookmarkPlusIconSvg = BookmarkPlusIcon;

export const BellIconSvg = BellIcon;

export const BrainIconSvg = BrainIcon;

export const BookOpenVariantIconSvg = BookOpenVariantIcon;

export const CheckDecagramIconSvg = CheckDecagramIcon;

export const CompassOutlineIconSvg = CompassOutlineIcon;

export const CalendarStarIconSvg = CalendarStarIcon;
