import React from 'react';

import { PdfText, PdfView } from '../../utils/Pdf';
import Color from '../../constants/Color';

export default function PdfProfitCard({
  title,
  amount,
  unit,
  invert = false,
  color,
  style = {},
  titleStyle = {},
  amountStyle = {},
  unitStyle = {}
}) {
  return (
    <PdfView
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        border: `1pt solid ${Color.warmGrey}`,
        backgroundColor: Color.white,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 4,
        paddingRight: 4,
        ...style
      }}
    >
      <PdfText
        style={{
          color: Color.lightSlate,
          fontSize: 12,
          fontWeight: 'bold',
          marginBottom: 8,
          ...titleStyle
        }}
      >
        {title}
      </PdfText>
      <PdfView style={{ flexDirection: 'row', alignItems: 'baseline' }}>
        <PdfText
          style={{
            color,
            fontSize: 20,
            fontWeight: 'bold',
            marginRight: 4,
            ...amountStyle
          }}
        >
          {amount}
        </PdfText>
        <PdfText
          style={{
            color,
            fontSize: 12,
            fontWeight: 'bold',
            ...unitStyle
          }}
        >
          {unit}
        </PdfText>
      </PdfView>
    </PdfView>
  );
}
