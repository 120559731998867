import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { validate } from '../../../utils/Validation';
import Color from '../../../constants/Color';
import Headline from '../../../components/Headline';
import TextFormField from '../../../components/Form/TextFormField';
import View from '../../../components/View';
import actions from '../../../actions';

class ProposalProfitFormField extends React.Component {
  renderProfitForm({
    title,
    no = '',
    params,
    rules,
    onChangeParams,
    titleKey,
    amountKey,
    unitKey
  }) {
    return (
      <View style={{ marginBottom: 24 }}>
        {title && (
          <Headline size={3} style={{ marginBottom: 8 }}>
            {title}
            {no}
          </Headline>
        )}
        <TextFormField
          staticControl={!Boolean(rules)}
          value={params[titleKey]}
          name={titleKey}
          direction="column"
          verification
          onChangeText={value => {
            onChangeParams({
              [titleKey]: value
            });
          }}
          label={`タイトル`}
          errorMessage={
            rules &&
            validate({
              label: `タイトル`,
              value: params[titleKey],
              rule: rules[titleKey]
            })
          }
          limitLength={rules[titleKey].maxLength}
          style={{ flex: 1, marginBottom: 8 }}
        />
        <View style={{ flexDirection: 'row' }}>
          <TextFormField
            staticControl={!Boolean(rules)}
            value={params[amountKey]}
            name={amountKey}
            type="number"
            direction="column"
            verification
            onChangeText={value => {
              onChangeParams({
                [amountKey]: value
              });
            }}
            label={`数値`}
            errorMessage={
              rules &&
              validate({
                label: `数値`,
                value: params[amountKey],
                rule: rules[amountKey]
              })
            }
            limitLength={rules[amountKey].maxLength}
            style={{ flex: 1, marginRight: 8 }}
          />
          <TextFormField
            staticControl={!Boolean(rules)}
            value={params[unitKey]}
            name={unitKey}
            direction="column"
            verification
            onChangeText={value => {
              onChangeParams({
                [unitKey]: value
              });
            }}
            label={`単位`}
            errorMessage={
              rules &&
              validate({
                label: `単位`,
                value: params[unitKey],
                rule: rules[unitKey]
              })
            }
            limitLength={rules[unitKey].maxLength}
            style={{ flex: 1 }}
          />
        </View>
      </View>
    );
  }

  renderTotal({ key, params, rules, onChangeParams }) {
    const titleKey = `${key}TotalTitle`;

    const amountKey = `${key}TotalAmount`;

    const unitKey = `${key}TotalUnit`;

    return this.renderProfitForm({
      title: '合計 (項目1 + 項目2 + 項目3)',
      params,
      rules,
      onChangeParams,
      titleKey,
      amountKey,
      unitKey
    });
  }

  render() {
    const { params, onChangeParams, rules, style = {} } = this.props;

    const items = [
      { key: 'sales', label: '売上' },
      { key: 'cost', label: 'コスト' }
    ];

    const range = new Array(3).fill(true);

    return (
      <View style={{ ...style }}>
        {items.map((item, index) => {
          return (
            <View style={{ marginBottom: 32 }}>
              <Headline
                size={2}
                style={{ color: Color.lightSlate, marginBottom: 16 }}
              >
                ({index + 1}) {item.label}
                に関する項目
              </Headline>
              {range.map((_, index) => {
                const no = index + 1;

                const titleKey = `${item.key}Title${no}`;

                const amountKey = `${item.key}Amount${no}`;

                const unitKey = `${item.key}Unit${no}`;

                return this.renderProfitForm({
                  title: '項目',
                  no,
                  params,
                  rules,
                  onChangeParams,
                  titleKey,
                  amountKey,
                  unitKey
                });
              })}
              {this.renderTotal({
                key: item.key,
                params,
                rules,
                onChangeParams
              })}
            </View>
          );
        })}
        <Headline
          size={2}
          style={{ color: Color.lightSlate, marginBottom: 16 }}
        >
          (3) 利益
        </Headline>
        {this.renderProfitForm({
          params,
          rules,
          onChangeParams,
          titleKey: 'profitTitle',
          amountKey: 'profitAmount',
          unitKey: 'profitUnit'
        })}
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProposalProfitFormField);
