import Model from './Model';

export default class DreamApplication extends Model {
  constructor(state = {}) {
    super();

    this.params = {
      ...state,
      name: state.name || '',
      email: state.email || '',
      phoneNumber: state.phoneNumber || '',
      title: state.title || '',
      detail: state.detail || '',
      reason: state.reason || ''
    };
  }

  get object() {
    return this.params;
  }

  get fields() {
    return {
      name: this.params.name,
      email: this.params.email,
      phoneNumber: this.params.phoneNumber,
      title: this.params.title,
      detail: this.params.detail,
      reason: this.params.reason
    };
  }

  fromFirestore() {
    return {
      ...this.params,
      createdAt: this.params.createdAt && this.params.createdAt.toDate(),
      updatedAt: this.params.updatedAt && this.params.updatedAt.toDate()
    };
  }
}
