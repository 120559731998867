import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Color from '../../../../constants/Color';
import Column from '../../../../components/Column';
import ContentContainer from '../../../../components/Page/ContentContainer';
import EmptyText from '../../../../components/EmptyText';
import FooterContainer from '../../../../components/FooterContainer';
import Headline from '../../../../components/Headline';
import MypageMenu from '../../../../containers/mypage/MypageMenu';
import PageHeader from '../../../../containers/page/PageContaineredHeader';
import Section from '../../../../components/Section/Section';
import SectionTransitItem from '../../../../components/Section/SectionTransitItem';
import View from '../../../../components/View';
import actions from '../../../../actions';

class MypagePaymentInfoIndex extends React.Component {
  componentDidMount() {
    this.props.getMyselfUser();
  }

  render() {
    const { myself } = this.props.user;

    if (!myself) {
      return null;
    }

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <ContentContainer>
          <Row style={{ margin: '40px 0' }}>
            <Column xs={4}>
              <MypageMenu />
            </Column>
            <Column xs={8}>
              <Headline style={{ fontSize: 32, marginBottom: 40 }}>
                お支払い情報
              </Headline>
              <Headline size={3} style={{ marginBottom: 16 }}>
                カード情報
              </Headline>
              <View style={{ marginBottom: 40 }}>
                {myself.paymentInfos.length < 1 ? (
                  <EmptyText>カード情報がありません。</EmptyText>
                ) : (
                  <Section sideline>
                    {myself.paymentInfos.map(info => {
                      const isDefault = info.id === myself.defaultPaymentInfoId;

                      return (
                        <Link to={`/mypage/paymentInfo/${info.id}`}>
                          <SectionTransitItem
                            title={`${info.source.brand}${
                              isDefault ? ' [デフォルト]' : ''
                            }`}
                            content={`${info.source.expYear}/${
                              info.source.expMonth
                            } | **** ${info.source.last4}`}
                          />
                        </Link>
                      );
                    })}
                  </Section>
                )}
              </View>
            </Column>
          </Row>
        </ContentContainer>
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MypagePaymentInfoIndex);
