import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../components/Button';
import Color from '../constants/Color';
import CommentItem from '../components/Dream/CommentItem';
import ModalBox from '../components/Modal/ModalBox';
import ModalBoxHeader from '../components/Modal/ModalBoxHeader';
import Text from '../components/Text';
import TextArea from '../components/Form/TextArea';
import View from '../components/View';
import actions from '../actions';

class ReplyModal extends React.Component {
  state = {
    text: ''
  };

  render() {
    return (
      <ModalBox
        visible={this.props.visible}
        onClose={() => this.props.onClose()}
      >
        <ModalBoxHeader
          title="リプライ"
          left={
            <View onClick={() => this.props.onClose()}>
              <Text style={{ color: Color.slate }}>キャンセル</Text>
            </View>
          }
          style={{ marginBottom: 16 }}
        />
        <CommentItem
          data={this.props.comment}
          founderId={this.props.founderId}
          onlyLast
          users={this.props.user.list.map(id => this.props.user.hash[id])}
        />
        <TextArea
          value={this.state.text}
          rows={3}
          onChange={text => {
            this.setState({ text });
          }}
          limitLength={140}
          style={{ marginBottom: 16 }}
        />
        <Button
          variant="contained"
          onClick={() => {
            this.props.onSubmit(this.state.text);
          }}
          style={{ marginBottom: 8 }}
        >
          リプライする
        </Button>
      </ModalBox>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplyModal);
