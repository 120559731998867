import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import LoadMoreList from '../../components/List/LoadMoreList';
import ReportTimelineItem from './ReportTimelineItem';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class ReportTimelineList extends React.Component {
  componentDidMount() {
    this.props.getDreamReports(this.props.dreamId, { reset: true });
  }

  render() {
    const { style = {} } = this.props;

    if (
      this.props.dreamReport.list.length < 1 &&
      !this.props.dreamReport.isGetLoading
    ) {
      return (
        <View
          style={{
            ...style
          }}
        >
          <Text>履歴がありません。</Text>
        </View>
      );
    }

    return (
      <View
        style={{
          ...style
        }}
      >
        <LoadMoreList
          data={this.props.dreamReport.list.map(
            id => this.props.dreamReport.hash[id]
          )}
          renderItem={({ item, index }) => {
            const isFirst = index === 0;

            const isLast = index === this.props.dreamReport.list.length - 1;

            return (
              <Link
                key={`report-${item.id}`}
                to={`/dream/${this.props.dreamId}/report/${item.id}`}
              >
                <ReportTimelineItem data={item} first={isFirst} last={isLast} />
              </Link>
            );
          }}
          onFetchMore={lastItem => {
            this.props.getDreamReports(this.props.dreamId, { lastItem });
          }}
          isLoading={this.props.dreamReport.isGetLoading}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportTimelineList);
