import React from 'react';
import numeral from 'numeral';

import { getGradient, getGradientCss } from '../../utils/ColorJs';
import AmountCardBody from '../Card/AmountCardBody';
import Color from '../../constants/Color';
import Headline from '../Headline';
import SeparatedCard from '../Card/SeparatedCard';
import Text from '../Text';
import View from '../View';

export default function DreamProgressCard({
  data: { currentFundingPrice, fundingGoalPrice },
  style = {}
}) {
  const rate = Math.floor((currentFundingPrice / fundingGoalPrice) * 100);

  const colors = getGradient(Color.pinkGradient, rate);

  return (
    <SeparatedCard
      head={
        <AmountCardBody
          style={{ padding: '16px 0 16px 0' }}
          title="集まった金額"
          amount={currentFundingPrice}
          unit="円"
        />
      }
      body={
        <View style={{ padding: '16px 24px 24px 24px' }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 4
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'baseline' }}>
              <Headline
                size={3}
                style={{
                  color: Color.lightSlate,
                  fontSize: 15,
                  marginRight: 16
                }}
              >
                目標金額
              </Headline>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'baseline'
                }}
              >
                <Text
                  style={{
                    color: Color.slate,
                    fontWeight: 'bold',
                    fontSize: 24
                  }}
                >
                  {numeral(fundingGoalPrice).format('0,0')}
                </Text>
                <Text
                  style={{
                    color: Color.slate,
                    fontWeight: 'bold',
                    fontSize: 17
                  }}
                >
                  円
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'baseline'
              }}
            >
              <Text
                style={{
                  color: Color.pink,
                  fontSize: 24,
                  fontWeight: 'bold'
                }}
              >
                {rate}
              </Text>
              <Text
                style={{
                  color: Color.slate,
                  fontSize: 15
                }}
              >
                %
              </Text>
            </View>
          </View>
          <View
            style={{
              borderRadius: 8,
              backgroundColor: Color.slate,
              padding: 2
            }}
          >
            <View
              style={{
                backgroundImage: getGradientCss(colors, 135),
                width: `${Math.min(rate, 100)}%`,
                height: 7,
                border: `1px solid ${Color.white}`,
                borderRadius: 4
              }}
            />
          </View>
        </View>
      }
      style={style}
    />
  );
}
