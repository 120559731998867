import React from 'react';
import moment from 'moment';

import Color from '../../constants/Color';
import GradientMask from '../Card/GradientMask';
import Image from '../Image';
import PanelCard from '../Card/PanelCard';
import Paragraph from '../Paragraph';
import Style from '../../constants/Style';
import Text from '../Text';
import View from '../../components/View';

export default class ReportTimelineItem extends React.Component {
  render() {
    const { data, first = false, last = false, style = {} } = this.props;

    return (
      <View style={style}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View
            style={{
              position: 'relative',
              width: 16,
              height: 28,
              marginRight: 16
            }}
          >
            <View
              style={{
                marginTop: 2,
                backgroundColor: Color.peach,
                borderRadius: 8,
                width: 16,
                height: 16
              }}
            />
            <View
              style={{
                position: 'absolute',
                backgroundColor: Color.peach,
                left: 7,
                width: 2,
                top: first ? 10 : 0,
                bottom: last ? 10 : 0
              }}
            />
          </View>
          <Text
            style={{
              color: Color.lightSlate,
              fontSize: 15,
              fontWeight: 'bold',
              height: 20,
              marginBottom: 8
            }}
          >
            {moment(data.startAt).format('YYYY.MM.DD')}
          </Text>
        </View>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 16, marginRight: 16 }}>
            {!last && (
              <View
                style={{
                  backgroundColor: Color.peach,
                  marginLeft: 7,
                  width: 2,
                  height: '100%'
                }}
              />
            )}
          </View>
          <PanelCard
            panel={
              <Image
                source={data.images[0]}
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 8,
                  ...Style.shadow.medium
                }}
              />
            }
            panelSize={80}
            style={{ flex: 1, marginBottom: 16 }}
            contentContainerStyle={{
              height: 80,
              overflow: 'hidden'
            }}
          >
            <Text
              style={{
                color: Color.slate,
                fontSize: 11,
                fontWeight: 'bold'
              }}
            >
              {data.title}
            </Text>
            <Paragraph style={{ fontSize: 10 }}>{data.content}</Paragraph>
            <GradientMask
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                left: 0,
                borderBottomRightRadius: 8,
                borderBottomLeftRadius: 8,
                width: '100%',
                height: 16
              }}
            />
          </PanelCard>
        </View>
      </View>
    );
  }
}
