import { getCollection, getTimestamp } from '../utils/Firebase';
import ErrorMessages from '../constants/ErrorMessages';
import * as types from '../constants/ActionTypes';

// create application
function fetchCreateDreamApplication() {
  return {
    type: types.FETCH_CREATE_DREAM_APPLICATION
  };
}

function fetchCreateDreamApplicationSuccess(id, params) {
  return {
    type: types.FETCH_CREATE_DREAM_APPLICATION_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateDreamApplicationFailed(error) {
  return {
    type: types.FETCH_CREATE_DREAM_APPLICATION_FAILED,
    error
  };
}

export function recoverCreateDreamApplication() {
  return {
    type: types.FETCH_CREATE_DREAM_APPLICATION_RECOVER
  };
}

export function createDreamApplication(params) {
  console.log(params);
  return (dispatch, getState) => {
    dispatch(fetchCreateDreamApplication());

    const applications = getCollection('dreamApplications');

    applications
      .add({
        ...params,
        createdAt: getTimestamp()
      })
      .then(result => {
        return dispatch(fetchCreateDreamApplicationSuccess());
      })
      .catch(error => {
        return dispatch(
          fetchCreateDreamApplicationFailed({
            code: error.code,
            message: ErrorMessages[error.code]
          })
        );
      });
  };
}
