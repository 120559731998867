export function validate({ label = '', value, rule, params }) {
  try {
    return Object.keys(rule).reduce((acc, key) => {
      switch (key) {
        case 'minLength':
          if (typeof value === 'string') {
            return value.length < rule[key]
              ? `${label}は${rule[key]}文字以上で入力してください。`
              : acc;
          }

          return value.length < rule[key]
            ? `${label}は${rule[key]}つ以上を入力してください。`
            : acc;

        case 'maxLength':
          if (typeof value === 'string') {
            return value.length > rule[key]
              ? `${label}は${rule[key]}文字以内で入力してください。`
              : acc;
          }

          return value.length > rule[key]
            ? `${label}は${rule[key]}つ以内を入力してください。`
            : acc;

        case 'matches':
          return rule[key].reduce((result, matchObject) => {
            const reg = new RegExp(matchObject.reg);

            return value.match(reg) ? result : matchObject.message;
          }, acc);

        case 'terms':
          return rule[key].reduce((result, termObject) => {
            const { message, operator, value: compareValue } = termObject;

            switch (operator) {
              case '==':
                return value === compareValue ? result : message;
              case '!=':
                return value !== compareValue ? result : message;
              case '<=':
                return value <= compareValue ? result : message;
              case '<':
                return value < compareValue ? result : message;
              case '>=':
                return value >= compareValue ? result : message;
              case '>':
                return value > compareValue ? result : message;
              default:
                return result;
            }
          }, acc);

        case 'children':
          const validationRule = rule[key].rule;

          const validationLabels = rule[key].label;

          if (Array.isArray(value)) {
            return value.reduce((result, item) => {
              // eslint-disable-next-line no-use-before-define
              return validateAll({
                labels: validationLabels,
                params: item,
                rules: validationRule
              });
            }, acc);
          }

          // eslint-disable-next-line no-use-before-define
          return validateAll({
            labels: validationLabels,
            params: value,
            rules: validationRule
          });

        case 'type':
          switch (rule[key]) {
            case 'number':
              if (String(Number(value)) !== String(value)) {
                return `${label}は数値で入力してください。`;
              }

              return acc;
            default:
              return acc;
          }

        case 'validators':
          return rule[key].reduce((result, validator) => {
            return validator(value, params) + result;
          }, acc);

        default:
          return acc;
      }
    }, '');
  } catch (error) {
    console.error(error, label, value);
  }
}

export function validateAll({ labels, params, rules }) {
  const message = Object.keys(params).reduce((acc, key) => {
    if (!rules[key]) {
      return acc;
    }

    const errorMessage = validate({
      label: labels[key],
      value: params[key],
      rule: rules[key],
      params
    });

    if (errorMessage) {
      return `${acc}${errorMessage}\n`;
    }

    return acc;
  }, '');

  if (message.length < 1) {
    return message;
  }

  return message.slice(0, message.length - 1);
}
