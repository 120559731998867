import React from 'react';

import Color from '../../constants/Color';
import Modal from './Modal';
import View from '../View';

export default class ModalBox extends React.Component {
  render() {
    const { visible, children, onClose = () => {} } = this.props;

    return (
      <Modal visible={visible} onClose={onClose}>
        <View
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: Color.transparent
          }}
          onClick={onClose}
        />
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: '16px 16px 0 0',
            padding: 16,
            backgroundColor: Color.white
          }}
        >
          {children}
        </View>
      </Modal>
    );
  }
}
