import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Color from '../../constants/Color';
import ReportSecretSliderItem from './ReportSecretSliderItem';
import Slider from '../../utils/Slider';
import Style from '../../constants/Style';
import View from '../View';
import actions from '../../actions';

class ReportSecretSlider extends React.Component {
  state = {
    activeIndex: 0
  };

  render() {
    const { data, style = {} } = this.props;

    return (
      <View style={style}>
        <Slider
          dots
          autoplay
          autoplaySpeed={3000}
          arrows={false}
          slidesToShow={1}
          slidesToScroll={1}
          speed={500}
          appendDots={dots => (
            <View>
              <ul> {dots} </ul>
            </View>
          )}
          customPaging={index => (
            <div
              style={{
                width: 12,
                height: 12,
                borderRadius: 6,
                backgroundColor:
                  index === this.state.activeIndex ? Color.slate : Color.white,
                ...Style.shadow.medium
              }}
            />
          )}
          afterChange={activeIndex => this.setState({ activeIndex })}
        >
          {data.filter(datum => datum.uri).map((item, index) => {
            const key = index;

            return <ReportSecretSliderItem key={key} source={item} />;
          })}
        </Slider>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportSecretSlider);
