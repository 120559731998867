import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import { toNumeral } from '../../../utils/Format';
import ArrowStream from '../../../components/Icons/ArrowStream';
import BusinessCategory from '../../../models/BusinessCategory';
import Button from '../../../components/Button';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import Container from '../../../components/Container';
import ContentContainer from '../../../components/Page/ContentContainer';
import FooterContainer from '../../../components/FooterContainer';
import FounderRecruitContainer from '../../../containers/FounderRecruitContainer';
import Headline from '../../../components/Headline';
import JobDesktopHeader from '../../../components/Job/JobDesktopHeader';
import PageHeader from '../../../containers/page/PageContaineredHeader';
import Paragraph from '../../../components/Paragraph';
import Text from '../../../components/Text';
import View from '../../../components/View';
import actions from '../../../actions';

class JobDetail extends React.Component {
  componentDidMount() {
    this.props.getJob(this.props.match.params.id);
  }

  render() {
    if (!this.props.job.hash[this.props.match.params.id]) {
      return null;
    }

    const job = this.props.job.hash[this.props.match.params.id];

    const {
      name,
      detail,
      businessCategoryId,
      yearlyProfitAmount,
      experiencedYears,
      fundAmount,
      planContent,
      skillContent,
      fundContent,
      successProjects
    } = job;

    const businessCategory = BusinessCategory.getItem(businessCategoryId);

    return (
      <View style={{ backgroundColor: Color.paleWhite }}>
        <PageHeader />
        <JobDesktopHeader data={job} businessCategory={businessCategory} />
        <ContentContainer>
          <Headline
            size={1}
            style={{ fontSize: 32, marginBottom: 16, marginTop: 40 }}
          >
            {name}
          </Headline>
          <Paragraph
            style={{ fontSize: 17 }}
            containerStyle={{ marginBottom: 40 }}
          >
            {detail}
          </Paragraph>
          <Headline size={2} style={{ fontSize: 20, marginBottom: 16 }}>
            {name}で開業する前に
          </Headline>
          <Paragraph
            style={{ fontSize: 17 }}
            containerStyle={{ marginBottom: 40 }}
          >
            {name}で開業をする前に、３つのポイントをチェックしておきましょう。
          </Paragraph>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'baseline',
              marginBottom: 16
            }}
          >
            <Text
              style={{
                color: Color.pink,
                fontWeight: 'bold',
                fontSize: 40,
                marginRight: 8,
                lineHeight: 1
              }}
            >
              01
            </Text>
            <Text
              style={{
                color: Color.slate,
                fontWeight: 'bold',
                fontSize: 20,
                marginRight: 8
              }}
            >
              計画
            </Text>
            <Text style={{ color: Color.lightSlate, fontSize: 15 }}>
              年利
              <Text
                style={{
                  color: Color.lightSlate,
                  fontWeight: 'bold',
                  fontSize: 15
                }}
              >
                {yearlyProfitAmount}
              </Text>
              万〜
            </Text>
          </View>
          <Paragraph
            style={{ fontSize: 17 }}
            containerStyle={{ marginBottom: 40 }}
          >
            {planContent}
          </Paragraph>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'baseline',
              marginBottom: 16
            }}
          >
            <Text
              style={{
                color: Color.pink,
                fontWeight: 'bold',
                fontSize: 40,
                marginRight: 8,
                lineHeight: 1
              }}
            >
              02
            </Text>
            <Text
              style={{
                color: Color.slate,
                fontWeight: 'bold',
                fontSize: 20,
                marginRight: 8
              }}
            >
              スキル
            </Text>
            <Text style={{ color: Color.lightSlate, fontSize: 15 }}>
              実務
              <Text
                style={{
                  color: Color.lightSlate,
                  fontWeight: 'bold',
                  fontSize: 15
                }}
              >
                {experiencedYears}
              </Text>
              年〜
            </Text>
          </View>
          <Paragraph
            style={{ fontSize: 17 }}
            containerStyle={{ marginBottom: 40 }}
          >
            {skillContent}
          </Paragraph>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'baseline',
              marginBottom: 16
            }}
          >
            <Text
              style={{
                color: Color.pink,
                fontWeight: 'bold',
                fontSize: 40,
                marginRight: 8,
                lineHeight: 1
              }}
            >
              03
            </Text>
            <Text
              style={{
                color: Color.slate,
                fontWeight: 'bold',
                fontSize: 20,
                marginRight: 8
              }}
            >
              資金
            </Text>
            <Text style={{ color: Color.lightSlate, fontSize: 15 }}>
              <Text
                style={{
                  color: Color.lightSlate,
                  fontWeight: 'bold',
                  fontSize: 15
                }}
              >
                {fundAmount}
              </Text>
              万〜
            </Text>
          </View>
          <Paragraph
            style={{ fontSize: 17 }}
            containerStyle={{ marginBottom: 40 }}
          >
            {fundContent}
          </Paragraph>
          <Headline size={2} style={{ fontSize: 20, marginBottom: 16 }}>
            {name}の資金調達事例
          </Headline>
          <Paragraph
            style={{ fontSize: 17 }}
            containerStyle={{ marginBottom: 40 }}
          >
            {name}
            がクラウドファンディングで事業資金を集め、成功している事例をご紹介します。
          </Paragraph>
          {successProjects.map((project, index) => {
            const minPrice = project.rewards.reduce((acc, reward) => {
              return Math.min(reward.price, acc);
            }, project.rewards[0].price);

            const maxPrice = project.rewards.reduce((acc, reward) => {
              return Math.min(reward.price, acc);
            }, project.rewards[0].price);

            return (
              <View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'baseline',
                    marginBottom: 16
                  }}
                >
                  <Text
                    style={{
                      color: Color.lightSlate,
                      fontWeight: 'bold',
                      fontSize: 32,
                      marginRight: 8,
                      lineHeight: 1
                    }}
                  >
                    {`0${index + 1}`}
                  </Text>
                  <Text
                    style={{
                      color: Color.slate,
                      fontWeight: 'bold',
                      fontSize: 20,
                      marginRight: 8
                    }}
                  >
                    {project.title}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: 16 }}>
                  <View style={{ marginRight: 32 }}>
                    <Text
                      style={{
                        color: Color.lightSlate,
                        fontSize: 15,
                        fontWeight: 'bold'
                      }}
                    >
                      コース単価
                    </Text>
                    <View
                      style={{ flexDirection: 'row', alignItems: 'baseline' }}
                    >
                      <Text
                        style={{
                          color: Color.pink,
                          fontSize: 20,
                          fontWeight: 'bold'
                        }}
                      >
                        {toNumeral(minPrice)}
                      </Text>
                      <Text
                        style={{
                          color: Color.slate,
                          fontSize: 13,
                          fontWeight: 'bold'
                        }}
                      >
                        円〜
                      </Text>
                      <Text
                        style={{
                          color: Color.pink,
                          fontSize: 20,
                          fontWeight: 'bold'
                        }}
                      >
                        {toNumeral(maxPrice)}
                      </Text>
                      <Text
                        style={{
                          color: Color.slate,
                          fontSize: 13,
                          fontWeight: 'bold'
                        }}
                      >
                        円
                      </Text>
                    </View>
                  </View>
                  <View style={{ marginRight: 32 }}>
                    <Text
                      style={{
                        color: Color.lightSlate,
                        fontSize: 15,
                        fontWeight: 'bold'
                      }}
                    >
                      支援者数
                    </Text>
                    <View
                      style={{ flexDirection: 'row', alignItems: 'baseline' }}
                    >
                      <Text
                        style={{
                          color: Color.pink,
                          fontSize: 20,
                          fontWeight: 'bold'
                        }}
                      >
                        {toNumeral(project.supporterCount)}
                      </Text>
                      <Text
                        style={{
                          color: Color.slate,
                          fontSize: 13,
                          fontWeight: 'bold'
                        }}
                      >
                        人以上
                      </Text>
                    </View>
                  </View>
                  <View>
                    <Text
                      style={{
                        color: Color.lightSlate,
                        fontSize: 15,
                        fontWeight: 'bold'
                      }}
                    >
                      達成金額
                    </Text>
                    <View
                      style={{ flexDirection: 'row', alignItems: 'baseline' }}
                    >
                      <Text
                        style={{
                          color: Color.pink,
                          fontSize: 20,
                          fontWeight: 'bold'
                        }}
                      >
                        {toNumeral(project.fundAmount)}
                      </Text>
                      <Text
                        style={{
                          color: Color.slate,
                          fontSize: 13,
                          fontWeight: 'bold'
                        }}
                      >
                        万円以上
                      </Text>
                    </View>
                  </View>
                </View>
                <Paragraph
                  style={{ fontSize: 17 }}
                  containerStyle={{ marginBottom: 40 }}
                >
                  {project.detail}
                </Paragraph>
                <Headline size={2} style={{ marginBottom: 16 }}>
                  コース一覧
                </Headline>
                {project.rewards.map(reward => {
                  return (
                    <View
                      style={{
                        border: `1px solid ${Color.lightGrey}`,
                        padding: 16
                      }}
                    >
                      <Headline
                        size={3}
                        style={{
                          fontSize: 20,
                          marginBottom: 8,
                          color: Color.lightSlate
                        }}
                      >
                        {reward.title}
                      </Headline>
                      <Paragraph
                        style={{ fontSize: 17 }}
                        containerStyle={{ marginBottom: 16 }}
                      >
                        {reward.detail}
                      </Paragraph>
                      <View style={{ flexDirection: 'row' }}>
                        <View style={{ marginRight: 32 }}>
                          <Text
                            style={{
                              color: Color.lightSlate,
                              fontSize: 15,
                              fontWeight: 'bold'
                            }}
                          >
                            単価
                          </Text>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'baseline'
                            }}
                          >
                            <Text
                              style={{
                                color: Color.pink,
                                fontSize: 20,
                                fontWeight: 'bold'
                              }}
                            >
                              {toNumeral(reward.price)}
                            </Text>
                            <Text
                              style={{
                                color: Color.slate,
                                fontSize: 13,
                                fontWeight: 'bold'
                              }}
                            >
                              円
                            </Text>
                          </View>
                        </View>
                        <View style={{ marginRight: 32 }}>
                          <Text
                            style={{
                              color: Color.lightSlate,
                              fontSize: 15,
                              fontWeight: 'bold'
                            }}
                          >
                            支援者数
                          </Text>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'baseline'
                            }}
                          >
                            <Text
                              style={{
                                color: Color.pink,
                                fontSize: 20,
                                fontWeight: 'bold'
                              }}
                            >
                              {toNumeral(reward.supporterCount)}
                            </Text>
                            <Text
                              style={{
                                color: Color.slate,
                                fontSize: 13,
                                fontWeight: 'bold'
                              }}
                            >
                              人以上
                            </Text>
                          </View>
                        </View>
                        <View>
                          <Text
                            style={{
                              color: Color.lightSlate,
                              fontSize: 15,
                              fontWeight: 'bold'
                            }}
                          >
                            合計
                          </Text>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'baseline'
                            }}
                          >
                            <Text
                              style={{
                                color: Color.pink,
                                fontSize: 20,
                                fontWeight: 'bold'
                              }}
                            >
                              {toNumeral(reward.price * reward.supporterCount)}
                            </Text>
                            <Text
                              style={{
                                color: Color.slate,
                                fontSize: 13,
                                fontWeight: 'bold'
                              }}
                            >
                              円以上
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  );
                })}
              </View>
            );
          })}
          <View style={{ alignItems: 'center', padding: '32px 0' }}>
            <ArrowStream color={Color.slate} />
          </View>
        </ContentContainer>
        <View
          style={{
            backgroundImage: `url(${require('../../../assets/img/sunrise-large.jpg')})`,
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom'
          }}
        >
          <View
            style={{
              alignItems: 'center',
              padding: '72px 0',
              marginBottom: 32
            }}
          >
            <Paragraph
              style={{
                color: Color.white,
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              あなたもクラウドファンディングで{'\n'}事業を始めてみませんか？
            </Paragraph>
            <Text
              style={{
                color: Color.white,
                fontSize: 24,
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 24
              }}
            >
              まずは事業計画を立ててみましょう！
            </Text>
          </View>
          <Container>
            <Row style={{ marginBottom: 72 }}>
              <Column xs={8} />
              <Column xs={4}>
                <View>
                  <Paragraph
                    style={{ color: Color.white, fontSize: 17 }}
                    containerStyle={{ marginBottom: 16 }}
                  >
                    あなたの事業をフォームに入力していくだけで、事業計画書が完成します。
                  </Paragraph>
                  <Text
                    style={{
                      color: Color.white,
                      fontSize: 17,
                      marginBottom: 32
                    }}
                  >
                    会員登録は不要です。入力後に
                    <br />
                    <Text
                      style={{
                        color: Color.white,
                        fontSize: 17,
                        fontWeight: 'bold',
                        textAlign: 'center'
                      }}
                    >
                      事業計画
                    </Text>
                    PDFがダウンロードできます。
                  </Text>
                  <Link to={`/proposal/new`}>
                    <Button>無料でかんたん事業計画</Button>
                  </Link>
                </View>
              </Column>
            </Row>
          </Container>
        </View>
        <FounderRecruitContainer />
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobDetail);
