import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import moment from 'moment';

import AmountCardBody from '../../../../components/Card/AmountCardBody';
import BusinessCategory from '../../../../models/BusinessCategory';
import CardPrimitive from '../../../../components/Card/CardPrimitive';
import Color from '../../../../constants/Color';
import Column from '../../../../components/Column';
import ContentContainer from '../../../../components/Page/ContentContainer';
import Deadline from '../../../../utils/Deadline';
import DreamCommentContent from '../../../../containers/dream/DreamCommentContent';
import DreamDesktopHeader from '../../../../components/Dream/DreamDesktopHeader';
import DreamProgressCard from '../../../../components/Dream/DreamProgressCard';
import DreamPurchaseContent from '../../../../containers/dream/DreamPurchaseContent';
import FooterContainer from '../../../../components/FooterContainer';
import FounderCard from '../../../../components/Founder/FounderCard';
import Headline from '../../../../components/Headline';
import Markdown from '../../../../components/Markdown';
import PageContaineredHeader from '../../../../containers/page/PageContaineredHeader';
import Paragraph from '../../../../components/Paragraph';
import ReportSecretSlider from '../../../../components/Dream/ReportSecretSlider';
import Text from '../../../../components/Text';
import View from '../../../../components/View';
import actions from '../../../../actions';

class DreamReportDetail extends React.Component {
  componentDidMount() {
    this.props.getDream(this.props.match.params.dreamId);

    this.props.getDreamReport(
      this.props.match.params.dreamId,
      this.props.match.params.reportId
    );
  }

  render() {
    const dream = this.props.dream.hash[this.props.match.params.dreamId];

    if (!dream) {
      return null;
    }

    const report = this.props.dreamReport.hash[
      this.props.match.params.reportId
    ];

    if (!report) {
      return null;
    }

    const businessCategory = BusinessCategory.getItem(dream.businessCategoryId);

    const deadline = new Deadline(dream.deadlineAt);

    return (
      <View>
        <PageContaineredHeader />
        <DreamDesktopHeader data={dream} businessCategory={businessCategory} />
        <ContentContainer>
          <Row>
            <Column xs={8}>
              <Headline
                size={2}
                style={{
                  color: Color.lightSlate,
                  marginTop: 40,
                  marginBottom: 16
                }}
              >
                {dream.title}
              </Headline>
              <Headline
                size={2}
                style={{
                  color: Color.slate,
                  fontSize: 32,
                  marginBottom: 24
                }}
              >
                {report.title}
              </Headline>
              <Text
                style={{
                  color: Color.lightSlate,
                  fontSize: 17,
                  fontWeight: 'bold',
                  marginBottom: 16
                }}
              >
                {moment(report.startAt).format('YYYY.MM.DD')}
              </Text>
              <Markdown large style={{ marginBottom: 16 }}>
                {report.content}
              </Markdown>
              <View style={{ marginBottom: 64 }}>
                {report.secrets
                  .filter(secret => secret.isExist)
                  .map(secret => {
                    return (
                      <View key={`secret-${secret.id}`}>
                        {!secret.isLocked && (
                          <Text
                            style={{
                              color: Color.lightSlate,
                              fontSize: 15,
                              fontWeight: 'bold',
                              marginBottom: 8
                            }}
                          >
                            ここから{secret.gift.title}
                          </Text>
                        )}
                        {secret.images.length > 0 && (
                          <ReportSecretSlider
                            data={secret.images}
                            style={{
                              marginBottom: secret.images.length > 1 ? 32 : 8
                            }}
                          />
                        )}
                        {secret.isLocked ? (
                          <Paragraph
                            onerow
                            style={{ color: Color.slate, fontSize: 13 }}
                          >
                            {secret.gift.title}({secret.gift.price}
                            )の特典です。
                          </Paragraph>
                        ) : (
                          <Markdown large>{secret.content}</Markdown>
                        )}
                      </View>
                    );
                  })}
              </View>
              <DreamCommentContent data={dream} />
            </Column>
            <Column xs={4}>
              <Link to={`/founder/${dream.founder.id}`}>
                <FounderCard
                  data={dream.founder}
                  style={{ marginTop: -120, marginBottom: 24 }}
                />
              </Link>
              <DreamProgressCard data={dream} style={{ marginBottom: 24 }} />
              <View style={{ flexDirection: 'row', marginBottom: 40 }}>
                <CardPrimitive style={{ flex: 1, marginRight: 16 }}>
                  <AmountCardBody
                    style={{ padding: '16px 0 16px 0' }}
                    title="支援者"
                    amount={dream.supporterCount}
                    unit="人"
                  />
                </CardPrimitive>
                <CardPrimitive style={{ flex: 1 }}>
                  <AmountCardBody
                    style={{ padding: '16px 0 16px 0' }}
                    title="募集締切まで"
                    amount={deadline.leastAmount}
                    unit={deadline.leastUnit}
                  />
                </CardPrimitive>
              </View>
              <DreamPurchaseContent data={dream} style={{ marginBottom: 24 }} />
            </Column>
          </Row>
        </ContentContainer>

        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamReportDetail);
