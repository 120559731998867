import React from 'react';

import {
  MARKETING_REALITY_VALUES_LABELS,
  MARKETING_REALITY_VALUES_VALIDATION_RULE,
  MARKETING_BASIC_INFO_LABELS,
  MARKETING_BASIC_INFO_VALIDATION_RULE,
  MARKETING_COMMUNICATION_LABELS,
  MARKETING_COMMUNICATION_VALIDATION_RULE,
  MARKETING_MARKET_LABELS,
  MARKETING_MARKET_VALIDATION_RULE,
  MARKETING_IDEAL_VALUES_LABELS,
  MARKETING_IDEAL_VALUES_VALIDATION_RULE
} from '../../constants/Validations';
import MarketingBasicInfoFormField from '../../containers/form/marketing/MarketingBasicInfoFormField';
import MarketingCommunicationFormField from '../../containers/form/marketing/MarketingCommunicationFormField';
import MarketingIdealValuesFormField from '../../containers/form/marketing/MarketingIdealValuesFormField';
import MarketingMarketFormField from '../../containers/form/marketing/MarketingMarketFormField';
import MarketingRealityValuesFormField from '../../containers/form/marketing/MarketingRealityValuesFormField';
import ProposalInputSectionCard from '../Proposal/ProposalInputSectionCard';
import View from '../View';

export default class SchemeFormField extends React.Component {
  render() {
    const { params, onChangeParams, style = {} } = this.props;

    return (
      <View style={style}>
        <ProposalInputSectionCard
          title="基本情報"
          number="01"
          description="あなたの事業の基盤となる情報をまとめましょう。"
          labels={MARKETING_BASIC_INFO_LABELS}
          rule={MARKETING_BASIC_INFO_VALIDATION_RULE}
          properties={params.marketing.basicInfo}
          style={{ marginBottom: 24 }}
        >
          <MarketingBasicInfoFormField
            params={params.marketing.basicInfo}
            onChangeParams={state => {
              onChangeParams({
                marketing: {
                  ...params.marketing,
                  basicInfo: { ...params.marketing.basicInfo, ...state }
                }
              });
            }}
            rules={MARKETING_BASIC_INFO_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="市場の定義"
          number="02"
          description="誰に語りかけるのか。誰に対して話しかけているのかを明確にしましょう。"
          labels={MARKETING_MARKET_LABELS}
          rule={MARKETING_MARKET_VALIDATION_RULE}
          properties={params.marketing.market}
          style={{ marginBottom: 24 }}
        >
          <MarketingMarketFormField
            params={params.marketing.market}
            onChangeParams={state =>
              onChangeParams({
                marketing: {
                  ...params.marketing,
                  market: {
                    ...params.marketing.market,
                    ...state
                  }
                }
              })
            }
            rules={MARKETING_MARKET_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="価値の定義 - あるべき姿"
          number="03"
          description="どういう価値を提供するのか、価値を定義しましょう。企業理念ではなく、あくまでお客さんにとっての価値をベースに考えます。"
          labels={MARKETING_IDEAL_VALUES_LABELS}
          rule={MARKETING_IDEAL_VALUES_VALIDATION_RULE}
          properties={params.marketing.idealValues}
          style={{ marginBottom: 24 }}
        >
          <MarketingIdealValuesFormField
            params={params.marketing.idealValues}
            onChangeParams={state =>
              onChangeParams({
                marketing: {
                  ...params.marketing,
                  idealValues: {
                    ...params.marketing.idealValues,
                    ...state
                  }
                }
              })
            }
            rules={MARKETING_IDEAL_VALUES_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="価値の創造 - 語るべき今"
          number="04"
          description="定義した価値を、実際の商品や広告コンセプトでどのようにして実現していくのかを決めましょう。重要度の高い価値から、具体的な商品やサポートの内容に落とし込みます。"
          labels={MARKETING_REALITY_VALUES_LABELS}
          rule={MARKETING_REALITY_VALUES_VALIDATION_RULE}
          properties={params.marketing.realityValues}
          style={{ marginBottom: 24 }}
        >
          <MarketingRealityValuesFormField
            params={params.marketing.realityValues}
            onChangeParams={state =>
              onChangeParams({
                marketing: {
                  ...params.marketing,
                  realityValues: {
                    ...params.marketing.realityValues,
                    ...state
                  }
                }
              })
            }
            rules={MARKETING_REALITY_VALUES_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
        <ProposalInputSectionCard
          title="価値の伝達"
          number="05"
          description="作り出した価値を実際にどのようにして伝えていくかを決めましょう。"
          labels={MARKETING_COMMUNICATION_LABELS}
          rule={MARKETING_COMMUNICATION_VALIDATION_RULE}
          properties={params.marketing.communication}
          style={{ marginBottom: 24 }}
        >
          <MarketingCommunicationFormField
            params={params.marketing.communication}
            onChangeParams={state =>
              onChangeParams({
                marketing: {
                  ...params.marketing,
                  communication: { ...params.marketing.communication, ...state }
                }
              })
            }
            rules={MARKETING_COMMUNICATION_VALIDATION_RULE}
          />
        </ProposalInputSectionCard>
      </View>
    );
  }
}
