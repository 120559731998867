import React from 'react';

import Color from '../../constants/Color';
import Headline from '../Headline';
import MaterialIcon from '../../utils/MaterialIcon';
import View from '../View';

export default class AdditionalFormCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: true
    };
  }

  render() {
    const { scheme, data, children } = this.props;

    const label = data[scheme.labelKey] || scheme.label;

    return (
      <View
        style={{
          borderRadius: 8,
          border: `1px solid ${Color.lightSlate}`,
          marginBottom: 16
        }}
      >
        <View
          onClick={() =>
            this.setState({ isCollapsed: !this.state.isCollapsed })
          }
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 16,
            borderBottom: !this.state.isCollapsed
              ? `1px solid ${Color.lightSlate}`
              : ''
          }}
        >
          <Headline size={3} style={{ color: Color.lightSlate }}>
            {label}
          </Headline>
          <MaterialIcon
            icon={
              !this.state.isCollapsed
                ? 'keyboard_arrow_up'
                : 'keyboard_arrow_down'
            }
            color={Color.lightSlate}
            size={17}
          />
        </View>
        {!this.state.isCollapsed && children}
      </View>
    );
  }
}
