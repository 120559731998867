import { request } from '../utils/Network';
import { uploadImage } from '../utils/Firebase';
import * as types from '../constants/ActionTypes';

// get marketing
function fetchGetMarketing(id) {
  return {
    type: types.FETCH_GET_MARKETING,
    state: {
      id
    }
  };
}

function fetchGetMarketingSuccess(id, params) {
  return {
    type: types.FETCH_GET_MARKETING_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetMarketingFailed(error) {
  return {
    type: types.FETCH_GET_MARKETING_FAILED,
    error
  };
}

export function recoverGetMarketing() {
  return {
    type: types.FETCH_GET_MARKETING_RECOVER
  };
}

export function getMarketing(id, password) {
  return dispatch => {
    dispatch(fetchGetMarketing(id));

    return request(dispatch, 'POST', '/getMarketing', {
      params: {
        id,
        password
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchGetMarketingFailed(error));
      }

      return dispatch(
        fetchGetMarketingSuccess(response.id, response.marketing)
      );
    });
  };
}

// create marketing
function fetchCreateMarketing() {
  return {
    type: types.FETCH_CREATE_MARKETING
  };
}

function fetchCreateMarketingSuccess(id, params) {
  return {
    type: types.FETCH_CREATE_MARKETING_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateMarketingFailed(error) {
  return {
    type: types.FETCH_CREATE_MARKETING_FAILED,
    error
  };
}

export function recoverCreateMarketing() {
  return {
    type: types.FETCH_CREATE_MARKETING_RECOVER
  };
}

export function createMarketing(
  { basicInfo: { profileImage, ...restBasicInfo }, ...rest },
  viewPassword,
  editPassword
) {
  return dispatch => {
    dispatch(fetchCreateMarketing());

    let marketingData = {};

    return uploadImage('/marketings/profileImages', profileImage)
      .then(path => {
        marketingData = {
          ...rest,
          basicInfo: {
            ...restBasicInfo,
            profileImage: { uri: path }
          }
        };

        return request(dispatch, 'POST', '/createMarketing', {
          params: {
            marketing: marketingData,
            viewPassword,
            editPassword
          }
        });
      })
      .then(({ response, error }) => {
        if (error) {
          return dispatch(fetchCreateMarketingFailed(error));
        }

        return dispatch(
          fetchCreateMarketingSuccess(response.id, marketingData)
        );
      });
  };
}

// update marketing
function fetchUpdateMarketing() {
  return {
    type: types.FETCH_UPDATE_MARKETING
  };
}

function fetchUpdateMarketingSuccess(id, params) {
  return {
    type: types.FETCH_UPDATE_MARKETING_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchUpdateMarketingFailed(error) {
  return {
    type: types.FETCH_UPDATE_MARKETING_FAILED,
    error
  };
}

export function recoverUpdateMarketing() {
  return {
    type: types.FETCH_UPDATE_MARKETING_RECOVER
  };
}

export function updateMarketing(
  id,
  { basicInfo: { profileImage, ...restBasicInfo }, ...rest },
  viewPassword,
  editPassword
) {
  return dispatch => {
    dispatch(fetchUpdateMarketing());

    let marketingData = {};

    return uploadImage('/marketings/profileImages', profileImage)
      .then(path => {
        marketingData = {
          ...rest,
          basicInfo: {
            ...restBasicInfo,
            profileImage: { uri: path }
          }
        };

        return request(dispatch, 'POST', '/updateMarketing', {
          params: {
            id,
            marketing: marketingData,
            viewPassword,
            editPassword
          }
        });
      })
      .then(({ response, error }) => {
        if (error) {
          return dispatch(fetchUpdateMarketingFailed(error));
        }

        return dispatch(
          fetchUpdateMarketingSuccess(response.id, marketingData)
        );
      });
  };
}
