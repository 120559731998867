import { Link } from 'react-router-dom';
import { Row } from 'react-grid-system';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../components/Button';
import CardPrimitive from '../../../components/Card/CardPrimitive';
import Color from '../../../constants/Color';
import Column from '../../../components/Column';
import ContentContainer from '../../../components/Page/ContentContainer';
import DreamDesktopItem from '../../../components/Dream/DreamDesktopItem';
import EdgedUserIcon from '../../../components/Icons/EdgedUserIcon';
import FooterContainer from '../../../components/FooterContainer';
import FounderProperty from '../../../components/Founder/FounderProperty';
import Headline from '../../../components/Headline';
import Markdown from '../../../components/Markdown';
import PageHeader from '../../../containers/page/PageContaineredHeader';
import Paragraph from '../../../components/Paragraph';
import PatronizeModal from '../../../containers/patron/PatronizeModal';
import RegisterModal from '../../../containers/RegisterModal';
import Text from '../../../components/Text';
import View from '../../../components/View';
import actions from '../../../actions';

class FounderDetail extends React.Component {
  state = {
    isOpenPatronizeModal: false,
    isOpenRegisterModal: false
  };

  componentDidMount() {
    this.props.getDreams({ reset: true });

    this.props.getFounder(this.props.match.params.id);
  }

  render() {
    const founder = this.props.founder.hash[this.props.match.params.id];

    if (!founder) {
      return null;
    }

    const dreams = this.props.dream.list
      .map(id => this.props.dream.hash[id])
      .filter(dream => dream.founder.id === this.props.match.params.id);

    return (
      <View>
        <PageHeader />
        <View
          style={{
            height: 317,
            backgroundImage: `url(${founder.desktopHeaderImage.uri})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: '98px 0'
          }}
        />
        <ContentContainer>
          <Row>
            <Column xs={8}>
              <View style={{ flexDirection: 'row' }}>
                <EdgedUserIcon
                  source={founder.iconImage}
                  style={{ marginTop: -80 }}
                />
                <View style={{ padding: 32 }}>
                  <Text
                    style={{
                      color: Color.slate,
                      fontWeight: 'bold',
                      fontSize: 32
                    }}
                  >
                    {founder.screenName}
                  </Text>
                </View>
              </View>
              <FounderProperty data={founder} style={{ marginBottom: 24 }} />
              <Paragraph style={{ color: Color.slate, fontSize: 13 }}>
                {founder.profile}
              </Paragraph>
            </Column>
            <Column xs={4}>
              <CardPrimitive style={{ marginTop: -40, padding: 16 }}>
                <Headline style={{ marginBottom: 16 }}>パトロン特典</Headline>
                <Markdown style={{ marginBottom: 24 }}>
                  {founder.patronGiftText}
                </Markdown>
                <Button
                  onClick={() => {
                    if (this.props.auth.uid) {
                      this.setState({ isOpenPatronizeModal: true });
                    } else {
                      this.setState({ isOpenRegisterModal: true });
                    }
                  }}
                >
                  パトロンになる
                </Button>
              </CardPrimitive>
            </Column>
          </Row>
          <Headline
            style={{
              fontSize: 32,
              textAlign: 'center',
              marginTop: 64,
              marginBottom: 56
            }}
          >
            PROJECTS
          </Headline>
          <Row style={{ paddingBottom: 72 }}>
            {dreams.map(exemplary => {
              return (
                <Column style={{ alignItems: 'center' }}>
                  <Link to={`/dream/${exemplary.id}`}>
                    <DreamDesktopItem data={exemplary} />
                  </Link>
                </Column>
              );
            })}
          </Row>
        </ContentContainer>
        <RegisterModal
          visible={this.state.isOpenRegisterModal}
          onClose={() => this.setState({ isOpenRegisterModal: false })}
          content={
            <View style={{ marginBottom: 8 }}>
              <Headline size={3} style={{ marginBottom: 16 }}>
                パトロンになるためには
              </Headline>
              <Paragraph>
                パトロンになるためには、新規登録/ログインをする必要があります。
              </Paragraph>
            </View>
          }
        />
        <PatronizeModal
          founderId={founder.id}
          visible={this.state.isOpenPatronizeModal}
          onClose={() => this.setState({ isOpenPatronizeModal: false })}
        />
        <FooterContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FounderDetail);
