import { request } from '../utils/Network';
import { uploadImage } from '../utils/Firebase';
import * as types from '../constants/ActionTypes';

// get scheme
function fetchGetScheme(id) {
  return {
    type: types.FETCH_GET_SCHEME,
    state: {
      id
    }
  };
}

function fetchGetSchemeSuccess(id, params) {
  return {
    type: types.FETCH_GET_SCHEME_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchGetSchemeFailed(error) {
  return {
    type: types.FETCH_GET_SCHEME_FAILED,
    error
  };
}

export function recoverGetScheme() {
  return {
    type: types.FETCH_GET_SCHEME_RECOVER
  };
}

export function getScheme(id, password) {
  return dispatch => {
    dispatch(fetchGetScheme(id));

    return request(dispatch, 'POST', '/getScheme', {
      params: {
        id,
        password
      }
    }).then(({ response, error }) => {
      if (error) {
        return dispatch(fetchGetSchemeFailed(error));
      }

      return dispatch(fetchGetSchemeSuccess(response.id, response.scheme));
    });
  };
}

// create scheme
function fetchCreateScheme() {
  return {
    type: types.FETCH_CREATE_SCHEME
  };
}

function fetchCreateSchemeSuccess(id, params) {
  return {
    type: types.FETCH_CREATE_SCHEME_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchCreateSchemeFailed(error) {
  return {
    type: types.FETCH_CREATE_SCHEME_FAILED,
    error
  };
}

export function recoverCreateScheme() {
  return {
    type: types.FETCH_CREATE_SCHEME_RECOVER
  };
}

export function createScheme(
  { basicInfo: { profileImage, ...restBasicInfo }, ...rest },
  viewPassword,
  editPassword
) {
  return dispatch => {
    dispatch(fetchCreateScheme());

    let schemeData = {};

    return uploadImage('/schemes/profileImages', profileImage)
      .then(path => {
        schemeData = {
          ...rest,
          basicInfo: {
            ...restBasicInfo,
            profileImage: { uri: path }
          }
        };

        return request(dispatch, 'POST', '/createScheme', {
          params: {
            scheme: schemeData,
            viewPassword,
            editPassword
          }
        });
      })
      .then(({ response, error }) => {
        if (error) {
          return dispatch(fetchCreateSchemeFailed(error));
        }

        return dispatch(fetchCreateSchemeSuccess(response.id, schemeData));
      });
  };
}

// update scheme
function fetchUpdateScheme() {
  return {
    type: types.FETCH_UPDATE_SCHEME
  };
}

function fetchUpdateSchemeSuccess(id, params) {
  return {
    type: types.FETCH_UPDATE_SCHEME_SUCCESS,
    state: {
      id,
      params
    }
  };
}

function fetchUpdateSchemeFailed(error) {
  return {
    type: types.FETCH_UPDATE_SCHEME_FAILED,
    error
  };
}

export function recoverUpdateScheme() {
  return {
    type: types.FETCH_UPDATE_SCHEME_RECOVER
  };
}

export function updateScheme(
  id,
  { basicInfo: { profileImage, ...restBasicInfo }, ...rest },
  viewPassword,
  editPassword
) {
  return dispatch => {
    dispatch(fetchUpdateScheme());

    let schemeData = {};

    return uploadImage('/schemes/profileImages', profileImage)
      .then(path => {
        schemeData = {
          ...rest,
          basicInfo: {
            ...restBasicInfo,
            profileImage: { uri: path }
          }
        };

        return request(dispatch, 'POST', '/updateScheme', {
          params: {
            id,
            scheme: schemeData,
            viewPassword,
            editPassword
          }
        });
      })
      .then(({ response, error }) => {
        if (error) {
          return dispatch(fetchUpdateSchemeFailed(error));
        }

        return dispatch(fetchUpdateSchemeSuccess(response.id, schemeData));
      });
  };
}
