import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Deadline from '../../utils/Deadline';
import LoadMoreList from '../../components/List/LoadMoreList';
import ProductItem from '../../components/Dream/ProductItem';
import Text from '../../components/Text';
import View from '../../components/View';
import actions from '../../actions';

class ProductList extends React.Component {
  render() {
    const { renderSubmit = () => {}, style = {} } = this.props;

    const dream = this.props.dream.hash[this.props.dreamId];

    if (!dream || this.props.dream.isGetLoading) {
      return null;
    }

    if (dream.products.length < 1) {
      return (
        <View style={{ padding: '0 24px 24px 0' }}>
          <Text>商品がありません。</Text>
        </View>
      );
    }

    const deadline = new Deadline(dream.deadlineAt);

    return (
      <View
        style={{
          ...style
        }}
      >
        <LoadMoreList
          data={dream.products}
          renderItem={({ item, index }) => {
            return (
              <ProductItem
                key={`product-${index}`}
                data={item}
                submit={!deadline.isFinished}
                style={{ marginBottom: 16 }}
                renderSubmit={element => renderSubmit({ element, item })}
              />
            );
          }}
          isLoading={this.props.dream.isGetLoading}
        />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductList);
