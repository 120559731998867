import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../components/Button';
import Color from '../constants/Color';
import Container from '../components/Container';
import FounderRecruitTargetItem from './recruit/FounderRecruitTargetItem';
import Headline from '../components/Headline';
import Paragraph from '../components/Paragraph';
import View from '../components/View';
import actions from '../actions';

class FoudnerRecruitContainer extends React.Component {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    this.props.getJobs();
  }

  render() {
    const { style = {} } = this.props;

    return (
      <View style={style}>
        <View
          style={{
            backgroundImage: `url(${require('../assets/img/mountain-large.jpg')})`,
            backgroundSize: 'cover',
            backgroundPositionX: 'center',
            backgroundPositionY: 'bottom'
          }}
        >
          <Container>
            <View style={{ padding: '48px 16px 0 16px' }}>
              <Headline
                size={2}
                style={{
                  fontSize: 24,
                  color: Color.white,
                  textShadow: `${Color.black} 0 2px 4px`,
                  marginBottom: 24
                }}
              >
                ファウンダー募集中
              </Headline>
              <Paragraph
                style={{
                  fontSize: 17,
                  color: Color.white,
                  textShadow: `${Color.black} 0 2px 4px`,
                  marginBottom: 48
                }}
              >
                VISIONSは、夢を叶えたい「ファウンダー（創業者）」を探しています。
              </Paragraph>
            </View>
            <View
              style={{
                flexDirection: 'row',
                overflow: 'scroll',
                paddingBottom: 8,
                marginBottom: 48
              }}
            >
              {this.props.job.list
                .map(id => this.props.job.hash[id])
                .map(job => {
                  return (
                    <Link key={job.id} to={`/job/${job.id}`}>
                      <FounderRecruitTargetItem
                        size={96}
                        fontSize={17}
                        source={job.iconImage}
                        label={job.name}
                        style={{ paddingLeft: 24, marginRight: 8 }}
                      />
                    </Link>
                  );
                })}
            </View>
          </Container>
        </View>
        <View
          style={{
            padding: '32px 16px 0 16px',
            backgroundColor: Color.navyBlack
          }}
        >
          <Container>
            <View style={{ alignItems: 'center' }}>
              <Paragraph
                style={{ color: Color.white, fontSize: 15 }}
                containerStyle={{ marginBottom: 16 }}
              >
                ファウンダーは、TwitterのDMにて募集しています。
                {'\n'}
                あなたのご応募をお待ちしております。
              </Paragraph>
              <a
                rel="noopener noreferrer"
                href="https://twitter.com/visions_fund"
                target="_blank"
              >
                <Button variant="flat" style={{ width: 240 }}>
                  Twitterへ
                </Button>
              </a>
            </View>
          </Container>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FoudnerRecruitContainer);
