import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import React from 'react';

import Button from '../../../../components/Button';
import Headline from '../../../../components/Headline';
import PageHeader from '../../../../containers/page/PageHeader';
import Paragraph from '../../../../components/Paragraph';
import Stepper from '../../../../components/Stepper';
import View from '../../../../components/View';
import actions from '../../../../actions';

class DreamProductComplete extends React.Component {
  render() {
    return (
      <View>
        <PageHeader />
        <Stepper
          steps={['コースの選択', '必要情報の入力', '購入', '完了']}
          activeIndex={3}
          style={{ padding: '24px 8px 16px 8px' }}
        />
        <View style={{ padding: '0 24px' }}>
          <Headline style={{ marginBottom: 16 }}>4. 完了</Headline>
          <Paragraph style={{ marginBottom: 24 }}>
            お申込みが完了いたしました。メールをご確認ください。
          </Paragraph>
        </View>
        <View style={{ padding: '0 16px', marginBottom: 32 }}>
          <Link to="/">
            <Button
              variant="flat"
              onClick={() => this.setState({ isOpenPurchaseModal: true })}
            >
              TOPへ戻る
            </Button>
          </Link>
        </View>
      </View>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DreamProductComplete);
