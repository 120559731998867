import React from 'react';

import Color from '../../constants/Color';
import FileInput from './FileInput';
import Image from '../Image';
import Label from './Label';
import MaterialIcon from '../../utils/MaterialIcon';
import Text from '../Text';
import View from '../View';

export default class ImageFormField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isStartInput: false
    };
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ isStartInput: true });
    }

    return true;
  }

  render() {
    const {
      label,
      labelWidth = 70,
      value,
      direction = 'row',
      verification = false,
      errorMessage,
      helpText,
      onChangeImage = () => {},
      staticControl,
      style = {},
      ...rest
    } = this.props;

    const containerStyle =
      direction === 'row'
        ? {
            flexDirection: 'row',
            alignItems: 'center'
          }
        : {
            flexDirection: 'column'
          };

    const verifyColor = !Boolean(errorMessage) ? Color.green : Color.warmGrey;

    return (
      <View style={{ ...style }}>
        <View style={containerStyle}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: direction === 'row' ? 0 : 8
            }}
          >
            <Label
              style={{
                minWidth: labelWidth
              }}
            >
              {label}
            </Label>
            {!staticControl &&
              verification && (
                <View style={{ marginLeft: 4 }}>
                  <MaterialIcon
                    icon="check_circle"
                    size={20}
                    color={verifyColor}
                  />
                </View>
              )}
          </View>
          <View style={{ flex: direction === 'row' ? 1 : 0 }}>
            {staticControl ? (
              <View>
                <Image
                  source={value}
                  alt="..."
                  style={{ width: 160, height: 160 }}
                />
              </View>
            ) : (
              <View>
                <Image
                  source={value}
                  alt="..."
                  style={{ width: 160, height: 160, marginBottom: 16 }}
                />
                <FileInput
                  onChangeFile={({ file, data }) => {
                    onChangeImage && onChangeImage({ file, data });
                  }}
                  ref="fileInput"
                  {...rest}
                />
              </View>
            )}
          </View>
        </View>
        {this.state.isStartInput &&
          errorMessage && (
            <Text
              style={{
                marginTop: 4,
                fontSize: 11,
                color: errorMessage ? Color.red : Color.lightGrey,
                paddingLeft: direction === 'row' ? labelWidth : 0
              }}
            >
              {this.state.isStartInput && errorMessage}
            </Text>
          )}
        {helpText && (
          <Text
            style={{
              marginTop: 4,
              fontSize: 11,
              color: Color.grey,
              paddingLeft: direction === 'row' ? labelWidth : 0
            }}
          >
            {helpText}
          </Text>
        )}
      </View>
    );
  }
}
