import React from 'react';

import Color from '../constants/Color';
import Text from './Text';
import View from './View';

export default function Stepper({ steps = [], activeIndex = 0, style = {} }) {
  return (
    <View style={{ flexDirection: 'row', ...style }}>
      {steps.map((step, index) => {
        const isActive = index === activeIndex;

        const isLast = index === steps.length - 1;

        return (
          <View
            key={`step-${index}`}
            style={{
              borderRadius: 16,
              backgroundColor: isActive ? Color.lightSlate : Color.transparent,
              padding: '4px 8px',
              marginRight: isLast ? 0 : 4
            }}
          >
            <Text
              style={{
                fontSize: 10,
                fontWeight: 'bold',
                color: isActive ? Color.white : Color.lightSlate
              }}
            >
              {index + 1}. {step}
            </Text>
          </View>
        );
      })}
    </View>
  );
}
